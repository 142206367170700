import {forwardRef} from "react";
import {Radio} from "antd";
import type {AppRadioProps} from "./Radio.types";

export const AppRadio = forwardRef<HTMLInputElement, AppRadioProps>(
  (props, ref) => {
    const {type = "radio", ...rest} = props;

    const Component = type === "radio" ? Radio : Radio.Button;

    return (
      <Component {...rest} ref={ref}/>
    );
  }
);