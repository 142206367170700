import React from "react";
import {AppFlex} from "shared/UI/UIKit/Flex";
import {AppText} from "shared/UI/UIKit/Text";
import {AppButton, AppIconButton} from "shared/UI/UIKit/Button";
import {AppReloadIcon} from "shared/UI/UIKit/icons/Reload";
import {useAppSelector} from "services/redux/hooks/use-selector";
import {selectCreateMainProgramState} from "features/create-main-program/redux/selectors";
import {useAppDispatch} from "services/redux/hooks/use-dispatch";
import {reloadWorkProgramsBySubjectIdAction} from "features/create-main-program/redux/actions";
import {usePayloadedModal} from "shared/UI/UIKit/Modal";
import {AppPlusIcon} from "shared/UI/UIKit/icons/Plus";
import {AppDeleteIcon} from "shared/UI/UIKit/icons/Delete";
import {removeSubjectFromSelectedCurriculum} from "features/create-main-program/redux/slice";
import type {Curriculum, LocalStorageSubject, MainProgramItem, Subject} from "services/api";
import {SubjectItem} from "../SubjectItem";
import {EditSubjectModal} from "./EditSubjectModal";
import styles from "./styles.module.scss";

type GroupedSubjectListProps = {
  subjects: Subject[];
  curriculum: Curriculum;
  title: string;
  required?: boolean;
  addMainProgramItemToStateByFieldName: (fieldName: string, item: MainProgramItem) => void;
  showCreateNewElementModal?: () => void;
  showCreateRequiredNewElementModal?: (id: number, indexToInsert: number) => void;
}

export const GroupedSubjectsList = (props: GroupedSubjectListProps) => {
  const {selectedEducationLevel} = useAppSelector(selectCreateMainProgramState);
  const dispatch = useAppDispatch();
  const {state, methods} = usePayloadedModal<{ subject: Subject }>();

  const createWorkProgram = (e: React.MouseEvent, id: number) => {
    e.preventDefault();
    const url = `${process.env.REACT_APP_CONSTRUCTOR_URL}/work-programs?educationLevel=${selectedEducationLevel}&subjectId=${id}`;
    window.open(url, "_blank");
  };

  const reloadWorkPrograms = (e: React.MouseEvent, id: number) => {
    e.preventDefault();
    dispatch(reloadWorkProgramsBySubjectIdAction(id));
  };

  const editSubjectName = (e: React.MouseEvent, subject: Subject) => {
    e.preventDefault();
    methods.show({subject});
  };

  const showCreateNewElementModal = (e: React.MouseEvent) => {
    e.preventDefault();
    if (props.showCreateNewElementModal) {
      props.showCreateNewElementModal();
    }
  };

  const showCreateRequiredNewElementModal = (e: React.MouseEvent, id: number, indexToInsert: number) => {
    e.preventDefault();
    if (props.showCreateRequiredNewElementModal) {
      props.showCreateRequiredNewElementModal(id, indexToInsert);
    }
  };

  const removeElement = (e: React.MouseEvent, subject: Subject) => {
    e.preventDefault();
    dispatch(removeSubjectFromSelectedCurriculum({
      curriculumId: props.curriculum.id,
      subject,
    }));

    const currentWizard = localStorage.getItem("WIZARD") || "{}";
    const parserWizard = JSON.parse(currentWizard);
    const curriculum = parserWizard.curriculums.find((item: Curriculum) => item.id === props.curriculum.id);
    const requiredElement = curriculum.subjects.find((item: Subject) => item.uniqueId === subject!.uniqueId);
    const extraElement = parserWizard.extraSubjects.find((item: LocalStorageSubject) => item.subject.uniqueId === subject!.uniqueId);

    let nextWizard;
    if (requiredElement) {
      nextWizard = {
        ...parserWizard,
        curriculums: parserWizard.curriculums.map((item: Curriculum) => {
          if (item.id === props.curriculum.id) {
            return {
              ...item,
              subjects: item.subjects.filter((sub => sub.uniqueId !== subject.uniqueId))
            };
          } else {
            return item;
          }
        }),
      };
    } else if (extraElement) {
      nextWizard = {
        ...parserWizard,
        extraSubjects: parserWizard.extraSubjects.filter(((extraSub: LocalStorageSubject) => extraSub.subject.uniqueId !== subject.uniqueId))
      };
    }

    localStorage.setItem("WIZARD", JSON.stringify(nextWizard));
  };

  return (
    <AppFlex>
      <AppText underline weight={500} size={20}>
        {props.title}
      </AppText>
      { !props.required && (
        <AppButton className={styles.createButton}
                   onClick={showCreateNewElementModal}>
          Добавить элемент
        </AppButton>
      )}
      {
        props.subjects.map((item, index) => {
          return (
            <AppFlex key={index} direction="row">
              <SubjectItem subject={item}
                           editSubjectName={editSubjectName}
                           curriculum={props.curriculum}
                           required={props.required}
                           index={index}
                           addMainProgramItemToStateByFieldName={props.addMainProgramItemToStateByFieldName}/>
              <AppFlex direction="row" className={styles.buttons}>
                {
                  (item.id === 24 || item.id === 25) && !item.canRemove && (
                    <AppIconButton type="primary" onClick={(e) => showCreateRequiredNewElementModal(e, item.id, index)}>
                      <AppPlusIcon/>
                    </AppIconButton>
                  )
                }
                {
                  item.canRemove && (
                    <AppIconButton type="primary" onClick={(e) => removeElement(e, item)}>
                      <AppDeleteIcon className={styles.removeIcon}/>
                    </AppIconButton>
                  )
                }
                {
                  !item.canRemove && item.id !== 24 && item.id !== 25 && (
                    <div className={styles.gap}/>
                  )
                }
                <AppIconButton type="primary" onClick={(e) => reloadWorkPrograms(e, item.id)}>
                  <AppReloadIcon/>
                </AppIconButton>
                <AppButton onClick={(e) => createWorkProgram(e, item.id)}>Создать</AppButton>
              </AppFlex>
            </AppFlex>
          );
        })
      }
      <EditSubjectModal isOpen={state.visible}
                        closeModal={methods.hide}
                        curriculum={props.curriculum}
                        selectedSubject={state.payload?.subject}/>
    </AppFlex>
  );
};