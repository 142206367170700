import {AppForm, AppFormItem} from "shared/UI/UIKit/Form";
import {AppTextArea} from "shared/UI/UIKit/TextArea";
import type {WidgetValueFormProps} from "./types";

export const WidgetValueForm = (props: WidgetValueFormProps) => {
  return (
    <AppForm form={props.form}>
      <AppFormItem label="Контент виджета" name="data-value">
        <AppTextArea placeholder="Введите данные"/>
      </AppFormItem>
    </AppForm>
  );
};
