import {
  closestCenter,
  DndContext,
  MeasuringStrategy, MouseSensor,
  useSensor,
  useSensors
} from "@dnd-kit/core";
import {restrictToVerticalAxis} from "@dnd-kit/modifiers";
import type {ProgramTemplateBlockTable} from "services/api";
import type {PropsWithChildren} from "shared/types";
import type {DragEndEvent} from "@dnd-kit/core";

type TableDragAndDropProviderProps = PropsWithChildren & {
  table: ProgramTemplateBlockTable;
  onRowDragEnd: (event: DragEndEvent) => void;
}

export const TableDragAndDropProvider = (props: TableDragAndDropProviderProps) => {
  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 0.1,
      },
    })
  );

  return (
    <DndContext sensors={sensors} modifiers={[restrictToVerticalAxis]}
                collisionDetection={closestCenter}
                onDragEnd={props.onRowDragEnd}
                measuring={{
                  droppable: {
                    strategy: MeasuringStrategy.Always,
                  }
                }}>
      {props.children}
    </DndContext>
  );
};