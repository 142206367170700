import {makeArray} from "shared/helpers/utils/make-array";
import type {CSSProperties} from "react";
import type {
  TableConstructorColumn,
  TableHeaderCellType,
  TableHeaderParentCell,
  TableHeaderChildCell,
  TableHeaderRow
} from "../../types";
import type {
  ColumnWithAttrs,
  SingleColumnWithAttrs,
  GroupColumnWithAttrs
} from "./types";
import {columnsHelpers} from "../columns";

export const isTableHeaderCellParent = (cell: TableHeaderCellType): cell is TableHeaderParentCell => {
  return columnsHelpers.isTableConstructorColumnGroup(cell.column);
};

export const isTableHeaderCellChild = (cell: TableHeaderCellType): cell is TableHeaderChildCell => {
  return !isTableHeaderCellParent(cell);
};

const getTotalRowCount = (columns: TableConstructorColumn[]): number => {
  let maxDepth = 0;

  const updateMaxDepth = (column: TableConstructorColumn, depth: number) => {
    if (columnsHelpers.isTableConstructorColumnHidden(column)) {
      return;
    }

    if (depth > maxDepth) {
      maxDepth = depth;
    }

    if (columnsHelpers.isTableConstructorColumnGroup(column)) {
      column.children.forEach(childColumn => updateMaxDepth(childColumn, depth + 1));
    }
  };

  columns.forEach(column => updateMaxDepth(column, 0));
  return maxDepth + 1;
};

const getColumnLeafCount = (column: TableConstructorColumn): number => {
  let leafCount = 0;

  const updateLeafCount = (col: TableConstructorColumn) => {
    if (columnsHelpers.isTableConstructorColumnHidden(col)) {
      return;
    }

    if (columnsHelpers.isTableConstructorColumnGroup(col)) {
      col.children.forEach(updateLeafCount);
    } else {
      leafCount++;
    }
  };

  if (columnsHelpers.isTableConstructorColumnGroup(column)) {
    column.children.forEach(updateLeafCount);
  }

  return leafCount;
};

const addAttrsToColumns = (columns: TableConstructorColumn[], totalRowCount: number): ColumnWithAttrs[] => {
  const addAttrsToColumn = (column: TableConstructorColumn, depth: number): ColumnWithAttrs => {
    if (columnsHelpers.isTableConstructorColumnGroup(column) && !!column.children.length) {
      return {
        column,
        attrs: {colSpan: getColumnLeafCount(column)},
        children: column.children.map(childColumn => addAttrsToColumn(childColumn, depth + 1)),
      };
    }

    return {
      column,
      attrs: {rowSpan: totalRowCount - depth},
    } as SingleColumnWithAttrs;
  };

  return columns.map(column => addAttrsToColumn(column, 0));
};

const makeTableHeaderRows = (columnsWithAttrs: ColumnWithAttrs[], totalRowCount: number): TableHeaderRow[] => {
  const res: TableHeaderRow[] = makeArray(totalRowCount, 0, () => ([]));
  let childCellIndex = -1;

  const updateRes = (columnWithAttr: ColumnWithAttrs, rowIndex: number) => {
    if (columnsHelpers.isTableConstructorColumnGroup(columnWithAttr.column)) {
      res[rowIndex].push({
        column: columnWithAttr.column,
        meta: {
          hidden: columnsHelpers.isTableConstructorColumnHidden(columnWithAttr.column),
          attrs: columnWithAttr.attrs,
        },
      });

      (columnWithAttr as GroupColumnWithAttrs).children.forEach(
        childColumnWithAttrs => updateRes(childColumnWithAttrs, rowIndex + 1)
      );
    } else {
      res[rowIndex].push({
        column: columnWithAttr.column,
        meta: {
          index: ++childCellIndex,
          hidden: !!columnWithAttr.column.hidden,
          attrs: columnWithAttr.attrs,
        },
      });
    }
  };

  columnsWithAttrs.forEach(columnWithAttr => updateRes(columnWithAttr, 0));
  return res;
};

export const getHeaderRows = (columns: TableConstructorColumn[]): TableHeaderRow[] => {
  const totalRowCount = getTotalRowCount(columns);
  const columnsWithAttrs = addAttrsToColumns(columns, totalRowCount);
  return makeTableHeaderRows(columnsWithAttrs, totalRowCount);
};

export const getColGroup = (columns: TableConstructorColumn[]): CSSProperties[] => {
  const singleColumns = columnsHelpers.getSingleTableConstructorColumns(columns);
  return singleColumns.map(column => ({
    ...(column.hidden
        ? {display: "none"}
        : {width: column.width}
    ),
  }));
};