import type {
  ProgramTemplateBlockTableSection,
  ProgramTemplateTableRowType,
  ProgramTemplateTableSectionType,
  ProgramTemplateBlockTableRowField
} from "services/api";
import type {TableConstructorSingleColumn} from "../../types";
import {columnsHelpers} from "../columns";

export const isFieldHidden = (...args: Parameters<typeof columnsHelpers.getColumnByField>): boolean => {
  return !!columnsHelpers.getColumnByField(...args)?.hidden;
};

export const isFieldTotalWorkHours = (
  columns: TableConstructorSingleColumn[],
  sectionType: ProgramTemplateTableSectionType,
  rowType: ProgramTemplateTableRowType,
  field: ProgramTemplateBlockTableRowField,
): boolean => {
  return (
    (field.type === "WIDGET_NUMBER" || field.type === "WIDGET_SUM") &&
    columnsHelpers.getColumnByField(columns, sectionType, rowType, field)?.subtype === "TOTAL_WORK_HOURS"
  );
};

export const isFieldVerificationWorkHours = (
  columns: TableConstructorSingleColumn[],
  sectionType: ProgramTemplateTableSectionType,
  rowType: ProgramTemplateTableRowType,
  field: ProgramTemplateBlockTableRowField,
): boolean => {
  return (
    (field.type === "WIDGET_NUMBER" || field.type === "WIDGET_PERCENT") &&
    columnsHelpers.getColumnByField(columns, sectionType, rowType, field)?.subtype === "VERIFICATION_WORK_HOURS"
  );
};

export const isFieldPracticalWorkHours = (
  columns: TableConstructorSingleColumn[],
  sectionType: ProgramTemplateTableSectionType,
  rowType: ProgramTemplateTableRowType,
  field: ProgramTemplateBlockTableRowField,
): boolean => {
  return (
    (field.type === "WIDGET_NUMBER" || field.type === "WIDGET_SUM") &&
    columnsHelpers.getColumnByField(columns, sectionType, rowType, field)?.subtype === "PRACTICAL_WORK_HOURS"
  );
};

export const findTotalWorkHoursField = (
  columns: TableConstructorSingleColumn[],
  sectionType: ProgramTemplateTableSectionType,
  rowType: ProgramTemplateTableRowType,
  fields: ProgramTemplateBlockTableRowField[],
): ProgramTemplateBlockTableRowField | undefined => {
  return fields.find(field => isFieldTotalWorkHours(columns, sectionType, rowType, field));
};

export const findVerificationWorkHoursField = (
  columns: TableConstructorSingleColumn[],
  sectionType: ProgramTemplateTableSectionType,
  rowType: ProgramTemplateTableRowType,
  fields: ProgramTemplateBlockTableRowField[],
): ProgramTemplateBlockTableRowField | undefined => {
  return fields.find(field => isFieldVerificationWorkHours(columns, sectionType, rowType, field));
};

export const findPracticalWorkHoursField = (
  columns: TableConstructorSingleColumn[],
  sectionType: ProgramTemplateTableSectionType,
  rowType: ProgramTemplateTableRowType,
  fields: ProgramTemplateBlockTableRowField[],
): ProgramTemplateBlockTableRowField | undefined => {
  return fields.find(field => isFieldPracticalWorkHours(columns, sectionType, rowType, field));
};

export const isVerificationWorkHoursPercentCellExists = (
  tableFooterSection: ProgramTemplateBlockTableSection,
  columns: TableConstructorSingleColumn[],
) => {
  const footerRow = tableFooterSection.rows.find(row => row.type === "SECTION_FOOTER_ROW")!;

  return !!findVerificationWorkHoursField(
    columns, tableFooterSection.type, footerRow.type, footerRow.fields
  );
};

export const isPracticalWorkHoursSumCellExists = (
  tableFooterSection: ProgramTemplateBlockTableSection,
  columns: TableConstructorSingleColumn[],
) => {
  const footerRow = tableFooterSection.rows.find(row => row.type === "SECTION_FOOTER_ROW")!;

  return !!findPracticalWorkHoursField(
    columns, tableFooterSection.type, footerRow.type, footerRow.fields
  );
};

export const splitFieldsByVisibility = (
  columns: TableConstructorSingleColumn[],
  sectionType: ProgramTemplateTableSectionType,
  rowType: ProgramTemplateTableRowType,
  fields: ProgramTemplateBlockTableRowField[],
) => {
  const [visibleFields, hiddenFields] = fields.reduce((acc, field) => {
    const isHidden = isFieldHidden(columns, sectionType, rowType, field);
    acc[isHidden ? 1 : 0].push(field);
    return acc;
  }, [[], []] as [ProgramTemplateBlockTableRowField[], ProgramTemplateBlockTableRowField[]]);

  return {visibleFields, hiddenFields};
};