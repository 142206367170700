import cn from "classnames";
import type {TableWithActionsProps} from "./types";
import {TableTitle} from "../TableTitle";
import {TableActions} from "../TableActions";
import {Table} from "../Table";
import {TableContextProvider} from "./contexts/table-context";
import "./styles/index.scss";

export const TableWithActions = (props: TableWithActionsProps) => {
  return (
    <TableContextProvider table={props.table}>
      <div className={cn("table-constructor-outer-container", props.containerClassName)}>
        <TableTitle title={props.table.name}/>
        <TableActions table={props.table}
                      tableMethods={props.tableMethods} moduleMethods={props.moduleMethods}
                      sectionMethods={props.sectionMethods} rowMethods={props.rowMethods}>
          <Table table={props.table}
                 headerMethods={{
                   onCellUpdate: props.headerMethods.updateCell,
                   onColumnEditClick: props.headerMethods.showColumnSettings,
                 }}
                 moduleMethods={{
                   create: props.moduleMethods.create,
                 }}
                 sectionMethods={{
                   create: props.sectionMethods.create,
                   insert: props.sectionMethods.insert,
                 }}
                 rowMethods={{
                   create: props.rowMethods.create,
                   dragEnd: props.rowMethods.dragEnd
                 }}
                 cellMethods={{
                   click: props.cellMethods.click,
                   update: props.cellMethods.update,
                 }}/>
        </TableActions>
      </div>
      {/*for docx generation*/}
      <div style={{visibility: "hidden", position: "absolute"}}><p>###L###</p></div>
    </TableContextProvider>
  );
};