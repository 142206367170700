export enum AppFilterOperator {
  "CONTAINS" = "contains",
  "DOES_NOT_CONTAIN" = "doesNotContain",
  "STARTS_WITH" = "startsWith",
  "ENDS_WITH" = "endsWith",
  "IS_NULL" = "isNull",
  "EQUAL" = "=",
  "NOT_EQUAL" = "<>",
  "MORE" = ">",
  "LESS" = "<",
  "MORE_OR_EQUAL" = ">=",
  "LESS_OR_EQUAL" = "<=",
  "INTERVAL" = "INTERVAL",
}

export enum AppFilterDateFormat {
  YEAR = "YEAR",
  MONTH = "MONTH",
  DAY = "DAY",
  HOUR = "HOUR",
  MINUTE = "MINUTE",
  SECOND = "SECOND",
}