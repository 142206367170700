import {AppInputNumber} from "shared/UI/UIKit/InputNumber";
import {AppFormItem, formRules} from "shared/UI/UIKit/Form";
import type {NumberFieldProps} from "./types";
import {FILTER_FORM_NAMES} from "../../../constants";

export const NumberField = ({onChange, placeholder, focusRef}: NumberFieldProps) => {
  return (
    <AppFormItem name={[FILTER_FORM_NAMES.values, 0]} rules={formRules.requiredNumber}
                 marginless withoutExplain>
      <AppInputNumber onChange={value => onChange([value])}
                      placeholder={placeholder} ref={focusRef}/>
    </AppFormItem>
  );
};