import {MemoChildren} from "shared/UI/UIKit/MemoChildren";
import type {AppBaseModalProps} from "./BaseModal.types";
import {ModalPortal} from "./components/ModalPortal";
import {ModalMask} from "./components/ModalMask";
import {ModalWrapper} from "./components/ModalWrapper";
import {ModalDialog} from "./components/ModalDialog";
import {ModalContent} from "./components/ModalContent";
import {ModalBlockingLoader} from "./components/ModalBlockingLoader";
import {ModalCloseBtn} from "./components/ModalCloseBtn";
import {ModalLayout} from "./components/ModalLayout";
import {useBaseModal} from "./hooks/use-base-modal";

export const AppBaseModal = ({
  children,
  visible,
  onCancel,
  afterClose,
  maskClosable = true,
  closeBtnVisible = true,
  destroyOnClose = true,
  forceRender = false,
  getContainer,
  loading,
  loadingOpacity = "medium",
  mask = true,
  keyboard = true,
  placement = "center",
  size = "auto",
  maskStyle,
  wrapStyle,
  style,
  contentStyle,
  bodyStyle,
  closeBtnStyle,
  layoutStyle,
  rootClassName,
  maskClassName,
  wrapClassName,
  className,
  contentClassName,
  bodyClassName,
  blockerClassName,
  closeBtnClassName,
  layoutClassName,
}: AppBaseModalProps) => {
  const [
    {
      modalWrapperRef,
      status,
    },
    {
      handleMaskClick,
      handleCloseBtnClick,
      handleKeyDown,
    }
  ] = useBaseModal({
    visible,
    destroyOnClose,
    forceRender,
    onCancel,
    afterClose,
    loading,
    maskClosable,
    keyboard,
    getContainer,
  });
  if (status.name === "destroyed" && status.stage === "done") {
    return null;
  }

  return (
    <ModalPortal getContainer={getContainer}>
      <div className={rootClassName}
           style={status.name === "hidden" && status.stage === "done" ? {display: "none"} : undefined}>
        <ModalMask visible={mask} className={maskClassName} style={maskStyle}
                   modalStatus={status} onClick={handleMaskClick}/>
        <ModalWrapper ref={modalWrapperRef} className={wrapClassName} style={wrapStyle}
                      placement={placement} modalStatus={status} onKeyDown={handleKeyDown}>
          <ModalDialog className={className} style={style} size={size} modalStatus={status}>
            <ModalContent className={contentClassName} style={contentStyle}
                          bodyClassName={bodyClassName} bodyStyle={bodyStyle}>
              <ModalBlockingLoader className={blockerClassName}
                                   loading={loading} loadingOpacity={loadingOpacity}>
                <ModalCloseBtn visible={closeBtnVisible} className={closeBtnClassName}
                               style={closeBtnStyle} onClick={handleCloseBtnClick}/>
                <ModalLayout className={layoutClassName} style={layoutStyle}>
                  <MemoChildren shouldUpdate={() => status.name === "visible"}>
                    {children}
                  </MemoChildren>
                </ModalLayout>
              </ModalBlockingLoader>
            </ModalContent>
          </ModalDialog>
        </ModalWrapper>
      </div>
    </ModalPortal>
  );
};