import {AppFormItem, isLessNumberValidator, isMoreNumberValidator} from "shared/UI/UIKit/Form";
import {AppInputNumber} from "shared/UI/UIKit/InputNumber";
import type {AppFormRuleRender} from "shared/UI/UIKit/Form";
import type {NumberIntervalFieldsProps} from "./types";
import {FILTER_FORM_NAMES} from "../../../constants";

const START_ITEM_NAME_PATH = [FILTER_FORM_NAMES.values, 0];
const END_ITEM_NAME_PATH = [FILTER_FORM_NAMES.values, 1];

export const NumberIntervalFields = ({
  onChange, focusRef, placeholderStart, placeholderEnd,
  }: NumberIntervalFieldsProps) => {
  const startItemRule: AppFormRuleRender = ({getFieldValue}) => {
    const validator = isLessNumberValidator(true, getFieldValue(END_ITEM_NAME_PATH),{
      variant: "less",
      comparisonValueRequired: false,
      message: "Начальное значение должно быть меньше конечного",
    });
    return {validator};
  };

  const endItemRule: AppFormRuleRender = ({getFieldValue}) => {
    const validator = isMoreNumberValidator(true, getFieldValue(START_ITEM_NAME_PATH),{
      variant: "more",
      comparisonValueRequired: false,
      message: "Конечное значение должно быть больше начального",
    });
    return {validator};
  };

  return (
    <>
      <AppFormItem noStyle shouldUpdate>
        {({getFieldValue}) => (
          <AppFormItem name={START_ITEM_NAME_PATH} marginless withoutExplain
                       dependencies={[END_ITEM_NAME_PATH]} rules={[startItemRule]}>
            <AppInputNumber onChange={(value) => onChange([value, getFieldValue(END_ITEM_NAME_PATH)])}
                            placeholder={placeholderStart} ref={focusRef}/>
          </AppFormItem>
        )}
      </AppFormItem>
      <AppFormItem noStyle shouldUpdate>
        {({getFieldValue}) => (
          <AppFormItem name={END_ITEM_NAME_PATH} marginless withoutExplain
                       dependencies={[START_ITEM_NAME_PATH]} rules={[endItemRule]}>
            <AppInputNumber onChange={(value) => onChange([getFieldValue(START_ITEM_NAME_PATH), value])}
                            placeholder={placeholderEnd}/>
          </AppFormItem>
        )}
      </AppFormItem>
    </>
  );
};