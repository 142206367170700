import type {TableConstructorSingleColumn} from "../../../types";
import {getColumnTypePlaceholder} from "../column-properties";
import {makeSingleColumn} from "./single-column";

export const makeWidgetListColumn = (column?: Partial<TableConstructorSingleColumn>): TableConstructorSingleColumn => {
  return makeSingleColumn({
    type: "WIDGET_LIST",
    placeholder: getColumnTypePlaceholder("WIDGET_LIST"),
    ...column,
  });
};