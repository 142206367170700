import cn from "classnames";
import type {ModalContentProps} from "./ModalContent.types";
import styles from "./ModalContent.module.scss";

export const ModalContent = ({
  children,
  className,
  bodyClassName,
  style,
  bodyStyle,
}: ModalContentProps) => {
  const contentClassNames = cn(styles.content, className);
  const bodyClassNames = cn(styles.body, bodyClassName);

  return (
    <div className={contentClassNames} style={style}>
      <div className={bodyClassNames} style={bodyStyle}>
        {children}
      </div>
    </div>
  );
};