import {createContext, useContext} from "react";
import {noop} from "shared/helpers/utils/noop";
import type {TableContextType} from "./types";

const defaultValue: TableContextType = {
  references: new Map(),
  referencesLoading: false,
  getDefaultDatePickerValue: noop,
};

export const TableContext = createContext<TableContextType>(defaultValue);

export const useTableContext = () => {
  return useContext(TableContext);
};