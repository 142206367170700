import {useState} from "react";
import type {AppBreadcrumbsItem} from "../../../ui/Breadcrumbs.types";
import type {BreadcrumbsService} from "./types";

export const useBreadcrumbsService = (
  initialBreadcrumbs: AppBreadcrumbsItem[] = [],
  initialBreadcrumbsLoading = false,
): BreadcrumbsService => {
  const [breadcrumbs, setBreadcrumbs] = useState<AppBreadcrumbsItem[]>(initialBreadcrumbs);
  const [breadcrumbsLoading, setBreadcrumbsLoading] = useState<boolean>(initialBreadcrumbsLoading);

  return {
    breadcrumbs,
    setBreadcrumbs,
    breadcrumbsLoading,
    setBreadcrumbsLoading,
  };
};