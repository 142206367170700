import {useCallback} from "react";
import {useModal, usePayloadedModal} from "shared/UI/UIKit/Modal";
import type {TableCreationModalPayload} from "./types";
import type {TableEditorWrapperProps} from "../../types";
import {visibility} from "../../../../../lib/core";

export const useTableEditorWrapper = (props: TableEditorWrapperProps) => {
  const {state: blockSettingsModalState, methods: blockSettingsModalMethods} = useModal();
  const {
    state: tableCreationModalState,
    methods: tableCreationModalMethods
  } = usePayloadedModal<TableCreationModalPayload>();
  const {state: tableImportModalState, methods: tableImportModalMethods} = useModal();

  const isBlockSettingsControlsVisible = visibility.checkBlockSettingsControlsVisible({
    isFullAccess: !!props.isFullAccess,
    editMode: !!props.editMode,
  });
  const isAddTableButtonVisible = visibility.checkAddTableButtonVisible({
    isFullAccess: !!props.isFullAccess,
    editMode: !!props.editMode,
  });

  const handleAddTableBtnClick = useCallback((variant: "import" | "simple" | "extended") => {
    switch (variant) {
      case "import": {
        tableImportModalMethods.show();
        break;
      }
      case "simple": {
        tableCreationModalMethods.show({type: "SIMPLE"});
        break;
      }
      case "extended": {
        tableCreationModalMethods.show({type: "EXTENDED"});
        break;
      }
      default: {
        break;
      }
    }
  }, []);

  return [
    {
      isBlockSettingsControlsVisible,
      isAddTableButtonVisible,
      blockSettingsModalState,
      tableCreationModalState,
      tableImportModalState,
    },
    {
      handleAddTableBtnClick,
      blockSettingsModalMethods,
      tableCreationModalMethods: {
        hide: tableCreationModalMethods.hide,
      },
      tableImportModalMethods: {
        hide: tableImportModalMethods.hide,
      },
    }
  ] as const;
};