import {createPortal} from "react-dom";
import {useLayoutEffect} from "react";
import {useForceRender} from "shared/helpers/hooks/use-force-render";
import type {CSSProperties} from "react";
import type {ControlPanelProps} from "./types";
import {ControlPanelView} from "./ControlPanelView";

export const ControlPanel = ({
  children,
  targetRef,
  containerRef,
  posY = "top",
  posX = "left",
  offsetX = 0,
  offsetY = 0,
  layout,
  visible = false,
  onHide,
  allowedClickContainers,
  className,
}: ControlPanelProps) => {
  const [, forceRerender] = useForceRender();
  const target = targetRef?.current;
  const container = containerRef.current;

  useLayoutEffect(() => {
    forceRerender(); // to get actual ref values
  }, [target, container]);

  if (!visible || !container) {
    return null;
  }

  const style: CSSProperties = target ? {
    top: target.offsetTop + (posY === "top" ? 0 : target.offsetHeight / 2) + offsetY,
    left: target.offsetLeft + (posX === "left" ? 0 : target.offsetWidth) + offsetX,
    transform: `translate(
      ${posX === "left" ? "-100%" : "0"},
      ${posY === "top" ? "0" : "-50%"}
    )`,
  } : {
    top: (posY === "top" ? 0 : container.offsetHeight / 2) + offsetY,
    left: (posX === "left" ? 0 : container.offsetWidth) + offsetX,
    transform: `translate(
      ${posX === "left" ? "-100%" : "0"},
      ${posY === "top" ? "0" : "-50%"}
    )`,
  };

  return createPortal(
    <ControlPanelView style={style} layout={layout}
                      onHide={onHide} className={className}
                      allowedClickContainers={allowedClickContainers}>
      {children}
    </ControlPanelView>,
    container
  );
};