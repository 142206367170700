import {memo} from "react";
import {AppFlex} from "shared/UI/UIKit/Flex";
import {AppSkeleton} from "shared/UI/UIKit/Skeleton";
import type {AppBreadcrumbsProps} from "./Breadcrumbs.types";
import {useBreadcrumbs} from "./hook";
import styles from "./Breadcrumbs.module.scss";

const AppBreadcrumbsCore = (props: AppBreadcrumbsProps) => {
  const {breadcrumbs} = useBreadcrumbs(props);

  const getComponent = () => {
    if (props.loading) {
      return <AppSkeleton className={styles.skeleton}/>;
    } else if (props.items.length) {
      return breadcrumbs;
    } else {
      return null;
    }
  };

  return (
    <AppFlex direction="row" unit="10">
      {getComponent()}
    </AppFlex>
  );
};

export const AppBreadcrumbs = memo(AppBreadcrumbsCore);