export const makeKeyGenerator = () => {
  const keyGenerator = (function* () {
    let id = 0;
    while (true) {
      yield id++;
    }
  })();

  return {
    getNextKey: () => `${keyGenerator.next().value}`,
  };
};