import {useNavigate} from "react-router-dom";
import {VerticalMenuHelpers as Helpers} from "shared/UI/UIKit/Menu";
import {AppFlex} from "shared/UI/UIKit/Flex";
import {isMobile} from "shared/helpers/utils/is-mobile";
import type {AppBreadcrumbsItem, AppBreadcrumbsProps} from "../Breadcrumbs.types";
import type {AppMenuItem} from "shared/UI/UIKit/Menu";
import {AppBreadcrumbDropdownItem, AppBreadcrumbItem} from "../Item";
import {isLast} from "./utils";

const BREADCRUMBS_MAX_COUNT = 4;
const LEFT_SIDE_MAX_ITEMS = 2;
const RIGHT_SIDE_MAX_ITEMS = 2;

export const useBreadcrumbs = ({items, separator}: AppBreadcrumbsProps) => {
  const navigate = useNavigate();
  const generateMenuItems = (extraBreadcrumbs: AppBreadcrumbsItem[]) => {
    return extraBreadcrumbs.map(item => {
      return Helpers.getTextMenuItem({
        key: item.url,
        label: item.title || "",
        icon: item.icon,
        onClick: () => navigate(item.url),
      });
    });
  };

  const generateBreadcrumbs = () => {
    if (items.length <= BREADCRUMBS_MAX_COUNT || !isMobile()) {
      return [items, []];
    } else {
      const breadcrumbs: AppBreadcrumbsItem[] = [];
      const extraBreadcrumbs: AppBreadcrumbsItem[] = [];
      items.forEach((item, index) => {
        if (index > LEFT_SIDE_MAX_ITEMS - 1 && index < items.length - RIGHT_SIDE_MAX_ITEMS) {
          extraBreadcrumbs.push(item);
        } else {
          breadcrumbs.push(item);
        }
      });

      return [breadcrumbs, extraBreadcrumbs];
    }
  };

  const getBreadcrumbsItems = () => {
    const [breadcrumbs, extraBreadcrumbs] = generateBreadcrumbs();
    const menuItems: AppMenuItem[] = generateMenuItems(extraBreadcrumbs);

    return breadcrumbs.map((item, index) => {
      if (index === LEFT_SIDE_MAX_ITEMS - 1 && menuItems.length) {
        return (
          <AppFlex direction="row" unit="4" key={index}>
            <AppBreadcrumbItem {...item} separator={separator}/>
            <AppBreadcrumbDropdownItem separator={separator} menuItems={menuItems}/>
          </AppFlex>
        );
      } else {
        return <AppBreadcrumbItem {...item} key={index}
                                  separator={separator}
                                  isLast={isLast(index, breadcrumbs)}/>;
      }
    });
  };

  return {
    breadcrumbs: getBreadcrumbsItems(),
  };
};