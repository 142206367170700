import {memo} from "react";
import type {TableModuleCreatorProps} from "./types";
import {AddButton} from "../../../ui/components/AddButton";
import styles from "./styles.module.scss";

export const TableModuleCreator = memo((props: TableModuleCreatorProps) => {
  return (
    <tr>
      <td colSpan={props.colSpan} className={styles.td}>
        <AddButton label="Добавить модуль" labelSize={14}
                   onClick={props.onModuleCreate}/>
      </td>
    </tr>
  );
});