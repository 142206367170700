import {getNumericFieldComparator} from "shared/helpers/utils/comparators";
import type {ActionReducerMapBuilder} from "@reduxjs/toolkit";
import type {MainProgramState} from "./types";
import {RenderMainProgramType} from "./types";
import {
  createMainProgramBlockAction, deleteMainProgramBlockAction,
  getDetailedCurriculumByIdAction,
  getMainProgramBlockByIdAction,
  getMainProgramByIdAction,
  getWorkProgramByIdAction, renameCurriculumAction, updateMainProgramBlockAction,
} from "./actions";

export function getMainProgramByIdReducerBuilder(builder: ActionReducerMapBuilder<MainProgramState>) {
  builder.addCase(getMainProgramByIdAction.pending, (state) => {
    state.loading = true;
  });
  builder.addCase(getMainProgramByIdAction.fulfilled, (state, action) => {
    state.mainProgram = {
      ...action.payload,
      blocks: action.payload.blocks.sort(getNumericFieldComparator((item) => item.index, "ascend")),
    };
    state.loading = false;
  });
  builder.addCase(getMainProgramByIdAction.rejected, (state) => {
    state.loading = false;
  });
}

export function getWorkProgramByIdReducerBuilder(builder: ActionReducerMapBuilder<MainProgramState>) {
  builder.addCase(getWorkProgramByIdAction.pending, (state) => {
    state.mainProgramPartLoading = true;
    state.mainProgramBlock = null;
    state.detailedCurriculum = null;
    state.renderType = null;
  });
  builder.addCase(getWorkProgramByIdAction.fulfilled, (state, action) => {
    state.workProgram = action.payload;
    state.mainProgramPartLoading = false;
    state.renderType = RenderMainProgramType.WORK_PROGRAM;
  });
  builder.addCase(getWorkProgramByIdAction.rejected, (state) => {
    state.mainProgramPartLoading = false;
    state.renderType = null;
  });
}

export function getMainProgramBlockByIdReducerBuilder(builder: ActionReducerMapBuilder<MainProgramState>) {
  builder.addCase(getMainProgramBlockByIdAction.pending, (state) => {
    state.mainProgramPartLoading = true;
    state.workProgram = null;
    state.detailedCurriculum = null;
    state.renderType = null;
  });
  builder.addCase(getMainProgramBlockByIdAction.fulfilled, (state, action) => {
    state.mainProgramBlock = action.payload;
    state.mainProgramPartLoading = false;
    state.renderType = RenderMainProgramType.MAIN_PROGRAM_BLOCK;
  });
  builder.addCase(getMainProgramBlockByIdAction.rejected, (state) => {
    state.mainProgramPartLoading = false;
    state.renderType = null;
  });
}

export function getDetailedCurriculumByIdReducerBuilder(builder: ActionReducerMapBuilder<MainProgramState>) {
  builder.addCase(getDetailedCurriculumByIdAction.pending, (state) => {
    state.mainProgramPartLoading = true;
    state.workProgram = null;
    state.mainProgramBlock = null;
    state.renderType = null;
  });
  builder.addCase(getDetailedCurriculumByIdAction.fulfilled, (state, action) => {
    state.detailedCurriculum = action.payload;
    state.mainProgramPartLoading = false;
    state.renderType = RenderMainProgramType.CURRICULUM;
  });
  builder.addCase(getDetailedCurriculumByIdAction.rejected, (state) => {
    state.mainProgramPartLoading = false;
    state.renderType = null;
  });
}

export function createMainProgramBlockActionReducerBuilder(builder: ActionReducerMapBuilder<MainProgramState>) {
  builder.addCase(createMainProgramBlockAction.pending, (state) => {
    state.mainProgramPartLoading = true;
  });
  builder.addCase(createMainProgramBlockAction.fulfilled, (state, action) => {
    if (state.mainProgram) {
      state.mainProgram.blocks = [...(state.mainProgram?.blocks || []), action.payload];
    }
    state.mainProgramPartLoading = false;
  });
  builder.addCase(createMainProgramBlockAction.rejected, (state) => {
    state.mainProgramPartLoading = false;
  });
}

export function updateMainProgramBlockActionReducerBuilder(builder: ActionReducerMapBuilder<MainProgramState>) {
  builder.addCase(updateMainProgramBlockAction.pending, (state) => {
    state.mainProgramPartLoading = true;
  });
  builder.addCase(updateMainProgramBlockAction.fulfilled, (state, action) => {
    if (state.mainProgram) {
      state.mainProgram.blocks = state.mainProgram.blocks.map(item => {
        if (item.id === action.payload.id) {
          return action.payload;
        } else {
          return item;
        }
      });
    }
    state.mainProgramBlock = action.payload;
    state.mainProgramPartLoading = false;
  });
  builder.addCase(updateMainProgramBlockAction.rejected, (state) => {
    state.mainProgramPartLoading = false;
  });
}

export function deleteMainProgramBlockActionReducerBuilder(builder: ActionReducerMapBuilder<MainProgramState>) {
  builder.addCase(deleteMainProgramBlockAction.pending, (state) => {
    state.mainProgramPartLoading = true;
  });
  builder.addCase(deleteMainProgramBlockAction.fulfilled, (state, action) => {
    if (state.mainProgram) {
      state.mainProgram.blocks = state.mainProgram.blocks.filter(item => item.id !== action.payload);
    }
    state.mainProgramPartLoading = false;
  });
  builder.addCase(deleteMainProgramBlockAction.rejected, (state) => {
    state.mainProgramPartLoading = false;
  });
}

export function renameCurriculumActionReducerBuilder(builder: ActionReducerMapBuilder<MainProgramState>) {
  builder.addCase(renameCurriculumAction.fulfilled, (state, {payload}) => {
    if (state.mainProgram) {
      state.mainProgram.curriculums = state.mainProgram.curriculums.map(curriculum => {
        if (payload.id === curriculum.id) {
          return {
            ...curriculum,
            name: payload.name,
          };
        } else {
          return curriculum;
        }
      });
    }

    if (state.detailedCurriculum?.blocks) {
      state.detailedCurriculum.blocks = state.detailedCurriculum.blocks.map((curriculumBlock, index) => {
        if (index === 0) {
          return {
            ...curriculumBlock,
            title: payload.name,
          };
        } else {
          return curriculumBlock;
        }
      });
    }

    state.mainProgramPartLoading = false;
  });
}
