import {AppForm, AppFormItem, formRules} from "shared/UI/UIKit/Form";
import {AppSelect} from "shared/UI/UIKit/Select";
import type {WidgetBlockFormProps} from "./types";
import {widgetBlockTypeOptions} from "./options";

export const WidgetBlockForm = (props: WidgetBlockFormProps) => {
  return (
    <AppForm form={props.form}>
      <AppFormItem rules={formRules.required} label="Тип виджета" name="data-type">
        <AppSelect options={widgetBlockTypeOptions} placeholder="Выберите тип"/>
      </AppFormItem>
    </AppForm>
  );
};