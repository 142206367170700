import type {ActionReducerMapBuilder} from "@reduxjs/toolkit";
import type {MainProgramsState} from "./types";
import {getMainProgramsAction, deleteMainProgramAction, updateMainProgramAction} from "./actions";

export function getMainProgramsReducerBuilder(builder: ActionReducerMapBuilder<MainProgramsState>) {
  builder.addCase(getMainProgramsAction.pending, (state) => {
    state.loading = true;
  });
  builder.addCase(getMainProgramsAction.fulfilled, (state, action) => {
    state.mainPrograms = action.payload.items;
    state.total = action.payload.total;
    state.loading = false;
  });
  builder.addCase(getMainProgramsAction.rejected, (state) => {
    state.loading = false;
  });
}

export function deleteMainProgramReducerBuilder(builder: ActionReducerMapBuilder<MainProgramsState>) {
  builder.addCase(deleteMainProgramAction.pending, (state) => {
    state.loading = true;
  });
  builder.addCase(deleteMainProgramAction.fulfilled, (state, action) => {
    state.mainPrograms = state.mainPrograms.filter(item => item.id !== action.payload);
    state.total = state.total! - 1;
    state.loading = false;
  });
  builder.addCase(deleteMainProgramAction.rejected, (state) => {
    state.loading = false;
  });
}

export function updateMainProgramReducerBuilder(builder: ActionReducerMapBuilder<MainProgramsState>) {
  builder.addCase(updateMainProgramAction.pending, (state) => {
    state.loading = true;
  });
  builder.addCase(updateMainProgramAction.fulfilled, (state, action) => {
    state.mainPrograms = state.mainPrograms.map(item => {
      if (item.id === action.payload.id) {
        return action.payload;
      } else {
        return item;
      }
    });
    state.loading = false;
  });
  builder.addCase(updateMainProgramAction.rejected, (state) => {
    state.loading = false;
  });
}

