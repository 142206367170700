import cn from "classnames";
import type {AppPrimaryButtonProps} from "./PrimaryButton.types";
import styles from "./PrimaryButton.module.scss";

export const getClassNames = (props: AppPrimaryButtonProps) => {
  const {color, className} = props;

  return cn(
    styles["type-primary"],
    color && styles[`color-${color}`],
    className,
  );
};