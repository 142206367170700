import {shallowEqual} from "shared/helpers/utils/shallow-equal";
import type {AppTableItem, AppTableGroupingConfig} from "../../../types";
import type {Columns, SingleColumn} from "../types";
import {isGroupingByParentKey, isTableColumnGroup} from "../../../Table.utils";

export const reorderColumns = <TRecord extends AppTableItem>(
  columns: Columns<TRecord>, groupingConfig?: AppTableGroupingConfig<TRecord>
): Columns<TRecord> => {
  if (
    !groupingConfig || isGroupingByParentKey(groupingConfig)
    || !groupingConfig.reorderColumns || !groupingConfig.dataIndexes.length
  ) {
    return columns;
  }

  const recursivelySplitColumns = (cols: Columns<TRecord>): Columns<TRecord>[] => {
    return cols.reduce(([head, tail], col) => {
      if (isTableColumnGroup(col)) {
        const [childrenHead, childrenTail] = recursivelySplitColumns(col.children);

        return [
          [...head, ...childrenHead],
          childrenTail.length ? [...tail, {...col, children: childrenTail}] : tail,
        ];
      }

      if (groupingConfig.dataIndexes.some(dataIndex => shallowEqual(dataIndex, col.dataIndex))) {
        return [
          [...head, col],
          tail,
        ];
      }

      return [
        head,
        [...tail, col],
      ];
    }, [[], []] as Columns<TRecord>[]);
  };

  const [head, tail] = recursivelySplitColumns(columns);

  const sortedHead = groupingConfig.dataIndexes.map(dataIndex =>
    head.find(col => shallowEqual(dataIndex, (col as SingleColumn<TRecord>).dataIndex))!
  );

  return [...sortedHead, ...tail];
};