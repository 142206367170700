import {AppCheckbox} from "shared/UI/UIKit/Checkbox";
import {useAppSelector} from "services/redux/hooks/use-selector";
import {selectCreateMainProgramSelectedCurriculumsEntities} from "features/create-main-program/redux/selectors";
import {useAppDispatch} from "services/redux/hooks/use-dispatch";
import {setSelectedCurriculumsEntitiesAction} from "features/create-main-program/redux/slice";
import {AppFlex} from "shared/UI/UIKit/Flex";
import {AppText} from "shared/UI/UIKit/Text";
import {AppTitle} from "shared/UI/UIKit/Title";
import type {CheckboxChangeEvent} from "antd/lib/checkbox/Checkbox";
import type {Curriculum, Subject} from "services/api";
import styles from "./styles.module.scss";

type CurriculumListProps = {
  curriculums: Curriculum[];
}

export const CurriculumList = (props: CurriculumListProps) => {
  const selectedCurriculumsEntities = useAppSelector(selectCreateMainProgramSelectedCurriculumsEntities);
  const dispatch = useAppDispatch();

  const selectCurriculum = (e: CheckboxChangeEvent) => {
    const curriculumFromEvent: Curriculum = {
      ...e.target.value,
      subjects: e.target.value.subjects?.map((subject: Subject, index: number) => {
        return {
          ...subject,
          baseSubjectName: subject.name,
          customSubjectName: subject.name,
          uniqueId: new Date().getTime() + index,
        };
      }) || [],
    };


    const selectedCurriculum = selectedCurriculumsEntities.find(item => item.id === curriculumFromEvent.id);

    let newCurriculums = [];
    if (selectedCurriculum) {
      newCurriculums = selectedCurriculumsEntities.filter(item => item.id !== curriculumFromEvent.id);
    } else {
      newCurriculums = selectedCurriculumsEntities.concat(curriculumFromEvent);
    }
    dispatch(setSelectedCurriculumsEntitiesAction(newCurriculums));
  };

  return (
    <div className={styles.list}>
      <AppFlex>
        {
          props.curriculums.length ? (
            props.curriculums.map(item => {
              const checked = selectedCurriculumsEntities.find(_item => _item.id === item.id);

              return (
                <AppCheckbox type="card"
                             onChange={selectCurriculum}
                             value={item}
                             checked={!!checked}
                             key={item.id}>
                  {item.name || <AppText size={18}>—</AppText>}
                </AppCheckbox>
              );
            })
          ) : (
            <AppTitle className={styles.empty} level={2}>
              Не найдено
            </AppTitle>
          )
        }
      </AppFlex>
    </div>
  );
};