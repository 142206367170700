import type {ProgramTemplateBlockTable} from "services/api";
import type {TableConstructorSingleColumn} from "../../types";
import {fieldsHelpers} from "../fields";

export type ViewSettingsValue = "showVerificationWorkHoursPercent" | "showPracticalWorkHoursSum";
export type ViewSettingsOption = {label: string, value: ViewSettingsValue};

export const viewSettingsOptions: ViewSettingsOption[] = [
  {
    label: "Отображать ячейку \"Сумма контрольных работ\"",
    value: "showVerificationWorkHoursPercent",
  },
  {
    label: "Отображать ячейку \"Сумма практических работ\"",
    value: "showPracticalWorkHoursSum",
  },
];

export const filterViewSettingsOptions = (
  options: ViewSettingsOption[],
  columns: TableConstructorSingleColumn[]
) => {
  return options.filter(option => {
    if (
      option.value === "showVerificationWorkHoursPercent" &&
      !columns.some(column => column.subtype === "VERIFICATION_WORK_HOURS")
    ) {
      return false;
    }

    if (
      option.value === "showPracticalWorkHoursSum" &&
      !columns.some(column => column.subtype === "PRACTICAL_WORK_HOURS")
    ) {
      return false;
    }

    return true;
  });
};

export const getViewSettings = (
  table: ProgramTemplateBlockTable,
  columns: TableConstructorSingleColumn[],
): ViewSettingsValue[] => {
  const tableFooterSection = table.sections.find(section => section.type === "TABLE_FOOTER")!;
  const viewSettingsValues: ViewSettingsValue[] = [];

  if (fieldsHelpers.isVerificationWorkHoursPercentCellExists(tableFooterSection, columns)) {
    viewSettingsValues.push("showVerificationWorkHoursPercent");
  }
  if (fieldsHelpers.isPracticalWorkHoursSumCellExists(tableFooterSection, columns)) {
    viewSettingsValues.push("showPracticalWorkHoursSum");
  }

  return viewSettingsValues;
};