import {createSelector} from "@reduxjs/toolkit";
import type {MainProgramsState} from "features/main-programs/redux/types";
import type {RootState} from "services/redux/types";


export const selectMainProgramsState = (state: RootState) => state.mainProgramsState;

export const selectMainProgramsLoading = createSelector(
  selectMainProgramsState,
  (mainProgramsState: MainProgramsState) => mainProgramsState.loading
);

export const selectAllMainPrograms = createSelector(
  selectMainProgramsState,
  (mainProgramsState: MainProgramsState) => mainProgramsState.mainPrograms
);

export const selectAllMainProgramsTotal = createSelector(
  selectMainProgramsState,
  (mainProgramsState: MainProgramsState) => mainProgramsState.total
);
