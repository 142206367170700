import {memo, useState} from "react";
import cn from "classnames";
import type {FocusEvent, KeyboardEvent} from "react";
import type {TableWidgetNumberCellProps} from "./types";
import type {NumberValueEditorSubmitValues} from "../../cell-value-editors/number-value-editor";
import {visibility} from "../../../../../lib/core";
import {numberValueRenderer} from "../../cell-value-renderers/number-value-renderer";
import {NumberValueEditor} from "../../cell-value-editors/number-value-editor";
import widgetCellStyles from "../../styles/widget.module.scss";
import {TableBaseCell} from "../TableBaseCell";
import {LockedIcon} from "../LockedIcon";
import widgetNumberCellStyles from "./styles.module.scss";

export const TableWidgetNumberCell = memo(({
  field,
  onChange,
  isPrograms,
  isFullAccess,
  editMode,
  validationStatus,
}: TableWidgetNumberCellProps) => {
  const [isEditorEnabled, setEditorEnabled] = useState(false);
  const isCellEditable = editMode && (isFullAccess || !field.readonly);
  const isCellHighlightable = editMode || !isPrograms;
  const isCellWidget = isCellEditable || (!editMode && !isPrograms && !field.readonly);
  const isCellWidgetError = isCellHighlightable && validationStatus === "error";
  const isLockedIconVisible = visibility.checkTableCellLockedIconVisible({editMode, isFullAccess});
  const cellClassName = cn(
    isCellWidget && widgetCellStyles["widget-cell"],
    isCellWidgetError && widgetCellStyles["widget-cell_error"],
    isEditorEnabled && widgetNumberCellStyles["widget-number-cell_edit"],
  );

  const handleCellClick = () => {
    if (isCellEditable) {
      setEditorEnabled(true);
    }
  };

  const handleCellBlur = (e: FocusEvent<HTMLTableCellElement>) => {
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setEditorEnabled(false);
    }
  };

  const handleEditorKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      setEditorEnabled(false);
    }
  };

  const handleEditorSubmit = (editorValues: NumberValueEditorSubmitValues) => {
    onChange({...field, value: editorValues.value});
  };

  const handleLockedIconToggle = (nextLocked: boolean) => {
    onChange({...field, readonly: nextLocked});
  };

  return (
    <TableBaseCell isFullAccess={isFullAccess} editMode={editMode}
                   index={field.index} editable={isCellEditable} className={cellClassName}
                   onClick={handleCellClick} onBlur={handleCellBlur}>
      {isEditorEnabled
        ? (
          <NumberValueEditor onSubmit={handleEditorSubmit} onKeyDown={handleEditorKeyDown}
                             value={{
                               initialValue: field.value,
                               placeholder: field.placeholder,
                               required: !isFullAccess && !!field.required,
                             }}/>
        ) : (
          <>
            {isLockedIconVisible && <LockedIcon locked={!!field.readonly} toggle={handleLockedIconToggle}/>}
            {numberValueRenderer(field.value, field.placeholder, isCellWidget)}
          </>
        )
      }
    </TableBaseCell>
  );
});