import cn from "classnames";
import {memo, useMemo, useState} from "react";
import type {FocusEvent, KeyboardEvent} from "react";
import type {TableWidgetListCellProps} from "./types";
import type {ListValueEditorSubmitValues} from "../../cell-value-editors/list-value-editor";
import {visibility} from "../../../../../lib/core";
import {listValueRenderer} from "../../cell-value-renderers/list-value-renderer";
import {ListValueEditor} from "../../cell-value-editors/list-value-editor";
import widgetCellStyles from "../../styles/widget.module.scss";
import {TableBaseCell} from "../TableBaseCell";
import {LockedIcon} from "../LockedIcon";
import {stringOptionsToSelectOptions} from "./utils";
import widgetListCellStyles from "./styles.module.scss";

export const TableWidgetListCell = memo(({
  field,
  onChange,
  isPrograms,
  isFullAccess,
  editMode,
  columnWidth,
  validationStatus,
}: TableWidgetListCellProps) => {
  const [isEditorEnabled, setEditorEnabled] = useState(false);
  const editorValueOptions = useMemo(() => {
    return stringOptionsToSelectOptions(field.options);
  }, [field.options]);
  const isCellEditableWithFullAccess = editMode && isFullAccess;
  const isCellEditableWithLimitedAccess = editMode && !isFullAccess && !field.readonly;
  const isCellEditable = isCellEditableWithFullAccess || isCellEditableWithLimitedAccess;
  const isCellHighlightable = editMode || !isPrograms;
  const isCellWidget = isCellEditable || (!editMode && !isPrograms && !field.readonly);
  const isCellWidgetError = isCellHighlightable && validationStatus === "error";
  const isLockedIconVisible = visibility.checkTableCellLockedIconVisible({editMode, isFullAccess});
  const cellClassName = cn(
    isCellWidget && widgetCellStyles["widget-cell"],
    isCellWidgetError && widgetCellStyles["widget-cell_error"],
    isEditorEnabled && widgetListCellStyles["widget-list-cell_edit"],
  );

  const handleCellClick = () => {
    if (isCellEditableWithLimitedAccess) {
      setEditorEnabled(true);
    }
  };

  const handleCellBlur = (e: FocusEvent<HTMLTableCellElement>) => {
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setEditorEnabled(false);
    }
  };

  const handleEditorInputKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.stopPropagation();
      setEditorEnabled(false);
    }
  };

  const handleEditorSubmit = (editorValues: ListValueEditorSubmitValues) => {
    onChange({...field, value: editorValues.value});
  };

  const handleLockedIconToggle = (nextLocked: boolean) => {
    onChange({...field, readonly: nextLocked});
  };

  return (
    <TableBaseCell isFullAccess={isFullAccess} editMode={editMode}
                   index={field.index} editable={isCellEditable} className={cellClassName}
                   onClick={handleCellClick} onBlur={handleCellBlur}>
      {isEditorEnabled
        ? (
          <ListValueEditor onSubmit={handleEditorSubmit} onInputKeyDown={handleEditorInputKeyDown}
                           containerWidth={columnWidth}
                           value={{
                             initialValue: field.value,
                             options: editorValueOptions,
                             placeholder: field.placeholder,
                             required: !isFullAccess && !!field.required,
                           }}/>
        ) : (
          <>
            {isLockedIconVisible && <LockedIcon locked={!!field.readonly} toggle={handleLockedIconToggle}/>}
            {listValueRenderer(field.value, field.placeholder, isCellWidget)}
          </>
        )
      }
    </TableBaseCell>
  );
});