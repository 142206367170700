import {
  getProgramTemplateBlockTableHeaderDTO,
  getProgramTemplateBlockTableRowDTO,
  getProgramTemplateBlockTableSectionDTO
} from "services/api/blocks/dto";
import type {
  ProgramTemplateBlockTable,
  ProgramTemplateTableSectionType,
  ProgramTemplateBlockTableRowField
} from "services/api";
import type {TableConstructorSingleColumn} from "../../../types";
import type {TableWorkHours} from "../types";
import {columnsHelpers} from "../../../helpers";
import {getFieldWithCalculatedValue} from "../helpers";

/*
  This function does:
  - updates header
  - removes "INDEX"/"FILLER" fields
  - calculates work hours
 */
const prepareTableForUpdate = (
  table: ProgramTemplateBlockTable,
  columns: TableConstructorSingleColumn[],
): ProgramTemplateBlockTable => {
  const tableWorkHours: TableWorkHours = {
    sectionTotal: 0,
    tableTotal: 0,
    tableVerification: 0,
    tablePractical: 0,
  };

  return {
    ...table,
    ...(table.header
      ? {header: table.header}
      : {}
    ),
    ...(table.sections
      ? {sections: table.sections.map((section) => {
        tableWorkHours.sectionTotal = 0;

        return getProgramTemplateBlockTableSectionDTO({
          ...section,
          rows: section.rows.map((row) => {
            return getProgramTemplateBlockTableRowDTO({
              ...row,
              fields: removeFrontEndFields(section.type, row.fields).map((field) => {
                return getFieldWithCalculatedValue(section.type, row.type, field, columns, tableWorkHours);
              }),
            });
          }),
        });
      })}
      : {}
    )
  };
};

const removeFrontEndFields = (
  sectionType: ProgramTemplateTableSectionType,
  fields: ProgramTemplateBlockTableRowField[]
) => {
  return fields.filter((field) => {
    return !(field.type === "INDEX" || field.type === "FILLER");
  });
};

export const normalizeBlockTableForUpdate = (table: ProgramTemplateBlockTable) => {
  const columns = columnsHelpers.getTableColumnsByHeader(getProgramTemplateBlockTableHeaderDTO(table.header));
  const singleColumns = columnsHelpers.getSingleTableConstructorColumns(columns);
  return prepareTableForUpdate(table, singleColumns);
};