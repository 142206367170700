import type {DictionaryItem} from "services/api";
import type {AppSelectOption} from "shared/UI/UIKit/Select";
import {FIELD_CONSTANTS} from "../../../../../lib/constants";

export const referenceItemsToOptions = (items: DictionaryItem[] | undefined): AppSelectOption[] => {
  if (!items) {
    return [];
  }

  return items.map(item => ({label: item.name, value: `${item.id}`}));
};

export const itemIdsToNames = (itemIds: string | null, referenceItems: DictionaryItem[] | undefined): string => {
  if (!referenceItems) {
    return "";
  }

  const ids = itemIds?.split(FIELD_CONSTANTS.MULTIPLE_VALUE_SPLITTER) ?? [];
  const names = ids.reduce((acc, id) => {
    const referenceItem = referenceItems.find(item => `${item.id}` === id);
    if (referenceItem) {
      acc.push(referenceItem.name);
    }
    return acc;
  }, [] as string[]);

  return names.join(FIELD_CONSTANTS.MULTIPLE_VALUE_SPLITTER);
};