import {getMinMaxObjectValue} from "shared/helpers/utils/arrays/get-min-max-value";
import {getProgramTemplateBlockTableSectionDTO} from "services/api/blocks/dto";
import type {ProgramTemplateBlockTable, ProgramTemplateBlockTableSection} from "services/api";
import {sectionsHelpers, modulesHelpers, indexHelpers} from "../../helpers";

export const createModule = (
  table: ProgramTemplateBlockTable,
  isPrograms: boolean,
): ProgramTemplateBlockTableSection[] => {
  const nextSections = [...table.sections];
  const [, maxSectionIndex] = getMinMaxObjectValue(nextSections, section => section.index, -1);

  const footerSection = nextSections.find(section => section.type === "TABLE_FOOTER")!;
  footerSection.index++;
  const newSection = modulesHelpers.createTableModule(table.id, maxSectionIndex, isPrograms);
  nextSections.push(newSection);

  return nextSections;
};

export const insertModule = (
  table: ProgramTemplateBlockTable,
  isPrograms: boolean,
  sectionId: string | number,
  placement: "above" | "below",
): ProgramTemplateBlockTableSection[] => {
  const nextSections = [...table.sections];
  const currentSectionIndex = nextSections.findIndex(section => section.id === sectionId);

  if (placement === "above") {
    const newSection = modulesHelpers.createTableModule(
      table.id, nextSections[currentSectionIndex].index, isPrograms
    );
    sectionsHelpers.incrementSectionIndexes(nextSections, currentSectionIndex);
    nextSections.push(newSection);
  } else {
    const nextModuleOrFooterIndex = modulesHelpers.findNextModuleOrTableFooterIndex(nextSections, currentSectionIndex);
    const newSection = modulesHelpers.createTableModule(
      table.id, nextSections[nextModuleOrFooterIndex].index, isPrograms
    );
    sectionsHelpers.incrementSectionIndexes(nextSections, nextModuleOrFooterIndex);
    nextSections.push(newSection);
  }

  nextSections[currentSectionIndex] = getProgramTemplateBlockTableSectionDTO(nextSections[currentSectionIndex]);

  return nextSections;
};

export const copyModule = (
  table: ProgramTemplateBlockTable,
  isPrograms: boolean,
  sectionId: string | number,
): ProgramTemplateBlockTableSection[] => {
  const nextSections = [...table.sections];
  const currentSectionIndex = nextSections.findIndex(section => section.id === sectionId);
  const nextModuleOrFooterIndex = modulesHelpers.findNextModuleOrTableFooterIndex(nextSections, currentSectionIndex);
  const lastSectionOfCurrentModule = nextSections[nextModuleOrFooterIndex - 1];
  const copiesCount = nextModuleOrFooterIndex - currentSectionIndex;
  const copies: ProgramTemplateBlockTableSection[] = [];

  for (
    let indexOfSectionToCopy = currentSectionIndex, newSectionIndex = lastSectionOfCurrentModule.index + 1;
    indexOfSectionToCopy < currentSectionIndex + copiesCount;
    indexOfSectionToCopy++, newSectionIndex++
  ) {
    const newSection = sectionsHelpers.copyTableSection(
      nextSections[indexOfSectionToCopy], newSectionIndex, isPrograms
    );
    copies.push(newSection);
  }

  sectionsHelpers.incrementSectionIndexes(nextSections, currentSectionIndex + copiesCount, copiesCount);
  nextSections.push(...copies);

  return nextSections;
};

export const moveModule = (
  table: ProgramTemplateBlockTable,
  sectionId: string | number,
  direction: "above" | "below",
): ProgramTemplateBlockTableSection[] | undefined => {
  const nextSections = [...table.sections];
  const currentSectionIndex = nextSections.findIndex(section => section.id === sectionId);

  if (direction === "above") {
    // if there's nothing above
    if (currentSectionIndex === 0) {
      // if the table has only one module, then we don't need to do anything
      if (!modulesHelpers.checkMoreThanOneTableModuleExists(nextSections)) {
        return;
      }
      // the table has at least two modules, so we need to make the first module last
      modulesHelpers.makeLowestTableModuleHighest(nextSections);
    } else { // there are some sections above
      const prevModuleIndex = modulesHelpers.findPrevModuleIndex(nextSections, currentSectionIndex);
      // if there aren't any modules above, but some sections
      if (prevModuleIndex === -1) {
        // we should only swap indexes of current section and section above
        indexHelpers.swapIndexes(nextSections.at(currentSectionIndex)!, nextSections.at(currentSectionIndex - 1)!);
      } else { // there's at least one module above
        // we need to swap current module with module above
        const nextModuleOrFooterIndex = modulesHelpers.findNextModuleOrTableFooterIndex(table.sections, currentSectionIndex);
        modulesHelpers.swapAdjacentTableModules(
          table.sections,
          prevModuleIndex,
          currentSectionIndex - 1,
          currentSectionIndex,
          nextModuleOrFooterIndex - 1,
        );
      }
    }
  } else {
    const nextModuleIndex = modulesHelpers.findNextModuleIndex(nextSections, currentSectionIndex);
    // if there's no module below
    if (nextModuleIndex === -1) {
      // if there's nothing above
      if (currentSectionIndex === 0) {
        return;
      }
      // there are some sections above, so we need to make the last module first
      modulesHelpers.makeHighestTableModuleLowest(nextSections, currentSectionIndex);
    } else { // there's at least one module below
      // we need to swap current module with module below
      const afterNextModuleOrFooterIndex = modulesHelpers.findNextModuleOrTableFooterIndex(table.sections, nextModuleIndex);
      modulesHelpers.swapAdjacentTableModules(
        table.sections,
        currentSectionIndex,
        nextModuleIndex - 1,
        nextModuleIndex,
        afterNextModuleOrFooterIndex - 1,
      );
    }
  }

  return nextSections;
};

export const removeModule = (
  table: ProgramTemplateBlockTable,
  sectionId: string | number,
): ProgramTemplateBlockTableSection[] => {
  const nextSections = [...table.sections];
  const currentSectionIndex = nextSections.findIndex(section => section.id === sectionId);
  const nextModuleOrFooterIndex = modulesHelpers.findNextModuleOrTableFooterIndex(table.sections, currentSectionIndex);
  const moduleSectionCount = nextModuleOrFooterIndex - currentSectionIndex;
  nextSections.splice(currentSectionIndex, moduleSectionCount);

  return nextSections;
};