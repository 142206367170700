import cn from "classnames";
import {AppCopyIcon} from "shared/UI/UIKit/icons/Copy";
import type {SvgProps} from "shared/types";
import styles from "../styles.module.scss";

export const CopyIcon = ({className, ...rest}: SvgProps) => {
  return (
    <AppCopyIcon className={cn(styles.icon, styles["icon_size_24"], styles["icon_fill"], className)}
                 strokeWidth={0.1} {...rest}/>
  );
};