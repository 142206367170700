import {getProgramTemplateBlockTableRowFieldDTO} from "services/api/blocks/dto";
import {floatToString, stringToFloat} from "shared/helpers/utils/converters";
import type {
  ProgramTemplateTableRowType,
  ProgramTemplateBlockTableRowField,
  ProgramTemplateTableSectionType,
} from "services/api";
import type {TableConstructorSingleColumn} from "../../types";
import type {TableWorkHours} from "./types";
import {fieldsHelpers} from "../../helpers";

export const getFieldWithCalculatedValue = (
  sectionType: ProgramTemplateTableSectionType,
  rowType: ProgramTemplateTableRowType,
  field: ProgramTemplateBlockTableRowField,
  columns: TableConstructorSingleColumn[],
  tableWorkHours: TableWorkHours,
): ProgramTemplateBlockTableRowField => {
  switch (field.type) {
    case "WIDGET_NUMBER": {
      const numFieldValue = stringToFloat(field.value);

      if (fieldsHelpers.isFieldTotalWorkHours(columns, sectionType, rowType, field)) {
        tableWorkHours.sectionTotal = tableWorkHours.sectionTotal + numFieldValue;
        tableWorkHours.tableTotal = tableWorkHours.tableTotal + numFieldValue;
      } else if (fieldsHelpers.isFieldVerificationWorkHours(columns, sectionType, rowType, field)) {
        tableWorkHours.tableVerification = tableWorkHours.tableVerification + numFieldValue;
      } else if (fieldsHelpers.isFieldPracticalWorkHours(columns, sectionType, rowType, field)) {
        tableWorkHours.tablePractical = tableWorkHours.tablePractical + numFieldValue;
      }

      return field;
    }
    case "WIDGET_SUM": {
      const numFieldValue = sectionType === "TABLE"
        ? tableWorkHours.sectionTotal
        : fieldsHelpers.isFieldTotalWorkHours(columns, sectionType, rowType, field)
          ? tableWorkHours.tableTotal
          : tableWorkHours.tablePractical;

      return getProgramTemplateBlockTableRowFieldDTO({
        ...field,
        value: floatToString(numFieldValue),
      });
    }
    case "WIDGET_PERCENT": {
      return getProgramTemplateBlockTableRowFieldDTO({
        ...field,
        value: floatToString(tableWorkHours.tableVerification),
      });
    }
    default: {
      return field;
    }
  }
};