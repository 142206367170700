import {useMemo} from "react";
import {useEvent} from "shared/helpers/hooks/use-event";
import type {TableHeaderProps} from "./types";
import type {TableHeaderCellType, TableConstructorColumn} from "../../../lib/types";
import {headerHelpers} from "../../../lib/helpers";
import {useTableConstructorContext} from "../../contexts/table-constructor-context";

export const useTableHeader = (props: TableHeaderProps) => {
  const {isFullAccess, editMode, columns} = useTableConstructorContext();
  const rows = useMemo(() => headerHelpers.getHeaderRows(columns), [columns]);
  const colgroup = useMemo(() => headerHelpers.getColGroup(columns), [columns]);

  const handleCellUpdate = useEvent((nextCell: TableHeaderCellType) => {
    props.onCellUpdate(nextCell);
  });

  const handleColumnEditClick = useEvent((column: TableConstructorColumn) => {
    props.onColumnEditClick(column);
  });

  return [
    {isFullAccess, editMode, rows, colgroup},
    {handleCellUpdate, handleColumnEditClick}
  ] as const;
};