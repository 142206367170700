import type {AppTableItem, AppTableColumns} from "../../types";
import type {TableColumnsServiceOptions, Columns} from "./types";
import {isTableColumnSingle} from "../../Table.utils";
import {addSorterToColumn} from "./sorter/add-sorter-to-column";
import {addFilterToColumn} from "./filter/add-filter-to-column";
import {addRenderToColumn} from "./render/add-render-to-column";
import {reorderColumns} from "./reorder/reorder-columns";

export const useTableColumns = <TRecord extends AppTableItem>({
  columns,
  sorters,
  multipleSorter,
  filters,
  onFilterChange,
  groupingConfig,
}: TableColumnsServiceOptions<TRecord>): Columns<TRecord> => {
  const recursivelyMapColumns = (cols: AppTableColumns<TRecord>): Columns<TRecord> => {
    return cols.map(col => {
      if (isTableColumnSingle(col)) {
        const colWithWidth = {...col, width: col.maxWidth};
        const colWithSorter = addSorterToColumn(colWithWidth, sorters, multipleSorter);
        const colWithFilter = addFilterToColumn(colWithSorter, filters, onFilterChange);
        const colWithRender = addRenderToColumn(colWithFilter);

        return colWithRender;
      }

      return {...col, children: recursivelyMapColumns(col.children)};
    });
  };

  const mappedColumns = recursivelyMapColumns(columns);
  const reorderedColumns = reorderColumns(mappedColumns, groupingConfig);

  return reorderedColumns;
};