import cn from "classnames";
import {AppText} from "shared/UI/UIKit/Text";
import type {ProgramTemplateBlockTableRowField} from "services/api";
import {FIELD_CONSTANTS} from "../../../../../lib/constants";
import styles from "./styles.module.scss";

export const listValueRenderer = (
  value: ProgramTemplateBlockTableRowField["value"],
  placeholder: ProgramTemplateBlockTableRowField["placeholder"],
  isWidget: boolean,
) => {
  const showValue = !!value || !isWidget;
  const className = cn(styles.text, !showValue && styles["text_empty"]);

  const getValue = () => {
    return (value || "")
      .split(FIELD_CONSTANTS.MULTIPLE_VALUE_SPLITTER)
      .join(FIELD_CONSTANTS.MULTIPLE_VALUE_JOINER);
  };

  return (
    <AppText size={16} className={className}>
      {showValue ? getValue() : placeholder}
    </AppText>
  );
};