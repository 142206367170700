import cn from "classnames";
import {memo, useState} from "react";
import type {FocusEvent, KeyboardEvent} from "react";
import type {TableWidgetDateCellProps} from "./types";
import type {DateValueEditorSubmitValues} from "../../cell-value-editors/date-value-editor";
import {visibility} from "../../../../../lib/core";
import {dateValueRenderer} from "../../cell-value-renderers/date-value-renderer";
import {DateValueEditor} from "../../cell-value-editors/date-value-editor";
import widgetCellStyles from "../../styles/widget.module.scss";
import {TableBaseCell} from "../TableBaseCell";
import {LockedIcon} from "../LockedIcon";
import widgetDateCellStyles from "./styles.module.scss";

export const TableWidgetDateCell = memo(({
  field,
  onChange,
  isPrograms,
  isFullAccess,
  editMode,
  defaultPickerValue,
  validationStatus,
}: TableWidgetDateCellProps) => {
  const [isEditorEnabled, setEditorEnabled] = useState(false);
  const isCellEditable = editMode && (isFullAccess || !field.readonly);
  const isCellHighlightable = editMode || !isPrograms;
  const isCellWidget = isCellEditable || (!editMode && !isPrograms && !field.readonly);
  const isCellWidgetError = isCellHighlightable && validationStatus === "error";
  const isLockedIconVisible = visibility.checkTableCellLockedIconVisible({editMode, isFullAccess});
  const cellClassName = cn(
    isCellWidget && widgetCellStyles["widget-cell"],
    isCellWidgetError && widgetCellStyles["widget-cell_error"],
    isEditorEnabled && widgetDateCellStyles["widget-date-cell_edit"],
  );

  const handleCellClick = () => {
    if (isCellEditable) {
      setEditorEnabled(true);
    }
  };

  const handleCellBlur = (e: FocusEvent<HTMLTableCellElement>) => {
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setEditorEnabled(false);
    }
  };

  const handleEditorKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      setEditorEnabled(false);
    }
  };

  const handleEditorSubmit = (editorValues: DateValueEditorSubmitValues) => {
    onChange({...field, value: editorValues.value});
  };

  const handleLockedIconToggle = (nextLocked: boolean) => {
    onChange({...field, readonly: nextLocked});
  };

  return (
    <TableBaseCell isFullAccess={isFullAccess} editMode={editMode}
                   index={field.index} editable={isCellEditable} className={cellClassName}
                   onClick={handleCellClick} onBlur={handleCellBlur}>
      {isEditorEnabled
        ? (
          <DateValueEditor onSubmit={handleEditorSubmit} onKeyDown={handleEditorKeyDown}
                           value={{
                             initialValue: field.value,
                             defaultPickerValue,
                             placeholder: field.placeholder,
                             required: !isFullAccess && !!field.required,
                           }}/>
        ) : (
          <>
            {isLockedIconVisible && <LockedIcon locked={!!field.readonly} toggle={handleLockedIconToggle}/>}
            {dateValueRenderer(field.value, field.placeholder, isCellWidget)}
          </>
        )
      }
    </TableBaseCell>
  );
});