import {AppText} from "shared/UI/UIKit/Text";
import type {ProgramTemplateBlockTableRowField} from "services/api";
import "./styles.scss";

export const numberValueRenderer = (
  value: ProgramTemplateBlockTableRowField["value"],
  placeholder: ProgramTemplateBlockTableRowField["placeholder"],
  isWidget: boolean,
) => {
  const showValue = !!value || !isWidget;

  return (
    <AppText size={16} className="table-widget-number">
      {showValue ? `${value || ""}` : placeholder}
    </AppText>
  );
};