import {generatePath} from "react-router";

export const buildUrl = (
  url: string,
  params: Record<string, string>,
  searchParams?: Record<string, string>
): string => {
  const mainUrl = generatePath(url, params);
  const searchParamsPart = new URLSearchParams(searchParams).toString();

  return searchParamsPart ? `${mainUrl}?${searchParamsPart}` : mainUrl;
};