import type {Curriculum, EducationLevel, MainProgramItem} from "services/api";

export const useOopLocalStorage = () => {
  const addEducationLevelToLocalStorage = (educationLevel: EducationLevel) => {
    const currentWizard = localStorage.getItem("WIZARD") || "{}";
    const nextWizard = JSON.parse(currentWizard);
    nextWizard.educationLevel = educationLevel;
    localStorage.setItem("WIZARD", JSON.stringify(nextWizard));
  };

  const addCurriculumsToLocalStorage = (curriculums: Curriculum[]) => {
    const currentWizard = localStorage.getItem("WIZARD") || "{}";
    const nextWizard = JSON.parse(currentWizard);
    nextWizard.curriculums = curriculums;
    localStorage.setItem("WIZARD", JSON.stringify(nextWizard));
  };

  const addActiveStepToLocalStorage = (activeStep: number) => {
    const currentWizard = localStorage.getItem("WIZARD") || "{}";
    const nextWizard = JSON.parse(currentWizard);
    nextWizard.activeStep = activeStep;
    localStorage.setItem("WIZARD", JSON.stringify(nextWizard));
  };

  const addWorkProgramByFieldNameToLocalStorage = (fieldName: string, mainProgramItem: MainProgramItem) => {
    const currentWizard = localStorage.getItem("WIZARD") || "{}";
    let nextWizard = JSON.parse(currentWizard);
    nextWizard = {
      ...nextWizard,
      workPrograms: nextWizard.workPrograms ? [
        ...nextWizard.workPrograms,
        {
          fieldName,
          mainProgramItem,
        }
      ] : [
        {
          fieldName,
          mainProgramItem,
        }
      ]
    };
    localStorage.setItem("WIZARD", JSON.stringify(nextWizard));
  };

  const clearWizardInLocalStorage = () => {
    localStorage.setItem("WIZARD", JSON.stringify({}));
  };

  const clearWorkProgramsByFieldNameInLocalStorage = () => {
    const currentWizard = localStorage.getItem("WIZARD") || "{}";
    let nextWizard = JSON.parse(currentWizard);
    nextWizard = {
      ...nextWizard,
      workPrograms: [],
    };
    localStorage.setItem("WIZARD", JSON.stringify(nextWizard));
  };

  return {
    addEducationLevelToLocalStorage,
    addCurriculumsToLocalStorage,
    addActiveStepToLocalStorage,
    clearWizardInLocalStorage,
    addWorkProgramByFieldNameToLocalStorage,
    clearWorkProgramsByFieldNameInLocalStorage,
  };
};
