export const appPagePaths = {
  root: "/",
  home: "/",
  logout: "/logout",
  authorization: "/authorization",
  mainPrograms: "/main-programs",
  mainProgramTemplates: "/main-program-templates",
  mainProgram: "/main-program/:mainProgramId",
  viewMainProgram: "/main-program/:mainProgramId/:mainProgramPartId",
  viewMainProgramTemplate: "/main-program-template/:mainProgramId/:mainProgramPartId",
  createMainProgram: "/main-program/create",
  previewMainProgram: "/main-program/preview/:mainProgramId/:mainProgramPartId",
  workProgramsElementary: "/work-programs-elementary",
  workProgramsGeneral: "/work-programs-general",
  workProgramsSecondary: "/work-programs-secondary",
};

export const appRouterPaths = {
  root: "/",
  home: "/",
  logout: "/logout",
  authorization: "/authorization",
  mainPrograms: "/main-programs",
  mainProgramTemplates: "/main-program-templates",
  mainProgram: "/main-program/:mainProgramId",
  viewMainProgram: "/main-program/:mainProgramId/:mainProgramPartId",
  viewMainProgramTemplate: "/main-program-template/:mainProgramId/:mainProgramPartId",
  createMainProgram: "/main-program/create",
  previewMainProgram: "/main-program/preview/:mainProgramId/:mainProgramPartId",
  workProgramsElementary: "/work-programs-elementary",
  workProgramsGeneral: "/work-programs-general",
  workProgramsSecondary: "/work-programs-secondary",
};


