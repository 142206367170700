import {useCallback} from "react";
import {useModal, usePayloadedModal} from "shared/UI/UIKit/Modal";
import type {TableConstructorColumn, ClickedCell} from "../../../../../lib/types";
import type {ColumnSettingsModalPayload, TableModalsReturnValue} from "./types";
import {useTableConstructorContext} from "../../../../contexts/table-constructor-context";

export const useTableModals = (): TableModalsReturnValue => {
  const {state: tableSettingsModalState, methods: tableSettingsModalMethods} = useModal();
  const {
    state: columnSettingsModalState,
    methods: columnSettingsModalMethods
  } = usePayloadedModal<ColumnSettingsModalPayload>();
  const {state: cellSettingsModalState, methods: cellSettingsModalMethods} = usePayloadedModal<ClickedCell>();
  const {isFullAccess, editMode} = useTableConstructorContext();

  const handleColumnSettingsModalShow = useCallback((column: TableConstructorColumn) => {
    columnSettingsModalMethods.show({column});
  }, []);

  const handleCellClicked = (cell: ClickedCell) => {
    if (!editMode) {
      return;
    }

    switch (cell.field.type) {
      case "WIDGET_LIST":
      case "WIDGET_REFERENCE":
      case "WIDGET_SUM":
      case "WIDGET_PERCENT": {
        if (isFullAccess) {
          cellSettingsModalMethods.show(cell);
        }
        break;
      }
      default: {
        return;
      }
    }
  };

  return [
    {
      tableSettingsModalState,
      columnSettingsModalState,
      cellSettingsModalState,
    },
    {
      tableSettingsModalMethods,
      columnSettingsModalMethods: {
        show: handleColumnSettingsModalShow,
        hide: columnSettingsModalMethods.hide,
      },
      cellSettingsModalMethods: {
        hide: cellSettingsModalMethods.hide,
      },
      clickedCellMethods: {
        click: handleCellClicked,
      }
    }
  ];
};