import {createSelector} from "@reduxjs/toolkit";
import type {WorkProgramsState} from "features/work-programs/redux/types";
import type {RootState} from "services/redux/types";


export const selectWorkProgramsState = (state: RootState) => state.workProgramsState;

export const selectWorkProgramsLoading = createSelector(
  selectWorkProgramsState,
  (workProgramsState: WorkProgramsState) => workProgramsState.loading
);

export const selectWorkPrograms = createSelector(
  selectWorkProgramsState,
  (workProgramsState: WorkProgramsState) => workProgramsState.workPrograms
);

export const selectAllSubjects = createSelector(
  selectWorkProgramsState,
  (workProgramsState: WorkProgramsState) => workProgramsState.subjects
);

export const selectWorkProgramsTotal = createSelector(
  selectWorkProgramsState,
  (workProgramsState: WorkProgramsState) => workProgramsState.total
);
