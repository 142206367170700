import classNames from "classnames";
import {useState} from "react";
import {useForm} from "antd/es/form/Form";
import {useAppDispatch} from "services/redux/hooks/use-dispatch";
import {updateAdditionalEducationTableAction} from "features/main-program/redux/slice";
import {useAppSelector} from "services/redux/hooks/use-selector";
import {selectMainProgramBlock} from "features/main-program/redux/selectors";
import {calculateSum} from "features/main-program/redux/utils";
import type {ExtracurricularTableBodyRow,ExtracurricularTableBodyCell} from "services/api";
import styles from "./styles.module.scss";
import {EditingBodyRowCell} from "./EditingCell";

type BodyRowCellProps = {
  cell: ExtracurricularTableBodyCell;
  editable?: boolean;
}

export const BodyRowCell = (props: BodyRowCellProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const [form] = useForm();
  const dispatch = useAppDispatch();
  const mainProgramBlock = useAppSelector(selectMainProgramBlock);

  const onCellBlur = () => {
    setIsEditing(false);
    const value = form.getFieldValue("value");
    const currentRows: ExtracurricularTableBodyRow[] = JSON.parse(mainProgramBlock!.content!);
    const newRows = currentRows.map(row => {
      return {
        ...row,
        cells: row.cells.map(cell => {
          if (cell.cellId === props.cell.cellId) {
            return {
              ...cell,
              value,
            };
          } else {
            return cell;
          }
        })
      };
    });

    const newContent = JSON.stringify(calculateSum(newRows));

    dispatch(updateAdditionalEducationTableAction(newContent));
    form.resetFields();
  };

  const onCellClick = () => {
    if (props.cell.editable && props.editable) {
      setIsEditing(true);
    }
  };

  const renderContent = () => {
    return Array.isArray(props.cell.value) ? (
      props.cell.value?.map((item, index) => {
        return (
          <div key={index}>{item}</div>
        );
      })
    ) : props.cell.value;
  };

  return (
    <td colSpan={props.cell.colSpan} style={props.cell.styles} onBlur={onCellBlur} onClick={onCellClick} className={classNames(
      props.cell.editable && props.editable && styles.editableCell
    )}>
      {
        isEditing ? (
          <EditingBodyRowCell form={form} cell={props.cell}/>
        ) : (
          <>{renderContent()}</>
        )
      }
    </td>
  );
};