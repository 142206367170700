import {AppLoader} from "shared/UI/UIKit/Loader";
import {AppFlex} from "shared/UI/UIKit/Flex";
import {AppEmpty} from "shared/UI/UIKit/Empty";
import type {NotFoundProps} from "./NotFound.types";

export const NotFound = (props: NotFoundProps) => {
  return props.loading ? (
    <AppFlex>
      <AppLoader/>
    </AppFlex>
  ) : (
    <AppEmpty/>
  );
};