import {useCallback, useImperativeHandle, useRef} from "react";
import type {FunctionType} from "shared/types";

/*
 main idea's from here:
 https://github.com/reactjs/rfcs/blob/useevent/text/0000-useevent.md#internal-implementation
*/
export const useEvent = <Args extends any[] = any[], Res = any>(
  handler: FunctionType<Args, Res>
): typeof handler => {
  const handlerRef = useRef(handler);

  useImperativeHandle(handlerRef, () => handler);

  return useCallback((...args) => handlerRef.current(...args), []);
};