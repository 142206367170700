import {homeBreadcrumbItem} from "shared/UI/UIKit/Breadcrumbs";
import {AppPage} from "common/Layout";
import {WorkPrograms} from "features/work-programs";
import {EducationLevel} from "services/api";

const breadcrumbs = [
  homeBreadcrumbItem,
  {
    title: "Рабочие программы НОО",
    url: ""
  },
];

export const WorkProgramsElementaryPage = () => {
  return (
    <AppPage breadcrumbs={{items: breadcrumbs}}
             documentTitle="Рабочие программы НОО">
      <WorkPrograms educationLevel={EducationLevel.НОО}/>
    </AppPage>
  );
};

