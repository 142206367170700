import cn from "classnames";
import type {CheckboxGroupDirection} from "./CheckboxGroup.types";
import styles from "./CheckboxGroup.module.scss";

export const getClassNames = (className?: string, direction?: CheckboxGroupDirection) => {
  return cn(
    {[styles.vertical]: direction === "vertical"},
    className,
  );
};
