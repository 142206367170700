import SunEditor from "suneditor-react";
import type {AppTextEditorProps} from "./TextEditor.types";
import type SunEditorCore from "suneditor/src/lib/core";
import {getContainerClassNames} from "./TextEditor.utils";
import {config} from "./config";
import "./styles/TextEditor.scss";
import "./styles/placeholder.scss";
import "./styles/placeholder-block.scss";

export const AppTextEditor = (props: AppTextEditorProps) => {

  const containerClassNames = getContainerClassNames(props.hideToolbar);

  const getSunEditorInstance = (sunEditor: SunEditorCore) => {
    props.editorRef.current = sunEditor;
  };

  return (
    <div className={containerClassNames} onClick={props.onEditorClick}>
      <SunEditor setOptions={config} getSunEditorInstance={getSunEditorInstance}
                 lang={"ru"} {...props}/>
    </div>
  );
};