import {createSelector} from "@reduxjs/toolkit";
import type {MainProgramState} from "./types";
import type {RootState} from "services/redux/types";

export const selectMainProgramState = (state: RootState) => state.mainProgramState;

export const selectMainProgramLoading = createSelector(
  selectMainProgramState,
  (mainProgramState: MainProgramState) => mainProgramState.loading || mainProgramState.mainProgramPartLoading
);

export const selectMainProgram = createSelector(
  selectMainProgramState,
  (mainProgramState: MainProgramState) => mainProgramState.mainProgram
);

export const selectRenderType = createSelector(
	selectMainProgramState,
	(mainProgramState: MainProgramState) => mainProgramState.renderType
);

export const selectWorkProgram = createSelector(
  selectMainProgramState,
  (mainProgramState: MainProgramState) => mainProgramState.workProgram
);

export const selectMainProgramBlock = createSelector(
  selectMainProgramState,
  (mainProgramState: MainProgramState) => mainProgramState.mainProgramBlock,
);

export const selectMainProgramCurriculum = createSelector(
	selectMainProgramState,
	(mainProgramState: MainProgramState) => mainProgramState.detailedCurriculum,
);
