import {useMemo} from "react";
import type {ProgramTemplateBlockTableSection} from "services/api";
import type {TableBodyProps} from "./types";
import {useTableConstructorContext} from "../../contexts/table-constructor-context";
import {visibility} from "../../../lib/core";
import {modulesHelpers} from "../../../lib/helpers";

export const useTableBody = (props: TableBodyProps) => {
  const {isFullAccess, editMode, visibleColumns} = useTableConstructorContext();
  const isTableSectionCreatorVisible = visibility.checkTableSectionCreatorVisible({
    tableType: props.type,
    editMode,
    isFullAccess,
    sectionCreationAllowed: !!props.settings.sectionCreationAllowed,
  });
  const isTableModuleCreatorVisible = visibility.checkTableModuleCreatorVisible({
    tableType: props.type,
    editMode,
    isFullAccess,
    sectionCreationAllowed: !!props.settings.sectionCreationAllowed,
  });
  const visibleColumnCount = visibleColumns.length;
  const moduleIndexes = modulesHelpers.getModuleIndexes(props.sections);
  const areModulesExist = !!moduleIndexes.length;
  const moduleLastSectionIndexes =
    moduleIndexes.concat(props.sections.length - 1) // concat module indexes with table footer index
      .slice(1) // start from the 2nd module (or from the table footer index if there's only one module)
      .map(index => index - 1); // and take previous index, so for each module it will return index of its last section

  const {tableSections, tableFooterSections} = useMemo(() => {
    return props.sections.reduce((acc, section) => {
      if (section.type === "TABLE") {
        return {...acc, tableSections: [...acc.tableSections, section]};
      } else {
        return {...acc, tableFooterSections: [...acc.tableFooterSections, section]};
      }
    }, {
      tableSections: [],
      tableFooterSections: [],
    } as {
      tableSections: ProgramTemplateBlockTableSection[];
      tableFooterSections: ProgramTemplateBlockTableSection[];
    });
  }, [props.sections]);

  return [
    {
      tableSections,
      tableFooterSections,
      isTableSectionCreatorVisible,
      isTableModuleCreatorVisible,
      visibleColumnCount,
      areModulesExist,
      moduleLastSectionIndexes,
    },
    null
  ] as const;
};