import type {ReactNode} from "react";
import type {AppMenuItem, AppMenuItemGroupChild} from "../../..";

export type GroupMenuItemOptions = {
  key?: string,
  label?: ReactNode,
  wrapperClassName?: string,
  children: AppMenuItem[],
}

export const getGroupMenuItem = ({
  key,
  label,
  wrapperClassName,
  children,
}: GroupMenuItemOptions): AppMenuItem => {
  return {
    type: "group",
    key,
    label,
    className: wrapperClassName,
    children: children as AppMenuItemGroupChild[],
  };
};