import type {Moment} from "moment";
import {errorMessages} from "../../error-messages";

export const dateValidator = (required = true, msg = errorMessages.required) => {
  return (_: any, value: Moment | null | undefined) => {
    if (!value) {
      return required ? Promise.reject(msg) : Promise.resolve();
    }

    return Promise.resolve();
  };
};