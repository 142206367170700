import {Typography} from "antd";
import type {AppTitleProps} from "./Title.types";

export const AppTitle = (props: AppTitleProps) => {
  const {level = 2, ...rest} = props;

  return (
    <Typography.Title level={level} {...rest}>
      {props.children}
    </Typography.Title>
  );
};
