export const serializeStyleWordList = (node: Document): void => {
  const lists = node.querySelectorAll<HTMLElement>("ul");
  lists.forEach((list) => {
    if (list.children.length === 0) {
      return;
    }
    let listItemMargin: string;

    Array.from(list.children).forEach((item, index) => {
      if (index === 0) {
        listItemMargin = (item as HTMLElement).style.marginLeft;
      }
      item.removeAttribute("style");
    });

    list.style.paddingLeft = listItemMargin!;
  });
};