import {useCallback, useEffect, useState} from "react";
import transparent from "shared/UI/assets/images/transparent.png";
import type {AppImageProps} from "../Image.types";

export const useAppImage = (originalSrc: AppImageProps["src"]) => {
  const [src, setSrc] = useState<AppImageProps["src"]>(originalSrc);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    setSrc(originalSrc);

    // undefined or null src value does not trigger onError callback,
    // so we call it manually (to show preview img for these cases)
    if (originalSrc === undefined || originalSrc === null) {
      onError();
    } else {
      setError(false);
    }
  }, [originalSrc]);

  const onError = useCallback(() => {
    // we set a transparent image to src as a workaround to hide default preview image
    // and show custom image via background-image
    setSrc(transparent);
    setError(true);
  }, []);

  return {
    src,
    error,
    onError,
  };
};