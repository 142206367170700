import type {ActionReducerMapBuilder} from "@reduxjs/toolkit";
import type {WorkProgramsState} from "./types";
import {
  getAllSubjectsAction,
  getWorkProgramsAction,
  setWorkProgramAvailableInMainProgramAction,
  toggleVisibleWorkProgramInMainProgramListAction
} from "./actions";

export function getWorkProgramsReducerBuilder(builder: ActionReducerMapBuilder<WorkProgramsState>) {
  builder.addCase(getWorkProgramsAction.pending, (state) => {
    state.loading = true;
  });
  builder.addCase(getWorkProgramsAction.fulfilled, (state, action) => {
    state.workPrograms = action.payload.items;
    state.total = action.payload.total;
    state.loading = false;
  });
  builder.addCase(getWorkProgramsAction.rejected, (state) => {
    state.loading = false;
  });
}

export function getAllSubjectsReducerBuilder(builder: ActionReducerMapBuilder<WorkProgramsState>) {
  builder.addCase(getAllSubjectsAction.pending, (state) => {
    state.loading = true;
  });
  builder.addCase(getAllSubjectsAction.fulfilled, (state, action) => {
    state.subjects = action.payload;
    state.loading = false;
  });
  builder.addCase(getAllSubjectsAction.rejected, (state) => {
    state.loading = false;
  });
}

export function setWorkProgramAvailableInMainProgramReducerBuilder(builder: ActionReducerMapBuilder<WorkProgramsState>) {
  builder.addCase(setWorkProgramAvailableInMainProgramAction.pending, (state) => {
    state.loading = true;
  });
  builder.addCase(setWorkProgramAvailableInMainProgramAction.fulfilled, (state, action) => {
    state.workPrograms = state.workPrograms.map(item => {
      if (item.id === action.payload) {
        return {
          ...item,
          showInMainPrograms: !item.showInMainPrograms,
        };
      } else {
        return item;
      }
    });
    state.loading = false;
  });
  builder.addCase(setWorkProgramAvailableInMainProgramAction.rejected, (state) => {
    state.loading = false;
  });
}

export function toggleVisibleWorkProgramInMainProgramListReducerBuilder(builder: ActionReducerMapBuilder<WorkProgramsState>) {
  builder.addCase(toggleVisibleWorkProgramInMainProgramListAction.pending, (state) => {
    state.loading = true;
  });
  builder.addCase(toggleVisibleWorkProgramInMainProgramListAction.fulfilled, (state, action) => {
    state.workPrograms = state.workPrograms.filter(item => item.id !== action.payload);
    state.total = state.total ? state.total - 1 : state.total;
    state.loading = false;
  });
  builder.addCase(toggleVisibleWorkProgramInMainProgramListAction.rejected, (state) => {
    state.loading = false;
  });
}
