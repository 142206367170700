import {forwardRef} from "react";
import type {AppOutlineButtonProps} from "./OutlineButton.types";
import {AppBaseButton} from "../BaseButton";
import {getClassNames} from "./OutlineButton.utils";

export const AppOutlineButton = forwardRef<HTMLButtonElement, AppOutlineButtonProps>((
  props, ref) => {
  const classNames = getClassNames(props);

  return (
    <AppBaseButton {...props} className={classNames} ref={ref}/>
  );
});