import {useCallback, useState} from "react";
import type {PayloadedModalService, PayloadedModalPayload} from "./types";
import {useModal} from "../use-modal";

export const usePayloadedModal = <T extends PayloadedModalPayload>(): PayloadedModalService<T> => {
  const [payload, setPayload] = useState<T | null>(null);
  const {state, methods} = useModal();

  const show = useCallback((nextPayload?: T) => {
    setPayload(nextPayload ?? null);
    methods.show();
  }, []);

  const hide = useCallback(() => {
    setPayload(null);
    methods.hide();
  }, []);

  return {
    state: {
      payload,
      visible: state.visible,
    },
    methods: {
      show,
      hide,
    },
  };
};