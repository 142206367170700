import {useCallback, useRef, useState} from "react";
import type {FunctionType} from "shared/types";
import type {SelectedRow, ActionTypes} from "../../../../../lib/types";
import type {TableActionOptions, TableActionReturnValue, SelectedRowActionNames} from "./types";
import {actionNames} from "../../../../../lib/core";
import {useTableConstructorContext} from "../../../../contexts/table-constructor-context";

export const useTableActions = (props: TableActionOptions): TableActionReturnValue => {
  const innerContainerRef = useRef<HTMLDivElement>(null);
  const selectedRowRef = useRef<HTMLElement | null>(null);
  const onUnselectCallbackRef = useRef<FunctionType | null>();
  const [selectedRow, setSelectedRow] = useState<SelectedRow | null>(null);
  const [selectedRowActionNames, setSelectedRowActionNames] = useState<SelectedRowActionNames | null>(null);
  const {editMode, isPrograms, isFullAccess} = useTableConstructorContext();

  const tableActions: ActionTypes.TableAction[] =
    actionNames.getTableActionNames({editMode, isPrograms, isFullAccess, settings: props.settings})
      .map(name => {
        switch (name) {
          case "tableSettings": return {name, onClick: props.tableMethods.showSettings};
          case "tableRemove": return {name, onClick: props.tableMethods.remove};
        }
      });

  const moduleActions: ActionTypes.ModuleAction[] = (
    selectedRow?.meta.row.type === "SECTION_HEADER_ROW" &&
    selectedRow?.meta.section.isModule
  ) ? (selectedRowActionNames as ActionTypes.ModuleActionName[])
      ?.map(name => {
        switch (name) {
          case "moduleInsert": return {name, onClick: props.moduleMethods.insert};
          case "moduleCopy": return {name, onClick: props.moduleMethods.copy};
          case "moduleMove": return {name, onClick: props.moduleMethods.move};
          case "moduleRemove": return {name, onClick: props.moduleMethods.remove};
        }
      })
    : [];

  const sectionActions: ActionTypes.SectionAction[] = (
    selectedRow?.meta.row.type === "SECTION_HEADER_ROW" &&
    !selectedRow?.meta.section.isModule
  ) ? (selectedRowActionNames as ActionTypes.SectionActionName[])
      ?.map(name => {
        switch (name) {
          case "sectionInsert": return {name, onClick: props.sectionMethods.insert};
          case "sectionCopy": return {name, onClick: props.sectionMethods.copy};
          case "sectionMove": return {name, onClick: props.sectionMethods.move};
          case "sectionRemove": return {name, onClick: props.sectionMethods.remove};
        }
      })
    : [];

  const rowActions: ActionTypes.RowAction[] = (
    selectedRow?.meta.row.type === "SECTION_BODY_ROW"
  ) ? (selectedRowActionNames as ActionTypes.RowActionName[])
      ?.map(name => {
        switch (name) {
          case "rowInsert": return {name, onClick: props.rowMethods.insert};
          case "rowCopy": return {name, onClick: props.rowMethods.copy};
          case "rowMove": return {name, onClick: props.rowMethods.move};
          case "rowRemove": return {name, onClick: props.rowMethods.remove};
        }
      })
    : [];

  const _setSelectedRow = (row: SelectedRow | null, onUnselectCallback?: FunctionType) => {
    if (row) {
      selectedRowRef.current = row.target;
      setSelectedRow(row);
      onUnselectCallbackRef.current = onUnselectCallback;
    } else {
      onUnselectCallbackRef.current?.();
      onUnselectCallbackRef.current = null;
      selectedRowRef.current = null;
      setSelectedRow(null);
    }
  };

  const handleRowSelect = useCallback((row: SelectedRow, onUnselectCallback?: FunctionType) => {
    switch (row.meta.row.type) {
      case "SECTION_HEADER_ROW": {
        const nextSectionActionNames = row.meta.section.isModule
          ? actionNames.getModuleActionNames(row, isFullAccess, props.settings)
          : actionNames.getSectionActionNames(row, isFullAccess, props.settings);
        setSelectedRowActionNames(nextSectionActionNames);
        _setSelectedRow(nextSectionActionNames.length ? row : null, onUnselectCallback);
        return;
      }
      case "SECTION_BODY_ROW": {
        const nextRowActionNames = actionNames.getRowActionNames(row, isFullAccess, props.settings);
        setSelectedRowActionNames(nextRowActionNames);
        _setSelectedRow(nextRowActionNames.length ? row : null, onUnselectCallback);
        return;
      }
      default: {
        return;
      }
    }
  }, [isFullAccess, props.settings]);

  const handleRowUnselect = useCallback(() => {
    setSelectedRowActionNames(null);
    _setSelectedRow(null);
  }, []);

  return [
    {
      innerContainerRef,
      selectedRowRef,
      selectedRow,
      actions: {
        tableActions,
        moduleActions,
        sectionActions,
        rowActions,
      },
    },
    {
      selectedRowMethods: {
        select: handleRowSelect,
        unselect: handleRowUnselect,
      },
    },
  ];
};