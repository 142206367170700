import {getStringFieldComparator} from "shared/helpers/utils/comparators";
import type {Comparator} from "shared/helpers/utils/comparators";
import type {AppSelectOption} from "../../";

export const getTextSorter = (sortByField = "label"): Comparator<AppSelectOption> => {
  return getStringFieldComparator<AppSelectOption>(
    item => {
      const value = item?.[sortByField];
      return (value === undefined || value === null)
        ? value
        : String(value).toLowerCase();
    },
    "ascend",
  );
};