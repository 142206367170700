import "moment/locale/ru";
import "./styles/index.scss";
import {AppErrorBoundary} from "shared/UI/UIKit/ErrorBoundary";
import {AppProviders} from "./providers";
import {AppRouting} from "./routing";

export const App = () => {
  return (
    <AppErrorBoundary>
      <AppProviders>
        <AppRouting/>
      </AppProviders>
    </AppErrorBoundary>
  );
};
