import type {AppTableItem, AppTableGroupingConfig} from "../../../types";
import {isGroupingByParentKey} from "../../../Table.utils";
import {groupByParentKey} from "./group-by-parent-key";
import {groupByDataIndexes} from "./group-by-data-indexes";

export const groupDataSource = <TRecord extends AppTableItem>(
  dataSource: TRecord[],
  groupingConfig?: AppTableGroupingConfig<TRecord>
): TRecord[] => {
  if (!groupingConfig) {
    return dataSource;
  }

  if (isGroupingByParentKey(groupingConfig)) {
    return groupByParentKey(dataSource, groupingConfig);
  }

  return groupByDataIndexes(dataSource, groupingConfig);
};