import {useMemo} from "react";
import {useMemoCompare} from "shared/helpers/hooks/use-memo-compare";
import {deepEqual} from "shared/helpers/utils/deep-equal";
import type {AppTableItem, AppTableProps} from "../../types";
import type {TableDataSourceServiceOptions} from "./types";
import {groupDataSource} from "./group";

export const useTableDataSource = <TRecord extends AppTableItem>({
  dataSource,
  rowKey,
  groupingConfig: nextGroupingConfig,
}: TableDataSourceServiceOptions<TRecord>): TRecord[] => {
  const groupingConfig = useMemoCompare(nextGroupingConfig, deepEqual);

  return useMemo(() => {
    const dataSourceWithKeys = addKeysToData(dataSource, rowKey);
    return groupDataSource(dataSourceWithKeys, groupingConfig);
  }, [dataSource, groupingConfig]);
};

const addKeysToData = <TRecord extends AppTableItem>(
  data: TRecord[],
  rowKey: AppTableProps<TRecord>["rowKey"],
): TRecord[] => {
  return data.map((item, index) => ({
    ...item,
    key: rowKey(item, index),
    children: item.children ? addKeysToData(item.children as TRecord[], rowKey) : undefined,
  }));
};