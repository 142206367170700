import {errorMessages} from "../../error-messages";

export const booleanValidator = (required = true, message = errorMessages.required) => {
  return (_: any, value: boolean | null | undefined) => {
    if (!value) {
      return required ? Promise.reject(message) : Promise.resolve();
    }

    return Promise.resolve();
  };
};