import {EducationLevel} from "services/api";
import {BasicEducationHeader} from "./Basic";
import {ElementaryEducationHeader} from "./Elementary";
import {HighEducationHeader} from "./High";

type HeaderProps = {
  educationLevel?: EducationLevel;
}

export const Header = (props: HeaderProps) => {
  if (props.educationLevel === EducationLevel.НОО) {
    return <BasicEducationHeader/>;
  } else if (props.educationLevel === EducationLevel.ООО) {
    return <ElementaryEducationHeader/>;
  } else {
    return <HighEducationHeader/>;
  }
};