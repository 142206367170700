import {AppFlex} from "shared/UI/UIKit/Flex";
import {AppLink} from "shared/UI/UIKit/Link";
import {getHomeUrl} from "services/navigation";
import {AppLogoIcon} from "shared/UI/UIKit/icons/Logo";
import {AppText} from "shared/UI/UIKit/Text";
import styles from "./Header.module.scss";
import {AppHeaderMenu} from "./Menu/Menu";

const HOME_URL = getHomeUrl();

export const AppHeader = () => {
  return (
    <header className={styles.header}>
      <AppFlex className={styles.container} unit="0" direction="row"
               alignItems="center" justifyContent="space-between">
        <AppLink to={HOME_URL}>
          <AppFlex unit="12" direction="row" alignItems="center">
            <AppLogoIcon/>
            <AppText type="secondary" size={16}>
              Система управления сервисом
            </AppText>
          </AppFlex>
        </AppLink>
        <AppHeaderMenu/>
      </AppFlex>
    </header>
  );
};