import cn from "classnames";
import {AppDeleteIcon} from "shared/UI/UIKit/icons/Delete";
import type {SvgProps} from "shared/types";
import styles from "../styles.module.scss";

export const DeleteIcon = ({className, ...rest}: SvgProps) => {
  return (
    <AppDeleteIcon className={cn(styles.icon, styles["icon_size_24"], className)}
                   {...rest}/>
  );
};