import cn from "classnames";
import {AppIconButton} from "shared/UI/UIKit/Button";
import {AppCloseIcon} from "shared/UI/UIKit/icons/Close";
import type {ModalCloseBtnProps} from "./ModalCloseBtn.types";
import styles from "./ModalCloseBtn.module.scss";

export const ModalCloseBtn = ({visible, className, style, onClick}: ModalCloseBtnProps) => {
  if (!visible) {
    return null;
  }

  const classNames = cn(styles.btn, className);

  return (
    <AppIconButton className={classNames} style={style} onClick={onClick}>
      <AppCloseIcon/>
    </AppIconButton>
  );
};