import {
  getProgramTemplateBlockTableRowDTO,
  getProgramTemplateBlockTableRowFieldDTO,
  getProgramTemplateBlockTableSectionDTO
} from "services/api/blocks/dto";
import {generateUUID} from "shared/helpers/utils/generate-uuid";
import type {ProgramTemplateBlockTable, ProgramTemplateBlockTableSection} from "services/api";

export const checkSectionIsModule = (section: ProgramTemplateBlockTableSection) => {
  return (
    section.type === "TABLE" &&
    section.rows.length === 1 &&
    section.rows[0].type === "SECTION_HEADER_ROW"
  );
};

export const getModuleIndexes = (sections: ProgramTemplateBlockTableSection[]): number[] => {
  return sections.reduce((acc, section, index) => {
    if (checkSectionIsModule(section)) {
      return [...acc, index];
    }

    return acc;
  }, [] as number[]);
};

export const createTableModule = (
  tableId: ProgramTemplateBlockTable["id"],
  sectionIndex: ProgramTemplateBlockTable["index"],
  createdOnWorkProgram: boolean,
) => {
  return getProgramTemplateBlockTableSectionDTO({
    id: generateUUID("fake_"),
    table: {id: tableId},
    index: sectionIndex,
    type: "TABLE",
    createdOnWorkProgram,
    rows: [
      getProgramTemplateBlockTableRowDTO({
        id: generateUUID("fake_"),
        section: null,
        index: 0,
        type: "SECTION_HEADER_ROW",
        createdOnWorkProgram,
        fields: [
          getProgramTemplateBlockTableRowFieldDTO({
            id: generateUUID("fake_"),
            row: null,
            index: 0,
            type: "WIDGET_STRING",
            value: "**[[Название модуля]]**",
            required: true,
            readonly: true,
            placeholder: "Введите название модуля",
          }),
        ],
      }),
    ],
  });
};

export const checkMoreThanOneTableModuleExists = (sections: ProgramTemplateBlockTableSection[]) => {
  let singleTableModuleExist = false;

  for (let i = 0; i < sections.length; i++) {
    if (checkSectionIsModule(sections[i])) {
      if (!singleTableModuleExist) {
        singleTableModuleExist = true;
      } else {
        return true;
      }
    }
  }

  return false;
};

export const makeLowestTableModuleHighest = (
  sections: ProgramTemplateBlockTableSection[],
): void => {
  const currentModuleIndex = 0;
  const nextModuleIndex = findNextModuleIndex(sections, currentModuleIndex);

  for (let i = currentModuleIndex; i < sections.length - 1; i++) { // sections.length - 1 because the last section is the footer
    sections[i].index -= nextModuleIndex;
  }

  for (let i = currentModuleIndex; i < nextModuleIndex; i++) {
    sections[i].index = sections.at(-2)!.index + 1 + i; // -2 because we need a last section with type TABLE
  }
};

export const makeHighestTableModuleLowest = (
  sections: ProgramTemplateBlockTableSection[],
  firstModuleSectionIndex: number,
): void => {
  const footerIndex = sections.length - 1;
  const lastModuleSectionIndex = footerIndex - 1;
  const moduleSectionCount = lastModuleSectionIndex - firstModuleSectionIndex + 1;
  const firstSectionIndex = sections[0].index;

  for (let i = 0; i < footerIndex; i++) {
    sections[i].index += moduleSectionCount;
  }

  for (let i = firstModuleSectionIndex, counter = 0; i <= lastModuleSectionIndex; i++, counter++) {
    sections[i].index = firstSectionIndex + counter;
  }
};

/*
  ModuleA must have lower indexes than ModuleB
 */
export const swapAdjacentTableModules = (
  sections: ProgramTemplateBlockTableSection[],
  moduleAStartIndex: number,
  moduleAEndIndex: number,
  moduleBStartIndex: number,
  moduleBEndIndex: number,
): void => {
  const moduleASectionCount = moduleAEndIndex - moduleAStartIndex + 1;
  const moduleBSectionCount = moduleBEndIndex - moduleBStartIndex + 1;
  let moduleATargetIndexes: number[];
  let moduleBTargetIndexes: number[];

  if (moduleASectionCount < moduleBSectionCount) {
    moduleATargetIndexes = sections
      .slice(
        moduleBStartIndex + moduleBSectionCount - moduleASectionCount,
        moduleBStartIndex + moduleBSectionCount
      )
      .map(section => section.index);
    moduleBTargetIndexes = sections
      .slice(moduleAStartIndex, moduleAStartIndex + moduleASectionCount)
      .concat(sections.slice(moduleBStartIndex, moduleBStartIndex + moduleBSectionCount - moduleASectionCount))
      .map(section => section.index);
  } else if (moduleASectionCount > moduleBSectionCount) {
    moduleATargetIndexes = sections
      .slice(moduleAStartIndex + moduleBSectionCount, moduleBStartIndex)
      .concat(sections.slice(moduleBStartIndex, moduleBStartIndex + moduleBSectionCount))
      .map(section => section.index);
    moduleBTargetIndexes = sections
      .slice(moduleAStartIndex, moduleAStartIndex + moduleBSectionCount)
      .map(section => section.index);
  } else {
    moduleATargetIndexes = sections
      .slice(moduleBStartIndex, moduleBStartIndex + moduleBSectionCount)
      .map(section => section.index);
    moduleBTargetIndexes = sections
      .slice(moduleAStartIndex, moduleAStartIndex + moduleASectionCount)
      .map(section => section.index);
  }

  for (let i = moduleAStartIndex, targetIndex = 0; i <= moduleAEndIndex; i++, targetIndex++) {
    sections[i].index = moduleATargetIndexes[targetIndex];
  }
  for (let i = moduleBStartIndex, targetIndex = 0; i <= moduleBEndIndex; i++, targetIndex++) {
    sections[i].index = moduleBTargetIndexes[targetIndex];
  }
};

export const findNextModuleOrTableFooterIndex = (
  sections: ProgramTemplateBlockTableSection[],
  currentModuleIndex: number,
): number => {
  let index = currentModuleIndex + 1;

  while (index < sections.length) {
    if (checkSectionIsModule(sections[index]) || sections[index].type === "TABLE_FOOTER") {
      return index;
    }

    index++;
  }

  return -1;
};

export const findPrevModuleIndex = (
  sections: ProgramTemplateBlockTableSection[],
  currentModuleIndex: number,
): number => {
  let index = currentModuleIndex - 1;

  while (index >= 0) {
    if (checkSectionIsModule(sections[index])) {
      return index;
    }

    index--;
  }

  return -1;
};

export const findNextModuleIndex = (
  sections: ProgramTemplateBlockTableSection[],
  currentModuleIndex: number,
): number => {
  let index = currentModuleIndex + 1;

  while (index < sections.length) {
    if (checkSectionIsModule(sections[index])) {
      return index;
    }

    index++;
  }

  return -1;
};