import type {TableConstructorSingleColumn} from "../../../types";
import {getColumnTypePlaceholder} from "../column-properties";
import {makeSingleColumn} from "./single-column";

export const makeWidgetReferenceColumn = (column?: Partial<TableConstructorSingleColumn>): TableConstructorSingleColumn => {
  return makeSingleColumn({
    type: "WIDGET_REFERENCE",
    placeholder: getColumnTypePlaceholder("WIDGET_REFERENCE"),
    ...column,
  });
};