import type {AppIconProps} from "../Icons.types";
import {getClassNames} from "../Icons.utils";
import styles from "./Settings.module.scss";

export const AppSettingsIcon = (props: AppIconProps) => {
  const {className, ...rest} = props;
  const classNames = getClassNames(styles["settings"], className);

  return (
    <svg {...rest} className={classNames} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" fill="none">
      <path d="M19.9645 8.89562L18.5616 8.65762C18.4154 8.1858 18.2276 7.73069 17.9979 7.29645L18.8246 6.13987C19.1754 5.64718 19.1211 4.97495 18.691 4.54906L17.4468 3.3048C17.2129 3.07098 16.904 2.94154 16.5741 2.94154C16.3152 2.94154 16.0689 3.02088 15.8601 3.17119L14.6994 3.99791C14.2484 3.75992 13.7766 3.56367 13.2881 3.41754L13.0543 2.03132C12.9541 1.43424 12.4405 1 11.8351 1H10.0772C9.47182 1 8.95825 1.43424 8.85804 2.03132L8.61587 3.45094C8.14823 3.59708 7.69311 3.78914 7.25887 4.02296L6.11065 3.19624C5.90188 3.04593 5.65136 2.9666 5.39248 2.9666C5.06263 2.9666 4.74948 3.09603 4.51983 3.32985L3.2714 4.57411C2.84551 5 2.78706 5.67223 3.13779 6.16493L3.97286 7.3382C3.74322 7.77662 3.5595 8.23173 3.41754 8.70355L2.03132 8.93737C1.43424 9.03758 1 9.55115 1 10.1566V11.9144C1 12.5198 1.43424 13.0334 2.03132 13.1336L3.45094 13.3758C3.59708 13.8434 3.78914 14.2985 4.02296 14.7328L3.20042 15.8768C2.84969 16.3695 2.90397 17.0418 3.33403 17.4676L4.57829 18.7119C4.81211 18.9457 5.12109 19.0752 5.45094 19.0752C5.70981 19.0752 5.95616 18.9958 6.16493 18.8455L7.3382 18.0104C7.75992 18.2317 8.20251 18.4113 8.65762 18.5532L8.89144 19.9562C8.99165 20.5532 9.50522 20.9875 10.1106 20.9875H11.8727C12.4781 20.9875 12.9916 20.5532 13.0919 19.9562L13.3299 18.5532C13.8017 18.4071 14.2568 18.2192 14.691 17.9896L15.8476 18.8163C16.0564 18.9666 16.3069 19.0459 16.5658 19.0459V19.0459C16.8956 19.0459 17.2046 18.9165 17.4384 18.6827L18.6827 17.4384C19.1086 17.0125 19.167 16.3403 18.8163 15.8476L17.9896 14.6868C18.2192 14.2484 18.4113 13.7933 18.5532 13.3257L19.9562 13.0919C20.5532 12.9916 20.9875 12.4781 20.9875 11.8727V10.1148C20.9958 9.50939 20.5616 8.99582 19.9645 8.89562Z" stroke="#737781" strokeWidth="2"/>
      <path d="M10.9967 6.67859C8.61679 6.67859 6.68359 8.61178 6.68359 10.9917C6.68359 13.3717 8.61679 15.3049 10.9967 15.3049C13.3767 15.3049 15.3099 13.3717 15.3099 10.9917C15.3099 8.61178 13.3767 6.67859 10.9967 6.67859Z" stroke="#737781" strokeWidth="2"/>
    </svg>
  );
};