import {Spin} from "antd";
import type {AppLoaderProps} from "./Loader.types";
import {getClassNames} from "./Loader.utils";

export const AppLoader = (props: AppLoaderProps) => {
  const {className, color = "primary", stretched = false, ...rest} = props;
  const classNames = getClassNames({className, color, stretched});

  return (
    <Spin {...rest} className={classNames}/>
  );
};