/* https://github.com/JiHong88/SunEditor/blob/master/README.md#options */
import plugins from "suneditor/src/plugins";
import katex from "katex";
import type {LineHeightsItem, SunEditorOptions} from "suneditor/src/options";
import {placeholder_plugin, placeholder_block_plugin, custom_indent_plugin, custom_outdent_plugin} from "../plugins";
import "katex/dist/katex.min.css";

const lineHeights: LineHeightsItem[] = [];
for (let item = 0; item < 3.1; item += 0.1) {
  lineHeights.push({text: `${+item.toFixed(1)}`, value: +item.toFixed(1)});
}

export const config: SunEditorOptions = {
  mode: "inline",
  pasteTagsWhitelist: "*",
  attributesWhitelist: {
    all: "*"
  },
  defaultStyle: "font-family: \"Times New Roman\"; font-size: 1.4rem",
  katex: katex,
  buttonList: [
    ["undo"],
    ["redo"],
    ["fontSize"],
    ["formatBlock"],
    ["bold"],
    ["underline"],
    ["italic"],
    ["removeFormat"],
    ["fontColor"],
    ["hiliteColor"],
    ["align", "lineHeight"],
    ["horizontalRule"],
    ["list"],
    ["table"],
    // ["preview"],
    // ["print"],
    // ["showBlocks"],
    ["customIndent"],
    ["customOutdent"],
    ["placeholder"],
    ["placeholderBlock"],
    ["math"],
  ],
  formats: ["div", "blockquote", "h1", "h2", "h3", "h4", "h5", "h6"],
  lineHeights,
  plugins: {
    ...plugins,
    placeholder_plugin,
    placeholder_block_plugin,
    custom_indent_plugin,
    custom_outdent_plugin,
  }
};