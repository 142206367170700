import {AppPage} from "common/Layout";
import {homeBreadcrumbItem} from "shared/UI/UIKit/Breadcrumbs";
import {MainPrograms} from "features/main-programs";

const breadcrumbs = [
  homeBreadcrumbItem,
  {
    title: "Конструктор ООП",
    url: ""
  },
];

export const MainProgramsPage = () => {
  return (
    <AppPage breadcrumbs={{items: breadcrumbs}}
             documentTitle="Конструктор основной образовательной программы">
      <MainPrograms/>
    </AppPage>
  );
};

