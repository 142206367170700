import {useEffect, useMemo, useRef} from "react";
import {Form} from "antd";
import {AppMultipleSelect} from "shared/UI/UIKit/Select";
import {AppForm, AppFormItem} from "shared/UI/UIKit/Form";
import type {Callbacks as FormCallbacks} from "rc-field-form/lib/interface";
import type {FocusEvent} from "react";
import type {ListValueEditorProps, ListValueEditorFormValues} from "./types";
import {formNames} from "./constants";
import {
  getInitialFormValues,
  getSubmitValues,
  formFieldValidators,
  formRules,
  getMaxWidth,
} from "./utils";
import styles from "./styles.module.scss";

const MIN_WIDTH = "17rem";
const DEFAULT_MAX_WIDTH = "17rem";

export const ListValueEditor = ({value, containerWidth, onSubmit, onInputKeyDown}: ListValueEditorProps) => {
  const [form] = Form.useForm<ListValueEditorFormValues>();
  const formValuesRef = useRef<ListValueEditorFormValues>(); // we use ref because form values aren't available at unmount stage
  const maxWidth = useMemo(() => getMaxWidth(containerWidth, DEFAULT_MAX_WIDTH), [containerWidth]);

  const handleValuePickerBlur = (e: FocusEvent<HTMLElement>) => {
    (e.target as HTMLElement).focus();
  };

  const handleFormValuesChange: FormCallbacks<ListValueEditorFormValues>["onValuesChange"] = (
    changedValues, values
  ) => {
    formValuesRef.current = values;
  };

  useEffect(() => {
    const submit = async () => {
      try {
        const {checkValueValid} = formFieldValidators;
        await checkValueValid(formValuesRef.current!.value, !!value.required);
        const submitValues = getSubmitValues(formValuesRef.current!);
        onSubmit(submitValues);
      } catch (e) {
        // just don't submit values
      }
    };

    return () => {
      submit();
    };
  }, []);

  return (
    <AppForm form={form} onValuesChange={handleFormValuesChange}
             initialValues={getInitialFormValues({
               value: value.initialValue,
             })}>
      <AppFormItem name={formNames.value} marginless
                   rules={[formRules.getValueValidator(!!value.required)]}>
        <AppMultipleSelect autoFocus onBlur={handleValuePickerBlur}
                           onInputKeyDown={onInputKeyDown}
                           className={styles.select}
                           dropdownClassName={styles["select__dropdown"]}
                           dropdownMatchSelectWidth={false} showArrow={false}
                           style={{minWidth: MIN_WIDTH, maxWidth}}
                           placeholder={value.placeholder || ""}
                           options={value.options}/>
      </AppFormItem>
    </AppForm>
  );
};