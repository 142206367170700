import cn from "classnames";
import {useState} from "react";
import type {FocusEvent, MouseEvent} from "react";
import type {TableWidgetStringCellFullAccessProps} from "./types";
import type {StringValueEditorSubmitValues} from "../../../cell-value-editors/string-value-editor";
import {stringValueRenderer} from "../../../cell-value-renderers/string-value-renderer";
import {StringValueEditor} from "../../../cell-value-editors/string-value-editor";
import widgetCellStyles from "../../../styles/widget.module.scss";
import {TableBaseCell} from "../../TableBaseCell";
import widgetStringCellStyles from "./styles.module.scss";

export const TableWidgetStringCellFullAccess = ({
  field,
  isPrograms,
  onChange,
  editMode,
  colSpan,
  validationStatus,
  editablePartPositions,
  isCellFirst,
  sectionOrdinalNumber,
}: TableWidgetStringCellFullAccessProps) => {
  const [isEditorEnabled, setEditorEnabled] = useState(false);
  const isCellEditable = editMode;
  const isCellHighlightable = editMode || !isPrograms;
  const isCellWidget = isCellEditable || (!isPrograms && !!editablePartPositions?.length);
  const isCellWidgetError = isCellHighlightable && validationStatus === "error";
  const cellClassName = cn(
    isCellWidget && widgetCellStyles["widget-cell"],
    isCellWidgetError && widgetCellStyles["widget-cell_error"],
    !isEditorEnabled && isCellFirst && widgetStringCellStyles["widget-string-cell_first"],
    isEditorEnabled && widgetStringCellStyles["widget-string-cell_edit"],
  );

  const handleCellClick = (e: MouseEvent<HTMLTableCellElement>) => {
    if (isCellEditable) {
      e.stopPropagation();
      setEditorEnabled(true);
    }
  };

  const handleCellBlur = (e: FocusEvent<HTMLTableCellElement>) => {
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setEditorEnabled(false);
    }
  };

  const handleEditorSubmit = (editorValues: StringValueEditorSubmitValues) => {
    onChange({...field, value: editorValues.value});
  };

  return (
    <TableBaseCell isFullAccess editMode={editMode}
                   index={field.index} editable={isCellEditable} className={cellClassName}
                   onClick={handleCellClick} onBlur={handleCellBlur} colSpan={colSpan}>
      {isEditorEnabled
        ? (
          <StringValueEditor isFullAccess onSubmit={handleEditorSubmit}
                             value={{
                               initialValue: field.value,
                               placeholder: field.placeholder,
                               required: false,
                             }}/>
        ) : (
          stringValueRenderer({
            field,
            isFullAccess: true,
            isPrograms,
            editMode,
            editablePartPositions,
            sectionOrdinalNumber,
          })
        )
      }
    </TableBaseCell>
  );
};