import {getTextMenuItem} from "./get-text-menu-item";
import {getCheckboxMenuItem} from "./get-checkbox-menu-item";
import {getSubMenuItem} from "./get-submenu-item";
import {getGroupMenuItem} from "./get-group-menu-item";
import {getLinkMenuItem} from "./get-link-menu-item";
import {getDividerMenuItem} from "./get-divider-menu-item";

export class BaseMenuHelpers {
  static getTextMenuItem = getTextMenuItem;
  static getCheckboxMenuItem = getCheckboxMenuItem;
  static getSubMenuItem = getSubMenuItem;
  static getGroupMenuItem = getGroupMenuItem;
  static getLinkMenuItem = getLinkMenuItem;
  static getDividerMenuItem = getDividerMenuItem;
}