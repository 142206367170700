import type {TableConstructorSingleColumn} from "../../../types";
import {getColumnTypePlaceholder} from "../column-properties";
import {makeSingleColumn} from "./single-column";

export const makeIndexColumn = (column?: Partial<TableConstructorSingleColumn>): TableConstructorSingleColumn => {
  return makeSingleColumn({
    type: "INDEX",
    name: "№ п/п",
    width: "4.7rem",
    locked: true,
    lockDisabled: true,
    placeholder: getColumnTypePlaceholder("INDEX"),
    ...column,
  });
};