import {AppTooltip} from "shared/UI/UIKit/Tooltip";
import {AppInfoIcon} from "shared/UI/UIKit/icons/Info";
import type {HintIconProps} from "./types";
import styles from "./styles.module.scss";

export const HintIcon = ({content}: HintIconProps) => {
  return (
    <AppTooltip title={content} align={{offset: [0, 0]}}
                placement="bottom" showArrow={false}
                overlayClassName={styles["tooltip-overlay"]}>
      <div className={styles["icon-wrapper"]}>
        <AppInfoIcon className={styles.icon}/>
      </div>
    </AppTooltip>
  );
};