import type {ProgramTemplateBlockTableHeader} from "services/api";

const defaultObj: ProgramTemplateBlockTableHeader = {
  variant: "VARIANT_4",
  lockedColumnIndexes: null,
  hiddenColumnIndexes: null,
};

export const getProgramTemplateBlockTableHeaderDTO = (
  obj: Partial<ProgramTemplateBlockTableHeader>
): ProgramTemplateBlockTableHeader => ({
  ...defaultObj,
  ...obj,
});