import {useState} from "react";
import cn from "classnames";
import {AppText} from "shared/UI/UIKit/Text";
import {AppChevronLeftIcon} from "shared/UI/UIKit/icons/Chevron";
import {AppFlex} from "shared/UI/UIKit/Flex";
import type {AppSidebarProps} from "./Sidebar.types";
import {SidebarMenu} from "./components/Menu";
import styles from "./Sidebar.module.scss";

export const AppSidebar = (props: AppSidebarProps) => {
  const [sidebarHidden, setSidebarHidden] = useState(false);

  return (
    <aside className={cn(styles.sidebar, sidebarHidden && styles.hidden, props.isStickyItems && styles.sticky, props.className, "sidebar")}>
      <AppFlex onClick={() => setSidebarHidden(!sidebarHidden)} unit="35"
               alignItems="center" className={styles.control} direction="row">
        <AppFlex className={cn(styles["icon-wrapper"], sidebarHidden && styles["icon-hidden"])}>
          <AppChevronLeftIcon/>
        </AppFlex>
        <AppText weight={400} size={16}>
          Свернуть
        </AppText>
      </AppFlex>
      <SidebarMenu openKeys={props.openKeys} onOpenChange={props.onOpenChange} menuItems={props.menuItems} isStickyItems={props.isStickyItems}>
        {props.children}
      </SidebarMenu>
    </aside>
  );
};
