import type {AppIconProps} from "../Icons.types";
import {getClassNames} from "../Icons.utils";
import styles from "./Copy.module.scss";

export const AppCopyIcon = (props: AppIconProps) => {
  const {className, ...rest} = props;
  const classNames = getClassNames(styles.icon, className);

  return (
    <svg {...rest} className={classNames} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M2 13C2 13.5523 2.44772 14 3 14H9C9.55228 14 10 13.5523 10 13V12H2V13Z" fill="#737781"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M8.66675 12H13.0001C13.5524 12 14.0001 11.5523 14.0001 11V10H8.66675V12Z" fill="#737781"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M2 6L2 13H4L4 6H2Z" fill="#737781"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M6 3V6H8L8 3L6 3Z" fill="#737781"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M8 6L8 13H10L10 6H8Z" fill="#737781"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M12 3L12 10H14L14 3L12 3Z" fill="#737781"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M2 7H10V6C10 5.44772 9.55228 5 9 5H3C2.44772 5 2 5.44772 2 6V7Z" fill="#737781"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M7 2C6.44772 2 6 2.44772 6 3V4H14V3C14 2.44772 13.5523 2 13 2H7Z" fill="#737781"/>
    </svg>
  );
};