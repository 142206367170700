import type {CurriculumDetailedTableRow} from "services/api";
import {CurriculumRow} from "../CurriculumRow";

type CurriculumFooterProps = {
  rows: CurriculumDetailedTableRow[];
}
export const CurriculumFooter = (props: CurriculumFooterProps) => {
  return (
    <tfoot>
      {
        props.rows.map(row => {
          return <CurriculumRow key={row.id} Tag="td" row={row}/>;
        })
      }
    </tfoot>
  );
};