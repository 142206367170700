import {Link} from "react-router-dom";
import {forwardRef} from "react";
import type {AppLinkProps} from "./Link.types";
import {getClassNames} from "./Link.utils";

export const AppLink = forwardRef<HTMLAnchorElement, AppLinkProps>(
  (props, ref) => {
    const {size, children, className} = props;
    const classNames = getClassNames({size, className});

    return (
      <Link {...props} className={classNames} ref={ref}>
        {children}
      </Link>
    );
  }
);