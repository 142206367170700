import {useLayoutEffect} from "react";
import type {TableStylesOptions} from "./types";
import {updateTableBorderRadius} from "./utils";

export const useTableStyles = ({table, innerContainerRef}: TableStylesOptions) => {
  useLayoutEffect(() => {
    if (innerContainerRef.current) {
      updateTableBorderRadius(innerContainerRef.current);
    }
  }, [table, innerContainerRef.current]);
};