// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const allCSS = [...document.styleSheets]
.map(styleSheet => {
  try {
    return [...styleSheet.cssRules]
    .map(rule => rule.cssText)
    .join("");
  } catch (e) {
    console.log("Access to stylesheet %s is denied. Ignoring...", styleSheet.href);
  }
})
.filter(Boolean)
.join("\n");

export const docxStyles = `
  * {
    background: white !important;
  }
  
  table {
    width: 100% !important;
    border-collapse: collapse !important;
  }
  
  table th, table td {
    border: 1px solid black;
    padding: 10px;
  }
  
  .placeholder-block {
    border: 1px dashed var(--color-danger);
    display: flex !important;
    flex-direction: row;
    justify-content: flex-end;
    padding: 1.8rem !important;
    position: relative;
  }
  
  .with-margin-bottom {
    margin-bottom: 2rem !important;
  }
  
  .with-margin-bottom_small {
    margin-bottom: 1rem !important;
  }
  
  .with-text-align-right {
    text-align: right;
  }
  
  .considered {
    padding-right: 1rem !important;
    width: 33.33%;
    padding-left: 1rem !important;
  }
  
  .agreed {
    padding-right: 1rem !important;
    width: 33.33%;
    padding-left: 1rem !important;
  }
  
  .approved {
    padding-right: 1rem !important;
    width: 33.33%;
    padding-left: 1rem !important;
  }
  
  .placeholder-final-document-view {
    border: none !important;
    background: inherit !important;
    color: inherit !important;
    cursor: initial !important;
  }
  
  .table-constructor-cell_hidden {
    display: none;
  }
  
  .table-widget-number {
    text-align: center;
    line-height: 1.15 !important;
  }
  
  p {
    margin: 0 !important;
  }
  
  .table-block-title-docx {
    font-weight: 700;
    text-transform: uppercase;
  }
  
  .table-title-docx {
    font-weight: 700;
    text-transform: uppercase;
  }
  
  .se-wrapper-inner {
      height: 100% !important;
  }
`;