import {createPortal} from "react-dom";
import {isFunction} from "shared/helpers/utils/is-function";
import type {ModalPortalProps} from "./ModalPortal.types";

export const ModalPortal = ({children, getContainer}: ModalPortalProps) => {
  const container = isFunction(getContainer)
    ? getContainer()
    : getContainer || document.body;

  return createPortal(children, container);
};