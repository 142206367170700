import {forwardRef} from "react";
import {AppFlex} from "shared/UI/UIKit/Flex";
import type {AppModalFooterProps} from "./ModalFooter.types";
import {getClassNames} from "./ModalFooter.utils";

export const AppModalFooter = forwardRef<HTMLDivElement, AppModalFooterProps>((props, ref) => {
  const {
    className,
    direction = "row",
    justifyContent = "flex-start",
    ...rest
  } = props;
  const classNames = getClassNames(className);

  return (
    <AppFlex direction={direction}
             justifyContent={justifyContent}
             className={classNames}
             {...rest} ref={ref}/>
  );
});