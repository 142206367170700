import {AppPage} from "common/Layout";
import {AppFlex} from "shared/UI/UIKit/Flex";
import {AppSidebar} from "common/Layout/Layout/Sidebar";
import {AppHeader} from "common/Layout/Layout/Header";
import {AppFooter} from "common/Layout/Layout/Footer";
import {MainProgram} from "features/main-program";
import type {AppMenuItem} from "shared/UI/UIKit/Menu";
import {useMainProgramPage} from "./use-main-program";
import styles from "./styles.module.scss";

export const ViewMainProgramTemplatePage = () => {
  const {
    breadcrumbs,
    breadcrumbsLoading,
    mainProgram,
    menuItems,
    openKeys,
    onOpenChange
  } = useMainProgramPage(true);

  return (
    <AppFlex unit="0" direction="row" style={{width: "100%"}}>
      <AppSidebar className={styles.sidebar} openKeys={openKeys} onOpenChange={onOpenChange} isStickyItems menuItems={menuItems as AppMenuItem[]}/>
      <AppFlex unit="0" style={{width: "100%", zIndex: 1}}>
        <AppHeader/>
        <AppPage breadcrumbs={{items: breadcrumbs, loading: breadcrumbsLoading}}
                 documentTitle={`Образовательная программа ${mainProgram?.educationLevel}`}>
          <MainProgram isTemplate/>
        </AppPage>
        <AppFooter/>
      </AppFlex>
    </AppFlex>
  );
};

