export class LocaleStorageService {
  static removeItem(key: string) {
    localStorage.removeItem(key);
  }

  static setStringItem(key: string, value: string) {
    localStorage.setItem(key, value);
  }

  static setObjectItem<V extends object>(key: string, value: V) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  static setBooleanItem(key: string, value: boolean) {
    localStorage.setItem(key, value ? "true" : "false");
  }

  static getBooleanItem(key: string, fallback?: boolean): boolean {
    const value = localStorage.getItem(key);
    if (value === null) {
      return fallback === true;
    }
    return value === "true";
  }

  static getStringItem<T extends string>(key: string): T | null;
  static getStringItem<T extends string>(key: string, fallback: T): T;
  static getStringItem<T extends string>(key: string, fallback?: T): T | null {
    const value: string | null = localStorage.getItem(key);
    if (value === null) {
      return fallback ?? null;
    }
    return value as T;
  }

  static getObjectItem<T extends object>(key: string): T | null;
  static getObjectItem<T extends object>(key: string, fallback: T): T;
  static getObjectItem<T extends object>(key: string, fallback?: T): T | null {
    const value: string | null = localStorage.getItem(key);
    if (value === null) {
      return fallback ?? null;
    }
    try {
      return JSON.parse(value) as T;
    } catch {
      return fallback ?? null;
    }
  }

  static clear() {
    localStorage.clear();
  }
}
