import {generateUUID} from "shared/helpers/utils/generate-uuid";
import {
  directionOfExtracurricularActivitiesOptions,
  formsOfExtracurricularActivitiesOptions
} from "features/main-program/redux/constants";
import type {ExtracurricularTableBodyRow} from "services/api";

export const getHOORow = (name: string): ExtracurricularTableBodyRow => {
  return {
    rowId: generateUUID(),
    cells: [
      {
        value: "",
        type: "LIST",
        options: directionOfExtracurricularActivitiesOptions,
        editable: true,
        cellId: generateUUID(),
      },
      {
        value: name || "",
        type: "TEXT",
        options: [],
        cellId: generateUUID(),
      },
      {
        value: [],
        type: "LIST",
        options: formsOfExtracurricularActivitiesOptions,
        multipleSelect:true,
        editable: true,
        cellId: generateUUID(),
      },
      {
        type: "NUMBER",
        options: [],
        editable: true,
        cellId: generateUUID(),
        styles: {
          textAlign: "center",
        },
        factor: 33
      },
      {
        type: "NUMBER",
        options: [],
        editable: true,
        styles: {
          textAlign: "center",
        },
        cellId: generateUUID()
      },
      {
        type: "NUMBER",
        options: [],
        editable: true,
        styles: {
          textAlign: "center",
        },
        cellId: generateUUID()
      },
      {
        type: "NUMBER",
        options: [],
        editable: true,
        styles: {
          textAlign: "center",
        },
        cellId: generateUUID()
      },
      {
        type: "SUM",
        options: [],
        styles: {
          textAlign: "center",
        },
        cellId: generateUUID()
      },
    ]
  };
};

export const getOOORow = (name: string): ExtracurricularTableBodyRow => {
  return {
    rowId: generateUUID(),
    cells: [
      {
        value: "",
        type: "LIST",
        options: directionOfExtracurricularActivitiesOptions,
        editable: true,
        cellId: generateUUID(),
      },
      {
        value: name || "",
        type: "TEXT",
        options: [],
        cellId: generateUUID(),
      },
      {
        value: [],
        type: "LIST",
        options: formsOfExtracurricularActivitiesOptions,
        multipleSelect: true,
        editable: true,
        cellId: generateUUID(),
      },
      {
        type: "NUMBER",
        options: [],
        editable: true,
        cellId: generateUUID(),
        styles: {
          textAlign: "center",
        },
      },
      {
        type: "NUMBER",
        options: [],
        editable: true,
        cellId: generateUUID(),
        styles: {
          textAlign: "center",
        },
      },
      {
        type: "NUMBER",
        options: [],
        editable: true,
        cellId: generateUUID(),
        styles: {
          textAlign: "center",
        },
      },
      {
        type: "NUMBER",
        options: [],
        editable: true,
        cellId: generateUUID(),
        styles: {
          textAlign: "center",
        },
      },
      {
        type: "NUMBER",
        options: [],
        editable: true,
        cellId: generateUUID(),
        styles: {
          textAlign: "center",
        },
      },
      {
        type: "SUM",
        options: [],
        cellId: generateUUID(),
        styles: {
          textAlign: "center",
        },
      },
    ]
  };
};

export const getCOORow = (name: string): ExtracurricularTableBodyRow => {
  return {
    rowId: generateUUID(),
    cells: [
      {
        value: "",
        type: "LIST",
        options: directionOfExtracurricularActivitiesOptions,
        editable: true,
        cellId: generateUUID(),
      },
      {
        value: name || "",
        type: "TEXT",
        options: [],
        cellId: generateUUID(),
      },
      {
        value: [],
        type: "LIST",
        editable: true,
        options: formsOfExtracurricularActivitiesOptions,
        multipleSelect: true,
        cellId: generateUUID(),
      },
      {
        type: "NUMBER",
        options: [],
        editable: true,
        cellId: generateUUID(),
        styles: {
          textAlign: "center",
        },
      },
      {
        type: "NUMBER",
        options: [],
        editable: true,
        cellId: generateUUID(),
        styles: {
          textAlign: "center",
        },
      },
      {
        type: "SUM",
        options: [],
        cellId: generateUUID(),
        styles: {
          textAlign: "center",
        },
      }
    ]
  };
};