import {useEffect, useState} from "react";
import {useForm} from "antd/es/form/Form";
import {AppModal} from "shared/UI/UIKit/Modal";
import {AppFlex} from "shared/UI/UIKit/Flex";
import {AppButton} from "shared/UI/UIKit/Button";
import {AppForm, AppFormItem, formRules} from "shared/UI/UIKit/Form";
import {AppSelect} from "shared/UI/UIKit/Select";
import {
  EducationLevel,
  type ExtracurricularTableBodyRow,
  getWorkProgramsBySubjectId,
  PROGRAM_TEMPLATE_BLOCK_TYPE,
  SubjectObligation,
  updateMainProgram, updateMainProgramBlock
} from "services/api";
import {AppInput} from "shared/UI/UIKit/Input";
import {useAppSelector} from "services/redux/hooks/use-selector";
import {selectMainProgram, selectMainProgramBlock} from "features/main-program/redux/selectors";
import {toOptions} from "shared/helpers/utils/to-options";
import {useAppDispatch} from "services/redux/hooks/use-dispatch";
import {setMainProgramAction, updateAdditionalEducationTableAction} from "features/main-program/redux/slice";
import {prepareTableContent} from "features/main-program/redux/utils";
import {getCOORow, getHOORow, getOOORow} from "features/main-program/components/AddNewElementModal/utils";
import type {WorkProgram, MainProgram, MainProgramItem, MainProgramBlockDtoBody} from "services/api";
import type {AppSelectOption} from "shared/UI/UIKit/Select";

type AddNewElementModalProps = {
  visible: boolean;
  hideModal: () => void;
}

const curriculumPartOptions: AppSelectOption[] = [
  {
    value: SubjectObligation.EXTRACURRICULAR,
    label: "Внеурочная деятельность",
  }/*,
  {
    value: SubjectObligation.ADDITIONAL,
    label: "Часть формируемая участниками образовательных отношений",
  }*/
];

type SubjectsState = Record<number, WorkProgram[]>;

export const AddNewElementModal = (props: AddNewElementModalProps) => {
  const [form] = useForm();
  const mainProgram = useAppSelector(selectMainProgram);
  const activeBlock = useAppSelector(selectMainProgramBlock);
  const {profile} = useAppSelector(state => state.profileState);
  const [loading, setLoading] = useState(false);
  const [workPrograms, setWorkPrograms] = useState<SubjectsState>({
    1000: [],
    2000: [],
  });
  const [workProgramsOptions, setWorkProgramsOptions] = useState<AppSelectOption[]>([]);
  const dispatch = useAppDispatch();
  const selectedExtracurricularWorkProgram = mainProgram?.items.filter(item => item.workProgram.obligation === SubjectObligation.EXTRACURRICULAR) || [];
  const selectedExtracurricularWorkProgramIds = selectedExtracurricularWorkProgram.map(item => item.workProgram.id);

  useEffect(() => {
    getWorkPrograms();
  }, []);

  const getWorkPrograms = async () => {
    setLoading(true);
    const educationLevel = mainProgram?.educationLevel;
    const additionalWorkPrograms = await getWorkProgramsBySubjectId(1000, educationLevel!, profile?.organization?.id);
    const extracurricularWorkPrograms = await getWorkProgramsBySubjectId(2000, educationLevel!, profile?.organization?.id);

    setWorkPrograms({
      1000: additionalWorkPrograms,
      2000: extracurricularWorkPrograms,
    });
    setLoading(false);
  };

  const obligationChange = (value: string) => {
    form.resetFields(["workProgram"]);
    let availablePrograms: WorkProgram[] = [];
    if (value === SubjectObligation.ADDITIONAL) {
      availablePrograms = workPrograms![1000];
    } else {
      availablePrograms = workPrograms![2000];
    }

    const options = toOptions<WorkProgram>(availablePrograms, "id", "programName");
    setWorkProgramsOptions(options);
  };

  const hideModal = () => {
    setLoading(false);
    form.resetFields();
    setWorkProgramsOptions([]);
    props.hideModal();
  };

  const submit = () => {
    setLoading(true);
    form.validateFields().then(values => {
      const activeId = values.part === SubjectObligation.ADDITIONAL ? 1000 : 2000;
      const programs = workPrograms[activeId];
      const selectedWorkProgram: WorkProgram = programs.find((program) => program.id === values.workProgramId)!;

      const newItem: MainProgramItem = {
          index: 0,
          baseSubjectName: values.name,
          customSubjectName: values.name,
          subject: {
            id: activeId,
            name: values.name,
            obligation: null as any,
          },
          workProgram: {
            id: selectedWorkProgram.id,
            name: `Рабочая программа по учебному предмету ${values.name.charAt(0).toUpperCase()}${values.name.slice(1)}`,
            obligation: values.part,
          } as any,
          curriculum: mainProgram!.items[0]?.curriculum,
      };

      const nextMainProgram: MainProgram = {
        ...mainProgram!,
        items: [
          ...mainProgram!.items,
          newItem,
        ]
      };

      updateMainProgram(nextMainProgram).then((res) => {
        dispatch(setMainProgramAction(res));
        const mainProgramExtracurricularPlanTableBlock = mainProgram?.blocks.find(block => block.type === PROGRAM_TEMPLATE_BLOCK_TYPE.TABLE);
        if (mainProgramExtracurricularPlanTableBlock) {
          if (mainProgramExtracurricularPlanTableBlock.content && mainProgramExtracurricularPlanTableBlock.content !== "[]") {

            const content = JSON.parse(mainProgramExtracurricularPlanTableBlock.content) as ExtracurricularTableBodyRow[] || [];
            let newRow: ExtracurricularTableBodyRow | null = null;

            switch (nextMainProgram.educationLevel) {
              case EducationLevel.НОО:
                newRow = getHOORow(values.name);
                break;
              case EducationLevel.ООО:
                newRow = getOOORow(values.name);
                break;
              case EducationLevel.СОО:
                newRow = getCOORow(values.name);
                break;
            }

            if (!newRow) {
              setLoading(false);
              hideModal();
              return;
            }


            content.splice(content.length - 1, 0, newRow);

            const nextBlock: MainProgramBlockDtoBody = {
              ...mainProgramExtracurricularPlanTableBlock,
              content: JSON.stringify(content),
              mainProgram: {
                id: mainProgram!.id!
              }
            };

            updateMainProgramBlock(nextBlock as MainProgramBlockDtoBody).then(() => {
              if (activeBlock) {
                dispatch(updateAdditionalEducationTableAction(JSON.stringify(content)));
              }

              setLoading(false);
              hideModal();
            });
          } else {
            const nextTableContent = prepareTableContent("", nextMainProgram);
            dispatch(updateAdditionalEducationTableAction(nextTableContent));
            setLoading(false);
            hideModal();
          }
        } else {
          setLoading(false);
          hideModal();
        }
      });

    }).catch(console.log);
  };

  const footer = (
    <AppFlex direction="row" justifyContent="flex-start">
      <AppButton onClick={submit}>
        Сохранить
      </AppButton>
      <AppButton type="outline" onClick={hideModal}>
        Отмена
      </AppButton>
    </AppFlex>
  );

  const content = (
    <AppForm form={form}>
      <AppFormItem name="part" label="Часть учебного плана" rules={formRules.required}>
        <AppSelect onChange={obligationChange} options={curriculumPartOptions} placeholder="Выберите часть учебного плана"/>
      </AppFormItem>
      <AppFormItem name="workProgramId" label="Рабочая программа" rules={formRules.requiredNumber}>
        <AppSelect options={workProgramsOptions.filter(item => !selectedExtracurricularWorkProgramIds.includes(item.value as number))} placeholder="Выберите рабочую программу"/>
      </AppFormItem>
      <AppFormItem name="name" label="Название предмета" rules={formRules.required}>
        <AppInput placeholder="Введите название предмета"/>
      </AppFormItem>
    </AppForm>
  );

  return (
    <AppModal visible={props.visible} onCancel={hideModal} size="auto"
              content={content} destroyOnClose footer={footer} loading={loading}
              title="Добавить предмет"/>
  );
};
