import type {AppTableChangeEvent, AppTablePagination} from "../../../ui";

export const getLimitPage = (pagination: AppTablePagination): Pick<AppTableChangeEvent, "limit" | "page"> => {
  if (!pagination.current || !pagination.pageSize) {
    return {
      page: 0,
      limit: 0,
    };
  }

  return {
    page: pagination.current - 1,
    limit: pagination.pageSize,
  };
};