import type {ProgramTemplateBlockTableRowField} from "services/api";

export const getHintContent = (field: ProgramTemplateBlockTableRowField) => {
  if (!field.targetValue) {
    return null;
  }

  if (!field.operator) {
    return `Рекомендовано ${field.targetValue}% от общего количества часов`;
  }

  if (field.operator === "required") {
    return `Должно равняться ${field.targetValue}% от общего количества часов`;
  }

  if (field.operator === "min") {
    return `Не менее ${field.targetValue}% от общего количества часов`;
  }

  if (field.operator === "max") {
    return `Не более ${field.targetValue}% от общего количества часов`;
  }

  return null;
};