import type {DateFilterFormValue} from "../types";
import type {DateFilterFieldsProps} from "./types";
import {FILTER_FORM_NAMES} from "../../../constants";
import {AppFilterOperator} from "../../../../constants";
import {DateField} from "./DateField";
import {DateIntervalFields} from "./DateIntervalFields";

export const DateFilterFields = ({form, options, focusRef}: DateFilterFieldsProps) => {
  const operator = form.getFieldValue(FILTER_FORM_NAMES.operator) as AppFilterOperator;

  const onChange = (values: DateFilterFormValue[]) => {
    form.setFieldsValue({values});
  };

  switch (operator) {
    case AppFilterOperator.IS_NULL: {
      return null;
    }
    case AppFilterOperator.INTERVAL: {
      return (
        <DateIntervalFields onChange={onChange} focusRef={focusRef}
                            placeholderStart={options?.placeholderStart}
                            placeholderEnd={options?.placeholderEnd}/>
      );
    }
    default: {
      return (
        <DateField onChange={onChange} focusRef={focusRef}
                   placeholder={options?.placeholder}/>
      );
    }
  }
};