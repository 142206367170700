import {useState} from "react";
import {AppModal} from "shared/UI/UIKit/Modal";
import {useAppDispatch} from "services/redux/hooks/use-dispatch";
import {AppFlex} from "shared/UI/UIKit/Flex";
import {AppText} from "shared/UI/UIKit/Text";
import {AppButton} from "shared/UI/UIKit/Button";
import {deleteMainProgramAction} from "features/main-programs/redux/actions";
import type {MainProgram} from "services/api";
import styles from "./Remove.module.scss";

type RemoveMainProgramModalProps = {
  item: MainProgram | null;
  hideModal: () => void;
  visible: boolean;
}

export const RemoveMainProgramModal = (props: RemoveMainProgramModalProps) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);

  if (!props.item) {
    return null;
  }

  const remove = () => {
    setLoading(true);
    dispatch(deleteMainProgramAction(props.item!.id))
      .unwrap()
      .then(props.hideModal)
      .finally(() => {
        setLoading(false);
      });
  };

  const footer = (
    <AppFlex direction="row" justifyContent="flex-start">
      <AppButton onClick={remove}>
        Удалить
      </AppButton>
      <AppButton type="outline" onClick={props.hideModal}>
        Отмена
      </AppButton>
    </AppFlex>
  );
  const content = (
    <AppFlex unit="8" className={styles.content}>
      <AppText size={16} type="secondary">
        Подтвердите удаление ООП
      </AppText>
      <AppText size={18}>
        Образовательная программа {props.item.educationLevel}
      </AppText>
    </AppFlex>
  );

  return (
    <AppModal visible={props.visible} onCancel={props.hideModal} size="auto"
              content={content} destroyOnClose footer={footer} loading={loading}
              title="Удаление ООП"/>
  );
};