const trashProperties: string[] = [
  "box-sizing",
  "font-family",
  "font-variant-ligatures",
  "font-variant-caps",
  "font-weight",
  "font-style",
  "overflow",
  "overflow-x",
  "overflow-y",
  "display",
  "vertical-align",
  "margin",
  "margin-top",
  "margin-bottom",
  "margin-right",
  "margin-left",
  "padding",
  "padding-top",
  "padding-bottom",
  "padding-right",
  "padding-left",
  "letter-spacing",
  "orphans",
  "white-space",
  "widows",
  "word-spacing",
  "-webkit-text-stroke-width",
  "-webkit-user-drag",
  "text-decoration-thickness",
  "text-decoration-style",
  "text-decoration-color",
  "float",
];

export const removeTrashStyles = (node: Document | HTMLElement): void => {
  const root: HTMLElement = node instanceof Document ? node.body : node;
  for (const item of Array.from(root.children)) {
    const foundProperties: string[] = [];
    for (let index = 0; index < (item as HTMLElement).style.length; index++) {
      const property = (item as HTMLElement).style[index];
      if (trashProperties.includes(property)) {
        foundProperties.push(property);
      }
    }

    foundProperties.forEach((property) => {
      (item as HTMLElement).style.removeProperty(property);
    });

    if (item.children.length > 0) {
      removeTrashStyles(item as HTMLElement);
    }
  }
};