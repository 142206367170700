import {createSlice} from "@reduxjs/toolkit";
import type {Curriculum, EducationLevel} from "services/api";
import type {PayloadAction} from "@reduxjs/toolkit";
import type {
  CreateMainProgramState,
  SetSelectedWorkProgramByFieldNameActionPayload,
  UpdateCustomSubjectNameInSelectedCurriculumActionPayload,
  AddSubjectInSelectedCurriculumActionPayload,
  RemoveSubjectInSelectedCurriculumActionPayload, AddRequiredSubjectInSelectedCurriculumActionPayload
} from "./types";
import {CreateWorkProgramTabs} from "./types";
import {
  createMainProgramReducerBuilder,
  getCurriculumsByEducationLevelReducerBuilder,
  getWorkProgramsBySubjectsReducerBuilder, reloadWorkProgramsBySubjectIdReducerBuilder
} from "./reducers";

const initialState: CreateMainProgramState = {
  loading: false,
  allCurriculums: [],
  allCurriculumsTotal: 0,
  selectedCurriculumsEntities: [],
  selectedEducationLevel: undefined,
  activeTabId: CreateWorkProgramTabs.EDUCATION_LEVEL,
  workProgramsOptionsBySubjectId: {},
  selectedWorkProgramByFieldName: {},
};


const createMainProgramSlice = createSlice({
  name: "createMainProgram",
  initialState,
  reducers: {
    setSelectedEducationLevelAction: (state, action: PayloadAction<EducationLevel>) => {
      state.selectedEducationLevel = action.payload;
    },
    setSelectedCurriculumsEntitiesAction: (state, action: PayloadAction<Curriculum[]>) => {
      state.selectedCurriculumsEntities = action.payload;
    },
    setActiveTabIdAction: (state, action: PayloadAction<CreateWorkProgramTabs>) => {
      state.activeTabId = action.payload;
    },
    setSelectedWorkProgramByFieldNameAction: (state, action: PayloadAction<SetSelectedWorkProgramByFieldNameActionPayload>) => {
      state.selectedWorkProgramByFieldName[action.payload.fieldName] = action.payload.item;
    },
    clearSelectedCurriculumsEntitiesAction: (state) => {
      state.selectedCurriculumsEntities = [];
    },
    clearAllCurriculumsAction: (state) => {
      state.allCurriculums = [];
      state.allCurriculumsTotal = 0;
    },
    clearWorkProgramsOptionsBySubjectIdAction: (state) => {
      state.workProgramsOptionsBySubjectId = {};
    },
    clearSelectedWorkProgramByFieldNameAction: (state) => {
      state.selectedWorkProgramByFieldName = {};
    },
    updateCustomSubjectNameInSelectedCurriculum: (state, action: PayloadAction<UpdateCustomSubjectNameInSelectedCurriculumActionPayload>) => {
      state.selectedCurriculumsEntities = state.selectedCurriculumsEntities.map(selectedCurriculum => {
        if (selectedCurriculum.id === action.payload.curriculumId) {
          return {
            ...selectedCurriculum,
            subjects: selectedCurriculum.subjects.map((subject) => {
              if (subject.uniqueId === action.payload.subjectId) {
                return {
                  ...subject,
                  customSubjectName: action.payload.customSubjectName,
                };
              } else {
                return subject;
              }
            })
          };
        } else {
          return selectedCurriculum;
        }
      });
    },
    addSubjectInSelectedCurriculum: (state, action: PayloadAction<AddSubjectInSelectedCurriculumActionPayload>) => {
      state.selectedCurriculumsEntities = state.selectedCurriculumsEntities.map(selectedCurriculum => {
        if (selectedCurriculum.id === action.payload.curriculumId) {
          return {
            ...selectedCurriculum,
            subjects: [...selectedCurriculum.subjects, action.payload.subject]
          };
        } else {
          return selectedCurriculum;
        }
      });
    },
    addRequiredSubjectInSelectedCurriculum: (state, action: PayloadAction<AddRequiredSubjectInSelectedCurriculumActionPayload>) => {
      const nextData = state.selectedCurriculumsEntities.map(selectedCurriculum => {
        if (selectedCurriculum.id === action.payload.curriculumId) {
          const clone = [...selectedCurriculum.subjects];
          clone.splice(action.payload.indexToInsert + 1, 0, action.payload.subject);
          let positionToInsert = 0;

          return {
            ...selectedCurriculum,
            subjects: clone.map((item, index) => {
              if (index < action.payload.indexToInsert) {
                return item;
              } else if (index === action.payload.indexToInsert) {
                positionToInsert = item.position!;
                return item;
              } else {
                positionToInsert = positionToInsert + 1;
                return {
                  ...item,
                  position: positionToInsert,
                };
              }
            })
          };
        } else {
          return selectedCurriculum;
        }
      });
      state.selectedCurriculumsEntities = nextData;
      const currentWizard = localStorage.getItem("WIZARD") || "{}";
      const nextWizard = JSON.parse(currentWizard);
      nextWizard.curriculums = nextData;
      localStorage.setItem("WIZARD", JSON.stringify(nextWizard));
    },
    removeSubjectFromSelectedCurriculum: (state, action: PayloadAction<RemoveSubjectInSelectedCurriculumActionPayload>) => {
      state.selectedCurriculumsEntities = state.selectedCurriculumsEntities.map(selectedCurriculum => {
        if (selectedCurriculum.id === action.payload.curriculumId) {
          return {
            ...selectedCurriculum,
            subjects: selectedCurriculum.subjects.filter(item => item.uniqueId !== action.payload.subject.uniqueId)
          };
        } else {
          return selectedCurriculum;
        }
      });
    },
    clearCreateMainProgramStateAction: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    getCurriculumsByEducationLevelReducerBuilder(builder);
    getWorkProgramsBySubjectsReducerBuilder(builder);
    createMainProgramReducerBuilder(builder);
    reloadWorkProgramsBySubjectIdReducerBuilder(builder);
  }
});

export const createMainProgramReducer = createMainProgramSlice.reducer;
export const {
  setSelectedEducationLevelAction,
  setSelectedCurriculumsEntitiesAction,
  clearSelectedCurriculumsEntitiesAction,
  clearAllCurriculumsAction,
  setActiveTabIdAction,
  clearWorkProgramsOptionsBySubjectIdAction,
  setSelectedWorkProgramByFieldNameAction,
  clearSelectedWorkProgramByFieldNameAction,
  clearCreateMainProgramStateAction,
  updateCustomSubjectNameInSelectedCurriculum,
  addSubjectInSelectedCurriculum,
  addRequiredSubjectInSelectedCurriculum,
  removeSubjectFromSelectedCurriculum,
} = createMainProgramSlice.actions;
