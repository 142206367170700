import moment from "moment";
import {BACK_END_DATE_TIME_FORMAT, USER_DATE_FORMAT} from "shared/constants/date";
import type {DateRendererProps} from "../types";
import {TextRenderer} from "./TextRenderer";

export const DateRenderer = ({
  date,
  sourceFormat = BACK_END_DATE_TIME_FORMAT,
  targetFormat = USER_DATE_FORMAT,
  ...rest
}: DateRendererProps) => {
  if (!date) {
    return null;
  }

  const value = moment(date, sourceFormat).format(targetFormat);

  return (
    <TextRenderer text={value} {...rest}/>
  );
};