import {stringToFloat} from "shared/helpers/utils/converters";
import type {
  ProgramTemplateBlockTableRowField,
  ProgramTemplateTableRowType,
  ProgramTemplateTableSectionType
} from "services/api";
import type {TableConstructorSingleColumn} from "../../../types";
import type {CellValidationResult} from "./types";
import {fieldsHelpers} from "../../../helpers";
import {makeErrorValidationResult, makeSuccessValidationResult} from "./utils";
import {validationMessages} from "./constants";

type WidgetNumberCellValidatorInfo = {
  columns: TableConstructorSingleColumn[];
  sectionType: ProgramTemplateTableSectionType;
  rowType: ProgramTemplateTableRowType;
  fields: ProgramTemplateBlockTableRowField[];
}

const checkTotalWorkHoursFieldValid = (
  field: ProgramTemplateBlockTableRowField,
  info: WidgetNumberCellValidatorInfo,
): boolean => {
  const totalWorkHoursSum = stringToFloat(field.value);
  const verificationWorkHoursSum = stringToFloat(fieldsHelpers.findVerificationWorkHoursField(
    info.columns, info.sectionType, info.rowType, info.fields,
  )?.value);
  const practicalWorkHoursSum = stringToFloat(fieldsHelpers.findPracticalWorkHoursField(
    info.columns, info.sectionType, info.rowType, info.fields,
  )?.value);

  return totalWorkHoursSum >= verificationWorkHoursSum + practicalWorkHoursSum;
};

export const widgetNumberCellValidator = (
  field: ProgramTemplateBlockTableRowField,
  info: WidgetNumberCellValidatorInfo,
): CellValidationResult => {
  if (!field.value && field.required) {
    return makeErrorValidationResult(validationMessages.valueIsEmpty);
  }

  if (
    fieldsHelpers.isFieldTotalWorkHours(info.columns, info.sectionType, info.rowType, field) &&
    !checkTotalWorkHoursFieldValid(field, info)
  ) {
    return makeErrorValidationResult(validationMessages.totalWorkHoursIsInvalid);
  }

  return makeSuccessValidationResult();
};