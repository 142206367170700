import {AppCheckbox} from "shared/UI/UIKit/Checkbox";
import {AppText} from "shared/UI/UIKit/Text";
import type {MouseEventHandler} from "react";
import type {AppCheckboxProps} from "shared/UI/UIKit/Checkbox";
import type {CheckboxMenuItemProps} from "./CheckboxMenuItem.types";
import {useMenuContext} from "../../../ui/context";
import {getClassNames} from "./CheckboxMenuItem.utils";
import styles from "./CheckboxMenuItem.module.scss";

export const CheckboxMenuItem = (props: CheckboxMenuItemProps) => {
  const {
    id,
    label,
    icon,
    className,
    disabled,
    checked,
    onChange,
    shouldCloseMenu,
    ellipsis,
  } = props;
  const context = useMenuContext();
  const classNames = getClassNames(className);

  const onWrapperClickHandler: MouseEventHandler<HTMLDivElement> = (e) => {
    // call the stopPropagation() to prevent the submenu from closing right after clicking on the checkbox label
    e.stopPropagation();
  };

  const onCheckboxChangeHandler: AppCheckboxProps["onChange"] = (e) => {
    if (disabled) {
      return;
    }

    onChange?.(id, e);
    context.onClick({
      key: id,
      itemType: "CheckboxItem",
      event: e,
      shouldCloseMenu,
    });
  };

  return (
    <div onClick={onWrapperClickHandler}>
      <AppCheckbox className={classNames}
                   disabled={disabled}
                   checked={checked}
                   onChange={onCheckboxChangeHandler}>
        <AppText disabled={disabled} ellipsis={ellipsis}>
          {icon && <span className={styles.icon}>{icon}</span>}
          <span>{label}</span>
        </AppText>
      </AppCheckbox>
    </div>
  );
};