import {useForm} from "antd/es/form/Form";
import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {AppFlex} from "shared/UI/UIKit/Flex";
import {AppButton} from "shared/UI/UIKit/Button";
import {AppForm, AppFormItem, formRules} from "shared/UI/UIKit/Form";
import {AppSelect} from "shared/UI/UIKit/Select";
import {AppModal} from "shared/UI/UIKit/Modal";
import {useAppSelector} from "services/redux/hooks/use-selector";
import {selectMainProgram} from "features/main-program/redux/selectors";
import {getWorkProgramsBySubjectId, updateMainProgramItem} from "services/api";
import {toOptions} from "shared/helpers/utils/to-options";
import {setMainProgramAction} from "features/main-program/redux/slice";
import {useAppDispatch} from "services/redux/hooks/use-dispatch";
import {getViewMainProgramUrl} from "services/navigation";
import type {ViewMainProgramPageParams} from "services/navigation";
import type {
  MainProgram,
  MainProgramItem,
  WorkProgram
} from "services/api";
import type {AppSelectOption} from "shared/UI/UIKit/Select";

type ChangeWorkProgramModalProps = {
  visible: boolean;
  hideModal: () => void;
}

export const ChangeWorkProgramModal = (props: ChangeWorkProgramModalProps) => {
  const [form] = useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const mainProgram = useAppSelector(selectMainProgram);
  const {mainProgramPartId, mainProgramId} = useParams<ViewMainProgramPageParams>();
  const [activeMainProgramItem, setActiveMainProgramItem] = useState<MainProgramItem>();
  const [workPrograms, setWorkPrograms] = useState<WorkProgram[]>([]);
  const [workProgramOptions, setWorkProgramOptions] = useState<AppSelectOption[]>([]);
  const {profile} = useAppSelector(state => state.profileState);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (props.visible) {
      initModal();
    }
  }, [props.visible]);


  const initModal = async () => {
    const activeItem = mainProgram?.items?.find(item => item.workProgram.id.toString() === mainProgramPartId);
    if (activeItem) {
      const workProgramsBySubjectId = await getWorkProgramsBySubjectId(activeItem.subject.id, mainProgram!.educationLevel!, profile?.organization.id);
      setWorkPrograms(workProgramsBySubjectId);
      setActiveMainProgramItem(activeItem);
      form.setFieldValue("workProgramId", activeItem.workProgram.id);
      const selectOptions = toOptions(workProgramsBySubjectId, "id", "programName");
      setWorkProgramOptions(selectOptions);
    }

  };

  const submit = () => {
    setLoading(true);

    form.validateFields().then(values => {
      const selectedWorkProgram = workPrograms.find(item => item.id === values.workProgramId);

      const nextMainProgramItem = {
        ...activeMainProgramItem,
        workProgram: {
          id: selectedWorkProgram!.id!,
          name: activeMainProgramItem?.workProgram?.name,
          obligation: activeMainProgramItem!.workProgram!.obligation
        }
      };

      updateMainProgramItem(nextMainProgramItem!.id!, nextMainProgramItem as MainProgramItem)
        .then(() => {
          const nextMainProgram = {
            ...mainProgram,
            items: mainProgram!.items.map((item) => {
              if (item.id === nextMainProgramItem.id) {
                return nextMainProgramItem as MainProgramItem;
              } else {
                return item;
              }
            }),
          } as MainProgram;

          dispatch(setMainProgramAction(nextMainProgram));

          navigate(getViewMainProgramUrl({
            mainProgramId: mainProgramId!,
            mainProgramPartId: selectedWorkProgram!.id.toString(),
          }));
          setLoading(false);
          hideModal();
        })
        .catch((error) => {
          console.error(error);

        });
    });

  };

  const hideModal = () => {
    form.resetFields();
    props.hideModal();
  };

  const footer = (
    <AppFlex direction="row" justifyContent="flex-start">
      <AppButton onClick={submit}>
        Сохранить
      </AppButton>
      <AppButton type="outline" onClick={hideModal}>
        Отмена
      </AppButton>
    </AppFlex>
  );

  const content = (
    <AppForm form={form}>
      <AppFormItem name="workProgramId" label="Рабочая программа" rules={formRules.requiredNumber}>
        <AppSelect options={workProgramOptions} placeholder="Выберите рабочую программу"/>
      </AppFormItem>
    </AppForm>
  );

  return (
    <AppModal visible={props.visible} onCancel={hideModal}
              content={content} destroyOnClose footer={footer} loading={loading}
              title="Изменение рабочей программы"/>
  );
};