import {useRef} from "react";
import {isWordContent} from "tinymce-word-paste-filter/standalone/WordFilter";
import {clearWordNumberedList, removeTrashStyles, serializeStyleWordList} from "shared/helpers/utils/DOM";
import type SunEditorCore from "suneditor/src/lib/core";
import type {BlockTextEditorProps} from "./DocumentEditor";
import {wordPasteFilter} from "./utils";

export const usePasteInDocumentEditor = (props: BlockTextEditorProps) => {
  const editorRef = useRef<SunEditorCore>();

  const onPaste = (event: ClipboardEvent, cleanData: string) => {
    if (!props.editable) {
      return false;
    }

    const filteredData = wordPasteFilter(cleanData);

    const node = new DOMParser().parseFromString(filteredData, "text/html");

    if (isWordContent(cleanData)) {
      clearWordNumberedList(node);
      serializeStyleWordList(node);
    } else {
      removeTrashStyles(node);
    }

    const htmlString = node.body.innerHTML;
    editorRef.current?.insertHTML(htmlString, true);

    return false;
  };



  return {
    editorRef,
    onPaste,
  };
};