import {notification} from "antd";
import {mainProgramAppApi} from "services/api/api-instance";
import type {UserProfile} from "./types";

export const getCurrentUser = (): Promise<UserProfile> => {
  return mainProgramAppApi
    .get("/users_profile/current_profile")
      .then(res => res.data)
      .catch(error => {
        notification.error({message: "Ошибка получения профиля"});
        throw error;
      });
};
