import {memo} from "react";
import type {TableHeaderCellProps} from "./types";
import {headerHelpers} from "../../../lib/helpers";
import {TableHeaderBaseCell} from "./components/TableHeaderBaseCell";
import {TableHeaderParentCell} from "./components/TableHeaderParentCell";
import {TableHeaderChildCell} from "./components/TableHeaderChildCell";

export const TableHeaderCell = memo((props: TableHeaderCellProps) => {
  if (props.cell.meta.hidden) {
    return (
      <TableHeaderBaseCell isFullAccess={props.isFullAccess} editMode={props.editMode}
                           hidden={props.cell.meta.hidden}/>
    );
  }

  return headerHelpers.isTableHeaderCellParent(props.cell)
    ? (
      <TableHeaderParentCell isFullAccess={props.isFullAccess} editMode={props.editMode}
                             cell={props.cell}/>
    )
    : (
      <TableHeaderChildCell isFullAccess={props.isFullAccess} editMode={props.editMode}
                            cell={props.cell}
                            onCellUpdate={props.onCellUpdate} onColumnEditClick={props.onColumnEditClick}/>
    );
});