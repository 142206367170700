import {useMemo} from "react";
import type {TableActionsContextProviderProps,TableActionsContextType} from "./types";
import {TableActionsContext} from "./context";

export const TableActionsContextProvider = ({
  selectRow,
  unselectRow,
  children,
}: TableActionsContextProviderProps) => {
  const value = useMemo<TableActionsContextType>(() => ({
    showActionsPanel: selectRow,
    hideActionsPanel: unselectRow,
  }), [selectRow, unselectRow]);

  return (
    <TableActionsContext.Provider value={value}>
      {children}
    </TableActionsContext.Provider>
  );
};