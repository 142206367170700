type PagesList = {
  key: number;
  title: string;
}

export const pagesList: PagesList[] = [
  {
    key: 1,
    title: "Пользователи",
  },
  {
    key: 2,
    title: "Справочники",
  },
  {
    key: 3,
    title: "Шаблоны рабочих программ",
  },
  {
    key: 4,
    title: "Конструктор рабочих программ",
  },
  {
    key: 5,
    title: "Конструктор основной образовательной программы",
  },
];