export const compareValues = (src: string | null, target: string | null): -1 | 0 | 1 => {
  const srcNum = parseFloat(src || "") || 0;
  const targetNum = parseFloat(target || "") || 0;

  if (srcNum < targetNum) {
    return -1;
  }
  if (srcNum > targetNum) {
    return 1;
  }
  return 0;
};