import {setIndent} from "../utils";

export const custom_indent_plugin = {
  name: "customIndent",
  display: "command",
  title: "Увеличить отступ",
  innerHTML: "<span>Отступ</span>",
  add: function (core, targetElement) {
    targetElement.firstElementChild.outerHTML = core.icons.indent;
  },
  action: function () {
    setIndent(this, "indent");
  },
};