import {useEffect, useRef} from "react";
import {Form} from "antd";
import {AppMarkdownEditor} from "shared/UI/UIKit/MarkdownEditor";
import {AppTextArea} from "shared/UI/UIKit/TextArea";
import {AppForm, AppFormItem} from "shared/UI/UIKit/Form";
import type {Callbacks as FormCallbacks} from "rc-field-form/lib/interface";
import type {StringValueEditorProps, StringValueEditorFormValues} from "./types";
import {bracketHelpers} from "../../../../../lib/helpers";
import {formNames} from "./constants";
import {getInitialFormValues, getSubmitValues, formFieldValidators, formRules} from "./utils";
import styles from "./styles.module.scss";

export const StringValueEditor = ({isFullAccess, value, onSubmit}: StringValueEditorProps) => {
  const [form] = Form.useForm<StringValueEditorFormValues>();
  const formValuesRef = useRef<StringValueEditorFormValues>(); // we use ref because form values aren't available at unmount stage

  const handleFormValuesChange: FormCallbacks<StringValueEditorFormValues>["onValuesChange"] = (
    changedValues, values
  ) => {
    formValuesRef.current = values;
  };

  useEffect(() => {
    const submit = async () => {
      try {
        const {checkValueValid} = formFieldValidators;
        await checkValueValid(formValuesRef.current!.value, !!value.required, isFullAccess);

        const submitValues = getSubmitValues({
          value: value.position
            ? bracketHelpers.replaceValueAtPosition(value.initialValue, value.position, formValuesRef.current!.value || "")
            : formValuesRef.current!.value,
        });

        onSubmit(submitValues);
      } catch (e) {
        // just don't submit values
      }
    };

    return () => {
      submit();
    };
  }, []);

  return (
    <AppForm form={form} onValuesChange={handleFormValuesChange}
             initialValues={getInitialFormValues({
               value: value.position
                ? bracketHelpers.getValueAtPosition(value.initialValue, value.position)
                : value.initialValue,
             })}>
      <AppFormItem name={formNames.value} marginless
                   rules={[formRules.getValueValidator(!!value.required, isFullAccess)]}>
        {isFullAccess
          ? (
            <AppMarkdownEditor textareaProps={{placeholder: value.placeholder || ""}}
                               className={styles["md-editor"]}
                               autoFocus preview="edit" visibleDragbar={false}
                               minHeight={undefined} maxHeight={undefined} height={200}/>
          )
          : (
            <AppTextArea placeholder={value.placeholder || ""}
                         className={styles["textarea-editor"]} autoFocus
                         autoSize={{minRows: 8, maxRows: 8}}/>
          )
        }
      </AppFormItem>
    </AppForm>
  );
};