import {getDictionaryList, getDictionaryByCode} from "services/api";
import type {DictionaryListRequest} from "services/api";

export const getAll = async () => {
  const payload: DictionaryListRequest = {limit: 200};

  return await getDictionaryList(payload);
};

export const getByCode = async (code: string | null | undefined) => {
  if (!code) {
    return null;
  }

  return await getDictionaryByCode(code);
};