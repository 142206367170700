import {AppFlex} from "shared/UI/UIKit/Flex";
import {AppEditIcon} from "shared/UI/UIKit/icons/Edit";
import {AppDeleteIcon} from "shared/UI/UIKit/icons/Delete";
import {AppCopyIcon} from "shared/UI/UIKit/icons/Copy";
import type {MouseEvent} from "react";
import type {TableControlsProps} from "./types";
import styles from "./TableControls.module.scss";

export const AppTableControls = (props: TableControlsProps) => {

  const edit = (e: MouseEvent) => {
    e.stopPropagation();
    if (props.edit) {
      props.edit();
    }
  };

  const remove = (e: MouseEvent) => {
    e.stopPropagation();
    if (props.remove) {
      props.remove();
    }
  };

  const copy = (e: MouseEvent) => {
    e.stopPropagation();
    if (props.copy) {
      props.copy();
    }
  };

  return (
    <AppFlex className={styles.container} direction="row" alignItems="center" justifyContent="center">
      {props.edit && <AppEditIcon onClick={edit} className={styles.icon}/>}
      {props.remove && <AppDeleteIcon onClick={remove} className={styles.icon}/>}
      {props.copy && <AppCopyIcon onClick={copy} className={styles.icon}/>}
    </AppFlex>
  );
};