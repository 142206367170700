import type {FormatNumberOptions} from "./types";

const defaultOptions: Required<FormatNumberOptions> = {
  min: 0,
  minIntegerPart: 0,
  minFractionPart: 0,
  max: 1000,
  maxIntegerPart: 1000,
  maxFractionPart: 99,
};

export const formatNumber = (
  value: string | null | undefined,
  options?: FormatNumberOptions,
): string => {
  if (!value) {
    return "";
  }

  const {
    min,
    minIntegerPart,
    minFractionPart,
    max,
    maxIntegerPart,
    maxFractionPart
  } = {...defaultOptions, ...options};
  const [integer, fraction] = value.split(".");

  if (integer) {
    const intInteger = parseInt(integer);
    if (intInteger < minIntegerPart) {
      return fraction ? [minIntegerPart, fraction].join(".") : `${minIntegerPart}`;
    }
    if (intInteger > maxIntegerPart) {
      return fraction ? [maxIntegerPart, fraction].join(".") : `${maxIntegerPart}`;
    }
  }

  if (fraction) {
    const intFraction = parseInt(fraction);
    if (intFraction < minFractionPart) {
      return [integer, minFractionPart].join(".");
    }
    if (intFraction > maxFractionPart) {
      return [integer, maxFractionPart].join(".");
    }
  }

  const floatValue = parseFloat(value);
  if (floatValue < min) {
    return `${min}`;
  }
  if (floatValue > max) {
    return `${max}`;
  }

  return value;
};