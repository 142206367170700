import {Outlet} from "react-router-dom";
import {useAppLocation} from "services/navigation/use-location";
import {getAuthorizationUrl} from "services/navigation";
import {useAuth} from "services/auth";
import {PageProtectorBase} from "./Base/PageProtectorBase";

const AUTH_PAGE_PATH = getAuthorizationUrl();

export const AuthorizedPageProtector = () => {
  const location = useAppLocation();
  const {authenticated} = useAuth();

  return (
    <PageProtectorBase to={AUTH_PAGE_PATH} condition={authenticated}
                       state={{from: location}}>
      <Outlet/>
    </PageProtectorBase>
  );
};