import {Body} from "common/BlockEditors/BlockTableAdditionalEducationEditor/components/Body";
import {AppTitle} from "shared/UI/UIKit/Title";
import {EducationLevel} from "services/api";
import type {ExtracurricularTableBodyRow, MainProgramBlockDto} from "services/api";
import styles from "./styles.module.scss";
import {BlockEditorTableComponent} from "./components/Table";
import {Header} from "./components/Header";

type BlockTableAdditionalEducationEditorProps = {
  mainProgramBlock: MainProgramBlockDto;
  educationLevel?: EducationLevel;
  editable?: boolean
}

export const BlockTableAdditionalEducationEditor = ({
  mainProgramBlock,
  educationLevel,
  editable = true,
}: BlockTableAdditionalEducationEditorProps) => {
  const rows = JSON.parse(mainProgramBlock.content) as ExtracurricularTableBodyRow[] || [];
  const currentEducationLevel = mainProgramBlock.mainProgram?.educationLevel || educationLevel;

  const getTitle = () => {
    if (currentEducationLevel === EducationLevel.НОО) {
      return "ПЛАН ВНЕУРОЧНОЙ ДЕЯТЕЛЬНОСТИ НАЧАЛЬНОГО ОБЩЕГО ОБРАЗОВАНИЯ";
    } else if (currentEducationLevel === EducationLevel.ООО) {
      return "ПЛАН ВНЕУРОЧНОЙ ДЕЯТЕЛЬНОСТИ ОСНОВНОГО ОБЩЕГО ОБРАЗОВАНИЯ";
    } else {
      return "ПЛАН ВНЕУРОЧНОЙ ДЕЯТЕЛЬНОСТИ СРЕДНЕГО ОБЩЕГО ОБРАЗОВАНИЯ";
    }
  };

  return (
    <div className={styles.editor} key={mainProgramBlock.id}>
      <AppTitle level={4} className={styles.title}>
        {getTitle()}
      </AppTitle>
      <BlockEditorTableComponent>
        <Header educationLevel={currentEducationLevel}/>
        <Body rows={rows} editable={editable}/>
      </BlockEditorTableComponent>
    </div>
  );
};