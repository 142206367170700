import {AppNavLink} from "shared/UI/UIKit/Link";
import {AppText} from "shared/UI/UIKit/Text";
import type {MouseEventHandler} from "react";
import type {AppLinkProps} from "shared/UI/UIKit/Link";
import type {LinkMenuItemProps} from "./LinkMenuItem.types";
import {useMenuContext} from "../../../ui/context";
import {getClassNames} from "./LinkMenuItem.utils";
import styles from "./LinkMenuItem.module.scss";

export const LinkMenuItem = (props: LinkMenuItemProps) => {
  const {
    id,
    label,
    icon,
    className,
    disabled,
    shouldCloseMenu,
    ellipsis,
    onClick,
    linkProps,
  } = props;
  const context = useMenuContext();
  const classNames = getClassNames(className);

  const onWrapperClickHandler: MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();
  };

  const onLinkChangeHandler: AppLinkProps["onClick"] = (e) => {
    if (disabled) {
      return;
    }

    onClick?.(linkProps.to);
    context.onClick({
      key: id,
      itemType: "LinkItem",
      event: e,
      shouldCloseMenu,
    });
  };

  return (
    <div onClick={onWrapperClickHandler}>
      <AppNavLink {...linkProps} className={classNames} onClick={onLinkChangeHandler}>
        <AppText size={16} className={styles.title} disabled={disabled} ellipsis={ellipsis}>
          {icon && <span className={styles.icon}>{icon}</span>}
          <span>{label}</span>
        </AppText>
      </AppNavLink>
    </div>
  );
};