import {SortableContext, verticalListSortingStrategy} from "@dnd-kit/sortable";
import type {PropsWithChildren} from "shared/types";
import {useTableConstructorContext} from "../../contexts/table-constructor-context";

type SortableContextProviderProps = PropsWithChildren & {
  sectionBodyRowsIds: (string | number)[]
}

export const SortableContextProvider = (props: SortableContextProviderProps) => {
  const {editMode} = useTableConstructorContext();
  return (
    <SortableContext items={props.sectionBodyRowsIds}
                     disabled={!editMode}
                     strategy={verticalListSortingStrategy}>
      {props.children}
    </SortableContext>
  );
};