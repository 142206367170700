import {EditableCell, EditableRow, EditableWrapper} from "shared/UI/UIKit/Table/ui/hooks/use-editable-table/components";
import type {AppTableColumns, AppTableItem, AppTableSingleColumn} from "../../types";
import type {EditableCellsServiceOptions} from "./types";
import type {CustomizeComponent} from "rc-table/lib/interface";
import {isTableColumnSingle} from "../../Table.utils";

export const useEditableCells = <TRecord extends AppTableItem>(
  options: EditableCellsServiceOptions<TRecord>
) => {

  if (!options.editable) {
    return {
      components: options.components,
      columns: options.columns
    };
  }

  const handleSave = (row: TRecord) => {
    if (options.handleSave) {
      options.handleSave(row);
    }
  };
  const getEditableColumn = (col: AppTableSingleColumn<TRecord>) => {
    return col.editable ? {
      ...col,
      onCell: (record: TRecord) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    } : col;
  };
 // todo add types
  const getColumns = (columns: AppTableColumns<TRecord>): any => {
    return columns.map(col => {
      if (isTableColumnSingle(col)) {
        return getEditableColumn(col);
      } else {
        return {...col, children: getColumns(col.children)};
      }
    });
  };

  const columns = getColumns(options.columns);

  const RowComponent = (options.components?.body as any)?.row as CustomizeComponent | undefined;

  const components = {
    ...options.components,
    body: {
      ...options.components?.body,
      row: RowComponent ? ((props: any) => (
        <EditableWrapper>
          <RowComponent {...props}>
            {props.children}
          </RowComponent>
        </EditableWrapper>
      )) : EditableRow,
      cell: EditableCell,
    },
  };

  return {
    columns,
    components,
  };
};