import {notification} from "antd";
import {generateUUID} from "shared/helpers/utils/generate-uuid";

export const placeholder_plugin = {
  name: "placeholder",
  display: "command",
  title: "Добавить виджет",
  innerHTML: "<span>Виджет</span>",
  add: function (core, targetElement) {
    const context = core.context;
    context.placeholder = {
      targetButton: targetElement,
      tag: core.util.createElement("PLACEHOLDER")
    };
  },
  active: function(element) {
    if (element && element.getAttribute("data-type") === "placeholder") {
      const filteredArray = Array.from(this.codeViewDisabledButtons).filter((_, index) => index !== 7);
      this.functions.util.setDisabledButtons(true, filteredArray, true);
    } else {
      this.functions.util.setDisabledButtons(false, this.codeViewDisabledButtons, true);
    }
    return true;
  },
  action: function () {
    const selection = window.getSelection();
    const parentNode = selection.getRangeAt(0).commonAncestorContainer.parentNode;
    if (parentNode.getAttribute("data-widget")) {
      notification.error({
        message: "Нельзя добавить виджет"
      });
      return;
    }
    this.functions.insertHTML(`<span><span class="placeholder-mask">&zwnj;</span><span data-placeholder="Ввод данных" title="Свободный ввод" data-widget="true" data-type="placeholder" id=${generateUUID()} class="placeholder">Ввод данных</span><span class="placeholder-mask">&zwnj;</span></span>&zwnj;`, true);
  },
};