import React, {forwardRef} from "react";
import {omit} from "shared/helpers/utils/omit";
import {AppLoadingOutlinedIcon} from "shared/UI/UIKit/icons/Loading";
import type {AppBaseButtonProps} from "./BaseButton.types";
import {getClassNames} from "./BaseButton.utils";
import styles from "./styles/index.module.scss";

export const AppBaseButton = forwardRef<HTMLButtonElement, AppBaseButtonProps>(
(props, ref) => {
  const {loading, children} = props;
  const loadingIcon = <AppLoadingOutlinedIcon/>;
  const icon = loading ? loadingIcon : props.icon;
  const tabIndex = loading ? -1 : props.tabIndex;
  const className = getClassNames(props);
  const onClick = (event: React.MouseEvent<HTMLButtonElement>) => !props.loading && props.onClick?.(event);

  const buttonProps = omit(
    {...props, tabIndex, onClick, className},
    ["size", "color", "loading", "shape", "icon"]
  );

  return (
    <button {...buttonProps} ref={ref}>
      {icon && <span className="app-button-icon-container">{icon}</span>}
      {children !== undefined && <span className={styles.content}>{children}</span>}
    </button>
  );
});