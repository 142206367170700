import type {AppIconProps} from "../Icons.types";
import {getClassNames} from "../Icons.utils";
import styles from "./User.module.scss";

export const AppUserIcon = (props: AppIconProps) => {
  const {className, ...rest} = props;
  const classNames = getClassNames(styles.icon, className);

  return (
    <svg {...rest} className={classNames} viewBox="0 0 14 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.4191 16.9603L11.419 16.9603C9.8458 17.6188 8.2746 17.9447 6.70833 17.9447C5.14206 17.9447 3.57085 17.6188 1.9976 16.9603C1.39342 16.7074 1.00008 16.1165 1 15.4614C1 15.4613 1 15.4613 1 15.4613V13.5747C1 10.6145 3.52673 8.16666 6.70833 8.16666C9.88993 8.16666 12.4167 10.6145 12.4167 13.5747V15.4613C12.4167 16.1165 12.0233 16.7075 11.4191 16.9603Z"
        strokeWidth="2"
      />
      <circle cx="7.00004" cy="4.66667" r="3.66667" strokeWidth="2"/>
    </svg>
  );
};
