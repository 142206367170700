import {useAppSelector} from "services/redux/hooks/use-selector";
import {selectMainProgramCurriculum} from "features/main-program/redux/selectors";
import {PROGRAM_TEMPLATE_BLOCK_TYPE} from "services/api";
import {DocumentEditor} from "common/BlockEditors";
import {CurriculumTable} from "./CurriculumTable";

export const CurriculumViewer = () => {
	const curriculum = useAppSelector(selectMainProgramCurriculum);

	if (!curriculum) {
		return null;
	}

  return (
    <>
      {
        curriculum.blocks.map(block => {
          return block.type === PROGRAM_TEMPLATE_BLOCK_TYPE.DOCUMENT ? (
            <DocumentEditor key={block.id} id={block.id} content={block.content}/>
          ) : (
            <CurriculumTable block={block} key={block.id}/>
          );
        })
      }
    </>
  );
};