import type {SortOrder} from "shared/types";
import type {NumericComparatorGetter, NumericFieldComparatorGetter, ComparableNumber} from "../types";
import {getObjectComparator} from "../object/object-comparator";

export const getNumericComparator: NumericComparatorGetter = (sortOrder) => {
  const order = sortOrder === "ascend" ? 1 : -1;
  return (a, b) => {
    if (a === undefined || a === null || isNaN(a)) {
      return Infinity;
    }

    if (b === undefined || b === null || isNaN(b)) {
      return -Infinity;
    }

    return order * (a - b);
  };
};

export const getNumericFieldComparator: NumericFieldComparatorGetter = <TObject extends object>(
  getValue: ((item: TObject) => ComparableNumber),
  sortOrder: SortOrder,
) => {
  const comparator = getNumericComparator(sortOrder);
  return getObjectComparator(getValue, comparator);
};