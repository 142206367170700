import {AppFlex} from "shared/UI/UIKit/Flex";
import {AppDropdownMenu} from "shared/UI/UIKit/DropdownMenu";
import {AppText} from "shared/UI/UIKit/Text";
import type {AppBreadcrumbDropdownItemProps} from "./BreadcrumbDropdownItem.types";
import styles from "../BreadcrumbItem.module.scss";
import {AppBreadcrumbItemSeparator} from "../Separator";

export const AppBreadcrumbDropdownItem = (props: AppBreadcrumbDropdownItemProps) => {
  return (
    <AppFlex direction="row" unit="6" alignItems="flex-end">
      <AppDropdownMenu placement="bottom" menu={{items: props.menuItems}}>
        <AppText className={styles.more} size={18}>&#8226; &#8226; &#8226;</AppText>
      </AppDropdownMenu>
      <AppBreadcrumbItemSeparator separator={props.separator}/>
    </AppFlex>
  );
};