import {generateUUID} from "shared/helpers/utils/generate-uuid";
import {getMinMaxObjectValue} from "shared/helpers/utils/arrays/get-min-max-value";
import {
  getProgramTemplateBlockTableDTO,
  getProgramTemplateBlockTableHeaderDTO,
  getProgramTemplateBlockTableRowDTO,
  getProgramTemplateBlockTableRowFieldDTO,
  getProgramTemplateBlockTableSectionDTO
} from "services/api/blocks/dto";
import type {
  ProgramTemplateBlockTable,
  ProgramTemplateTableSectionType,
  ProgramTemplateBlockTableRow,
  ProgramTemplateBlockTableRowField
} from "services/api";
import type {TableConstructorSingleColumn} from "../../../types";
import type {TableWorkHours} from "../types";
import {indexHelpers, columnsHelpers, fieldsHelpers} from "../../../helpers";
import {getFieldWithCalculatedValue} from "../helpers";

/*
  This function does:
  - sorts sections/rows/fields
  - adds "INDEX"/"FILLER" fields
  - calculates work hours
 */
const prepareTableForRender = (
  table: ProgramTemplateBlockTable,
  columns: TableConstructorSingleColumn[],
): ProgramTemplateBlockTable => {
  const tableWorkHours: TableWorkHours = {
    sectionTotal: 0,
    tableTotal: 0,
    tableVerification: 0,
    tablePractical: 0,
  };

  return getProgramTemplateBlockTableDTO({
    ...table,
    sections: indexHelpers.sortByIndex(table.sections).map((section) => {
      tableWorkHours.sectionTotal = 0;

      return getProgramTemplateBlockTableSectionDTO({
        ...section,
        rows: indexHelpers.sortByIndex(section.rows).map((row) => {
          const [, maxFieldsIndex] = getMinMaxObjectValue(row.fields, field => field.index, -1);
          const indexField = createIndexField(section.type, row);
          const fillerField = createFillerField(section.type, row, maxFieldsIndex + 1, columns);
          const rowFields: ProgramTemplateBlockTableRowField[] = [
            ...(indexField ? [indexField] : []),
            ...row.fields,
            ...(fillerField ? [fillerField] : []),
          ];
          const filledRowFields = fillFieldGapsWithFiller(rowFields);

          return getProgramTemplateBlockTableRowDTO({
            ...row,
            fields: indexHelpers.sortByIndex(filledRowFields).map((field) => {
              return getFieldWithCalculatedValue(section.type, row.type, field, columns, tableWorkHours);
            }),
          });
        }),
      });
    }),
  });
};

const createIndexField = (
  sectionType: ProgramTemplateTableSectionType,
  row: ProgramTemplateBlockTableRow,
): ProgramTemplateBlockTableRowField | null => {
  const shouldCreateField = sectionType === "TABLE" && row.type === "SECTION_BODY_ROW";

  if (!shouldCreateField) {
    return null;
  }

  return getProgramTemplateBlockTableRowFieldDTO({
    id: generateUUID("fake_"),
    row,
    index: 0,
    type: "INDEX",
  });
};

const createFillerField = (
  sectionType: ProgramTemplateTableSectionType,
  row: ProgramTemplateBlockTableRow,
  fieldIndex: number,
  columns: TableConstructorSingleColumn[],
): ProgramTemplateBlockTableRowField | null => {
  const shouldCreateField = () => {
    if (sectionType === "TABLE" && row.type !== "SECTION_FOOTER_ROW") {
      return false;
    }

    const visibleColumns = columns.filter(column => !column.hidden);
    const visibleFields = row.fields.filter(field => {
      return !fieldsHelpers.isFieldHidden(columns, sectionType, row.type, field);
    });
    return visibleFields.length + 1 < visibleColumns.length; // visibleFields.length + 1 because zero cell always takes two cell spaces
  };

  if (!shouldCreateField()) {
    return null;
  }

  return getProgramTemplateBlockTableRowFieldDTO({
    id: generateUUID("fake_"),
    row,
    index: fieldIndex,
    type: "FILLER",
  });
};

const fillFieldGapsWithFiller = (fields: ProgramTemplateBlockTableRowField[]): ProgramTemplateBlockTableRowField[] => {
  const result = [...fields];
  const [minIndex, maxIndex] = getMinMaxObjectValue(fields, field => field.index, -1);

  for (let i = minIndex; i <= maxIndex; i++) {
    if (!fields.find(field => field.index === i)) {
      const filler = getProgramTemplateBlockTableRowFieldDTO({
        id: generateUUID("fake_"),
        row: null,
        index: i,
        type: "FILLER",
      });
      result.push(filler);
    }
  }

  return result;
};

export const normalizeBlockTableForRender = (table: ProgramTemplateBlockTable) => {
  const columns = columnsHelpers.getTableColumnsByHeader(getProgramTemplateBlockTableHeaderDTO(table.header));
  const singleColumns = columnsHelpers.getSingleTableConstructorColumns(columns);
  return prepareTableForRender(table, singleColumns);
};