import cn from "classnames";
import type {MouseEvent} from "react";
import type {ModalDialogProps} from "./ModalDialog.types";
import styles from "./ModalDialog.module.scss";

export const ModalDialog = ({
  children,
  size = "auto",
  className,
  style,
  modalStatus,
}: ModalDialogProps) => {
  const classNames = cn(
    styles.dialog,
    styles[`dialog_size_${size}`],
    styles[`dialog_${modalStatus.name}_${modalStatus.stage}`],
    className,
  );

  const handleDialogClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  return (
    <div role="dialog" aria-modal className={classNames} style={style}
         onClick={handleDialogClick}>
      {children}
    </div>
  );
};