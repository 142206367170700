import {forwardRef} from "react";
import {Radio} from "antd";
import type {AppRadioGroupProps} from "./RadioGroup.types";
import {AppRadio} from "../Radio";
import {getWrapperClassNames} from "./RadioGroup.utils";

export const AppRadioGroup = forwardRef<HTMLDivElement, AppRadioGroupProps>(
  (props, ref) => {
    const {
      direction = "horizontal",
      optionType = "radio",
      options,
      itemClassName,
      className,
      ...rest
    } = props;

    const getOptions = () => {
      return options.map(({label, value}) => (
        <AppRadio key={value}
                  type={optionType}
                  value={value}
                  className={itemClassName}>
          {label}
        </AppRadio>
      ));
    };

    const wrapperClassName = getWrapperClassNames(direction, optionType, className);

    return (
      <Radio.Group {...rest} className={wrapperClassName} ref={ref}>
        {getOptions()}
      </Radio.Group>
    );
  }
);