import type {AppMenuItem} from "../../../ui/Menu.types";
import type {CheckboxMenuItemProps} from "../../components/CheckboxMenuItem";
import {CheckboxMenuItem} from "../../components/CheckboxMenuItem";

export type CheckboxMenuItemOptions = {
  key: CheckboxMenuItemProps["id"],
  label: CheckboxMenuItemProps["label"],
  className?: string,
  wrapperClassName?: string,
  icon?: CheckboxMenuItemProps["icon"],
  disabled?: CheckboxMenuItemProps["disabled"],
  shouldCloseMenu?: CheckboxMenuItemProps["shouldCloseMenu"],
  checked?: CheckboxMenuItemProps["checked"],
  onChange?: CheckboxMenuItemProps["onChange"],
  ellipsis?: CheckboxMenuItemProps["ellipsis"],
}

export const getCheckboxMenuItem = ({
  key,
  label,
  className,
  wrapperClassName,
  icon,
  disabled,
  shouldCloseMenu = false,
  checked,
  onChange,
  ellipsis = {tooltip: label},
}: CheckboxMenuItemOptions): AppMenuItem => {
  const getLabel = () => {
    return (
      <CheckboxMenuItem id={key}
                        className={className}
                        label={label}
                        icon={icon}
                        disabled={disabled}
                        checked={checked}
                        onChange={onChange}
                        shouldCloseMenu={shouldCloseMenu}
                        ellipsis={ellipsis}/>
    );
  };

  return {
    key,
    label: getLabel(),
    className: wrapperClassName,
    disabled,
  };
};