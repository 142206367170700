import cn from "classnames";
import type {DividerWithChildrenProps} from "./DividerWithChildren.types";
import styles from "./DividerWithChildren.module.scss";

export const getClassNames = (props: DividerWithChildrenProps) => {
  return cn(
    styles.divider,
    props.className,
    props.childrenPosition && styles[`divider--${props.childrenPosition}`],
    props.borderStyle && styles[`divider--${props.borderStyle}`],
    props.width && styles[`divider--${props.width}`],
  );
};