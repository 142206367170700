export const formNames = {
  value: "value",
  listId: "listId",
  readonly: "readonly",
} as const;

export const formItemLabels = {
  value: "Значение",
  listId: "Справочник",
  readonly: "Только для чтения",
} as const;