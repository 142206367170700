import {AppDatePicker} from "shared/UI/UIKit/DatePicker";
import {AppFormItem, formRules} from "shared/UI/UIKit/Form";
import type {DateFieldProps} from "./types";
import {FILTER_FORM_NAMES} from "../../../constants";

export const DateField = ({onChange, placeholder, focusRef}: DateFieldProps) => {
  return (
    <AppFormItem name={[FILTER_FORM_NAMES.values, 0]} rules={formRules.requiredDate}
                 marginless withoutExplain>
      <AppDatePicker onChange={value => onChange([value])}
                     placeholder={placeholder} ref={focusRef}/>
    </AppFormItem>
  );
};