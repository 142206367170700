import {AppPage} from "common/Layout";
import {homeBreadcrumbItem} from "shared/UI/UIKit/Breadcrumbs";
import {getMainProgramsUrl} from "services/navigation";
import {CreateMainProgram} from "features/create-main-program";

const breadcrumbs = [
  homeBreadcrumbItem,
  {
    title: "Конструктор ООП",
    url: getMainProgramsUrl(),
  },
  {
    title: "Создание ООП",
    url: "",
  },
];

export const CreateMainProgramPage = () => {
  return (
    <AppPage breadcrumbs={{items: breadcrumbs}}
             documentTitle="Создание основной образовательной программы">
      <CreateMainProgram/>
    </AppPage>
  );
};