import type {TableActionsProps} from "./types";
import {TableActionsPanel} from "../TableActionsPanel";
import {ModuleActionsPanel} from "../ModuleActionsPanel";
import {SectionActionsPanel} from "../SectionActionsPanel";
import {RowActionsPanel} from "../RowActionsPanel";
import {useTableStyles} from "./hooks/use-table-styles";
import {useTableActions} from "./hooks/use-table-actions";
import {TableActionsContextProvider} from "./contexts/table-actions-context";

export const TableActions = (props: TableActionsProps) => {
  const [
    {innerContainerRef, selectedRowRef, selectedRow, actions},
    {selectedRowMethods},
  ] = useTableActions({
    settings: props.table.settings,
    tableMethods: props.tableMethods,
    moduleMethods: props.moduleMethods,
    sectionMethods: props.sectionMethods,
    rowMethods: props.rowMethods,
  });

  useTableStyles({table: props.table, innerContainerRef});

  return (
    <div ref={innerContainerRef} className="table-constructor-inner-container">
      <TableActionsContextProvider selectRow={selectedRowMethods.select}
                                   unselectRow={selectedRowMethods.unselect}>
        {props.children}
        <TableActionsPanel containerRef={innerContainerRef} actions={actions.tableActions}/>
        <ModuleActionsPanel containerRef={innerContainerRef} targetRef={selectedRowRef}
                            meta={selectedRow?.meta} actions={actions.moduleActions}
                            onHide={selectedRowMethods.unselect}/>
        <SectionActionsPanel containerRef={innerContainerRef} targetRef={selectedRowRef}
                             meta={selectedRow?.meta} actions={actions.sectionActions}
                             onHide={selectedRowMethods.unselect}/>
        <RowActionsPanel containerRef={innerContainerRef} targetRef={selectedRowRef}
                         meta={selectedRow?.meta} actions={actions.rowActions}
                         onHide={selectedRowMethods.unselect}/>
      </TableActionsContextProvider>
    </div>
  );
};