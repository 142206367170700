import {getNumericFieldComparator} from "shared/helpers/utils/comparators";

type ObjWithIndex = {
  index: number;
}

const indexComparator = getNumericFieldComparator<ObjWithIndex>(item => item.index, "ascend");

export const sortByIndex = <T extends ObjWithIndex>(data: T[]): T[] => {
  return data
    .slice() // slice() is important because data comes from immutable redux store
    .sort(indexComparator);
};

export const swapIndexes = <T extends ObjWithIndex>(a: T, b: T): void => {
  const aIndex = a.index;
  a.index = b.index;
  b.index = aIndex;
};