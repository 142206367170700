import {useState} from "react";
import type {ReactNode} from "react";
import type {RowActionsPanelProps} from "./types";
import type {ActionTypes} from "../../../lib/types";
import {ControlPanel} from "../../../ui/components/ControlPanel";
import {CopyAction} from "./components/CopyAction";
import {InsertAction} from "./components/InsertAction";
import {MoveAction} from "./components/MoveAction";
import {RemoveAction} from "./components/RemoveAction";
import styles from "./styles.module.scss";

export const RowActionsPanel = (props: RowActionsPanelProps) => {
  const [insertActionMenuElement, setInsertActionMenuElement] = useState<HTMLUListElement | null>(null);
  const [deleteActionConfirmElement, setDeleteActionConfirmElement] = useState<HTMLElement | null>(null);
  const visible = !!props.actions.length && !!props.meta;

  if (!visible) {
    return null;
  }

  const renderAction = (action: ActionTypes.RowAction): ReactNode => {
    switch (action.name) {
      case "rowInsert": {
        const handleClick = (placement: "above" | "below") => {
          action.onClick(props.meta!.section.id, props.meta!.row.id!, placement);
          props.onHide();
        };

        return (
          <InsertAction key={action.name} refCallback={setInsertActionMenuElement}
                        onClick={handleClick}/>
        );
      }
      case "rowCopy": {
        const handleClick = () => {
          action.onClick(props.meta!.section.id, props.meta!.row.id!);
          props.onHide();
        };

        return (
          <CopyAction key={action.name} onClick={handleClick}/>
        );
      }
      case "rowMove": {
        const handleClick = (placement: "above" | "below") => {
          action.onClick(props.meta!.section.id, props.meta!.row.id!, placement);
          props.onHide();
        };

        return (
          <MoveAction key={action.name} onClick={handleClick}/>
        );
      }
      case "rowRemove": {
        const handleConfirm = () => {
          action.onClick(props.meta!.section.id, props.meta!.row.id!);
          props.onHide();
        };

        return (
          <RemoveAction key={action.name} onConfirm={handleConfirm}
                        onConfirmVisibleChange={setDeleteActionConfirmElement}/>
        );
      }
      default: {
        return null;
      }
    }
  };

  return (
    <ControlPanel containerRef={props.containerRef}
                  targetRef={props.targetRef}
                  posX="right" offsetX={-4}
                  posY="top"
                  layout="vertical" className={styles.panel}
                  allowedClickContainers={[
                    props.targetRef.current,
                    insertActionMenuElement,
                    deleteActionConfirmElement,
                  ]}
                  visible onHide={props.onHide}>
      {props.actions.map(renderAction)}
    </ControlPanel>
  );
};