import type {AppSkeletonProps} from "./Skeleton.types";
import {getClassNames} from "./Skeleton.utils";

export const AppSkeleton = (props: AppSkeletonProps) => {
  const {
    children,
    className,
    animation = true,
  } = props;

  const classNames = getClassNames(animation, className);

  return (
    <div className={classNames}>
      {children}
    </div>
  );
};