import {Outlet} from "react-router-dom";
import {AppFlex} from "shared/UI/UIKit/Flex";
import type {AppLayoutProps} from "./Layout.types";
import {AppFooter} from "./Footer";
import {AppHeader} from "./Header";
import {AppSidebar} from "./Sidebar";
import {WelcomeSidebar} from "./WelcomeSidebar";
import {useSidebar} from "./hooks/use-sidebar";
import styles from "./Layout.module.scss";

export const AppLayout = ({
  visibleFooter = true,
  visibleHeader = true,
  visibleSidebar = true,
  visibleWelcomeSidebar = false
}: AppLayoutProps) => {
  const {sidebarMenuItems, setOpenedKeys, openedKeys} = useSidebar(visibleSidebar);

  return (
    <AppFlex direction="row" unit="0">
      {visibleSidebar && <AppSidebar onOpenChange={setOpenedKeys} openKeys={openedKeys} menuItems={sidebarMenuItems}/>}
      {(visibleWelcomeSidebar && !visibleSidebar) && <WelcomeSidebar/>}
      <AppFlex unit="0" alignItems="stretch" className={styles.container}>
        {visibleHeader && <AppHeader/>}
        <Outlet/>
        {visibleFooter && <AppFooter/>}
      </AppFlex>
    </AppFlex>
  );
};