import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {useAuth} from "services/auth";
import {getHomeUrl} from "services/navigation";

export const AuthorizationPage = () => {
  const {login, authenticated} = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!authenticated) {
      login();
    } else {
      navigate(getHomeUrl());
    }
  }, [authenticated]);

  return null;
};
