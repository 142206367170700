import {forwardRef} from "react";
import type {AppTextButtonProps} from "./TextButton.types";
import {AppBaseButton} from "../BaseButton";
import {getClassNames} from "./TextButton.utils";

export const AppTextButton = forwardRef<HTMLButtonElement, AppTextButtonProps>((
  props, ref) => {
  const classNames = getClassNames(props);

  return (
    <AppBaseButton {...props} className={classNames} ref={ref}/>
  );
});