import {setIndent} from "../utils";

export const custom_outdent_plugin = {
  name: "customOutdent",
  display: "command",
  title: "Уменьшить отступ",
  innerHTML: "<span>Отступ</span>",
  add: function (core, targetElement) {
    targetElement.firstElementChild.outerHTML = core.icons.outdent;
  },
  action: function () {
    setIndent(this, "outdent");
  },
};