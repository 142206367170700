export const HighEducationHeader = () => {
  return (
    <thead>
      <tr>
        <th colSpan={1}
          rowSpan={2} style={{width: "20%"}}>
          Направление внеурочной деятельности
        </th>
        <th colSpan={1}
          rowSpan={2} style={{width: "20%"}}>
          Наименование курса внеурочной деятельности
        </th>
        <th colSpan={1}
          rowSpan={2} style={{width: "20%"}}>
          Формы внеурочной деятельности
        </th>
        <th colSpan={2}
          rowSpan={1}
          style={{width: "20%"}}>
          Классы/количество часов
        </th>
        <th colSpan={1}
          rowSpan={2}>
          Всего часов
        </th>
      </tr>
      <tr>
        <th colSpan={1}
          rowSpan={1} style={{width: "10%"}}>
          10 кл.
        </th>
        <th colSpan={1}
          rowSpan={1} style={{width: "10%"}}>
          11 кл.
        </th>
      </tr>
    </thead>
  );
};