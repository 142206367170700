import {AppFlex} from "shared/UI/UIKit/Flex";
import type {MainProgramItem,Curriculum} from "services/api";
import {CurriculumItem} from "../CurriculumItem";

type CurriculumsListProps = {
  curriculums: Curriculum[];
  addMainProgramItemToStateByFieldName: (fieldName: string, item: MainProgramItem) => void;
}

export const CurriculumsList = (props: CurriculumsListProps) => {
  return (
    <AppFlex unit="40">
      {
        props.curriculums?.map((item, index) => {
          const isLastCurriculumItem = index === props.curriculums.length - 1;
          return <CurriculumItem curriculum={item} key={index} isLastCurriculumItem={isLastCurriculumItem}
                                 addMainProgramItemToStateByFieldName={props.addMainProgramItemToStateByFieldName}/>;
        })
      }
    </AppFlex>
  );
};