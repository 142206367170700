import cn from "classnames";
import type {AppLinkProps} from "./Link.types";
import styles from "./Link.module.scss";

type AppLinkStyleProps = Pick<AppLinkProps, "size" | "className">;

export const getClassNames = (props: AppLinkStyleProps) => {
  return cn(
    styles.link,
    props.size && styles[`link--${props.size}`],
    props.className,
  );
};