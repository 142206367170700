import React, {forwardRef} from "react";
import type {AppIconButtonProps} from "./IconButton.types";
import {AppButton} from "../../Button";
import {getClassNames} from "./IconButton.utils";

export const AppIconButton = forwardRef<HTMLButtonElement, AppIconButtonProps>((props, ref) => {
  const {children, type = "text", ...rest} = props;
  const classNames = getClassNames(props);
  return (
    <AppButton {...rest}
               type={type}
               icon={children}
               className={classNames}
               ref={ref}/>
  );
});