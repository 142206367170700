import {AppText} from "shared/UI/UIKit/Text";
import {AppConfirm} from "shared/UI/UIKit/Confirm";
import type {AppTextProps} from "shared/UI/UIKit/Text";
import type {MouseEvent} from "react";
import type {TextMenuItemProps} from "./TextMenuItem.types";
import {useMenuContext} from "../../../ui/context";
import {getClassNames} from "./TextMenuItem.utils";
import styles from "./TextMenuItem.module.scss";

export const TextMenuItem = (props: TextMenuItemProps) => {
  const {
    id,
    label,
    icon,
    className,
    disabled,
    onClick,
    shouldCloseMenu,
    ellipsis,
    confirm,
  } = props;
  const context = useMenuContext();
  const classNames = getClassNames(className);

  const getTextItem = (onClickHandler: AppTextProps["onClick"]) => {
    return (
      <AppText size={16} className={classNames} disabled={disabled}
               ellipsis={ellipsis} onClick={onClickHandler}>
        {icon && <span className={styles.icon}>{icon}</span>}
        <span>{label}</span>
      </AppText>
    );
  };

  const renderTextItem = () => {
    const onTextClickHandler: AppTextProps["onClick"] = (e) => {
      // call the stopPropagation() to prevent the submenu from closing right after clicking on the label
      // we do this to unify the label click behavior regardless of its parent (menu, submenu, dropdown -> menu, dropdown -> submenu)
      e?.stopPropagation();

      if (disabled) {
        return;
      }

      onClick?.(id, e);
      context.onClick({
        key: id,
        itemType: "TextItem",
        event: e,
        shouldCloseMenu,
      });
    };

    return getTextItem(onTextClickHandler);
  };

  const renderConfirmTextItem = () => {
    const {
      onConfirm,
      onCancel,
      shouldCloseMenuOnConfirm,
      shouldCloseMenuOnCancel,
      ...restConfirm
    } = confirm!;

    const onTextClickHandler: AppTextProps["onClick"] = (e) => {
      // call the stopPropagation() to prevent the submenu from closing right after clicking on the label
      e?.stopPropagation();
    };

    const onConfirmClickHandler = (e: MouseEvent<HTMLElement> | undefined, isConfirmed: boolean) => {
      // call the stopPropagation() to prevent the submenu from closing right after clicking on the label
      // we do this to unify the label click behavior regardless of its parent (menu, submenu, dropdown -> menu, dropdown -> submenu)
      e?.stopPropagation();

      if (isConfirmed) {
        onConfirm?.(id, e);
      } else {
        onCancel?.(id, e);
      }
      context.onClick({
        key: id,
        itemType: isConfirmed ? "ConfirmOkButton" : "ConfirmCancelButton",
        event: e,
        shouldCloseMenu: isConfirmed ? shouldCloseMenuOnConfirm : shouldCloseMenuOnCancel,
      });
    };

    return (
      <AppConfirm {...restConfirm}
                  disabled={disabled || restConfirm.disabled}
                  onConfirm={(e) => onConfirmClickHandler(e, true)}
                  onCancel={(e) => onConfirmClickHandler(e, false)}>
        {getTextItem(onTextClickHandler)}
      </AppConfirm>
    );
  };

  return confirm ? renderConfirmTextItem() : renderTextItem();
};