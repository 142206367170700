import {AppFlex} from "shared/UI/UIKit/Flex";
import {AppCheckIcon} from "shared/UI/UIKit/icons/Check";
import {AppLogoIcon} from "shared/UI/UIKit/icons/Logo";
import {AppText} from "shared/UI/UIKit/Text";
import {AppTitle} from "shared/UI/UIKit/Title";
import {pagesList} from "./pages-list";
import styles from "./WelcomeSidebar.module.scss";

export const WelcomeSidebar = () => {
  return (
    <aside className={styles.sidebar}>
      <AppFlex unit="20" direction="row" alignItems="center">
        <AppLogoIcon className={styles.logo}/>
        <AppText type="secondary" size={16}>
          Система управления сервисом
        </AppText>
      </AppFlex>
      <AppTitle className={styles.title} level={1}>Добро пожаловать в систему управления сервисом</AppTitle>
      <AppFlex className={styles.list} unit="30">
        {pagesList.map((item) => {
          return (
            <AppFlex key={item.key} direction="row" alignItems="center">
              <AppCheckIcon/>
              <AppText size={18}>{item.title}</AppText>
            </AppFlex>
          );
        })}
      </AppFlex>
    </aside>
  );
};