import {Form} from "antd";
import {useEffect} from "react";
import {AppMultipleSelect} from "shared/UI/UIKit/Select";
import {AppFormItem, formRules} from "shared/UI/UIKit/Form";
import {AppCheckbox} from "shared/UI/UIKit/Checkbox";
import {AppTextArea} from "shared/UI/UIKit/TextArea";
import type {ListCellFormProps, ListCellFormValues} from "./types";
import {ModalHeader} from "../../ModalHeader";
import {ModalContent} from "../../ModalContent";
import {ModalFooter} from "../../ModalFooter";
import {formNames, formItemLabels} from "./constants";
import {getInitialFormValues, getSubmitValues, optionsStringToSelectOptions} from "./utils";

export const ListCellForm = (props: ListCellFormProps) => {
  const [form] = Form.useForm<ListCellFormValues>();
  const options = Form.useWatch<string | undefined>(
    formNames.options, form
  );

  const handleSubmit = () => {
    form.validateFields()
      .then((values) => {
        const submitValues = getSubmitValues(values);
        const nextValues = {
          ...(props.value?.visible
            ? {value: submitValues.value}
            : {}
          ),
          ...(props.options?.visible
              ? {options: submitValues.options}
              : {}
          ),
          ...(props.readonly?.visible
              ? {readonly: submitValues.readonly}
              : {}
          ),
        };

        props.onSubmit(nextValues);
      })
      .catch(() => null);
  };

  const handleOptionsChange = () => {
    form.setFieldValue(formNames.value, []);
  };

  useEffect(() => {
    form.setFieldsValue(getInitialFormValues({
      value: props.field.value,
      options: props.field.options,
      readonly: props.field.readonly,
    }));
  }, []);

  return (
    <>
      <ModalHeader isFullAccess={props.isFullAccess}/>
      <ModalContent form={form}>
        {props.value?.visible ? (
          <AppFormItem label={props.value?.label ?? formItemLabels.value}
                       rules={props.value?.required ? formRules.requiredArray : []}
                       name={formNames.value}>
            <AppMultipleSelect placeholder={props.field.placeholder || ""}
                               options={optionsStringToSelectOptions(options)}/>
          </AppFormItem>
        ) : null}
        {props.options?.visible ? (
          <AppFormItem label={props.options?.label ?? formItemLabels.options}
                       rules={props.options?.required ? formRules.required : []}
                       name={formNames.options}>
            <AppTextArea placeholder="введите возможные значения" onChange={handleOptionsChange}
                         autoSize={{minRows: 3, maxRows: 10}}/>
          </AppFormItem>
        ) : null}
        {props.readonly?.visible ? (
          <AppFormItem valuePropName="checked"
                       rules={props.readonly?.required ? formRules.required : []}
                       name={formNames.readonly}>
            <AppCheckbox>{props.readonly?.label ?? formItemLabels.readonly}</AppCheckbox>
          </AppFormItem>
        ) : null}
      </ModalContent>
      <ModalFooter onSubmit={handleSubmit} onCancel={props.onCancel}/>
    </>
  );
};