import {useEffect, useState} from "react";
import {useForm} from "antd/es/form/Form";
import {useParams} from "react-router-dom";
import {AppModal} from "shared/UI/UIKit/Modal";
import {useAppDispatch} from "services/redux/hooks/use-dispatch";
import {AppFlex} from "shared/UI/UIKit/Flex";
import {AppButton} from "shared/UI/UIKit/Button";
import {AppForm, AppFormItem, formRules} from "shared/UI/UIKit/Form";
import {useAppSelector} from "services/redux/hooks/use-selector";
import {selectMainProgram} from "features/main-program/redux/selectors";
import {AppInput} from "shared/UI/UIKit/Input";
import {renameCurriculumAction} from "features/main-program/redux/actions";
import type {ViewMainProgramPageParams} from "services/navigation";

type RenameCurriculumModalProps = {
  visible: boolean;
  hideModal: () => void;
}

export const RenameCurriculumModal = (props: RenameCurriculumModalProps) => {
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const {mainProgramPartId} = useParams<ViewMainProgramPageParams>();
  const mainProgram = useAppSelector(selectMainProgram);

  useEffect(() => {
    if (mainProgram && props.visible) {
      const curriculumName = mainProgram.curriculums.find(item => item.id === Number(mainProgramPartId))?.name;
      if (curriculumName) {
        form.setFieldValue("name", curriculumName);
      }
    }
  }, [mainProgram, props.visible]);


  const hideModal = () => {
    form.resetFields();
    props.hideModal();
    setLoading(false);
  };

  const submit = () => {
    form.validateFields().then(values => {
      setLoading(true);
      dispatch(renameCurriculumAction(values.name))
        .unwrap()
        .then(hideModal)
        .catch(console.log);
    }).catch(console.log);
  };

  const footer = (
    <AppFlex direction="row" justifyContent="flex-start">
      <AppButton onClick={submit}>
        Сохранить
      </AppButton>
      <AppButton type="outline" onClick={hideModal}>
        Отмена
      </AppButton>
    </AppFlex>
  );
  const content = (
    <AppForm form={form}>
      <AppFormItem name="name" label="Название учебного плана" rules={formRules.required}>
        <AppInput placeholder="Введите название"/>
      </AppFormItem>
    </AppForm>
  );

  return (
    <AppModal visible={props.visible} onCancel={hideModal} size="auto"
              content={content} destroyOnClose footer={footer} loading={loading}
              title="Редактирование учебного плана"/>
  );
};