import type {CellValidationResult} from "../types";

export const makeSuccessValidationResult = (message?: string): CellValidationResult => {
  return {
    status: "success",
    message,
  };
};

export const makeWarningValidationResult = (message?: string): CellValidationResult => {
  return {
    status: "warning",
    message,
  };
};

export const makeErrorValidationResult = (message: string): CellValidationResult => {
  return {
    status: "error",
    message,
  };
};