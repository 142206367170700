import {getProgramTemplateBlockTableDTO} from "services/api/blocks/dto";
import {useEvent} from "shared/helpers/hooks/use-event";
import type {ProgramTemplateBlockTable} from "services/api";
import type {UseTableConstructorOptions, UseTableConstructorReturnValue} from "./types";
import {tableNormalization} from "../../../lib/core";

export const useTableConstructor = ({
  table,
  onTableUpdate,
  onTableRemove,
}: UseTableConstructorOptions): UseTableConstructorReturnValue => {
  const normalizedTable = tableNormalization.normalizeBlockTableForRender(table);

  const handleTableUpdate = useEvent((nextTablePart: Partial<ProgramTemplateBlockTable>) => {
    const nextTable = getProgramTemplateBlockTableDTO({
      ...normalizedTable,
      ...nextTablePart,
    });
    const normalizedNextTable = tableNormalization.normalizeBlockTableForUpdate(nextTable);
    onTableUpdate(normalizedTable.id!, normalizedNextTable);
  });

  const handleTableRemove = useEvent(() => {
    onTableRemove(normalizedTable.id!);
  });

  return [
    {
      table: normalizedTable,
    },
    {
      onTableUpdate: handleTableUpdate,
      onTableRemove: handleTableRemove,
    },
  ];
};