import {AppFormItem, arrayValidator} from "shared/UI/UIKit/Form";
import {AppCheckbox, AppCheckboxGroup} from "shared/UI/UIKit/Checkbox";
import {AppText} from "shared/UI/UIKit/Text";
import type {EnumFieldProps} from "./types";
import {FILTER_FORM_NAMES} from "../../../constants";
import styles from "./EnumField.module.scss";

export const EnumField = ({options}: EnumFieldProps) => {
  const renderError = () => {
    return (
      <AppFormItem name={FILTER_FORM_NAMES.values}
                   rules={[{validator: () => Promise.reject()}]}
                   noStyle>
        <AppText type="danger">Ошибка: отсутствуют значения фильтра</AppText>
      </AppFormItem>
    );
  };

  const renderEnum = () => {
    return (
      <AppFormItem name={FILTER_FORM_NAMES.values}
                   rules={[{validator: arrayValidator()}]}
                   marginless>
        <AppCheckboxGroup direction="vertical" className={styles["checkbox-group"]}>
          {options?.map(option => {
            return (
              <AppCheckbox key={option.value} value={option.value}>
                {option.label}
              </AppCheckbox>
            );
          })}
        </AppCheckboxGroup>
      </AppFormItem>
    );
  };

  return !options?.length ? renderError() : renderEnum();
};