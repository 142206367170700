import type {TableEditorProps} from "./types";
import {TableConstructor} from "../constructor";
import {TableEditorWrapper} from "./components/TableEditorWrapper";
import {useTableEditor} from "./hooks/use-table-editor";

export const TableEditor = (props: TableEditorProps) => {
  const [{blockTables}, {blockSettingsMethods, blockTableMethods}] = useTableEditor(props);

  return (
    <TableEditorWrapper editMode={props.editMode} isFullAccess={props.isFullAccess}
                        block={props.block}
                        onBlockSettingsUpdate={blockSettingsMethods.update}
                        onTableCreate={blockTableMethods.create}>
      {blockTables.map(table => {
        return (
          <TableConstructor key={table.id}
                            isPrograms={props.isPrograms} isFullAccess={props.isFullAccess}
                            editMode={props.editMode} table={table}
                            onTableUpdate={blockTableMethods.update}
                            onTableRemove={blockTableMethods.remove}/>
        );
      })}
    </TableEditorWrapper>
  );
};