import {cloneElement, isValidElement} from "react";
import cn from "classnames";
import type {ReactNode} from "react";
import styles from "./styles.module.scss";

export const getIcon = (icon: ReactNode) => {
  if (!isValidElement(icon)) {
    return icon;
  }

  return cloneElement(icon, {
    className: cn(icon.props.className, styles["item-icon"]),
  });
};