import {AppModal} from "shared/UI/UIKit/Modal";
import {AppForm, AppFormItem, formRules, useAppForm} from "shared/UI/UIKit/Form";
import {AppInput} from "shared/UI/UIKit/Input";
import {AppButton} from "shared/UI/UIKit/Button";
import {useAppDispatch} from "services/redux/hooks/use-dispatch";
import {SubjectObligation} from "services/api";
import {addSubjectInSelectedCurriculum} from "features/create-main-program/redux/slice";
import type {Curriculum, Subject} from "services/api";

type CreateNewElementModalProps = {
  isOpen: boolean;
  closeModal: () => void;
  curriculum: Curriculum;
  subjectType: SubjectObligation;
  additionalSubjects: Subject[];
  extracurricularSubjects: Subject[];
}

export const CreateNewElementModal = (props: CreateNewElementModalProps) => {
  const [form] = useAppForm();
  const dispatch = useAppDispatch();

  const getNextPosition = (): number => {
    const subjects: Subject[] = props.subjectType === SubjectObligation.ADDITIONAL ?
      props.additionalSubjects :
      props.extracurricularSubjects;

    if (!subjects.length) {
      return 999;
    }

    let max: number = subjects[0].position!;

    subjects.forEach((subject, index) => {
      if (index === 0) {
        return;
      }

      if (subject.position! > max) {
        max = subject.position!;
      }
    });

    return max + 1;
  };

  const submit = () => {
    form.validateFields().then(values => {
      const body: Subject = {
        baseSubjectName: values.name,
        customSubjectName: values.name,
        id: props.subjectType === SubjectObligation.ADDITIONAL ? 1000 : 2000,
        name: values.name,
        obligation: props.subjectType,
        planId: props.curriculum.id,
        position: getNextPosition(),
        uniqueId: new Date().getTime(),
        canRemove: true,
      };

      dispatch(addSubjectInSelectedCurriculum({
        curriculumId: props.curriculum.id,
        subject: body,
      }));

      const currentWizard = localStorage.getItem("WIZARD") || "{}";
      const nextWizard = JSON.parse(currentWizard);
      nextWizard.extraSubjects = nextWizard.extraSubjects ? [
        ...nextWizard.extraSubjects,
        {
          curriculumId: props.curriculum.id,
          subject: body,
        }
      ] : [
        {
          curriculumId: props.curriculum.id,
          subject: body,
        }
      ];
      localStorage.setItem("WIZARD", JSON.stringify(nextWizard));

      hide();
    }).catch(() => null);
  };

  const hide = () => {
    props.closeModal();
    form.resetFields();
  };

  const getTitle = () => {
    if (props.subjectType === SubjectObligation.ADDITIONAL) {
      return "Создание нового элемента дополнительного образования";
    }

    return "Создание нового элемента внеурочной деятельности";
  };

  const content = (
    <AppForm form={form}>
      <AppFormItem name="name" label="Название элемента" rules={formRules.required}>
        <AppInput placeholder="Введите название"/>
      </AppFormItem>
    </AppForm>
  );

  const footer = (
    <AppButton onClick={submit}>
      Сохранить
    </AppButton>
  );

  return (
    <AppModal destroyOnClose loading={false}
              title={getTitle()}
              visible={props.isOpen} onCancel={hide}
              content={content} footer={footer}/>
  );
};