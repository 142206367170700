import {AppFlex} from "shared/UI/UIKit/Flex";
import {AppText} from "shared/UI/UIKit/Text";
import {AppButton} from "shared/UI/UIKit/Button";
import {AppInput} from "shared/UI/UIKit/Input";
import {AppPagination} from "shared/UI/UIKit/Pagination";
import {AppTitle} from "shared/UI/UIKit/Title";
import {useAppSelector} from "services/redux/hooks/use-selector";
import {selectCreateMainProgramSelectedCurriculumsEntities} from "features/create-main-program/redux/selectors";
import {CreateWorkProgramTabs} from "features/create-main-program/redux/types";
import {useAppDispatch} from "services/redux/hooks/use-dispatch";
import {setSelectedCurriculumsEntitiesAction} from "features/create-main-program/redux/slice";
import {DEFAULT_LIMIT} from "services/api";
import {AppDeleteIcon} from "shared/UI/UIKit/icons/Delete";
import type {Curriculum} from "services/api";
import {CurriculumList} from "../СurriculumForm";
import styles from "./styles.module.scss";

type SecondStepProps = {
  handleNextStep: () => void;
  handlePrevStep: () => void;
  searchQueryChange: (query: string) => void;
  paginationChange: (newPage: number) => void;
  curriculums: Curriculum[];
  paginationPage: number;
  total: number;
  activeTabId: CreateWorkProgramTabs;
}

export const SecondStep = (props: SecondStepProps) => {
  const selectedCurriculumsEntities = useAppSelector(selectCreateMainProgramSelectedCurriculumsEntities);
  const dispatch = useAppDispatch();

  if (props.activeTabId !== CreateWorkProgramTabs.CURRICULUM) {
    return null;
  }

  const unselectItem = (id: number) => {
    const newCurriculums = selectedCurriculumsEntities.filter(item => item.id !== id);

    dispatch(setSelectedCurriculumsEntitiesAction(newCurriculums));
  };

  return (
    <AppFlex unit="24">
      <AppText size={24}>
        Выберите учебный план
      </AppText>
      <AppInput onChange={e => props.searchQueryChange(e.target.value)}
                placeholder="Строка поиска"/>
      <AppTitle level={3}>
        Выбранные планы: {selectedCurriculumsEntities.length}
      </AppTitle>
      {
        selectedCurriculumsEntities.map((item, index) => {
          return (
            <AppFlex key={item.id} direction="row" justifyContent="space-between" className={styles.removeContainer}>
              <AppText size={16} className={styles.name}>{index + 1}. {item.name || "—"}</AppText>
              <AppDeleteIcon onClick={() => unselectItem(item.id)} className={styles.remove}/>
            </AppFlex>
          );
        })
      }
      <CurriculumList curriculums={props.curriculums}/>
      {/* eslint-disable-next-line react/no-unstable-nested-components */}
      <AppPagination showTotal={(total, range) => {
                       return <span>{range[0]}-{range[1]} из {total}</span>;
                     }}
                     className={styles.pagination}
                     showSizeChanger={false}
                     hideOnSinglePage
                     pageSize={DEFAULT_LIMIT}
                     total={props.total}
                     current={props.paginationPage + 1}
                     onChange={(newPage: number) => props.paginationChange(newPage - 1)}/>
      <AppFlex direction="row"
               justifyContent="space-between">
        <AppButton size="large"
                   onClick={props.handlePrevStep}>
          {"<"} Назад
        </AppButton>
        <AppButton size="large"
                   onClick={props.handleNextStep}>
          Шаг 3. Рабочая программа {">"}
        </AppButton>
      </AppFlex>
    </AppFlex>
  );
};