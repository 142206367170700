import {Form} from "antd";
import {forwardRef} from "react";
import type {KeyboardEvent} from "react";
import type {AppFormInstance, AppFormProps} from "./Form.types";
import {getClassNames} from "./Form.utils";
import {formValidateMessages} from "./config/validate-messages";

export const AppForm = forwardRef<AppFormInstance, AppFormProps>(
  (props, ref) => {
    const {className, children, ...rest} = props;
    const classNames = getClassNames(className);

    const onKeyDown = (e: KeyboardEvent<HTMLFormElement>) => {
      if (e.key === "Enter") {
        props.onSubmit?.();
      }
    };

    return (
      <Form layout="vertical" onKeyDown={onKeyDown} {...rest} className={classNames}
            validateMessages={formValidateMessages} ref={ref}>
        {children}
      </Form>
    );
  }
);