import {Form} from "antd";
import {useEffect} from "react";
import {AppFormItem, formRules} from "shared/UI/UIKit/Form";
import {AppInputNumber} from "shared/UI/UIKit/InputNumber";
import {AppSelect} from "shared/UI/UIKit/Select";
import {AppCheckbox} from "shared/UI/UIKit/Checkbox";
import {formatNumber} from "shared/helpers/utils/format-number";
import type {NumberCellFormProps, NumberCellFormValues} from "./types";
import {ModalHeader} from "../../ModalHeader";
import {ModalContent} from "../../ModalContent";
import {ModalFooter} from "../../ModalFooter";
import {formNames, formItemLabels} from "./constants";
import {numberCellFormRules} from "./utils/rules";

export const NumberCellForm = (props: NumberCellFormProps) => {
  const [form] = Form.useForm<NumberCellFormValues>();

  const handleSubmit = () => {
    form.validateFields()
      .then((values) => {
        const nextValues = {
          ...(props.targetValue?.visible
            ? {targetValue: formatNumber(values.targetValue, props.targetValue.options)}
            : {}
          ),
          ...(props.operator?.visible
            ? {operator: values.operator === "empty" ? null : values.operator}
            : {}
          ),
          ...(props.readonly?.visible
              ? {readonly: values.readonly}
              : {}
          ),
        };

        props.onSubmit(nextValues);
      })
      .catch(() => null);
  };

  useEffect(() => {
    form.setFieldValue(formNames.targetValue, props.field.targetValue);
    form.setFieldValue(
      formNames.operator,
      props.field.operator === null ? "empty" : props.field.operator
    );
    form.setFieldValue(formNames.readonly, props.field.readonly);
  }, []);

  return (
    <>
      <ModalHeader isFullAccess={props.isFullAccess}/>
      <ModalContent form={form}>
        {props.targetValue?.visible ? (
          <AppFormItem label={props.targetValue?.label ?? formItemLabels.targetValue}
                       rules={[
                         ...(props.targetValue?.required ? formRules.requiredStrNumber : []),
                         numberCellFormRules.requireIfOperatorIsNotEmpty,
                       ]}
                       dependencies={[formNames.operator]}
                       name={formNames.targetValue}>
            <AppInputNumber<string> stringMode
                                    formatter={value => formatNumber(value, props.targetValue?.options)}
                                    placeholder={props.field.placeholder || ""}/>
          </AppFormItem>
        ) : null}
        {props.operator?.visible ? (
          <AppFormItem label={props.operator?.label ?? formItemLabels.operator}
                       rules={props.operator?.required ? formRules.required : []}
                       name={formNames.operator}>
            <AppSelect options={props.operator?.options || []} placeholder="Выберите оператор"/>
          </AppFormItem>
        ) : null}
        {props.readonly?.visible ? (
          <AppFormItem valuePropName="checked"
                       rules={props.readonly?.required ? formRules.required : []}
                       name={formNames.readonly}>
            <AppCheckbox>{props.readonly?.label ?? formItemLabels.readonly}</AppCheckbox>
          </AppFormItem>
        ) : null}
      </ModalContent>
      <ModalFooter onSubmit={handleSubmit} onCancel={props.onCancel}/>
    </>
  );
};