import type {
  CheckBlockSettingsControlsVisibleOptions,
  CheckAddTableButtonVisibleOptions,
  CheckTableCellLockedIconVisibleOptions,
  CheckHeaderCellEditIconVisibleOptions,
  CheckHeaderCellLockedIconVisibleOptions,
  CheckTableSectionCreatorVisibleOptions,
  CheckTableSectionHeaderVisibleOptions,
  CheckTableSectionFooterVisibleOptions,
  CheckTableRowCreatorVisibleOptions,
  CheckTableFooterRowCreatorVisibleOptions,
  CheckTableModuleCreatorVisibleOptions
} from "./types";
import {modulesHelpers} from "../../helpers";

export const checkBlockSettingsControlsVisible = ({
  editMode,
  isFullAccess,
}: CheckBlockSettingsControlsVisibleOptions) => {
  return editMode && isFullAccess;
};

export const checkAddTableButtonVisible = ({
  editMode,
  isFullAccess,
}: CheckAddTableButtonVisibleOptions) => {
  return editMode && isFullAccess;
};

export const checkHeaderCellLockedIconVisible = ({
  editMode,
  isFullAccess,
}: CheckHeaderCellLockedIconVisibleOptions) => {
  return editMode && isFullAccess;
};

export const checkHeaderCellEditIconVisible = ({
  editMode,
  column,
}: CheckHeaderCellEditIconVisibleOptions) => {
  return editMode && column.subtype === "ADDITIONAL";
};

export const checkTableCellLockedIconVisible = ({
  editMode,
  isFullAccess,
}: CheckTableCellLockedIconVisibleOptions) => {
  return editMode && isFullAccess;
};

export const checkTableSectionCreatorVisible = ({
  tableType,
  editMode,
  isFullAccess,
  sectionCreationAllowed,
}: CheckTableSectionCreatorVisibleOptions) => {
  return (
    editMode &&
    tableType === "EXTENDED" &&
    (isFullAccess || sectionCreationAllowed)
  );
};

export const checkTableModuleCreatorVisible = ({
  tableType,
  editMode,
  isFullAccess,
  sectionCreationAllowed,
}: CheckTableModuleCreatorVisibleOptions) => {
  return (
    editMode &&
    tableType === "EXTENDED" &&
    (isFullAccess || sectionCreationAllowed)
  );
};

export const checkTableSectionHeaderVisible = ({
  tableType,
}: CheckTableSectionHeaderVisibleOptions) => {
  return tableType === "EXTENDED";
};

export const checkTableSectionFooterVisible = ({
  tableType,
  sectionType,
}: CheckTableSectionFooterVisibleOptions) => {
  return sectionType === "TABLE_FOOTER" || tableType === "EXTENDED";
};

export const checkTableRowCreatorVisible = ({
  editMode,
  isFullAccess,
  rowCreationAllowed,
  section,
}: CheckTableRowCreatorVisibleOptions) => {
  if (modulesHelpers.checkSectionIsModule(section)) {
    return false;
  }

  return (
    section.type === "TABLE" &&
    editMode &&
    (isFullAccess || rowCreationAllowed)
  );
};

export const checkTableFooterRowCreatorVisible = ({
  editMode,
  isFullAccess,
  sectionType,
}: CheckTableFooterRowCreatorVisibleOptions) => {
  return (
    sectionType === "TABLE_FOOTER" &&
    editMode &&
    isFullAccess
  );
};