import {AppForm} from "shared/UI/UIKit/Form";
import {AppModalContent} from "shared/UI/UIKit/Modal";
import type {ModalContentProps} from "./types";

export const ModalContent = <T,>(props: ModalContentProps<T>) => {
  return (
    <AppModalContent>
      <AppForm form={props.form}>
        {props.children}
      </AppForm>
    </AppModalContent>
  );
};