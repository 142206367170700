import {UserProfileRole} from "services/api";
import {useAppSelector} from "services/redux/hooks/use-selector";

export const useUserProfileRole = () => {
  const {profile} = useAppSelector(state => state.profileState);

  return {
    role: profile && profile.role,
    isAdmin: profile?.role === UserProfileRole.ADMIN,
    isUser: profile?.role === UserProfileRole.USER,
  };
};