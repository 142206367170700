import React from "react";
import type {AppTabsProps} from "./Tabs.types";
import {AppTabsSwitch} from "./components/TabSwitch";
import {AppTabsPanes} from "./components/TabsPanes";
import {getClassNames} from "./Tabs.utils";

export const AppTabs = ({
  tabs,
  activeId,
  className,
  tabsSwitchClassName,
}: AppTabsProps) => {
  const classNames = getClassNames(className);

  return (
    <div className={classNames}>
      <AppTabsSwitch className={tabsSwitchClassName} tabs={tabs} activeId={activeId}/>
      <AppTabsPanes tabs={tabs} activeId={activeId}/>
    </div>
  );
};