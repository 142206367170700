import {BACK_END_TIME_FORMAT, USER_TIME_FORMAT} from "shared/constants/date";
import type {TimeRendererProps} from "../types";
import {DateRenderer} from "./DateRenderer";

export const TimeRenderer = ({time, ...rest}: TimeRendererProps) => {
  return (
    <DateRenderer date={time}
                  sourceFormat={BACK_END_TIME_FORMAT}
                  targetFormat={USER_TIME_FORMAT}
                  {...rest}/>
  );
};