import type {AppModalProps} from "./Modal.types";
import {AppBaseModal} from "../BaseModal";
import {AppModalHeader} from "./components/ModalHeader";
import {AppModalTitle} from "./components/ModalTitle";
import {AppModalContent} from "./components/ModalContent";
import {AppModalFooter} from "./components/ModalFooter";

export const AppModal = (props: AppModalProps) => {
  if ("children" in props) {
    return (
      <AppBaseModal {...props}/>
    );
  }

  const {
    title,
    content,
    footer,
    customHeader,
    customContent,
    customFooter,
    ...baseModalProps
  } = props;

  return (
    <AppBaseModal {...baseModalProps}>
      {customHeader || (title && (
        <AppModalHeader>
          <AppModalTitle>{title}</AppModalTitle>
        </AppModalHeader>
      ))}
      {customContent || (content && <AppModalContent>{content}</AppModalContent>)}
      {customFooter || (footer && <AppModalFooter>{footer}</AppModalFooter>)}
    </AppBaseModal>
  );
};