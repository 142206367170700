import {memo} from "react";
import type {AddTableButtonProps} from "./types";
import {AddButton} from "../../../ui/components/AddButton";
import {DropdownMenu, getDropdownMenuItem} from "../../../ui/components/DropdownMenu";
import styles from "./styles.module.scss";

export const AddTableButton = memo((props: AddTableButtonProps) => {
  const items = [
    getDropdownMenuItem({
      key: "import",
      label: "Загрузить из Excel",
      onClick: () => props.onItemClick("import"),
    }),
    getDropdownMenuItem({
      key: "simple",
      label: "Создать простую таблицу",
      onClick: () => props.onItemClick("simple"),
    }),
    getDropdownMenuItem({
      key: "extended",
      label: "Создать таблицу с разделами",
      onClick: () => props.onItemClick("extended"),
    }),
  ];

  return (
    <DropdownMenu align={{offset: [74, -8]}} menu={{items}}>
      <AddButton className={styles.button}/>
    </DropdownMenu>
  );
});