import {getStringFieldComparator} from "shared/helpers/utils/comparators";
import type {TableConstructorColumn} from "../../types";
import {
  makeGroupColumn,
  makeIndexColumn,
  makeWidgetStringColumn,
  makeWidgetNumberColumn,
  makeWidgetNumberTotalWorkHoursColumn,
  makeWidgetNumberVerificationWorkHoursColumn,
  makeWidgetNumberPracticalWorkHoursColumn,
  makeWidgetDateColumn,
  makeWidgetListColumn,
  makeWidgetReferenceColumn,
} from "./column-makers";

type ColumnsVariant = {
  id: string;
  label: string;
  columns: TableConstructorColumn[];
}

const columnsVariants: Record<string, ColumnsVariant> = {
  "VARIANT_1": {
    id: "VARIANT_1",
    label: "Тематическое планирование",
    columns: [
      makeIndexColumn(),
      makeWidgetStringColumn({
        name: "Наименование тем программы",
        width: "30rem",
        required: true,
        lockDisabled: true,
      }),
      makeWidgetNumberTotalWorkHoursColumn({
        name: "Количество часов",
        width: "10rem",
      }),
      makeWidgetStringColumn({
        name: "Основное содержание",
      }),
      makeWidgetListColumn({
        name: "Виды деятельности",
        width: "12rem",
        availableTypes: ["WIDGET_STRING", "WIDGET_LIST", "WIDGET_REFERENCE"],
      }),
    ],
  },
  "VARIANT_2": {
    id: "VARIANT_2",
    label: "Поурочное планирование на уровень",
    columns: [
      makeIndexColumn(),
      makeWidgetStringColumn({
        name: "Тема урока",
        required: true,
        lockDisabled: true,
      }),
      makeGroupColumn({
        name: "Количество часов",
        children: [
          makeWidgetNumberTotalWorkHoursColumn(),
          makeWidgetNumberVerificationWorkHoursColumn(),
          makeWidgetNumberPracticalWorkHoursColumn(),
        ]
      }),
      makeWidgetReferenceColumn({
        name: "Электронные цифровые образовательные ресурсы",
        width: "25rem",
        availableTypes: ["WIDGET_STRING", "WIDGET_LIST", "WIDGET_REFERENCE"],
      }),
    ],
  },
  "VARIANT_3": {
    id: "VARIANT_3",
    label: "Тематическое планирование на уровень",
    columns: [
      makeIndexColumn(),
      makeWidgetStringColumn({
        name: "Наименование разделов и тем программы",
        required: true,
        lockDisabled: true,
      }),
      makeGroupColumn({
        name: "Количество часов",
        children: [
          makeWidgetNumberTotalWorkHoursColumn(),
          makeWidgetNumberVerificationWorkHoursColumn(),
          makeWidgetNumberPracticalWorkHoursColumn(),
        ],
      }),
      makeWidgetStringColumn({
        name: "Электронные (цифровые) образовательные ресурсы",
        width: "25rem",
        availableTypes: ["WIDGET_STRING", "WIDGET_LIST", "WIDGET_REFERENCE"],
      }),
    ],
  },
  "VARIANT_4": {
    id: "VARIANT_4",
    label: "Поурочное планирование на класс",
    columns: [
      makeIndexColumn(),
      makeWidgetStringColumn({
        name: "Тема урока",
        required: true,
        lockDisabled: true,
      }),
      makeGroupColumn({
        name: "Количество часов",
        children: [
          makeWidgetNumberTotalWorkHoursColumn(),
          makeWidgetNumberVerificationWorkHoursColumn(),
          makeWidgetNumberPracticalWorkHoursColumn(),
        ],
      }),
      makeWidgetDateColumn({
        availableTypes: ["WIDGET_DATE", "WIDGET_PERIOD"],
      }),
      makeWidgetReferenceColumn({
        name: "Электронные цифровые образовательные ресурсы",
        width: "15rem",
        availableTypes: ["WIDGET_STRING", "WIDGET_LIST", "WIDGET_REFERENCE"],
      }),
    ],
  },
  "VARIANT_5": {
    id: "VARIANT_5",
    label: "Тематическое планирование на класс",
    columns: [
      makeIndexColumn(),
      makeWidgetStringColumn({
        name: "Наименование разделов и тем программы",
        required: true,
        lockDisabled: true,
      }),
      makeGroupColumn({
        name: "Количество часов",
        children: [
          makeWidgetNumberTotalWorkHoursColumn(),
          makeWidgetNumberVerificationWorkHoursColumn(),
          makeWidgetNumberPracticalWorkHoursColumn(),
        ],
      }),
      makeWidgetDateColumn({
        availableTypes: ["WIDGET_DATE", "WIDGET_PERIOD"],
      }),
      makeWidgetListColumn({
        name: "Виды деятельности",
        width: "10rem",
        availableTypes: ["WIDGET_STRING", "WIDGET_LIST", "WIDGET_REFERENCE"],
      }),
      makeWidgetReferenceColumn({
        name: "Виды, формы контроля",
        width: "10rem",
        availableTypes: ["WIDGET_STRING", "WIDGET_LIST", "WIDGET_REFERENCE"],
      }),
      makeWidgetStringColumn({
        name: "Электронные (цифровые) образовательные ресурсы",
        width: "12rem",
        availableTypes: ["WIDGET_STRING", "WIDGET_LIST", "WIDGET_REFERENCE"],
      }),
    ],
  },
  "VARIANT_6": {
    id: "VARIANT_6",
    label: "Тематическое планирование на уровень (музыка)",
    columns: [
      makeIndexColumn(),
      makeWidgetStringColumn({
        name: "Наименование разделов и тем программы",
        required: true,
        lockDisabled: true,
      }),
      makeGroupColumn({
        name: "Количество часов",
        children: [
          makeWidgetNumberTotalWorkHoursColumn({
            width: "8.4rem",
          }),
          makeWidgetNumberVerificationWorkHoursColumn({
            width: "10rem",
          }),
          makeWidgetNumberPracticalWorkHoursColumn({
            width: "10rem",
          }),
        ],
      }),
      makeGroupColumn({
        name: "Репертуар",
        children: [
          makeWidgetNumberColumn({
            name: "Для слушания",
            width: "8.4rem",
          }),
          makeWidgetNumberColumn({
            name: "Для пения",
            width: "8.4rem",
          }),
          makeWidgetNumberColumn({
            name: "Для музицирования",
            width: "11rem",
          }),
        ],
      }),
      makeWidgetStringColumn({
        name: "Электронные (цифровые) образовательные ресурсы",
        width: "15rem",
        availableTypes: ["WIDGET_STRING", "WIDGET_LIST", "WIDGET_REFERENCE"],
      }),
    ],
  },
  "VARIANT_7": {
    id: "VARIANT_7",
    label: "Тематическое планирование на класс (музыка)",
    columns: [
      makeIndexColumn(),
      makeWidgetStringColumn({
        name: "Наименование разделов и тем программы",
        required: true,
        lockDisabled: true,
      }),
      makeGroupColumn({
        name: "Количество часов",
        children: [
          makeWidgetNumberTotalWorkHoursColumn({
            width: "5.3rem",
          }),
          makeWidgetNumberVerificationWorkHoursColumn({
            width: "7rem",
          }),
          makeWidgetNumberPracticalWorkHoursColumn({
            width: "7rem",
          }),
        ],
      }),
      makeGroupColumn({
        name: "Репертуар",
        children: [
          makeWidgetNumberColumn({
            name: "Для слушания",
            width: "7rem",
          }),
          makeWidgetNumberColumn({
            name: "Для пения",
            width: "5.3rem",
          }),
          makeWidgetNumberColumn({
            name: "Для музицирования",
            width: "7rem",
          }),
        ],
      }),
      makeWidgetDateColumn({
        width: "9.3rem",
        availableTypes: ["WIDGET_DATE", "WIDGET_PERIOD"],
      }),
      makeWidgetListColumn({
        name: "Виды деятельности",
        width: "9.2rem",
        availableTypes: ["WIDGET_STRING", "WIDGET_LIST", "WIDGET_REFERENCE"],
      }),
      makeWidgetReferenceColumn({
        name: "Виды, формы контроля",
        width: "6.8rem",
        availableTypes: ["WIDGET_STRING", "WIDGET_LIST", "WIDGET_REFERENCE"],
      }),
      makeWidgetStringColumn({
        name: "Электронные (цифровые) образовательные ресурсы",
        width: "11.2rem",
        availableTypes: ["WIDGET_STRING", "WIDGET_LIST", "WIDGET_REFERENCE"],
      }),
    ],
  },
  "VARIANT_8": {
    id: "VARIANT_8",
    label: "Тематическое планирование внеурочной деятельности",
    columns: [
      makeIndexColumn(),
      makeWidgetStringColumn({
        name: "Наименование разделов и тем программы",
        width: "30rem",
        required: true,
        lockDisabled: true,
      }),
      makeWidgetNumberTotalWorkHoursColumn({
        name: "Количество часов",
        width: "10rem",
      }),
      makeWidgetStringColumn({
        name: "Основное содержание",
      }),
      makeWidgetStringColumn({
        name: "Основные виды деятельности",
      }),
      makeWidgetStringColumn({
        name: "Электронные (цифровые) образовательные ресурсы",
        width: "11.2rem",
        availableTypes: ["WIDGET_STRING", "WIDGET_LIST", "WIDGET_REFERENCE"],
      }),
    ],
  },

};

export const VARIANT_OPTIONS = Object
  .values(columnsVariants)
  .map(variant => ({value: variant.id, label: variant.label}))
  .sort(getStringFieldComparator((option => option.label), "ascend"));

export const getColumnsByVariantId = (id: string | undefined): TableConstructorColumn[] => {
  return id ? columnsVariants[id].columns : [];
};