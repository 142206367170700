import {useEffect, useState} from "react";
import {useForm} from "antd/es/form/Form";
import {useNavigate} from "react-router-dom";
import {AppModal} from "shared/UI/UIKit/Modal";
import {useAppDispatch} from "services/redux/hooks/use-dispatch";
import {AppFlex} from "shared/UI/UIKit/Flex";
import {AppButton} from "shared/UI/UIKit/Button";
import {AppForm, AppFormItem, formRules} from "shared/UI/UIKit/Form";
import {useAppSelector} from "services/redux/hooks/use-selector";
import {selectMainProgram} from "features/main-program/redux/selectors";
import {AppInput} from "shared/UI/UIKit/Input";
import {createMainProgramBlockAction, updateMainProgramBlockAction} from "features/main-program/redux/actions";
import {getViewMainProgramTemplateUrl, getViewMainProgramUrl} from "services/navigation";
import {AppInputNumber} from "shared/UI/UIKit/InputNumber";
import {AppSelect} from "shared/UI/UIKit/Select";
import {PROGRAM_TEMPLATE_BLOCK_TYPE} from "services/api";
import type {AppSelectOption} from "shared/UI/UIKit/Select";
import type {MainProgramBlockDto, MainProgramBlockDtoBody} from "services/api";

type MainProgramBlockProps = {
  visible: boolean;
  hideModal: () => void;
  isTemplate?: boolean;
  selectedBlock?: MainProgramBlockDto | null;
}

const blockTypesOptions: AppSelectOption[] = [
  {
    value: PROGRAM_TEMPLATE_BLOCK_TYPE.DOCUMENT,
    label: "Документ",
  },
  {
    value: PROGRAM_TEMPLATE_BLOCK_TYPE.TABLE,
    label: "Таблица",
  }
];

export const MainProgramBlockModal = (props: MainProgramBlockProps) => {
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const mainProgram = useAppSelector(selectMainProgram);
  const navigate = useNavigate();

  useEffect(() => {
    if (props.visible && props.selectedBlock) {
      form.setFieldsValue({
        name: props.selectedBlock.name,
        section: props.selectedBlock.section,
        index: props.selectedBlock.index,
        type: props.selectedBlock.type,
      });
    }

    return () => {
      form.resetFields();
    };
  }, [props.visible, props.selectedBlock]);

  const hideModal = () => {
    form.resetFields();
    props.hideModal();
  };

  const submit = () => {
    form.validateFields().then(values => {
      setLoading(true);
      const body: MainProgramBlockDtoBody = {
        id: props.selectedBlock ? props.selectedBlock?.id : undefined,
        name: values.name,
        section: values.section ? `${values.section.charAt(0).toUpperCase()}${values.section.slice(1)}` : "",
        content: props.selectedBlock ? props.selectedBlock?.content : "",
        index: Number(values.index),
        type: values.type,
        mainProgram: {
          id: mainProgram!.id
        }
      };

      if (props.selectedBlock) {
        dispatch(updateMainProgramBlockAction(body))
          .unwrap()
          .then(hideModal)
          .catch(console.log);
      } else {
        dispatch(createMainProgramBlockAction(body))
          .unwrap()
          .then(newBlock => {
            const getUrl = props.isTemplate ? getViewMainProgramTemplateUrl : getViewMainProgramUrl;
            hideModal();
            navigate(getUrl({
              mainProgramId: String(mainProgram?.id),
              mainProgramPartId: String(newBlock.id),
            }));
          })
          .catch(console.log);
      }

    }).catch(console.log);
  };

  const footer = (
    <AppFlex direction="row" justifyContent="flex-start">
      <AppButton onClick={submit}>
        {props.selectedBlock ? "Редактировать" : "Создать"}
      </AppButton>
      <AppButton type="outline" onClick={hideModal}>
        Отмена
      </AppButton>
    </AppFlex>
  );

  const content = (
    <AppForm form={form}>
      <AppFormItem name="name" label="Название блока" rules={formRules.required}>
        <AppInput placeholder="Введите название"/>
      </AppFormItem>
      <AppFormItem name="type" label="Тип блока" rules={formRules.required}>
        <AppSelect options={blockTypesOptions} placeholder="Введите тип"/>
      </AppFormItem>
      <AppFormItem name="section" label="Название раздела">
        <AppInput placeholder="Введите название"/>
      </AppFormItem>
      <AppFormItem name="index" label="Порядковый номер" rules={formRules.requiredNumber}>
        <AppInputNumber placeholder="Введите порядковый номер"/>
      </AppFormItem>
    </AppForm>
  );

  return (
    <AppModal visible={props.visible} onCancel={hideModal} size="auto"
              content={content} destroyOnClose footer={footer} loading={loading}
              title={props.selectedBlock ? "Редактирование блока" : "Создание блока"}/>
  );
};