import type {TableHeaderParentCellProps} from "./types";
import {TableHeaderBaseCell} from "../TableHeaderBaseCell";

export const TableHeaderParentCell = (props: TableHeaderParentCellProps) => {
  return (
    <TableHeaderBaseCell isFullAccess={props.isFullAccess} editMode={props.editMode}
                         {...props.cell.meta.attrs}>
      {props.cell.column.name}
    </TableHeaderBaseCell>
  );
};