import {memo} from "react";
import type {TableConstructorProps} from "./types";
import {TableWithModals} from "./components/TableWithModals";
import {useTableConstructor} from "./hooks/use-table-constructor";
import {TableConstructorContextProvider} from "./contexts/table-constructor-context";

export const TableConstructor = memo((props: TableConstructorProps) => {
  const [{table}, {onTableUpdate, onTableRemove}] = useTableConstructor({
    table: props.table,
    onTableUpdate: props.onTableUpdate,
    onTableRemove: props.onTableRemove,
  });

  return (
    <TableConstructorContextProvider header={table.header} editMode={props.editMode}
                                     isPrograms={props.isPrograms} isFullAccess={props.isFullAccess}>
      <TableWithModals containerClassName={props.containerClassName}
                       table={table} onTableUpdate={onTableUpdate} onTableRemove={onTableRemove}/>
    </TableConstructorContextProvider>
  );
});