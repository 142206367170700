import type {AppIconProps} from "../Icons.types";
import {getClassNames} from "../Icons.utils";
import styles from "./AppMainProgram.module.scss";

export const AppMainProgramIcon = (props: AppIconProps) => {
  const {className, ...rest} = props;
  const classNames = getClassNames(styles.icon, className);

  return (
    <svg {...rest} className={classNames} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 24" fill="none">
      <path d="M11.4071 4.91632L18.6991 12.4294L21.6159 9.42408L14.3239 1.91112L11.4071 4.91632Z" stroke="#737781" strokeWidth="2"/>
      <path d="M6.03676 8.26574L13.2533 6.81787L16.8528 10.5264L15.4475 17.9617C15.3704 18.3698 15.0693 18.6941 14.677 18.7915L2.23132 21.8824L5.23134 9.05964C5.32592 8.65547 5.64066 8.34522 6.03676 8.26574Z" stroke="#737781" strokeWidth="2"/>
      <path d="M12.5008 11.3025C13.5077 12.3398 13.5077 14.0217 12.5008 15.059C11.494 16.0963 9.86163 16.0963 8.85481 15.059C7.84799 14.0217 7.84799 12.3398 8.85481 11.3025C9.86163 10.2652 11.494 10.2652 12.5008 11.3025Z" fill="#737781"/>
      <path d="M3.19995 20.8849L9.03355 14.8746" stroke="#737781" strokeWidth="2"/>
    </svg>
  );
};
