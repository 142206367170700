import type {AppTableSingleColumn, AppTableItem} from "../../../types";
import {ColumnCell} from "./components/ColumnCell";

export const addRenderToColumn = <TRecord extends AppTableItem>(
  column: AppTableSingleColumn<TRecord>
): AppTableSingleColumn<TRecord> => {
  return {
    ...column,
    render: (value: any, record: TRecord, index: number) => {
      return (
        <ColumnCell value={value} record={record} index={index}
                    column={column}/>
      );
    }
  };
};