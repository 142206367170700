import {Form} from "antd";
import {omit} from "shared/helpers/utils/omit";
import type {AppFormItemBaseProps} from "./FormItemBase.types";
import {getClassName, getWrapperClassName} from "./FormItemBase.utils";

export const AppFormItemBase = (props: AppFormItemBaseProps) => {
  const {flex = 1} = props;
  const rest = omit(props, ["withoutExplain", "marginless", "flex", "wrapperClassName"]);
  const classNames = getClassName(props);
  const styles = props.noStyle ? undefined : {flex};

  return (
    <div style={styles} className={getWrapperClassName(props)}>
      <Form.Item {...rest} className={classNames}>
        {props.children}
      </Form.Item>
    </div>
  );
};