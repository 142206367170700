import {memo, useRef} from "react";
import cn from "classnames";
import {AppText} from "shared/UI/UIKit/Text";
import type {BlockTitleProps} from "./types";
import {ControlPanel} from "../../../ui/components/ControlPanel";
import {SettingsIcon} from "../../../ui/components/icons";
import styles from "./styles.module.scss";

export const BlockTitle = memo((props: BlockTitleProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const targetRef = useRef<HTMLDivElement>(null);

  return (
    <div ref={containerRef} className={styles["title-container"]}>
      <AppText ref={targetRef} className={cn(styles.title, "table-block-title-docx")}
               size={16} weight={700}>
        {props.title}
      </AppText>
      <ControlPanel containerRef={containerRef}
                    targetRef={targetRef}
                    visible={props.isControlsVisible}
                    posX="right" offsetX={-4}>
        <SettingsIcon onClick={props.onEditModalShow}/>
      </ControlPanel>
    </div>
  );
});