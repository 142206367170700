import type {ProgramTemplateBlockTableRow} from "services/api";

const defaultObj: ProgramTemplateBlockTableRow = {
  id: null,
  section: null,
  index: 0,
  type: "SECTION_BODY_ROW",
  createdOnWorkProgram: null,
  fields: [],
};

export const getProgramTemplateBlockTableRowDTO = (
  obj: Partial<ProgramTemplateBlockTableRow>
): ProgramTemplateBlockTableRow => ({
  ...defaultObj,
  ...obj,
});