import {AppMarkdownPreview} from "shared/UI/UIKit/MarkdownPreview";
import {AppText} from "shared/UI/UIKit/Text";
import type {StringValueRendererOptions} from "./types";
import {bracketHelpers} from "../../../../../lib/helpers";
import styles from "./styles.module.scss";

export const stringValueRenderer = ({
  field,
  isFullAccess,
  isPrograms,
  editMode,
  editablePartPositions,
  sectionOrdinalNumber,
}: StringValueRendererOptions) => {
  const shouldDisplayValue = !editMode || !isFullAccess;
  const isProgramsPreview = isPrograms && !editMode;
  const displayedValue = (() => {
    const preprocessedValue = isProgramsPreview
      ? bracketHelpers.removeBracketsAtPositions(field.value, editablePartPositions)
      : (field.value || "");
    const sectionHeaderPrefix = sectionOrdinalNumber !== undefined ? `**Раздел ${sectionOrdinalNumber}.** ` : "";

    return `${sectionHeaderPrefix}${preprocessedValue}`;
  })();
  const showValue = displayedValue || shouldDisplayValue;

  return showValue
    ? <AppMarkdownPreview source={displayedValue} className={styles["md-preview"]}/>
    : <AppText size={16} className={styles.text}>
      {field.placeholder}
    </AppText>;
};