import type {TableHiddenCellProps} from "./components/TableHiddenCell";
import type {TableFillerCellProps} from "./components/TableFillerCell";
import type {TableIndexCellProps} from "./components/TableIndexCell";
import type {TableWidgetStringCellProps} from "./components/TableWidgetStringCell";
import type {TableWidgetNumberCellProps} from "./components/TableWidgetNumberCell";
import type {TableWidgetDateCellProps} from "./components/TableWidgetDateCell";
import type {TableWidgetPeriodCellProps} from "./components/TableWidgetPeriodCell";
import type {TableWidgetListCellProps} from "./components/TableWidgetListCell";
import type {TableWidgetReferenceCellProps} from "./components/TableWidgetReferenceCell";
import type {TableWidgetSumCellProps} from "./components/TableWidgetSumCell";
import type {TableWidgetPercentCellProps} from "./components/TableWidgetPercentCell";
import {TableHiddenCell} from "./components/TableHiddenCell";
import {TableFillerCell} from "./components/TableFillerCell";
import {TableIndexCell} from "./components/TableIndexCell";
import {TableWidgetStringCell} from "./components/TableWidgetStringCell";
import {TableWidgetNumberCell} from "./components/TableWidgetNumberCell";
import {TableWidgetDateCell} from "./components/TableWidgetDateCell";
import {TableWidgetPeriodCell} from "./components/TableWidgetPeriodCell";
import {TableWidgetListCell} from "./components/TableWidgetListCell";
import {TableWidgetReferenceCell} from "./components/TableWidgetReferenceCell";
import {TableWidgetSumCell} from "./components/TableWidgetSumCell";
import {TableWidgetPercentCell} from "./components/TableWidgetPercentCell";

export type TableBodyCellProps = {
  Hidden: TableHiddenCellProps,
  Filler: TableFillerCellProps,
  Index: TableIndexCellProps,
  WidgetString: TableWidgetStringCellProps,
  WidgetNumber: TableWidgetNumberCellProps,
  WidgetDate: TableWidgetDateCellProps,
  WidgetPeriod: TableWidgetPeriodCellProps,
  WidgetList: TableWidgetListCellProps,
  WidgetReference: TableWidgetReferenceCellProps,
  WidgetSum: TableWidgetSumCellProps,
  WidgetPercent: TableWidgetPercentCellProps,
}

const TableBodyCell = {
  Hidden: TableHiddenCell,
  Filler: TableFillerCell,
  Index: TableIndexCell,
  WidgetString: TableWidgetStringCell,
  WidgetNumber: TableWidgetNumberCell,
  WidgetDate: TableWidgetDateCell,
  WidgetPeriod: TableWidgetPeriodCell,
  WidgetList: TableWidgetListCell,
  WidgetReference: TableWidgetReferenceCell,
  WidgetSum: TableWidgetSumCell,
  WidgetPercent: TableWidgetPercentCell,
};

export {
  TableBodyCell,
};