import {AppModal} from "shared/UI/UIKit/Modal";
import {AppForm, AppFormItem, formRules, useAppForm} from "shared/UI/UIKit/Form";
import {AppInput} from "shared/UI/UIKit/Input";
import {AppButton} from "shared/UI/UIKit/Button";
import {useAppDispatch} from "services/redux/hooks/use-dispatch";
import {EducationLevel, SubjectObligation} from "services/api";
import {addRequiredSubjectInSelectedCurriculum} from "features/create-main-program/redux/slice";
import {useAppSelector} from "services/redux/hooks/use-selector";
import {selectCreateMainProgramEducationLevel} from "features/create-main-program/redux/selectors";
import type {Curriculum, Subject} from "services/api";

type CreateRequiredElementModalProps = {
  isOpen: boolean;
  closeModal: () => void;
  curriculum: Curriculum;
  id: number;
  indexToInsert: number;
  requiredSubjects: Subject[];
}

export const CreateRequiredElementModal = (props: CreateRequiredElementModalProps) => {
  const [form] = useAppForm();
  const dispatch = useAppDispatch();

  const educationLevel = useAppSelector(selectCreateMainProgramEducationLevel);


  const getSubjectAreaId = () => {
    if (educationLevel === EducationLevel.НОО) {
      return 3;
    }
    if (educationLevel === EducationLevel.ООО) {
      return 12;
    }
    if (educationLevel === EducationLevel.СОО) {
      return 22;
    }
  };

  const getNextPosition = (): number => {
    const subjects: Subject[] = props.requiredSubjects;

    if (!subjects.length) {
      return 999;
    }

    let max: number = subjects[0].position!;

    subjects.forEach((subject, index) => {
      if (index === 0) {
        return;
      }

      if (subject.position! > max) {
        max = subject.position!;
      }
    });

    return max + 1;
  };

  const submit = () => {
    form.validateFields().then(values => {
      const body: Subject = {
        baseSubjectName: values.name,
        customSubjectName: values.name,
        id: props.id,
        name: values.name,
        obligation: SubjectObligation.REQUIRED,
        planId: props.curriculum.id,
        position: getNextPosition(),
        uniqueId: new Date().getTime(),
        canRemove: true,
        subjectAreaId: getSubjectAreaId(),
      };

      dispatch(addRequiredSubjectInSelectedCurriculum({
        curriculumId: props.curriculum.id,
        subject: body,
        indexToInsert: props.indexToInsert,
      }));

      hide();
    }).catch(() => null);
  };

  const hide = () => {
    props.closeModal();
    form.resetFields();
  };

  const content = (
    <AppForm form={form}>
      <AppFormItem name="name" label="Название элемента" rules={formRules.required}>
        <AppInput placeholder="Введите название"/>
      </AppFormItem>
    </AppForm>
  );

  const footer = (
    <AppButton onClick={submit}>
      Сохранить
    </AppButton>
  );

  return (
    <AppModal destroyOnClose loading={false}
              title="Добавление иностранного языка"
              visible={props.isOpen} onCancel={hide}
              content={content} footer={footer}/>
  );
};
