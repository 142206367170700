import {useState} from "react";
import type {ReactNode} from "react";
import type {TableActionsPanelProps} from "./types";
import type {ActionTypes} from "../../../lib/types";
import {ControlPanel} from "../../../ui/components/ControlPanel";
import {SettingsAction} from "./components/SettingsAction";
import {RemoveAction} from "./components/RemoveAction";

export const TableActionsPanel = (props: TableActionsPanelProps) => {
  const [deleteActionConfirmElement, setDeleteActionConfirmElement] = useState<HTMLElement | null>(null);
  const visible = !!props.actions.length;

  if (!visible) {
    return null;
  }

  const renderAction = (action: ActionTypes.TableAction): ReactNode => {
    switch (action.name) {
      case "tableSettings": {
        return (
          <SettingsAction key={action.name} onClick={action.onClick}/>
        );
      }
      case "tableRemove": {
        return (
          <RemoveAction key={action.name} onConfirm={action.onClick}
                        onConfirmVisibleChange={setDeleteActionConfirmElement}/>
        );
      }
      default: {
        return null;
      }
    }
  };

  return (
    <ControlPanel containerRef={props.containerRef}
                  posX="right" offsetX={-4}
                  allowedClickContainers={[deleteActionConfirmElement]}
                  visible>
      {props.actions.map(renderAction)}
    </ControlPanel>
  );
};