import {forwardRef} from "react";
import {Select} from "antd";
import {AppArrowDropdownIcon} from "shared/UI/UIKit/icons/Arrows/ArrowDropdown/ArrowDropdown";
import type {AppSelectBaseProps, AppSelectComponent} from "./SelectBase.types";
import {getTextSorter} from "../utils/sorters/get-text-sorter";
import {getTextResolver} from "../utils/resolvers/get-text-resolver";
import {NotFound} from "./components/NotFound";

const defaultResolver = getTextResolver();
const defaultSorter = getTextSorter();

export const AppSelectBase = forwardRef<AppSelectComponent, AppSelectBaseProps>(
  (props, ref) => {
    const {filterOption = defaultResolver, filterSort = defaultSorter, showSearch = true, ...rest} = props;
    return <Select notFoundContent={<NotFound loading={!!props.loading}/>}
                   filterOption={filterOption} suffixIcon={<AppArrowDropdownIcon/>}
                   filterSort={filterSort} showSearch={showSearch} {...rest} ref={ref}/>;
  });
