import {AppInput} from "shared/UI/UIKit/Input";
import {AppFormItem, formRules} from "shared/UI/UIKit/Form";
import type {TextFieldsProps} from "./types";
import {FILTER_FORM_NAMES} from "../../../constants";

export const TextField = ({onChange, placeholder, focusRef}: TextFieldsProps) => {
  return (
    <AppFormItem name={[FILTER_FORM_NAMES.values, 0]} rules={formRules.required}
                 marginless withoutExplain>
      <AppInput onChange={onChange} placeholder={placeholder} ref={focusRef}/>
    </AppFormItem>
  );
};