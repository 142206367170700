import type {
  ProgramTemplateBlockTableRowField,
  ProgramTemplateTableRowType,
  ProgramTemplateTableSectionType,
  ProgramTemplateBlockTableHeader
} from "services/api";
import type {
  TableConstructorColumn,
  TableConstructorGroupColumn,
  TableConstructorSingleColumn,
} from "../../types";
import {makeWidgetStringAdditionalColumn} from "./column-makers";

export const isTableConstructorColumnGroup = (
  column: TableConstructorColumn
): column is TableConstructorGroupColumn => {
  return "children" in column;
};

export const isTableConstructorColumnSingle = (
  column: TableConstructorColumn
): column is TableConstructorSingleColumn => {
  return !isTableConstructorColumnGroup(column);
};

export const isTableConstructorColumnHidden = (column: TableConstructorColumn): boolean => {
  if (isTableConstructorColumnGroup(column)) {
    return column.children.every(child => isTableConstructorColumnHidden(child));
  }

  return !!column.hidden;
};

export const getSingleTableConstructorColumns = (
  columns: TableConstructorColumn[]
): TableConstructorSingleColumn[] => {
  const res: TableConstructorSingleColumn[] = [];

  const updateRes = (column: TableConstructorColumn) => {
    if (isTableConstructorColumnGroup(column)) {
      column.children.forEach(updateRes);
    } else {
      res.push(column);
    }
  };

  columns.forEach(updateRes);
  return res;
};

export const getUpdatedTableConstructorColumns = (
  columns: TableConstructorColumn[],
  updatedColumn: TableConstructorColumn,
) => {
  const columnMapper = (col: TableConstructorColumn): TableConstructorColumn => {
    if (isTableConstructorColumnGroup(col)) {
      return {...col, children: col.children.map(columnMapper)};
    }

    if (col.id === updatedColumn.id) {
      return updatedColumn;
    }

    return col;
  };

  return columns.map(columnMapper);
};

const stringifyTableHeaderColumns = (columns: TableConstructorColumn[]): string => {
  return JSON.stringify(columns);
};

const parseTableHeaderColumns = (variant: string | null | undefined): TableConstructorColumn[] => {
  if (!variant) {
    return [];
  }

  try {
    return JSON.parse(variant) as TableConstructorColumn[];
  } catch {
    return [];
  }
};

export const getTableColumnsByHeader = (header: ProgramTemplateBlockTableHeader): TableConstructorColumn[] => {
  const columns = parseTableHeaderColumns(header.variant);
  const shouldUpdateLockedProp = typeof header.lockedColumnIndexes === "string";
  const shouldUpdateHiddenProp = typeof header.hiddenColumnIndexes === "string";
  const lockedColumnIndexesSet = new Set<string>((header.lockedColumnIndexes || "").split(","));
  const hiddenColumnIndexesSet = new Set<string>((header.hiddenColumnIndexes || "").split(","));
  let childColumnIndex = -1;

  const updateColumn = (column: TableConstructorColumn): TableConstructorColumn => {
    if (isTableConstructorColumnGroup(column)) {
      return {
        ...column,
        children: column.children.map(updateColumn),
      };
    }

    childColumnIndex++;
    return {
      ...column,
      locked: shouldUpdateLockedProp
        ? lockedColumnIndexesSet.has(`${childColumnIndex}`)
        : column.locked,
      hidden: shouldUpdateHiddenProp
        ? hiddenColumnIndexesSet.has(`${childColumnIndex}`)
        : column.hidden,
    };
  };

  return columns.map(updateColumn);
};

export const getTableHeaderByColumns = (columns: TableConstructorColumn[]): ProgramTemplateBlockTableHeader => {
  const lockedColumnIndexesSet = new Set<string>();
  const hiddenColumnIndexesSet = new Set<string>();
  let childColumnIndex = -1;

  const updateSets = (column: TableConstructorColumn) => {
    if (isTableConstructorColumnGroup(column)) {
      column.children.forEach(updateSets);
      return;
    }

    childColumnIndex++;
    if (column.locked) {
      lockedColumnIndexesSet.add(`${childColumnIndex}`);
    }
    if (column.hidden) {
      hiddenColumnIndexesSet.add(`${childColumnIndex}`);
    }
  };

  columns.forEach(updateSets);

  return {
    variant: stringifyTableHeaderColumns(columns),
    lockedColumnIndexes: Array.from(lockedColumnIndexesSet.values()).join(","),
    hiddenColumnIndexes: Array.from(hiddenColumnIndexesSet.values()).join(","),
  };
};

export const addAdditionalColumnToHeader = (
  header: ProgramTemplateBlockTableHeader
): ProgramTemplateBlockTableHeader => {
  const columns = [...getTableColumnsByHeader(header), makeWidgetStringAdditionalColumn()];

  return getTableHeaderByColumns(columns);
};

export const getColumnByField = (
  columns: TableConstructorSingleColumn[],
  sectionType: ProgramTemplateTableSectionType,
  rowType: ProgramTemplateTableRowType,
  field: ProgramTemplateBlockTableRowField,
): TableConstructorSingleColumn | undefined => {
  switch (rowType) {
    case "SECTION_HEADER_ROW": {
      return;
    }
    case "SECTION_BODY_ROW":
    case "SECTION_FOOTER_ROW": {
      return columns[field.index];
    }
  }
};

export const filterColumnsByAvailableTypes = (columns: TableConstructorSingleColumn[]) => {
  return columns.filter(column => {
    const types = column.availableTypes || [];
    return types.length > 1;
  });
};