import {errorMessages} from "shared/UI/UIKit/Form";
import type {StringValueEditorFormValues, StringValueEditorSubmitValues} from "./types";
import {bracketHelpers} from "../../../../../lib/helpers";

export const getInitialFormValues = (values: StringValueEditorSubmitValues): StringValueEditorFormValues => {
  return {
    value: values.value,
  };
};

export const getSubmitValues = (values: StringValueEditorFormValues): StringValueEditorSubmitValues => {
  return {
    value: values.value,
  };
};

const editablePartsValidatorForFullAccess = (required = true) => {
  return (_: any, value: string | null | undefined) => {
    if (!bracketHelpers.validateBrackets(value)) {
      return Promise.reject("Неверно заданы области редактирования: существуют вложенные или лишние квадратные скобки");
    }

    if (bracketHelpers.getBracketsPositions(value).length > 1) {
      return Promise.reject("Укажите одну область редактирования");
    }

    if (!value) {
      return required ? Promise.reject(errorMessages.required) : Promise.resolve();
    }

    return Promise.resolve();
  };
};

const editablePartsValidatorForLimitedAccess = () => {
  return (_: any, value: string | null | undefined) => {
    if (value && /\[\[|\]\]/.test(value)) {
      return Promise.reject("В рабочих программах нельзя задавать области редактирования");
    }

    return Promise.resolve();
  };
};

export const formRules = {
  getValueValidator: (required: boolean, isFullAccess: boolean) => {
    return {
      validator: isFullAccess
        ? editablePartsValidatorForFullAccess(required)
        : editablePartsValidatorForLimitedAccess(),
    };
  },
};

export const formFieldValidators = {
  checkValueValid: async (value: StringValueEditorFormValues["value"], required: boolean, isFullAccess: boolean) => {
    const {validator} = formRules.getValueValidator(required, isFullAccess);
    return await validator(undefined, value);
  }
};