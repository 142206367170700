import type {CSSProperties} from "react";
import type {AppSelectOption} from "shared/UI/UIKit/Select";
import type {EducationLevel, ProgramTemplateBlockType} from "services/api";

export type GetCurriculumsByEducationLevelResponse = {
  content: Curriculum[];
  total: number;
}

export type GetCurriculumsByEducationLevelRequest = {
  page: number;
  searchQuery: string;
  educationLevel: EducationLevel;
}

export type ListResponse<T> = {
  items: T[],
  total: number;
}

export type Curriculum = {
  id: number;
  name: string;
  index?: number;
  subjects: Subject[];
}

export type CurriculumDetailedTableRowCell = {
  id: number;
  value: string;
  rowSpan: number;
  colSpan: number;
  bold?: boolean;
}

export type CurriculumDetailedTableRow = {
  id: number;
  cells: CurriculumDetailedTableRowCell[];
}

export type CurriculumDetailedBlock = {
  id: number;
  title: string;
  headerRows: CurriculumDetailedTableRow[];
  bodyRows: CurriculumDetailedTableRow[];
  footerRows: CurriculumDetailedTableRow[];
  type: ProgramTemplateBlockType;
  content: string;
}

export type CurriculumDetailed = {
  id: number;
  title: string;
  blocks: CurriculumDetailedBlock[];
}

export enum SubjectObligation {
  REQUIRED = "Обязательная",
  EXTRACURRICULAR = "Внеурочная",
  ADDITIONAL = "Дополнительная"
}

export type Subject = {
  id: number;
  name: string;
  obligation: SubjectObligation;
  baseSubjectName?: string;
  customSubjectName?: string;
  uniqueId?: number;
  planId?: number;
  position?: number;
  canRemove?: boolean;
  subjectAreaId?: number;
  initialPosition?: number;
}

export type LocalStorageSubject = {
  curriculumId: number;
  subject: Subject;
}

export type WorkProgram = {
  id: number;
  programName: string;
  name?: string;
  obligation: SubjectObligation;
  showInMainPrograms?: boolean;
  subject?: Subject;
  hasDocx?: boolean;
  hideForManagerInMainProgram?: boolean;
}

export type GetWorkProgramsPayload = {
  educationLevel: EducationLevel;
  page?: number;
  limit?: number;
  sort?: string[];
  organizationId: number;
  subjectId?: number;
  showHidden: boolean;
}

export type MainProgramItem = {
  curriculum: Curriculum;
  subject: Subject;
  workProgram: WorkProgram;
  baseSubjectName?: string;
  customSubjectName?: string;
  index?: number;
  id?: number;
}

export type MainProgramBlockDto = {
  id?: number;
  name: string;
  content: string;
  index: number;
  mainProgram?: MainProgram;
  section: string;
  type: string;
}

export type MainProgramBlockDtoBody = Omit<MainProgramBlockDto, "mainProgram"> & {
  mainProgram: {
    id: number;
  }
}

export enum MainProgramStatusEnum {
  PUBLISHED = "PUBLISHED",
  NOT_PUBLISHED = "NOT_PUBLISHED"
}

export type MainProgram = {
  id: number;
  educationLevel: EducationLevel;
  createTs: string;
  createdBy: string;
  isTemplate?: boolean;
  curriculums: Curriculum[];
  items: MainProgramItem[];
  status: MainProgramStatusEnum;
  blocks: MainProgramBlockDto[];
  name: string | null;
  linkToSite: string | null;
}

export type GetMainProgramListPayload = {
  page?: number;
  limit?: number;
  isTemplate?: boolean;
  sort?: string[];
}

export type SetWorkProgramAvailableInMainProgramPayload = {
  programId: number;
  isAvailable: boolean;
}

export type ToggleVisibleWorkProgramInMainProgramListPayload = {
  programId: number;
  isHidden: boolean;
}

export type CreateMainProgramBody = {
  educationLevel: string;
  curriculums: Curriculum[];
  items: MainProgramItem[];
  blocks: MainProgramBlockDto[];
}

export type ExtracurricularTableBodyRow = {
  rowId: string;
  cells: ExtracurricularTableBodyCell[];
}

export type ExtracurricularTableBodyCell = {
  value?: string | number | string[];
  type: "TEXT" | "NUMBER" | "LIST" | "SUM";
  editable?: true;
  cellId: string;
  options: AppSelectOption[];
  colSpan?: number;
  styles?: CSSProperties;
  factor?: number;
  multipleSelect?: boolean;
}
