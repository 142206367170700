import type {InsertActionProps} from "./types";
import {DropdownMenu, getDropdownMenuItem} from "../../../../../ui/components/DropdownMenu";
import {AddIcon} from "../../../../../ui/components/icons";

export const InsertAction = ({onClick, refCallback}: InsertActionProps) => {
  const items = [
    getDropdownMenuItem({
      key: "1",
      label: "Вставить раздел выше",
      onClick: () => onClick("above"),
    }),
    getDropdownMenuItem({
      key: "2",
      label: "Вставить раздел ниже",
      onClick: () => onClick("below"),
    }),
  ];

  return (
    <DropdownMenu ref={(ref) => refCallback(ref?.menu?.list ?? null)}
                  placement="bottomRight" align={{offset: [-38, -30]}}
                  menu={{items}}>
      <AddIcon/>
    </DropdownMenu>
  );
};