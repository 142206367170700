import type {
  AppTableChangeEvent,
  AppTableState
} from "../../../ui";
import {getSort} from "./get-sort";
import {getFilter} from "./get-filter";
import {getLimitPage} from "./get-limit-page";

export const getChangeEvent = (tableState: AppTableState): AppTableChangeEvent => {
  return {
    sort: getSort(tableState.sorters),
    filter: getFilter(tableState.filters),
    ...getLimitPage(tableState.pagination),
  };
};