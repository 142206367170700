import React, {useRef} from "react";
import cn from "classnames";
import type {ControlPanelViewProps} from "./types";
import {CONTROL_PANEL_VIEW_DATA_TYPE} from "./constants";
import styles from "./ControlPanelView.module.scss";

export const ControlPanelView = ({
  children,
  style,
  layout,
  onHide,
  allowedClickContainers,
  className,
}: ControlPanelViewProps) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const classNames = cn(
    styles["control-panel-view"],
    layout === "vertical" && styles["control-panel-view_vertical"],
    className,
  );

  const handlePointerLeave = (event: React.PointerEvent<HTMLDivElement>) => {
    if (allowedClickContainers?.some(container => container?.contains(event.relatedTarget as Node))) {
      return;
    }

    onHide?.();
  };

  return (
    <div ref={ref} onPointerLeave={handlePointerLeave}
         data-type={CONTROL_PANEL_VIEW_DATA_TYPE}
         className={classNames} style={style}>
      {children}
    </div>
  );
};