import moment from "moment/moment";
import type {ProgramTemplateBlockTableSection} from "services/api";
import type {TableConstructorSingleColumn} from "../../types";
import type {DefaultDatePickerValuesMap, DefaultDatePickerValuesMapValue} from "./types";
import {FIELD_CONSTANTS} from "../../constants";

export const makeDefaultDatePickerValuesMap = (
  columns: TableConstructorSingleColumn[],
  sections: ProgramTemplateBlockTableSection[],
): DefaultDatePickerValuesMap => {
  const widgetDateColumnIndexes = columns.reduce((acc, column, index) => {
    if (column.type === "WIDGET_DATE") {
      return [...acc, index];
    }
    return acc;
  }, [] as number[]);

  const result: DefaultDatePickerValuesMap = new Map();
  const initialDate = moment({
    year: new Date().getUTCFullYear(),
    month: 8,
    day: 1,
  });

  widgetDateColumnIndexes.forEach(widgetDateColumnIndex => {
    const rowIdDefaultDatePickerValueMap: DefaultDatePickerValuesMapValue = new Map();
    let prevDate = initialDate;

    sections.forEach(section => {
      section.rows.forEach(row => {
        if (row.type !== "SECTION_BODY_ROW") {
          return;
        }

        rowIdDefaultDatePickerValueMap.set(row.id!, prevDate);
        const currentValue = row.fields.find(field => field.index === widgetDateColumnIndex)?.value ?? null;
        if (currentValue) {
          prevDate = moment(currentValue, FIELD_CONSTANTS.VALUE_DATE_FORMAT);
        }
      });
    });

    result.set(widgetDateColumnIndex, rowIdDefaultDatePickerValueMap);
  });

  return result;
};