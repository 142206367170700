import cn from "classnames";
import type {AppRadioGroupProps} from "./RadioGroup.types";
import styles from "./RadioGroup.module.scss";

export const getWrapperClassNames = (
  direction: AppRadioGroupProps["direction"],
  optionType: AppRadioGroupProps["optionType"],
  className?: string
) => {
  return cn(
    styles[`${direction}--${optionType}`],
    className,
  );
};
