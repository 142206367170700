import {cloneElement} from "react";
import cn from "classnames";
import {AppTooltip} from "shared/UI/UIKit/Tooltip";
import type {TooltipRendererProps} from "../types";
import styles from "../styles.module.scss";

export const TooltipRenderer = ({
  content,
  contentClassName,
  contentStyle,
  overlay,
  overlayClassName,
  overlayStyle,
}: TooltipRendererProps) => {
  const tooltipOverlay = cloneElement(overlay ?? content, {
    className: cn(styles["tooltip-overlay"], overlayClassName),
    style: overlayStyle,
  });

  const tooltipContent = cloneElement(content, {
    className: cn(styles["tooltip-content"], contentClassName),
    style: contentStyle,
  });

  return (
    <AppTooltip overlay={tooltipOverlay}>
      <div className={styles["tooltip-wrapper"]}>
        {tooltipContent}
      </div>
    </AppTooltip>
  );
};