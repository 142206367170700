import moment from "moment";
import {dateRangeValidator} from "shared/UI/UIKit/Form";
import type {Moment} from "moment";
import type {PeriodValueEditorFormValues, PeriodValueEditorSubmitValues} from "./types";
import {FIELD_CONSTANTS} from "../../../../../lib/constants";

export const getInitialFormValues = (values: PeriodValueEditorSubmitValues): PeriodValueEditorFormValues => {
  const getValue = (): [Moment | null, Moment | null] => {
    if (!values.value) {
      return [null, null];
    }

    const [from, to] = values.value.split(FIELD_CONSTANTS.MULTIPLE_VALUE_SPLITTER);
    return [moment(from, FIELD_CONSTANTS.VALUE_DATE_FORMAT), moment(to, FIELD_CONSTANTS.VALUE_DATE_FORMAT)];
  };

  return {
    value: getValue(),
  };
};

export const getSubmitValues = (values: PeriodValueEditorFormValues): PeriodValueEditorSubmitValues => {
  const getValue = (): string | null => {
    if (!values.value) {
      return null;
    }

    const [from, to] = values.value;

    if (!from && !to) {
      return null;
    }

    return [
      from!.format(FIELD_CONSTANTS.VALUE_DATE_FORMAT),
      to!.format(FIELD_CONSTANTS.VALUE_DATE_FORMAT)
    ].join(FIELD_CONSTANTS.MULTIPLE_VALUE_SPLITTER);
  };

  return {
    value: getValue(),
  };
};

export const formRules = {
  getValueValidator: (required: boolean) => {
    return {
      validator: dateRangeValidator(required),
    };
  }
};

export const formFieldValidators = {
  checkValueValid: async (value: PeriodValueEditorFormValues["value"], required: boolean) => {
    const {validator} = formRules.getValueValidator(required);
    return await validator(undefined, value);
  }
};