import {AppText} from "shared/UI/UIKit/Text";
import type {TextRendererProps} from "../types";

export const TextRenderer = ({text, ...rest}: TextRendererProps) => {
  if (!text) {
    return null;
  }

  return (
    <AppText {...rest}>
      {text}
    </AppText>
  );
};