import cn from "classnames";
import type {AppOutlineButtonProps} from "./OutlineButton.types";
import styles from "./OutlineButton.module.scss";

export const getClassNames = (props: AppOutlineButtonProps) => {
  const {color, className} = props;

  return cn(
    styles["type-outline"],
    color && styles[`color-${color}`],
    className,
  );
};