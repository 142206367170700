import {floatToString, stringToFloat} from "shared/helpers/utils/converters";
import type {
  ProgramTemplateBlockTableRowField,
  ProgramTemplateTableRowType,
  ProgramTemplateTableSectionType
} from "services/api";
import type {TableConstructorSingleColumn} from "../../../types";
import type {CellValidationResult} from "./types";
import {fieldsHelpers} from "../../../helpers";
import {
  compareValues,
  makeErrorValidationResult,
  makeSuccessValidationResult,
  makeWarningValidationResult
} from "./utils";
import {validationMessages} from "./constants";

type WidgetPercentCellValidatorInfo = {
  columns: TableConstructorSingleColumn[];
  sectionType: ProgramTemplateTableSectionType;
  rowType: ProgramTemplateTableRowType;
  fields: ProgramTemplateBlockTableRowField[];
}

const calcPercent = (
  field: ProgramTemplateBlockTableRowField,
  info: WidgetPercentCellValidatorInfo,
): number => {
  const totalSumStr = fieldsHelpers.findTotalWorkHoursField(
    info.columns, info.sectionType, info.rowType, info.fields
  )!.value;
  const verificationSumStr = field.value;
  const totalSum = stringToFloat(totalSumStr);
  const verificationSum = stringToFloat(verificationSumStr);

  return verificationSum * 100 / totalSum;
};

export const widgetPercentCellValidator = (
  field: ProgramTemplateBlockTableRowField,
  info: WidgetPercentCellValidatorInfo,
): CellValidationResult => {
  if (!field.value && field.required) {
    return makeErrorValidationResult(validationMessages.valueIsEmpty);
  }

  const percent = calcPercent(field, info);
  const compareResult = compareValues(floatToString(percent), field.targetValue);
  if (field.targetValue && compareResult !== 0 && field.operator === "required") {
    return makeErrorValidationResult(validationMessages.valueIsNotEqualToTargetValue);
  }
  if (field.targetValue && compareResult === -1 && field.operator === "min") {
    return makeErrorValidationResult(validationMessages.valueIsLessThanTargetValue);
  }
  if (field.targetValue && compareResult === 1 && field.operator === "max") {
    return makeErrorValidationResult(validationMessages.valueIsMoreThanTargetValue);
  }
  if (field.targetValue && compareResult !== 0 && !field.operator) {
    return makeWarningValidationResult();
  }

  return makeSuccessValidationResult();
};