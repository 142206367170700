import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {AppModal} from "shared/UI/UIKit/Modal";
import {useAppDispatch} from "services/redux/hooks/use-dispatch";
import {AppFlex} from "shared/UI/UIKit/Flex";
import {AppText} from "shared/UI/UIKit/Text";
import {AppButton} from "shared/UI/UIKit/Button";
import {deleteMainProgramBlockAction} from "features/main-program/redux/actions";
import {useAppSelector} from "services/redux/hooks/use-selector";
import {selectMainProgram} from "features/main-program/redux/selectors";
import {getViewMainProgramTemplateUrl, getViewMainProgramUrl} from "services/navigation";
import type {MainProgramBlockDto} from "services/api";
import styles from "./Remove.module.scss";

type RemoveMainProgramBlockModalProps = {
  selectedBlock: MainProgramBlockDto | null;
  hideModal: () => void;
  visible: boolean;
  isTemplate?: boolean;
}

export const RemoveMainProgramBlockModal = (props: RemoveMainProgramBlockModalProps) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const mainProgram = useAppSelector(selectMainProgram);
  const navigate = useNavigate();
  if (!props.selectedBlock) {
    return null;
  }

  const remove = () => {
    setLoading(true);
    dispatch(deleteMainProgramBlockAction(props.selectedBlock!.id!))
      .unwrap()
      .then((id) => {
        const nextBlock = mainProgram?.blocks.filter(block => block.id !== id)[0];
        const nextPartId = (nextBlock ? nextBlock.id : mainProgram?.items[0].workProgram.id) || mainProgram?.curriculums[0].id;

        const getUrl = props.isTemplate ? getViewMainProgramTemplateUrl : getViewMainProgramUrl;
        navigate(getUrl({
          mainProgramId: String(mainProgram?.id),
          mainProgramPartId: String(nextPartId),
        }));
        props.hideModal();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const footer = (
    <AppFlex direction="row" justifyContent="flex-start">
      <AppButton onClick={remove}>
        Удалить
      </AppButton>
      <AppButton type="outline" onClick={props.hideModal}>
        Отмена
      </AppButton>
    </AppFlex>
  );
  const content = (
    <AppFlex unit="8" className={styles.content}>
      <AppText size={16} type="secondary">
        Подтвердите удаление блока
      </AppText>
      <AppText size={18}>
        Блок: {props.selectedBlock.name}
      </AppText>
    </AppFlex>
  );

  return (
    <AppModal visible={props.visible} onCancel={props.hideModal} size="auto"
              content={content} destroyOnClose footer={footer} loading={loading}
              title="Удаление блока"/>
  );
};