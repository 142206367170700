import {useNavigate} from "react-router-dom";
import {useEffect, useRef} from "react";
import {
  AppTable, type AppTableOnChange, getDataIndexedColumns,
  getDefaultTablePagination,
  useTableStateManager
} from "shared/UI/UIKit/Table";
import {useAppSelector} from "services/redux/hooks/use-selector";
import {useAppDispatch} from "services/redux/hooks/use-dispatch";
import {getViewMainProgramTemplateUrl, getViewMainProgramUrl} from "services/navigation";
import {usePayloadedModal} from "shared/UI/UIKit/Modal";
import {RemoveMainProgramModal} from "features/main-programs/components/Modals/Remove/Remove";
import {EditMainProgramModal} from "features/main-programs/components/Modals/Edit";
import type {AppTableProps,
  AppTableColumn,
  AppTableState} from "shared/UI/UIKit/Table";
import type {MainProgram} from "services/api";
import {getMainProgramsAction} from "../../redux/actions";
import {selectAllMainPrograms, selectAllMainProgramsTotal, selectMainProgramsLoading} from "../../redux/selectors";
import {getMainProgramsTableColumns} from "./columns";

type MainProgramsTableProps = {
  isTemplate?: boolean;
}

const getInitialStateWithSorters = (
  tableColumns: AppTableColumn<MainProgram>[]
): AppTableState => {
  return {
    sorters: getDataIndexedColumns(tableColumns)
    .filter(({key}) => key !== "controls" && key !== "linkToSite")
    .map(({key, dataIndex}) => {
      return key === "id" ? {
        key,
        dataIndex,
        order: "descend",
      } : {
        key,
        dataIndex,
      };
    }),
    filters: [],
    pagination: getDefaultTablePagination(),
  };
};

export const MainProgramsTable = (props: MainProgramsTableProps) => {
  const mainPrograms = useAppSelector(selectAllMainPrograms);
  const mainProgramsTotal = useAppSelector(selectAllMainProgramsTotal);
  const loading = useAppSelector(selectMainProgramsLoading);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {state: removeMainProgramState, methods: removeMainProgramMethods} = usePayloadedModal<MainProgram>();
  const {state: editMainProgramState, methods: editMainProgramMethods} = usePayloadedModal<MainProgram>();
  const onDeleteMainProgram = (record: MainProgram) => removeMainProgramMethods.show(record);
  const onEditMainProgram = (record: MainProgram) => editMainProgramMethods.show(record);
  const columns = getMainProgramsTableColumns(onDeleteMainProgram, onEditMainProgram);
  const {tableState, tableMethods} = useTableStateManager(getInitialStateWithSorters(columns));
  const wasInit = useRef<boolean>(false);

  useEffect(() => {
    if (mainProgramsTotal || mainProgramsTotal === 0) {
      tableMethods.setTableState({
        ...tableState,
        pagination: {
          ...tableState.pagination,
          total: mainProgramsTotal
        }
      });
    }
  }, [mainProgramsTotal]);

  const onRow: AppTableProps<MainProgram>["onRow"] = (data) => {
    // todo костыль чтобы найти первый блок
    const titleBlock = data.blocks.find(block => block.name === "Титульный лист");

    return {
      onClick: () => {
        const getUrl = props.isTemplate ? getViewMainProgramTemplateUrl : getViewMainProgramUrl;
        navigate(getUrl({
          mainProgramId: String(data.id),
          mainProgramPartId: String(titleBlock?.id) || String(data.items[0].workProgram.id)
        }));
      }
    };
  };

  const getTableItems: AppTableOnChange = (nextTableState, _, actionType) => {
    if (wasInit.current && actionType === "init") {
      return;
    }
    if (actionType === "init") {
      wasInit.current = true;
    }
    const sort = nextTableState.sorters.filter((col) => !!col.order).map((col) => `${col.order === "ascend" ? "+" : "-"}${col.dataIndex.join(".")}`);

    tableMethods.setTableState(nextTableState);
    dispatch(getMainProgramsAction({
      isTemplate: props.isTemplate,
      page: nextTableState.pagination.current! - 1,
      limit: nextTableState.pagination.pageSize,
      sort,
    }));
  };

  return (
    <>
      <AppTable columns={columns} loading={loading}
                dataSource={mainPrograms} onRow={onRow}
                pagination={tableState.pagination}
                multipleSorter
                onChange={getTableItems} sorters={tableState.sorters}
                rowKey={(record: MainProgram) => record.id}/>
      <RemoveMainProgramModal item={removeMainProgramState.payload}
                              hideModal={removeMainProgramMethods.hide}
                              visible={removeMainProgramState.visible}/>
      <EditMainProgramModal mainProgram={editMainProgramState.payload}
                            hideModal={editMainProgramMethods.hide}
                            visible={editMainProgramState.visible}/>
    </>
  );
};