import {useContext, useEffect, useMemo, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useAppSelector} from "services/redux/hooks/use-selector";
import {homeBreadcrumbItem, useBreadcrumbsService} from "shared/UI/UIKit/Breadcrumbs";
import {
  getMainProgramsUrl,
  getMainProgramTemplatesUrl,
  getViewMainProgramTemplateUrl,
  getViewMainProgramUrl
} from "services/navigation";
import {
  selectMainProgram,
  selectMainProgramBlock,
  selectMainProgramCurriculum,
  selectMainProgramLoading, selectRenderType,
  selectWorkProgram
} from "features/main-program/redux/selectors";
import {SidebarMenuHelpers} from "common/Layout/Layout/Sidebar";
import {makeKeyGenerator} from "shared/helpers/utils/key-generator";
import {EducationLevel, SubjectObligation} from "services/api";
import {VisibleIndexContext} from "features/main-program/context/visible-index-context";
import {WORK_PROGRAMS_GROUP_NAME} from "common/constants";
import {RenderMainProgramType} from "features/main-program/redux/types";
import type {AppMenuItem} from "shared/UI/UIKit/Menu";
import type {MainProgramItem,MainProgramBlockDto} from "services/api";
import type {ItemType} from "antd/es/menu/hooks/useItems";
import styles from "./styles.module.scss";

export enum SubMenuKeys {
  TARGET = "TARGET",
  WORK_PROGRAMS_REQUIRED = "WORK_PROGRAMS_REQUIRED",
  WORK_PROGRAMS_ADDITIONAL = "WORK_PROGRAMS_ADDITIONAL",
  WORK_PROGRAMS_EXTRACURRICULAR = "WORK_PROGRAMS_EXTRACURRICULAR",
  CONTENT_SECTION = "CONTENT_SECTION",
  ORGANIZATIONAL_SECTION = "ORGANIZATIONAL_SECTION",
}

const getMainProgramBreadcrumbs = (title = "", isTemplate?: boolean) => {
  return [
    homeBreadcrumbItem,
    {
      title: isTemplate ? "Шаблоны ООП" : "Конструктор ООП",
      url: isTemplate ? getMainProgramTemplatesUrl() : getMainProgramsUrl(),
    },
    {
      title,
      url: "",
    },
  ];
};

const EducationLevelFull = {
  [EducationLevel.НОО]: "начального",
  [EducationLevel.ООО]: "основного",
  [EducationLevel.СОО]: "среднего",
};

export const useMainProgramPage = (isTemplate?: boolean, isDocx?: boolean) => {
  const mainProgram = useAppSelector(selectMainProgram);
  const activeMainProgramBlock = useAppSelector(selectMainProgramBlock);
  const activeWorkProgram = useAppSelector(selectWorkProgram);
  const activeDetailedCurriculum = useAppSelector(selectMainProgramCurriculum);
  const renderType = useAppSelector(selectRenderType);
  const loading = useAppSelector(selectMainProgramLoading);
  const breadcrumbsService = useBreadcrumbsService();
  const {isIndexesVisible} = useContext(VisibleIndexContext);
  const [openKeys, onOpenChange] = useState<string[]>([
    SubMenuKeys.TARGET,
    SubMenuKeys.WORK_PROGRAMS_REQUIRED,
    SubMenuKeys.WORK_PROGRAMS_ADDITIONAL,
    SubMenuKeys.WORK_PROGRAMS_EXTRACURRICULAR,
    SubMenuKeys.CONTENT_SECTION,
    SubMenuKeys.ORGANIZATIONAL_SECTION,
  ]);
  const navigate = useNavigate();
  const getMainProgramUrl = isTemplate ? getViewMainProgramTemplateUrl : getViewMainProgramUrl;

  const {getNextKey} = makeKeyGenerator();

  const addIndexToLabel = (label = "Без названия", index: number) => {
    return isIndexesVisible ? `${label}(${index})` : label;
  };

  const breadcrumbTitle = useMemo(() => {
    if (!mainProgram) {
      return "";
    }

    switch (renderType) {
      case RenderMainProgramType.WORK_PROGRAM:
        return `Рабочая программа по учебному предмету ${activeWorkProgram?.subject?.name}`;
      case RenderMainProgramType.MAIN_PROGRAM_BLOCK:
        return activeMainProgramBlock?.name;
      case RenderMainProgramType.CURRICULUM:
        return `Учебный план ${EducationLevelFull[mainProgram.educationLevel!]} общего образования`;
      default:
        return "";
    }
  }, [renderType, mainProgram]);

  useEffect(() => {
    breadcrumbsService.setBreadcrumbsLoading(true);
    if (!loading) {
      breadcrumbsService.setBreadcrumbs(getMainProgramBreadcrumbs(breadcrumbTitle, isTemplate));
      breadcrumbsService.setBreadcrumbsLoading(false);
    }
  }, [loading, activeMainProgramBlock, activeWorkProgram, activeDetailedCurriculum, mainProgram, breadcrumbTitle]);

  const menuItems = useMemo(() => {
    if (!mainProgram) {
      return [];
    }

    const requiredWorkPrograms = mainProgram.items.filter(item => item.workProgram.obligation === SubjectObligation.REQUIRED);
    const additionalWorkPrograms = mainProgram.items.filter(item => item.workProgram.obligation === SubjectObligation.ADDITIONAL);
    const extracurricularWorkPrograms = mainProgram.items.filter(item => item.workProgram.obligation === SubjectObligation.EXTRACURRICULAR);

    const getProgramsMenuItems = (items: MainProgramItem[]) => {
      return items.map(item => {
        return SidebarMenuHelpers.getLinkItem({
          key: getNextKey(),
          index: item.index,
          label: addIndexToLabel(item.workProgram.name, item.index!),
          className: styles.level3,
          linkProps: {
            to: getMainProgramUrl({
              mainProgramId: String(mainProgram.id),
              mainProgramPartId: String(item.workProgram.id),
            }),
          },
          onClick: () => window.scroll(0, 0),
        });
      });
    };

    const getMainProgramBlocksMenuItems = () => {
      if (!mainProgram.blocks) {
        return [];
      }

      const excludeBlocks = isDocx || isTemplate ? [] : ["Содержание"];
      const excludeSections = ["Организационный раздел", "Содержательный раздел"];

      const filteredBlocks = mainProgram.blocks
        .filter(block => !excludeSections.includes(block.section))
        .filter(block => !excludeBlocks.includes(block.name));

      let blockItems: ItemType[] = [];

      const separateBlocks = filteredBlocks.filter(item => !item.section);
      const groupedBlocks = filteredBlocks.filter(item => item.section);

      const allSectionNames = groupedBlocks.map(item => item.section);
      const uniqueSectionNames = Array.from(new Set([...allSectionNames]));

      if (separateBlocks.length) {
        blockItems = separateBlocks.map(item => {
          return SidebarMenuHelpers.getLinkItem({
            key: getNextKey(),
            index: item.index,
            label: addIndexToLabel(item.name, item.index),
            className: styles.level1,
            id: item.id,
            linkProps: {
              to: getMainProgramUrl({
                mainProgramId: String(mainProgram.id),
                mainProgramPartId: String(item.id),
              }),
            },
            onClick: () => window.scroll(0, 0),
          });
        });
      }

      if (uniqueSectionNames.length) {
        blockItems = [...blockItems, ...uniqueSectionNames.map(sectionName => {
          const groupedItems = groupedBlocks.filter(item => item.section === sectionName);
          return getMainProgramBlocksSubmenuItem(groupedItems);
        })];
      }

      return [...blockItems].sort((a, b) => (a as any).index - (b as any).index );

    };

    const getMinIndex = (blocks: MainProgramBlockDto[]) => {
      if (blocks.length === 1) {
        return blocks[0].index;
      }

      let min = blocks[0].index;

      blocks.forEach((item) => {
        if (item.index < min) {
          min = item.index;
        }
      });

      return min;
    };

    const getMainProgramBlocksSubmenuItem = (blocks: MainProgramBlockDto[] = []) => {
      return SidebarMenuHelpers.getSubMenuItem({
        key: `${SubMenuKeys.TARGET}_${blocks[0].section}`,
        index: getMinIndex(blocks),
        label: addIndexToLabel(blocks[0].section, getMinIndex(blocks)),
        className: styles.level1,
        onClick: () => {
          if (openKeys.includes(`${SubMenuKeys.TARGET}_${blocks[0].section}`)) {
            return;
          }

          const allSortedChildren = blocks.sort((a: any, b: any) => a.index - b.index);

          if (allSortedChildren.length) {
            const url = getMainProgramUrl({
              mainProgramId: String(mainProgram.id),
              mainProgramPartId: String(allSortedChildren[0].id),
            });

            navigate(url);
          }
        },
        children: blocks
          .sort((a: any, b: any) => a.index - b.index)
          .map(item => {
            return SidebarMenuHelpers.getLinkItem({
              key: getNextKey(),
              label: addIndexToLabel(item.name, item.index),
              index: item.index,
              id: item.id,
              className: styles.level2,
              linkProps: {
                to: getMainProgramUrl({
                  mainProgramId: String(mainProgram.id),
                  mainProgramPartId: String(item.id),
                }),
              },
              onClick: () => window.scroll(0, 0),
            });
        })
      });
    };

    const getOrganizationalMenuItems = () => {
      const curriculumsMenuItems = (mainProgram.curriculums || []).map((item) => {
        return SidebarMenuHelpers.getLinkItem({
          key: getNextKey(),
          label: addIndexToLabel(item.name, item.index!),
          className: styles.level2,
          index: item.index,
          id: item.id,
          linkProps: {
            to: getMainProgramUrl({
              mainProgramId: String(mainProgram.id),
              mainProgramPartId: String(item.id),
            }),
          },
          onClick: () => window.scroll(0, 0),
        });
      });

      const organizationalSectionBlocks = mainProgram.blocks.filter(item => item.section === "Организационный раздел");
      const organizationalSectionBlocksMenuItems = organizationalSectionBlocks.map(item => {
        return SidebarMenuHelpers.getLinkItem({
          key: getNextKey(),
          index: item.index,
          id: item.id,
          label: addIndexToLabel(item.name, item.index),
          className: styles.level2,
          linkProps: {
            to: getMainProgramUrl({
              mainProgramId: String(mainProgram.id),
              mainProgramPartId: String(item.id),
            }),
          },
          onClick: () => window.scroll(0, 0),
        });
      });

      return SidebarMenuHelpers.getSubMenuItem({
        key: SubMenuKeys.ORGANIZATIONAL_SECTION,
        label: addIndexToLabel("Организационный раздел", 4),
        className: styles.level1,
        onClick: () => {
          if (openKeys.includes(SubMenuKeys.ORGANIZATIONAL_SECTION)) {
            return;
          }

          const allSortedChildren = [...mainProgram.curriculums, ...organizationalSectionBlocks].sort((a: any, b: any) => a.index - b.index);
          if (allSortedChildren.length) {
            const url = getMainProgramUrl({
              mainProgramId: String(mainProgram.id),
              mainProgramPartId: String(allSortedChildren[0].id),
            });

            navigate(url);
          }
        },
        index: 4,
        children: [...curriculumsMenuItems, ...organizationalSectionBlocksMenuItems].sort((a: any, b: any) => a.index - b.index)
      });
    };

    const getMenuItemWithChildren = (items: MainProgramItem[], title: string, key: string) => {
      return items.length ? SidebarMenuHelpers.getSubMenuItem({
        key,
        label: addIndexToLabel(title, 0),
        index: 0,
        children: getProgramsMenuItems(items),
        onClick: () => {
          if (openKeys.includes(key)) {
            return;
          }

          if (items[0]) {
            const url = getMainProgramUrl({
              mainProgramId: String(mainProgram.id),
              mainProgramPartId: String(items[0].workProgram.id),
            });

            navigate(url);
          }
        },
        className: styles.level2,
      }) : null;
    };

    const getBlocksForContentSection = () => {
      const contentSectionBlocks = mainProgram.blocks.filter(item => item.section === "Содержательный раздел");

      if (!contentSectionBlocks.length) {
        return [];
      }

      return contentSectionBlocks.map(item => {
        return SidebarMenuHelpers.getLinkItem({
          key: getNextKey(),
          index: item.index,
          id: item.id,
          label: addIndexToLabel(item.name, item.index),
          className: styles.level2,
          linkProps: {
            to: getMainProgramUrl({
              mainProgramId: String(mainProgram.id),
              mainProgramPartId: String(item.id),
            }),
          },
          onClick: () => window.scroll(0, 0),
        });
      });
    };

    const getContentSectionMenuItems = () => {
      const filteredWorkPrograms = [
        getMenuItemWithChildren(requiredWorkPrograms, WORK_PROGRAMS_GROUP_NAME.REQUIRED, SubMenuKeys.WORK_PROGRAMS_REQUIRED),
        getMenuItemWithChildren(additionalWorkPrograms, WORK_PROGRAMS_GROUP_NAME.ADDITIONAL, SubMenuKeys.WORK_PROGRAMS_ADDITIONAL),
        getMenuItemWithChildren(extracurricularWorkPrograms, WORK_PROGRAMS_GROUP_NAME.EXTRACURRICULAR, SubMenuKeys.WORK_PROGRAMS_EXTRACURRICULAR),
        ...getBlocksForContentSection(),
      ].filter(item => item !== null)
       .sort((a: any, b: any) => a.index - b.index) as AppMenuItem[];

      return SidebarMenuHelpers.getSubMenuItem({
        key: SubMenuKeys.CONTENT_SECTION,
        label: addIndexToLabel("Содержательный раздел", 3),
        index: 3,
        onClick: () => {
          if (openKeys.includes(SubMenuKeys.CONTENT_SECTION)) {
            return;
          }

          onOpenChange([...openKeys, SubMenuKeys.WORK_PROGRAMS_REQUIRED]);

          const firstMainProgramItem = requiredWorkPrograms
            .filter(item => item !== null)
            .sort((a: any, b: any) => a.index - b.index)[0];
          if (firstMainProgramItem) {
            const url = getMainProgramUrl({
              mainProgramId: String(mainProgram.id),
              mainProgramPartId: String(firstMainProgramItem.workProgram.id),
            });

            navigate(url);
          }
        },
        className: styles.level1,
        children: filteredWorkPrograms,
      });
    };

    return [
      ...getMainProgramBlocksMenuItems(),
      getContentSectionMenuItems(),
      getOrganizationalMenuItems(),
    ].filter(Boolean).sort((a: any, b: any) => a.index - b.index);
  }, [mainProgram, isIndexesVisible, openKeys]);

  return {
    breadcrumbs: breadcrumbsService.breadcrumbs,
    breadcrumbsLoading: breadcrumbsService.breadcrumbsLoading,
    mainProgram,
    menuItems,
    openKeys,
    onOpenChange,
  };
};