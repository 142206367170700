import {stringValidator} from "shared/UI/UIKit/Form";
import type {NumberValueEditorFormValues, NumberValueEditorSubmitValues} from "./types";

export const getInitialFormValues = (values: NumberValueEditorSubmitValues): NumberValueEditorFormValues => {
  return {
    value: values.value,
  };
};

export const getSubmitValues = (values: NumberValueEditorFormValues): NumberValueEditorSubmitValues => {
  return {
    value: values.value,
  };
};

export const formRules = {
  getValueValidator: (required: boolean) => {
    return {
      validator: stringValidator(required),
    };
  }
};

export const formFieldValidators = {
  checkValueValid: async (value: NumberValueEditorFormValues["value"], required: boolean) => {
    const {validator} = formRules.getValueValidator(required);
    return await validator(undefined, value);
  }
};