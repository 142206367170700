import cn from "classnames";
import type {AppLoaderProps} from "./Loader.types";
import styles from "./Loader.module.scss";

type AppLoaderStyleProps = Pick<AppLoaderProps, "color" | "className" | "stretched">;

export const getClassNames = (props: AppLoaderStyleProps) => {
  return cn(
    styles.loader,
    props.color && styles[`color-${props.color}`],
    props.stretched && styles["loader--stretched"],
    props.className,
  );
};