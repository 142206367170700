import cloneDeep from "lodash.clonedeep";
import type {TreeType} from "./group-by.types";

export function groupBy<T extends TreeType>(
  initialArray: T[],
  key: keyof T,
  parentKey: keyof T,
  immutable = true,
  onParentItemChange?: (parentItem: T) => void,
): T[] {
  const data: T[] = immutable ? cloneDeep(initialArray) : initialArray;
  const arrMap = new Map<any, T>(data.map((item: T) => [item[key], item]));
  const tree: T[] = [];

  for (let i = 0; i < data.length; i++) {
    generatePartOfTree<T>(data[i], parentKey, tree, arrMap, onParentItemChange);
  }

  return tree;
}

export function asyncGroupBy<T extends TreeType>(
  initialArray: T[],
  key: keyof T,
  parentKey: keyof T,
  immutable = true,
  onParentItemChange?: (parentItem: T) => void,
): Promise<T[]> {
  return new Promise(resolve => {
    const data: T[] = immutable ? cloneDeep(initialArray) : initialArray;
    const arrMap = new Map<any, T>(data.map((item: T) => [item[key], item]));
    const tree: T[] = [];
    const SLICE_SIZE = 100;
    const SLICES_COUNT = Math.ceil(data.length / SLICE_SIZE);

    if (SLICES_COUNT === 0) {
      resolve([]);
    }

    for (let i = 0; i < SLICES_COUNT; i++) {
      setTimeout(() => {
        const items = data.slice(i * SLICE_SIZE, (i + 1) * SLICE_SIZE);
        for (let j = 0; j < items.length; j++) {
          const currentItem = items[j];
          generatePartOfTree<T>(currentItem, parentKey, tree, arrMap, onParentItemChange);
        }
        if (i + 1 === SLICES_COUNT) {
          resolve(tree);
        }
      });
    }
  });
}

function generatePartOfTree<T extends TreeType>(
  currentItem: T,
  parentKey: keyof T,
  tree: T[],
  arrMap: Map<any, T>,
  onParentItemChange?: (parentItem: T) => void,
) {
  if (currentItem[parentKey]) {
    const parentItem: T | undefined = arrMap.get(currentItem[parentKey]);
    if (parentItem) {
      parentItem.children = parentItem.children ? [...parentItem.children, currentItem] : [currentItem];
      onParentItemChange?.(parentItem);
    } else {
      tree.push(currentItem);
    }
  } else {
    tree.push(currentItem);
  }
}