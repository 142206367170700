import type {AppIconProps} from "../Icons.types";
import {getClassNames} from "../Icons.utils";
import styles from "./Expand.module.scss";

export const AppExpandIcon = (props: AppIconProps) => {
  const {className, ...rest} = props;
  const classNames = getClassNames(styles.icon, className);

  return (
    <svg {...rest} className={classNames} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 8" fill="none">
      <path d="M0.853552 1.35355C0.53857 1.03857 0.761654 0.5 1.20711 0.5H12.7929C13.2383 0.5 13.4614 1.03857 13.1464 1.35355L7.35355 7.14645C7.15829 7.34171 6.84171 7.34171 6.64645 7.14645L0.853552 1.35355Z" fill="#818181"/>
    </svg>
  );
};