import {useCallback, useState} from "react";
import {debounce} from "throttle-debounce";
import {useAppDispatch} from "services/redux/hooks/use-dispatch";
import {getCurriculumsByEducationLevelAction} from "features/create-main-program/redux/actions";
import {useAppSelector} from "services/redux/hooks/use-selector";
import {selectCurriculumsTotalCount} from "features/create-main-program/redux/selectors";

type GetCurriculumsEvent = {
  page: number;
  searchQuery: string;
}

export const useCurriculums = () => {
  const dispatch = useAppDispatch();
  const [page, setPage] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const total = useAppSelector(selectCurriculumsTotalCount);

  const getCurriculums = (eventObj: GetCurriculumsEvent) => {
    dispatch(getCurriculumsByEducationLevelAction({
      page: eventObj.page,
      searchQuery: eventObj.searchQuery,
    }));
  };

  const searchQueryChange = useCallback(debounce(700, (query: string) => {
    setPage(0);
    setSearchQuery(query);

    getCurriculums({
      page,
      searchQuery: query,
    });
  }), [page]);

  const paginationChange = (newPage: number) => {
    setPage(newPage);

    getCurriculums({
      page: newPage,
      searchQuery,
    });
  };

  return {
    getCurriculums,
    searchQueryChange,
    setPage,
    page: page,
    total,
    paginationChange,
    searchQuery,
    setSearchQuery,
  };
};