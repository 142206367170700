import type {AppSelectOption} from "shared/UI/UIKit/Select";
import type {ReferenceCellFormValues, ReferenceCellSubmitValues} from "./types";
import {FIELD_CONSTANTS} from "../../../../../../lib/constants";
import {dictionaryServices} from "../../../../../../lib/services";

export const getInitialFormValues = (values: ReferenceCellSubmitValues): ReferenceCellFormValues => {
  const getValue = (): string[] => {
    if (!values.value) {
      return [];
    }

    return values.value.split(FIELD_CONSTANTS.MULTIPLE_VALUE_SPLITTER);
  };

  return {
    value: getValue(),
    listId: values.listId,
    readonly: values.readonly,
  };
};

export const getSubmitValues = (values: ReferenceCellFormValues): ReferenceCellSubmitValues => {
  const getValue = (): string | null => {
    if (!values.value) {
      return null;
    }

    return values.value.join(FIELD_CONSTANTS.MULTIPLE_VALUE_SPLITTER);
  };

  return {
    value: getValue(),
    listId: values.listId,
    readonly: values.readonly,
  };
};

export const fetchReferenceOptions = async (): Promise<AppSelectOption[]> => {
  const dictionaryList = await dictionaryServices.getAll();
  return dictionaryList.data.map(dictionary => ({label: dictionary.name, value: dictionary.code}));
};

export const fetchReferenceItemsOptions = async (code: string | null): Promise<AppSelectOption[]> => {
  const dictionary = await dictionaryServices.getByCode(code);

  if (!dictionary) {
    return [];
  }

  return dictionary.items.map(item => ({label: item.name, value: `${item.id}`}));
};