import {AppButton} from "shared/UI/UIKit/Button";
import {AppModalFooter} from "shared/UI/UIKit/Modal";
import type {ModalFooterProps} from "./types";

export const ModalFooter = (props: ModalFooterProps) => {
  return (
    <AppModalFooter>
      <AppButton onClick={props.onSubmit}>
        Сохранить
      </AppButton>
      <AppButton type="outline" onClick={props.onCancel}>
        Отмена
      </AppButton>
    </AppModalFooter>
  );
};