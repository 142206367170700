import type {ProgramTemplateBlockTableRowField} from "services/api";
import type {CellValidationResult} from "./types";
import {
  compareValues,
  makeErrorValidationResult,
  makeSuccessValidationResult,
  makeWarningValidationResult
} from "./utils";
import {validationMessages} from "./constants";

export const widgetSumCellValidator = (
  field: ProgramTemplateBlockTableRowField,
): CellValidationResult => {
  if (!field.value && field.required) {
    return makeErrorValidationResult(validationMessages.valueIsEmpty);
  }

  const compareResult = compareValues(field.value, field.targetValue);
  if (field.targetValue && compareResult !== 0 && field.operator === "required") {
    return makeErrorValidationResult(validationMessages.valueIsNotEqualToTargetValue);
  }
  if (field.targetValue && compareResult === -1 && field.operator === "min") {
    return makeErrorValidationResult(validationMessages.valueIsLessThanTargetValue);
  }
  if (field.targetValue && compareResult === 1 && field.operator === "max") {
    return makeErrorValidationResult(validationMessages.valueIsMoreThanTargetValue);
  }
  if (field.targetValue && compareResult !== 0 && !field.operator) {
    return makeWarningValidationResult();
  }

  return makeSuccessValidationResult();
};