import {useNavigate, useParams} from "react-router-dom";
import {useEffect} from "react";
import {notification} from "antd";
import {AppLoader} from "shared/UI/UIKit/Loader";
import {getMainProgram} from "services/api";
import {getMainProgramsUrl, getViewMainProgramTemplateUrl, getViewMainProgramUrl} from "services/navigation";
import type {MainProgramPageParams} from "services/navigation";
import styles from "./styles.module.scss";

export const MainProgramPage = () => {
  const {mainProgramId} = useParams<MainProgramPageParams>();
  const navigate = useNavigate();

  useEffect(() => {
    if (mainProgramId) {
      getMainProgram(mainProgramId).then(res => {
        const titleBlock = res.blocks.find(block => block.name === "Титульный лист");

        const getUrl = res.isTemplate ? getViewMainProgramTemplateUrl : getViewMainProgramUrl;

        navigate(getUrl({
          mainProgramId,
          mainProgramPartId: String(titleBlock?.id) || String(res.items[0].workProgram.id)
        }));
      }).catch(() => {
        notification.error({message: "При загрузке произошла ошибка"});
        navigate(getMainProgramsUrl());
      });
    }
  }, []);

  return (
    <div className={styles.loader}>
      <AppLoader size='large'/>
    </div>
  );
};