import type {MainProgramItem, Curriculum} from "services/api";

export const extractUniqueSubjectIdsFromSelectedCurriculums = (
  selectedCurriculumsEntitiesIds: Curriculum[],
): number[] => {
  const subjectIds: Set<number> = selectedCurriculumsEntitiesIds.reduce((acc: Set<number>, item: Curriculum) => {
    item.subjects.map(subject => {
      if (subject.id) {
        acc.add(subject.id);
      }
    });
    return acc;
  }, new Set<number>());

  subjectIds.add(1000).add(2000);

  return Array.from(subjectIds);
};

export const extractWorkProgramItems = (
  selectedWorkProgramByFieldName: Record<string, MainProgramItem>,
  selectedCurriculumsEntities: Curriculum[],
): MainProgramItem[] => {
  const values = Object.values(selectedWorkProgramByFieldName);

  return values.sort((a, b) => Number(a.index) - Number(b.index)).map(mainProgramItem => {
    const curriculum = selectedCurriculumsEntities.find(item => item.id === mainProgramItem.curriculum.id);
    const subject = curriculum?.subjects.find(subjectItem => subjectItem.uniqueId === mainProgramItem.subject.uniqueId);
      return {
        ...mainProgramItem,
        customSubjectName: subject?.customSubjectName || "",
      };
  });
};