import type {MainProgramItem, ProgramTemplate} from "services/api";

export const getUniqueItems = (items: MainProgramItem[]): MainProgramItem[] => {
  const ids: number[] = [];
  const nextItems: MainProgramItem[] = [];

  for (let i = 0; i < items.length; i++) {
    const item = items[i];
    if (!ids.includes(item.workProgram.id)) {
      ids.push(item.workProgram.id);
      nextItems.push(item);
    }
  }

  return nextItems;
};

export const filterEmptyTables = (program: ProgramTemplate): ProgramTemplate => {
  return {
    ...program,
    blocks: program.blocks.map(block => {
      if (block.type === "DOCUMENT") {
        return block;
      } else {
        const {tables} = block;
        const notEmptyTables = [];

        for (let i = 0; i < tables.length; i++) {
          for (let j = 0; j < tables[i].sections.length; j++) {
            const currentSection = tables[i].sections[j];
            const hasBodyRow = currentSection.rows.some(row => row.type === "SECTION_BODY_ROW");
            if (hasBodyRow) {
              notEmptyTables.push(tables[i]);
              break;
            }
          }
        }
        return {
          ...block,
          tables: notEmptyTables,
        };
      }
    })
  };
};