import MDPreview from "@uiw/react-markdown-preview";
import rehypeSanitize from "rehype-sanitize";
import type {AppMarkdownPreviewProps} from "./MarkdownPreview.types";
import "./styles.scss";

export const AppMarkdownPreview = (props: AppMarkdownPreviewProps) => {
  return (
    <div data-color-mode="light">
      <MDPreview rehypePlugins={[rehypeSanitize]} {...props}/>
    </div>
  );
};