import type {ModalStatus} from "./types";

export const getInitialStatus = (
  visible: boolean,
  destroyOnClose: boolean,
  forceRender: boolean
): ModalStatus => {
  if (visible) {
    return {name: "visible", stage: "init"};
  } else if (!destroyOnClose && forceRender) {
    return {name: "hidden", stage: "done"};
  } else {
    return {name: "destroyed", stage: "done"};
  }
};

export const getStatusOnVisibilityChange = (
  visible: boolean,
  destroyOnClose: boolean,
): ModalStatus => {
  if (visible) {
    return {name: "visible", stage: "init"};
  } else if (!destroyOnClose) {
    return {name: "hidden", stage: "init"};
  } else {
    return {name: "destroyed", stage: "init"};
  }
};