import {Input} from "antd";
import {forwardRef} from "react";
import type {AppInputProps, AppInputRef} from "./Input.types";

export const AppInput = forwardRef<AppInputRef, AppInputProps>((props, ref) => {
  return <Input {...props} ref={ref}/>;
});

export const AppInputPassword = forwardRef<AppInputRef, AppInputProps>((props, ref) => {
  return <Input.Password {...props} ref={ref}/>;
});