import {forwardRef} from "react";
import {AppFlex} from "shared/UI/UIKit/Flex";
import type {AppModalHeaderProps} from "./ModalHeader.types";
import {getClassNames} from "./ModalHeader.utils";

export const AppModalHeader = forwardRef<HTMLDivElement, AppModalHeaderProps>((props, ref) => {
  const {
    className,
    direction = "row",
    alignItems = "center",
    ...rest
  } = props;
  const classNames = getClassNames(className);

  return (
    <AppFlex direction={direction}
             alignItems={alignItems}
             className={classNames}
             {...rest} ref={ref}/>
  );
});