import type {ProgramTemplateBlockTable} from "services/api";

const defaultObj: ProgramTemplateBlockTable = {
  id: null,
  block: null,
  name: "",
  index: 0,
  type: "SIMPLE",
  settings: {
    canDownloadXlsx: false,
    // access rights
    sectionCreationAllowed: false,
    programTemplateSectionMovingAllowed: false,
    workProgramSectionMovingAllowed: false,
    programTemplateSectionDeletionAllowed: false,
    workProgramSectionDeletionAllowed: false,
    rowCreationAllowed: false,
    programTemplateRowMovingAllowed: false,
    workProgramRowMovingAllowed: false,
    programTemplateRowDeletionAllowed: false,
    workProgramRowDeletionAllowed: false,
  },
  header: {
    variant: "VARIANT_4",
    lockedColumnIndexes: null,
    hiddenColumnIndexes: null,
  },
  sections: [],
};

export const getProgramTemplateBlockTableDTO = (
  obj: Partial<ProgramTemplateBlockTable>
): ProgramTemplateBlockTable => ({
  ...defaultObj,
  ...obj,
});