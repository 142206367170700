import type {OperatorOption, OperatorValue} from "./types";

export const formNames = {
  targetValue: "targetValue",
  operator: "operator",
  readonly: "readonly",
} as const;

export const formItemLabels = {
  targetValue: "Целевое значение",
  operator: "Оператор",
  readonly: "Только для чтения",
} as const;

export const numberCellOperatorOptions: Record<OperatorValue, OperatorOption> = {
  empty: {
    value: "empty",
    label: "Отсутствует",
  },
  min: {
    value: "min",
    label: "Минимум",
  },
  max: {
    value: "max",
    label: "Максимум",
  },
  required: {
    value: "required",
    label: "Равно",
  },
};