import type {SortOrder} from "shared/types";
import type {StringComparatorGetter, StringFieldComparatorGetter, ComparableString} from "../types";
import {getObjectComparator} from "../object/object-comparator";

const DEFAULT_LOCALES: string[] = ["ru", "en"];
const DEFAULT_OPTIONS: Intl.CollatorOptions = {
  usage: "sort",
  localeMatcher: "lookup",
  numeric: true,
  caseFirst: "upper",
  sensitivity: "variant",
  ignorePunctuation: false,
};

export const getStringComparator: StringComparatorGetter = (
  sortOrder,
  locales = DEFAULT_LOCALES,
  options = DEFAULT_OPTIONS,
) => {
  const order = sortOrder === "ascend" ? 1 : -1;
  return (a, b) => {
    if (a === undefined || a === null) {
      return Infinity;
    }

    if (b === undefined || b === null) {
      return -Infinity;
    }

    return order * a.localeCompare(b, locales, options);
  };
};

export const getStringFieldComparator: StringFieldComparatorGetter = <TObject extends object>(
  getValue: ((item: TObject) => ComparableString),
  sortOrder: SortOrder,
  locales?: string [],
  options?: Intl.CollatorOptions,
) => {
  const comparator = getStringComparator(sortOrder, locales, options);
  return getObjectComparator(getValue, comparator);
};