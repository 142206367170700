import type {AppModalContentProps} from "./ModalContent.types";
import {getClassNames} from "./ModalContent.utils";

export const AppModalContent = (props: AppModalContentProps) => {
  const {className, ...rest} = props;
  const classNames = getClassNames(className);

  return (
    <div {...rest} className={classNames}/>
  );
};