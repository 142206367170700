export const URI_SCHEME = {
  DATA: "data",
  FILE: "file",
  FTP: "ftp",
  GEO: "geo",
  HTTP: "http",
  HTTPS: "https",
  MAILTO: "mailto",
  TEL: "tel",
  WS: "ws",
  WSS: "wss",
};