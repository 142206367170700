import type {ChangeEvent} from "react";
import type {TextFilterFieldsProps} from "./types";
import {AppFilterOperator} from "../../../../constants";
import {FILTER_FORM_NAMES} from "../../../constants";
import {TextField} from "./TextField";

export const TextFilterFields = ({form, options, focusRef}: TextFilterFieldsProps) => {
  const operator = form.getFieldValue(FILTER_FORM_NAMES.operator) as AppFilterOperator;

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    form.setFieldsValue({
      values: [e.target.value],
    });
  };

  switch (operator) {
    case AppFilterOperator.IS_NULL: {
      return null;
    }
    default: {
      return (
        <TextField onChange={onChange} placeholder={options?.placeholder}
                   focusRef={focusRef}/>
      );
    }
  }
};