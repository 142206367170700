import type {EnumFilterFieldsProps} from "./types";
import {FILTER_FORM_NAMES} from "../../../constants";
import {AppFilterOperator} from "../../../../constants";
import {EnumField} from "./EnumField";

export const EnumFilterFields = ({form, options}: EnumFilterFieldsProps) => {
  const operator = form.getFieldValue(FILTER_FORM_NAMES.operator) as AppFilterOperator;

  switch (operator) {
    case AppFilterOperator.IS_NULL: {
      return null;
    }
    default: {
      return (
        <EnumField options={options?.enumOptions}/>
      );
    }
  }
};