import {memo} from "react";
import type {TableWidgetStringCellProps} from "./types";
import {bracketHelpers} from "../../../../../lib/helpers";
import {TableWidgetStringCellFullAccess} from "./TableWidgetStringCellFullAccess";
import {TableWidgetStringCellLimitedAccess} from "./TableWidgetStringCellLimitedAccess";

export const TableWidgetStringCell = memo(({
  field,
  onChange,
  isFullAccess,
  isPrograms,
  editMode,
  colSpan,
  rowType,
  sectionType,
  sectionOrdinalNumber: sectionOrdinalNumberProp,
  validationStatus,
}: TableWidgetStringCellProps) => {
  const editablePartPositions = bracketHelpers.getBracketsPositions(field.value);
  const sectionOrdinalNumber = rowType === "SECTION_HEADER_ROW" && sectionOrdinalNumberProp !== undefined
    ? sectionOrdinalNumberProp
    : undefined;
  const isCellFirst = (
    (sectionType === "TABLE" && rowType !== "SECTION_BODY_ROW") ||
    (sectionType === "TABLE_FOOTER" && field.index === 1)
  );

  if (isFullAccess) {
    return (
      <TableWidgetStringCellFullAccess field={field} editMode={editMode} colSpan={colSpan}
                                       isPrograms={isPrograms} validationStatus={validationStatus}
                                       sectionOrdinalNumber={sectionOrdinalNumber}
                                       editablePartPositions={editablePartPositions}
                                       onChange={onChange} isCellFirst={isCellFirst}/>
    );
  }

  return (
    <TableWidgetStringCellLimitedAccess field={field} editMode={editMode} colSpan={colSpan}
                                        isPrograms={isPrograms} validationStatus={validationStatus}
                                        sectionOrdinalNumber={sectionOrdinalNumber}
                                        editablePartPositions={editablePartPositions}
                                        onChange={onChange} isCellFirst={isCellFirst}/>
  );
});