import type {MoveActionProps} from "./types";
import {MoveAboveIcon, MoveBelowIcon} from "../../../../../ui/components/icons";

export const MoveAction = ({onClick}: MoveActionProps) => {
  return (
    <>
      <MoveAboveIcon onClick={() => onClick("above")}/>
      <MoveBelowIcon onClick={() => onClick("below")}/>
    </>
  );
};