import {memo} from "react";
import type {TableHiddenCellProps} from "./types";
import {TableBaseCell} from "../TableBaseCell";

export const TableHiddenCell = memo(({
  isFullAccess,
  editMode,
  field,
}: TableHiddenCellProps) => {
  return (
    <TableBaseCell isFullAccess={isFullAccess} editMode={editMode}
                   index={field.index} hidden/>
  );
});