import {useMemo} from "react";
import {useEvent} from "shared/helpers/hooks/use-event";
import type {ProgramTemplateBlockTableRow, ProgramTemplateBlockTableRowField} from "services/api";
import type {TableSectionProps} from "./types";
import {useTableConstructorContext} from "../../contexts/table-constructor-context";
import {visibility} from "../../../lib/core";
import {modulesHelpers} from "../../../lib/helpers";

export const useTableSection = (props: TableSectionProps) => {
  const {isFullAccess, editMode, visibleColumns} = useTableConstructorContext();
  const isTableSectionHeaderVisible = visibility.checkTableSectionHeaderVisible({
    tableType: props.tableType,
  });
  const isTableSectionFooterVisible = visibility.checkTableSectionFooterVisible({
    tableType: props.tableType,
    sectionType: props.section.type,
  });
  const isTableRowCreatorVisible = visibility.checkTableRowCreatorVisible({
    editMode,
    isFullAccess,
    rowCreationAllowed: !!props.settings.rowCreationAllowed,
    section: props.section,
  });
  const isTableFooterRowCreatorVisible = visibility.checkTableFooterRowCreatorVisible({
    editMode,
    isFullAccess,
    sectionType: props.section.type,
  });
  const isSectionModule = modulesHelpers.checkSectionIsModule(props.section);
  const visibleColumnCount = visibleColumns.length;

  const {sectionHeaderRows, sectionBodyRows, sectionFooterRows, sectionBodyRowsIds} = useMemo(() => {
    return props.section.rows.reduce((acc, row) => {
      if (row.type === "SECTION_HEADER_ROW") {
        return {...acc, sectionHeaderRows: [...acc.sectionHeaderRows, row]};
      }
      if (row.type === "SECTION_BODY_ROW") {
        return {
          ...acc,
          sectionBodyRows: [...acc.sectionBodyRows, row],
          sectionBodyRowsIds: [...acc.sectionBodyRowsIds, row.id!]
        };
      }
      return {...acc, sectionFooterRows: [...acc.sectionFooterRows, row]};
    }, {
      sectionHeaderRows: [],
      sectionBodyRows: [],
      sectionFooterRows: [],
      sectionBodyRowsIds: [],
    } as {
      sectionHeaderRows: ProgramTemplateBlockTableRow[];
      sectionBodyRows: ProgramTemplateBlockTableRow[];
      sectionFooterRows: ProgramTemplateBlockTableRow[];
      sectionBodyRowsIds: (string | number)[]
    });
  }, [props.section]);

  const handleRowCreate = useEvent(() => {
    props.methods.createRow(props.section.id!);
  });

  const handleCellClick = (
    field: ProgramTemplateBlockTableRowField,
    row: ProgramTemplateBlockTableRow,
    fieldValuePosition?: number[],
  ) => {
    if (!editMode) {
      return;
    }

    props.methods.clickCell({
      field,
      meta: {
        sectionId: props.section.id!,
        sectionType: props.section.type,
        rowId: row.id!,
        rowType: row.type,
        fieldValuePosition,
      }
    });
  };

  const handleCellUpdate = useEvent((
    field: ProgramTemplateBlockTableRowField,
    rowId: string | number,
    rowType: ProgramTemplateBlockTableRow["type"],
  ) => {
    if (!editMode) {
      return;
    }

    props.methods.updateCell({
      field,
      meta: {
        sectionId: props.section.id!,
        sectionType: props.section.type,
        rowId,
        rowType,
      }
    });
  });

  return [
    {
      sectionHeaderRows,
      sectionBodyRows,
      sectionBodyRowsIds,
      sectionFooterRows,
      isTableSectionHeaderVisible,
      isTableSectionFooterVisible,
      isTableRowCreatorVisible,
      isTableFooterRowCreatorVisible,
      isSectionModule,
      visibleColumnCount,
    },
    {
      handleRowCreate,
      handleCellClick,
      handleCellUpdate,
    }
  ] as const;
};