import {Form} from "antd";
import {useEffect, useState} from "react";
import type {AppSelectOption} from "shared/UI/UIKit/Select";
import type {ReferenceCellFormProps, ReferenceCellFormValues} from "./types";
import {formNames} from "./constants";
import {
  fetchReferenceOptions,
  fetchReferenceItemsOptions,
  getSubmitValues,
  getInitialFormValues,
} from "./utils";

export const useReferenceCellForm = (props: ReferenceCellFormProps) => {
  const [form] = Form.useForm<ReferenceCellFormValues>();
  const [referenceOptions, setReferenceOptions] = useState<AppSelectOption[]>([]);
  const [referenceItemsOptions, setReferenceItemsOptions] = useState<AppSelectOption[]>([]);
  const [referenceOptionsLoading, setReferenceOptionsLoading] = useState(false);
  const [referenceItemsOptionsLoading, setReferenceItemsOptionsLoading] = useState(false);
  const listId = Form.useWatch<string | undefined>(formNames.listId, form);

  const loadReferenceItemsOptions = (code: string | null) => {
    setReferenceItemsOptionsLoading(true);
    fetchReferenceItemsOptions(code)
      .then(setReferenceItemsOptions)
      .finally(() => setReferenceItemsOptionsLoading(false));
  };

  const loadReferenceOptions = () => {
    setReferenceOptionsLoading(true);
    fetchReferenceOptions()
      .then(setReferenceOptions)
      .finally(() => setReferenceOptionsLoading(false));
  };

  const handleSubmit = () => {
    form.validateFields()
      .then((values) => {
        const submitValues = getSubmitValues(values);
        const nextValues = {
          ...(props.value?.visible
              ? {value: submitValues.value}
              : {}
          ),
          ...(props.listId?.visible
              ? {listId: submitValues.listId}
              : {}
          ),
          ...(props.readonly?.visible
              ? {readonly: submitValues.readonly}
              : {}
          ),
        };

        props.onSubmit(nextValues);
      })
      .catch(() => null);
  };

  const handleListIdSelect = (nextListId: string | null) => {
    if (listId !== nextListId) {
      form.setFieldValue(formNames.value, []);
      setReferenceItemsOptions([]);
      loadReferenceItemsOptions(nextListId);
    }
  };

  const handleListIdClear = () => {
    handleListIdSelect(null);
  };

  useEffect(() => {
    form.setFieldsValue(getInitialFormValues({
      value: props.field.value,
      listId: props.field.listId,
      readonly: props.field.readonly,
    }));
  }, []);

  useEffect(() => {
    loadReferenceItemsOptions(props.field.listId);

    if (props.listId?.visible) {
      loadReferenceOptions();
    }
  }, []);

  return [
    {
      form,
      referenceOptions,
      referenceItemsOptions,
      referenceOptionsLoading,
      referenceItemsOptionsLoading,
    },
    {handleListIdSelect, handleListIdClear, handleSubmit}
  ] as const;
};