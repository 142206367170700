import type {CurriculumDetailedTableRow} from "services/api";
import {CurriculumRow} from "../CurriculumRow";

type CurriculumHeaderProps = {
  rows: CurriculumDetailedTableRow[];
}
export const CurriculumHeader = (props: CurriculumHeaderProps) => {
  return (
    <thead>
      {
        props.rows.map((row, index) => {
          return <CurriculumRow isFirstRow={index === 0}
                                key={`${row.id}-${index}`} Tag="th" row={row}/>;
        })
      }
    </thead>
  );
};