import {useForm} from "antd/es/form/Form";
import {useEffect} from "react";
import {AppModal} from "shared/UI/UIKit/Modal";
import {AppFlex} from "shared/UI/UIKit/Flex";
import {AppButton} from "shared/UI/UIKit/Button";
import {agreedBlock, approvedBlock, consideredBlock} from "shared/UI/UIKit/TextEditor/plugins/placeholder_block_plugin";
import type {WidgetBlockSettingsModalProps, WidgetBlockSettingsFormValues} from "./types";
import {WidgetBlockForm} from "./WidgetBlockSettingsForm";

export const WidgetBlockSettingsModal = (props: WidgetBlockSettingsModalProps) => {
  const [form] = useForm<WidgetBlockSettingsFormValues>();

  useEffect(() => {
    if (props.selectedWidget) {
      form.setFieldValue("data-type", props.selectedWidget.getAttribute("data-type"));
    }
  }, [props.selectedWidget]);

  const onSubmit = () => {
    form.validateFields().then(values => {
      props.selectedWidget!.setAttribute("data-type", values["data-type"]);
      const considered = props.selectedWidget?.querySelector("#considered");
      const agreed = props.selectedWidget?.querySelector("#agreed");
      const approved = props.selectedWidget?.querySelector("#approved");

      if (values["data-type"] === "considered-agreed-approved") {
        considered!.innerHTML = !elementIsEmpty(considered!) ? considered!.innerHTML : consideredBlock;
        agreed!.innerHTML = !elementIsEmpty(agreed!) ? agreed!.innerHTML : agreedBlock;
        approved!.innerHTML = !elementIsEmpty(approved!) ? approved!.innerHTML : approvedBlock;

        (considered! as any).style.width = "33.33%";
        (agreed! as any).style.width = "33.33%";
        (approved! as any).style.width = "33.33%";
      }

      if (values["data-type"] === "considered-approved") {
        considered!.innerHTML = !elementIsEmpty(considered!) ? considered!.innerHTML : consideredBlock;
        agreed!.innerHTML = "&zwnj;";
        approved!.innerHTML = !elementIsEmpty(approved!) ? approved!.innerHTML : approvedBlock;

        (considered! as any).style.width = "33.33%";
        (agreed! as any).style.width = "0px";
        (approved! as any).style.width = "33.33%";
      }

      if (values["data-type"] === "agreed-approved") {
        considered!.innerHTML = "&zwnj;";
        agreed!.innerHTML = !elementIsEmpty(agreed!) ? agreed!.innerHTML : agreedBlock;
        approved!.innerHTML = !elementIsEmpty(approved!) ? approved!.innerHTML : approvedBlock;

        (considered! as any).style.width = "0px";
        (agreed! as any).style.width = "33.33%";
        (approved! as any).style.width = "33.33%";
      }

      if (values["data-type"] === "approved") {
        considered!.innerHTML = "&zwnj;";
        agreed!.innerHTML = "&zwnj;";

        approved!.innerHTML = !elementIsEmpty(approved!) ? approved!.innerHTML : approvedBlock;


        (considered! as any).style.width = "0px";
        (agreed! as any).style.width = "0px";
        (approved! as any).style.width = "33.33%";
      }

      props.setHtmlContent(document.querySelector(".se-wrapper-inner.se-wrapper-wysiwyg")!.innerHTML);
    });
    props.hide();
  };

  const elementIsEmpty = (element: Element) => {
    return element!.innerHTML && element!.innerHTML.length === 1;
  };

  const onCancel = () => {
    form.resetFields();
    props.hide();
  };

  const content = <WidgetBlockForm form={form}/>;

  const footer = (
    <AppFlex direction="row">
      <AppButton onClick={onSubmit}>
        Сохранить
      </AppButton>
      <AppButton type="outline" color="danger"
                 onClick={onCancel}>
        Отмена
      </AppButton>
    </AppFlex>
  );

  return (
    <AppModal content={content}
              title="Настройка виджета"
              footer={footer}
              visible={props.visible}
              onCancel={onCancel}/>
  );
};