import cn from "classnames";
import type {AppBaseButtonProps} from "./BaseButton.types";
import styles from "./styles/index.module.scss";

type AppBaseButtonStyleProps = Pick<AppBaseButtonProps, "size" | "shape" | "loading" | "className">;

export const getClassNames = (props: AppBaseButtonStyleProps) => {
  const {size, shape, className, loading} = props;

  return cn(
    styles.button,
    size && styles[`size-${size}`],
    shape && styles[`shape-${shape}`],
    loading && styles.loading,
    className,
  );
};