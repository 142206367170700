import {AppFlex} from "shared/UI/UIKit/Flex";
import type {DividerWithChildrenProps} from "./DividerWithChildren.types";
import {getClassNames} from "./DividerWithChildren.utils";

export const DividerWithChildren = (props: DividerWithChildrenProps) => {
  const {children} = props;
  const classNames = getClassNames(props);

  return (
    <AppFlex className={classNames} direction="row">
      <span>{children}</span>
    </AppFlex>
  );
};