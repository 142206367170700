import {useEffect, useRef, useState} from "react";
import {
  AppTable, type AppTableOnChange,
  type AppTableState,
  getDefaultTablePagination,
  useTableStateManager
} from "shared/UI/UIKit/Table";
import {useAppSelector} from "services/redux/hooks/use-selector";
import {
  selectAllSubjects,
  selectWorkPrograms,
  selectWorkProgramsLoading,
  selectWorkProgramsTotal
} from "features/work-programs/redux/selectors";
import {useAppDispatch} from "services/redux/hooks/use-dispatch";
import {
  getAllSubjectsAction,
  getWorkProgramsAction,
  setWorkProgramAvailableInMainProgramAction, toggleVisibleWorkProgramInMainProgramListAction
} from "features/work-programs/redux/actions";
import {AppFlex} from "shared/UI/UIKit/Flex";
import {AppSelect} from "shared/UI/UIKit/Select";
import {toOptions} from "shared/helpers/utils/to-options";
import {clearWorkProgramsStateAction} from "features/work-programs/redux/slice";
import {AppButton} from "shared/UI/UIKit/Button";
import type {EducationLevel, WorkProgram} from "services/api";
import {getWorkProgramsTableColumns} from "./columns";

const getInitialStateWithSorters = (): AppTableState => {
  return {
    sorters: [
      {
        key: "id",
        dataIndex: ["id"],
      },
      {
        key: "name",
        dataIndex: ["name"],
      },
      {
        key: "subject",
        dataIndex: ["subject", "name"],
      },
      {
        key: "hasDocx",
        dataIndex: ["hasDocx"],
      },
      {
        key: "showInMainPrograms",
        dataIndex: ["showInMainPrograms"],
      }
    ],
    filters: [],
    pagination: getDefaultTablePagination(),
  };
};

type WorkProgramsTableProps = {
  educationLevel: EducationLevel;
}

export const WorkProgramsTable = (props: WorkProgramsTableProps) => {
  const workProgramsLoading = useAppSelector(selectWorkProgramsLoading);
  const workPrograms = useAppSelector(selectWorkPrograms);
  const workProgramsTotal = useAppSelector(selectWorkProgramsTotal);
  const subjects = useAppSelector(selectAllSubjects);
  const {profile} = useAppSelector(state => state.profileState);
  const wasInit = useRef<boolean>(false);
  const dispatch = useAppDispatch();
  const {tableState, tableMethods} = useTableStateManager(getInitialStateWithSorters());
  const [subjectId, setSubjectId] = useState<number | undefined>();
  const [isShowHidden, setIsShowHidden] = useState<boolean>(false);

  // метод изменяет видимость программы при создании ООП, но не удаляет ее из списка
  const toggleVisibleInMainProgram = (record: WorkProgram) => {
    dispatch(setWorkProgramAvailableInMainProgramAction({
      programId: record.id,
      isAvailable: !record.showInMainPrograms,
    }));
  };

  // удаляет РП из списка
  const toggleVisibleInMainProgramList = (record: WorkProgram) => {
    dispatch(toggleVisibleWorkProgramInMainProgramListAction({
      programId: record.id,
      isHidden: !record.hideForManagerInMainProgram
    }));
  };

  const toggleWorkProgramsVisibilityFilter = () => {
    const sort = tableState.sorters.filter((col) => !!col.order).map((col) => `${col.order === "ascend" ? "+" : "-"}${col.dataIndex.join(".")}`);

    dispatch(getWorkProgramsAction({
      page: tableState.pagination.current! - 1,
      limit: tableState.pagination.pageSize,
      sort,
      educationLevel: props.educationLevel,
      subjectId: subjectId,
      showHidden: !isShowHidden,
      organizationId: profile!.organization.id!,
    }));

    setIsShowHidden(!isShowHidden);
  };

  const columns = getWorkProgramsTableColumns(toggleVisibleInMainProgram, toggleVisibleInMainProgramList);

  useEffect(() => {
    dispatch(getAllSubjectsAction());

    return () => {
      dispatch(clearWorkProgramsStateAction());
    };
  }, []);

  useEffect(() => {
    if (workProgramsTotal || workProgramsTotal === 0) {
      tableMethods.setTableState({
        ...tableState,
        pagination: {
          ...tableState.pagination,
          total: workProgramsTotal
        }
      });
    }
  }, [workProgramsTotal]);

  const getTableItems: AppTableOnChange = (nextTableState, _, actionType) => {
    if (wasInit.current && actionType === "init") {
      return;
    }
    if (actionType === "init") {
      wasInit.current = true;
    }
    const sort = nextTableState.sorters.filter((col) => !!col.order).map((col) => `${col.order === "ascend" ? "+" : "-"}${col.dataIndex.join(".")}`);

    tableMethods.setTableState(nextTableState);
    dispatch(getWorkProgramsAction({
      page: nextTableState.pagination.current! - 1,
      limit: nextTableState.pagination.pageSize,
      sort,
      educationLevel: props.educationLevel,
      subjectId: subjectId,
      organizationId: profile!.organization.id!,
      showHidden: isShowHidden,
    }));
  };

  const handleChange = (value?: number) => {
    setSubjectId(value);
    const sort = tableState.sorters.filter((col) => !!col.order).map((col) => `${col.order === "ascend" ? "+" : "-"}${col.dataIndex.join(".")}`);

    dispatch(getWorkProgramsAction({
      page: tableState.pagination.current! - 1,
      limit: tableState.pagination.pageSize,
      sort,
      educationLevel: props.educationLevel,
      subjectId: value,
      organizationId: profile!.organization.id!,
      showHidden: isShowHidden,
    }));
  };

  return (
    <AppFlex>
      <AppButton style={{alignSelf: "flex-start"}} onClick={toggleWorkProgramsVisibilityFilter}>
        {
          isShowHidden ? "Показать доступные" : "Показать скрытые"
        }
      </AppButton>
      <AppFlex direction="row">
        <AppSelect placeholder="Выберите предмет" value={subjectId} options={toOptions(subjects, "id", "name")} onChange={handleChange}/>
        <AppButton onClick={() => handleChange()}>Сбросить</AppButton>
      </AppFlex>
      <AppTable columns={columns} dataSource={workPrograms}
                rowKey={(record: WorkProgram) => record.id} onChange={getTableItems}
                loading={workProgramsLoading} sorters={tableState.sorters}
                pagination={tableState.pagination} filters={tableState.filters}/>
    </AppFlex>

  );
};