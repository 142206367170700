import type {ProgramTemplateBlockTableSection} from "services/api";

const defaultObj: ProgramTemplateBlockTableSection = {
  id: null,
  table: null,
  index: 0,
  type: "TABLE",
  name: "",
  createdOnWorkProgram: null,
  rows: [],
};

export const getProgramTemplateBlockTableSectionDTO = (
  obj: Partial<ProgramTemplateBlockTableSection>
): ProgramTemplateBlockTableSection => ({
  ...defaultObj,
  ...obj,
});