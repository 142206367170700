import cn from "classnames";
import type {AppTabItem} from "../../Tabs.types";
import styles from "./TabsSwitch.module.scss";

export const getClassNames = (className?: string) => {
  return cn(styles.container, className);
};

type ListItemStyleProps = AppTabItem & {
  isActive: boolean,
}

export const getItemClassNames = ({isActive}: ListItemStyleProps) => {
  return cn(
    styles.item,
    isActive && styles.active,
  );
};