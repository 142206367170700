import {createSlice} from "@reduxjs/toolkit";
import type {MainProgramsState} from "./types";
import {
  deleteMainProgramReducerBuilder,
  getMainProgramsReducerBuilder, updateMainProgramReducerBuilder
} from "./reducers";

const initialState: MainProgramsState = {
  loading: false,
  mainPrograms: [],
  total: null,
};


const mainProgramsSlice = createSlice({
  name: "mainPrograms",
  initialState,
  reducers: {
    clearMainProgramsStateAction: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    getMainProgramsReducerBuilder(builder);
    deleteMainProgramReducerBuilder(builder);
    updateMainProgramReducerBuilder(builder);
  }
});

export const mainProgramsReducer = mainProgramsSlice.reducer;
export const {clearMainProgramsStateAction} = mainProgramsSlice.actions;
