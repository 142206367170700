import cn from "classnames";
import {memo} from "react";
import type {TableWidgetSumCellProps} from "./types";
import {numberValueRenderer} from "../../cell-value-renderers/number-value-renderer";
import widgetCellStyles from "../../styles/widget.module.scss";
import {TableBaseCell} from "../TableBaseCell";
import {HintIcon} from "../HintIcon";
import {getHintContent} from "./utils";

export const TableWidgetSumCell = memo(({
  field,
  isPrograms,
  isFullAccess,
  editMode,
  validationStatus,
  isCellSimple,
}: TableWidgetSumCellProps) => {
  if (isCellSimple) {
    return (
      <TableBaseCell isFullAccess={isFullAccess} editMode={editMode}
                     index={field.index} onClick={e => e.stopPropagation()}>
        {numberValueRenderer(field.value, field.placeholder, false)}
      </TableBaseCell>
    );
  }

  const isCellEditable = editMode && isFullAccess;
  const isCellWidget = editMode || !isPrograms;
  const isCellWidgetSuccess = isCellWidget && validationStatus === "success";
  const isCellWidgetWarning = isCellWidget && validationStatus === "warning";
  const isCellWidgetError = isCellWidget && validationStatus === "error";
  const hintContent = isCellWidget ? getHintContent(field) : null;
  const cellClassName = cn(
    isCellWidget && widgetCellStyles["widget-cell"],
    isCellWidgetSuccess && widgetCellStyles["widget-cell_success"],
    isCellWidgetWarning && widgetCellStyles["widget-cell_warning"],
    isCellWidgetError && widgetCellStyles["widget-cell_error"],
  );

  return (
    <TableBaseCell isFullAccess={isFullAccess} editMode={editMode}
                   index={field.index} editable={isCellEditable} className={cellClassName}>
      <>
        {!!hintContent && <HintIcon content={hintContent}/>}
        {numberValueRenderer(field.value, field.placeholder, isCellWidget)}
      </>
    </TableBaseCell>
  );
});