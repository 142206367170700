import {useRef} from "react";
import type {KeyboardEvent} from "react";

export const useAppBlocker = (blocking: boolean) => {
  const topRef = useRef<HTMLDivElement>(null);
  const bottomRef = useRef<HTMLDivElement>(null);

  const blockingTab = (e: KeyboardEvent, withShift = false) => {
    return blocking && (e.key === "Tab" || e.keyCode === 9) && e.shiftKey === withShift;
  };

  // tabbed from the top outside element -> changing focus to bottom blocker
  const tabbedUpTop = (e: KeyboardEvent) => {
    if (blockingTab(e)) {
      bottomRef.current?.focus();
    }
  };

  // tabbed from the top blocker -> changing focus to bottom blocker
  const tabbedDownTop = (e: KeyboardEvent) => {
    if (blockingTab(e)) {
      e.preventDefault();
      bottomRef.current?.focus();
    }
  };

  // tabbed up from the bottom outside -> changing focus to top blocker
  const tabbedUpBottom = (e: KeyboardEvent) => {
    if (blockingTab(e, true)) {
      topRef.current?.focus();
    }
  };

  // tabbed up from the bottom blocker -> changing focus to top blocker
  const tabbedDownBottom = (e: KeyboardEvent) => {
    if (blockingTab(e, true)) {
      e.preventDefault();
      topRef.current?.focus();
    }
  };

  return {
    topRef,
    bottomRef,
    tabbedUpTop,
    tabbedDownTop,
    tabbedUpBottom,
    tabbedDownBottom,
  };
};