export const BasicEducationHeader = () => {
  return (
    <thead>
      <tr>
        <th colSpan={1}
            rowSpan={2}
            style={{width: "20%"}}>
          Направление внеурочной деятельности
        </th>
        <th colSpan={1}
            rowSpan={2}
            style={{width: "20%"}}>
          Наименование курса внеурочной деятельности
        </th>
        <th colSpan={1}
            rowSpan={2}
            style={{width: "20%"}}>
          Формы внеурочной деятельности
        </th>
        <th colSpan={4}
            rowSpan={1}
            style={{width: "30%"}}>
          Классы/количество часов
        </th>
        <th colSpan={1}
            rowSpan={2}
            style={{width: "8%"}}>
          Всего часов
        </th>
      </tr>
      <tr>
        <th colSpan={1}
            rowSpan={1}
            style={{width: "8%"}}>
          1 кл.
        </th>
        <th colSpan={1}
            rowSpan={1}
            style={{width: "8%"}}>
          2 кл.
        </th>
        <th colSpan={1}
            rowSpan={1}
            style={{width: "8%"}}>
          3 кл.
        </th>
        <th colSpan={1}
            rowSpan={1}
            style={{width: "8%"}}>
          4 кл.
        </th>
      </tr>
    </thead>
  );
};