import type {AppSelectOption} from "shared/UI/UIKit/Select";

export const widgetBlockTypeOptions: AppSelectOption[] = [
  {
    label: "Рассмотрено, Согласовано, Утверждено",
    value: "considered-agreed-approved",
  },
  {
    label: "Рассмотрено, Утверждено",
    value: "considered-approved",
  },
  {
    label: "Согласовано, Утверждено",
    value: "agreed-approved",
  },
  {
    label: "Утверждено",
    value: "approved",
  },
];