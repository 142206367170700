import type {TableHeaderProps} from "./types";
import {TableHeaderCell} from "../TableHeaderCell";
import {useTableHeader} from "./use-table-header";

export const TableHeader = (props: TableHeaderProps) => {
  const [
    {isFullAccess, editMode, rows, colgroup},
    {handleCellUpdate, handleColumnEditClick}
  ] = useTableHeader(props);

  return (
    <>
      <colgroup>
        {colgroup.map((colStyle, colIndex) => {
          return (
            <col key={colIndex} style={colStyle}/>
          );
        })}
      </colgroup>
      <thead className="table-constructor-thead">
        {rows.map((row, rowIndex) => {
          return (
            <tr key={rowIndex}>
              {row.map((cell, cellIndex) => {
                return (
                  <TableHeaderCell key={cellIndex} cell={cell}
                                   isFullAccess={isFullAccess} editMode={editMode}
                                   onCellUpdate={handleCellUpdate} onColumnEditClick={handleColumnEditClick}/>
                );
              })}
            </tr>
          );
        })}
      </thead>
    </>
  );
};