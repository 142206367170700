import {useNavigate} from "react-router-dom";
import {getHomeUrl} from "services/navigation";
import {AppPage} from "common/Layout";
import {AppButton} from "shared/UI/UIKit/Button";
import {AppFlex} from "shared/UI/UIKit/Flex";
import {AppTitle} from "shared/UI/UIKit/Title";
import styles from "./styles.module.scss";

const HOME_URL = getHomeUrl();

export const NotFoundPage = () => {
  const navigate = useNavigate();

  return (
    <AppPage documentTitle="Страница не найдена">
      <AppFlex className={styles.container} direction="column" alignItems="center" justifyContent="center">
        <AppTitle>Ошибка 404. Страница не найдена</AppTitle>
        <AppButton onClick={() => navigate(HOME_URL, {replace: true})}>
          Вернуться на главную
        </AppButton>
      </AppFlex>
    </AppPage>
  );
};