import {AppModal} from "shared/UI/UIKit/Modal";
import type {ProgramTemplateBlockTableRowField} from "services/api";
import type {CellSettingsModalProps} from "./types";
import {useTableConstructorContext} from "../../contexts/table-constructor-context";
import {
  NumberCellForm, numberCellOperatorOptions,
  ListCellForm,
  ReferenceCellForm,
} from "./components/forms";

export const CellSettingsModal = (props: CellSettingsModalProps) => {
  const {isFullAccess} = useTableConstructorContext();

  const handleSubmit = (fieldPart: Partial<ProgramTemplateBlockTableRowField>) => {
    props.onCellUpdate({
      ...props.cell!,
      field: {
        ...props.cell!.field,
        ...fieldPart,
      },
    });
    handleCancel();
  };

  const handleCancel = () => {
    props.onHide();
  };

  const getModalForm = () => {
    if (!props.cell) {
      return null;
    }

    const {field, meta} = props.cell;
    const isValueRequired = isFullAccess ? false : !!field.required;

    switch (meta.rowType) {
      case "SECTION_BODY_ROW": {
        if (field.type === "WIDGET_LIST") {
          return (
            <ListCellForm isFullAccess={isFullAccess} field={field}
                          value={{visible: true, required: isValueRequired}}
                          options={{visible: isFullAccess, required: false}}
                          onSubmit={handleSubmit} onCancel={handleCancel}/>
          );
        }
        if (field.type === "WIDGET_REFERENCE") {
          return (
            <ReferenceCellForm isFullAccess={isFullAccess} field={field}
                               value={{visible: true, required: isValueRequired}}
                               listId={{visible: isFullAccess, required: false}}
                               onSubmit={handleSubmit} onCancel={handleCancel}/>
          );
        }
        return null;
      }
      case "SECTION_FOOTER_ROW": {
        if (field.type === "WIDGET_SUM") {
          return (
            <NumberCellForm isFullAccess={isFullAccess} field={field}
                            targetValue={{visible: true, required: false}}
                            operator={{
                              visible: true,
                              required: false,
                              options: [
                                numberCellOperatorOptions.empty,
                                numberCellOperatorOptions.required,
                                numberCellOperatorOptions.min,
                                numberCellOperatorOptions.max,
                              ],
                            }}
                            onSubmit={handleSubmit} onCancel={handleCancel}/>
          );
        }
        if (field.type === "WIDGET_PERCENT") {
          return (
            <NumberCellForm isFullAccess={isFullAccess} field={field}
                            targetValue={{
                              label: "Целевое значение (в процентах)",
                              visible: true,
                              required: false,
                              options: {max: 100},
                            }}
                            operator={{
                              visible: true,
                              required: false,
                              options: [
                                numberCellOperatorOptions.empty,
                                numberCellOperatorOptions.required,
                                numberCellOperatorOptions.min,
                                numberCellOperatorOptions.max,
                              ],
                            }}
                            onSubmit={handleSubmit} onCancel={handleCancel}/>
          );
        }
        return null;
      }
      default: {
        return null;
      }
    }
  };

  return (
    <AppModal visible={props.visible} onCancel={handleCancel}>
      {getModalForm()}
    </AppModal>
  );
};