import React from "react";
import type {AppTabsSwitchProps} from "./TabsSwitch.types";
import {getClassNames, getItemClassNames} from "./TabsSwitch.utils";
import styles from "./TabsSwitch.module.scss";

export const AppTabsSwitch = ({tabs, className, activeId}: AppTabsSwitchProps) => {
  const classNames = getClassNames(className);

  return (
    <div className={classNames}>
      <ul className={styles.list}>
        {
          tabs.map(tab => {
            const isActive = tab.id === activeId;
            const itemClassNames = getItemClassNames({...tab, isActive});
            return (
              <li key={tab.id} className={itemClassNames}>
                {tab.title}
              </li>
            );
          })
        }
      </ul>
    </div>
  );
};
