import {useMemo} from "react";
import {getProgramTemplateBlockTableHeaderDTO} from "services/api/blocks/dto";
import type {TableConstructorContextProviderProps, TableConstructorContextType} from "./types";
import {columnsHelpers} from "../../../lib/helpers";
import {TableConstructorContext} from "./context";

export const TableConstructorContextProvider = ({
  editMode,
  isPrograms,
  isFullAccess,
  header,
  children,
}: TableConstructorContextProviderProps) => {
  const calculatedColumns = useMemo(() => {
    const columns = columnsHelpers.getTableColumnsByHeader(getProgramTemplateBlockTableHeaderDTO(header));
    const singleColumns = columnsHelpers.getSingleTableConstructorColumns(columns);
    const visibleColumns = singleColumns.filter(column => !column.hidden);

    return {
      columns,
      singleColumns,
      visibleColumns,
    };
  }, [header]);

  const value = useMemo<TableConstructorContextType>(() => ({
    editMode: !!editMode,
    isPrograms: !!isPrograms,
    isFullAccess: !!isFullAccess,
    ...calculatedColumns,
  }),[editMode, isPrograms, isFullAccess, calculatedColumns]);

  return (
    <TableConstructorContext.Provider value={value}>
      {children}
    </TableConstructorContext.Provider>
  );
};