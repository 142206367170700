import {useMemo, useState} from "react";
import {isFunction} from "shared/helpers/utils/is-function";
import type {AppTablePagination} from "../../ui";
import type {TableStateManager, TableStateManagerState, TableStateManagerMethods} from "./types";
import {getDefaultTablePagination} from "../../config/default-table-state";
import {resetCurrentPageOnPaginationUpdate, getMergedPagination} from "./utils";

export const useTableStateManager = (
  initialState: TableStateManagerState | (() => TableStateManagerState)
): TableStateManager => {
  const initialTableState = useMemo(() => isFunction(initialState) ? initialState() : initialState , []);
  const [state, setState] = useState<TableStateManagerState>(initialTableState);

  const setSortersState: TableStateManagerMethods["setSortersState"] = (nextSorters) => {
    setState(currentState => ({
      ...currentState,
      sorters: nextSorters,
    }));
  };

  const setFiltersState: TableStateManagerMethods["setFiltersState"] = (nextFilters) => {
    setState(currentState => ({
      ...currentState,
      filters: nextFilters,
    }));
  };

  const setPaginationState: TableStateManagerMethods["setPaginationState"] = (nextPagination) => {
    setState(currentState => ({
      ...currentState,
      pagination: nextPagination,
    }));
  };

  const setTableState: TableStateManagerMethods["setTableState"] = (nextTableState) => {
    setState({...nextTableState});
  };

  const updatePaginationState: TableStateManagerMethods["updatePaginationState"] = (
    nextPagination, resetCurrentPage = resetCurrentPageOnPaginationUpdate
  ) => {
    const getNextCurrentPage = (currentPagination: AppTablePagination) => {
      if (typeof resetCurrentPage === "function") {
        return resetCurrentPage(currentPagination, nextPagination);
      }

      return resetCurrentPage ? getDefaultTablePagination().current : currentPagination.current;
    };

    setState(currentState => ({
      ...currentState,
      pagination: getMergedPagination(
        currentState.pagination,
        {...nextPagination, current: getNextCurrentPage(currentState.pagination)},
      ),
    }));
  };

  const resetSortersState: TableStateManagerMethods["resetSortersState"] = () => {
    setSortersState(initialTableState.sorters);
  };

  const resetFiltersState: TableStateManagerMethods["resetFiltersState"] = () => {
    setFiltersState(initialTableState.filters);
  };

  const resetPaginationState: TableStateManagerMethods["resetPaginationState"] = () => {
    setPaginationState(initialTableState.pagination);
  };

  const resetTableState: TableStateManagerMethods["resetTableState"] = () => {
    setTableState(initialTableState);
  };

  const resetCurrentPage: TableStateManagerMethods["resetCurrentPage"] = () => {
    updatePaginationState({}, true);
  };

  return {
    tableState: state,
    tableMethods: {
      setSortersState,
      setFiltersState,
      setPaginationState,
      setTableState,
      updatePaginationState,
      resetSortersState,
      resetFiltersState,
      resetPaginationState,
      resetTableState,
      resetCurrentPage,
    }
  };
};