export const directionOfExtracurricularActivitiesOptions = [
  {
    label: "Формирование функциональной грамотности обучающихся",
    value: "Формирование функциональной грамотности обучающихся",
  },
  {
    label: "Профориентационная работа",
    value: "Профориентационная работа",
  },
  {
    label: "Развитие личности и самореализации обучающихся",
    value: "Развитие личности и самореализации обучающихся",
  },
  {
    label: "Дополнительное изучение учебных предметов",
    value: "Дополнительное изучение учебных предметов",
  },
  {
    label: "Педагогическая поддержка обучающихся",
    value: "Педагогическая поддержка обучающихся",
  },
  {
    label: "Организация деятельности ученических сообществ",
    value: "Организация деятельности ученических сообществ",
  },
  {
    label: "Реализация воспитательного компонента патриотической, нравственной и экологической направленности",
    value: "Реализация воспитательного компонента патриотической, нравственной и экологической направленности",
  },
  {
    label: "Спортивно-оздоровительная деятельность обучающихся",
    value: "Спортивно-оздоровительная деятельность обучающихся",
  },
  {
    label: "Художественно-эстетическая, творческая деятельность обучающихся",
    value: "Художественно-эстетическая, творческая деятельность обучающихся",
  },
  {
    label: "Проектно-исследовательская работа",
    value: "Проектно-исследовательская работа",
  },
  {
    label: "Развитие коммуникативных навыков обучающихся",
    value: "Развитие коммуникативных навыков обучающихся",
  },
];

export const formsOfExtracurricularActivitiesOptions = [
  {
    label: "Эвристическая беседа",
    value: "Эвристическая беседа",
  },
  {
    label: "Профориентационные беседы",
    value: "Профориентационные беседы",
  },
  {
    label: "Деловые игры / ролевые игры / театрализованные игры",
    value: "Деловые игры / ролевые игры / театрализованные игры",
  },
  {
    label: "Квесты",
    value: "Квесты",
  },
  {
    label: "Решение кейсов",
    value: "Решение кейсов",
  },
  {
    label: "Профессиональные пробы",
    value: "Профессиональные пробы",
  },
  {
    label: "Экскурсии",
    value: "Экскурсии",
  },
  {
    label: "Дискуссия",
    value: "Дискуссия",
  },
  {
    label: "Проектная деятельность",
    value: "Проектная деятельность",
  },
  {
    label: "Исследовательская работа",
    value: "Исследовательская работа",
  },
  {
    label: "Краеведческая работа",
    value: "Краеведческая работа",
  },
  {
    label: "Научная конференция",
    value: "Научная конференция",
  },
  {
    label: "Викторина, брейн-ринг",
    value: "Викторина, брейн-ринг",
  },
  {
    label: "Групповая проблемная работа",
    value: "Групповая проблемная работа",
  },
  {
    label: "Интеллектуальный марафон",
    value: "Интеллектуальный марафон",
  },
  {
    label: "Школьный музей",
    value: "Школьный музей",
  },
  {
    label: "Тематическая выставка",
    value: "Тематическая выставка",
  },
];