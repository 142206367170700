import type {AppMenuItem} from "../../../ui/Menu.types";

export type DividerMenuItemOptions = {
  key?: string,
  wrapperClassName?: string,
}

export const getDividerMenuItem = ({
  key,
  wrapperClassName,
}: DividerMenuItemOptions = {}): AppMenuItem => {
  return {
    type: "divider",
    key,
    className: wrapperClassName,
  };
};