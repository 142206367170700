import {AppFlex} from "shared/UI/UIKit/Flex";
import {AppText} from "shared/UI/UIKit/Text";
import type {MainProgramItem,Curriculum} from "services/api";
import {SubjectsList} from "../SubjectsList";

type CurriculumItemProps = {
  curriculum: Curriculum;
  isLastCurriculumItem: boolean;
  addMainProgramItemToStateByFieldName: (fieldName: string, item: MainProgramItem) => void;
}

export const CurriculumItem = (props: CurriculumItemProps) => {
  return (
    <AppFlex unit="30">
      <AppText size={24}>
        Выберите рабочие программы ({props.curriculum.name})
      </AppText>
      <SubjectsList curriculum={props.curriculum}
                    isLastCurriculumItem={props.isLastCurriculumItem}
                    addMainProgramItemToStateByFieldName={props.addMainProgramItemToStateByFieldName}
                    subjects={props.curriculum.subjects}/>
    </AppFlex>
  );
};