import {notification} from "antd";
import {generateUUID} from "shared/helpers/utils/generate-uuid";

export const placeholder_block_plugin = {
    name: "placeholderBlock",
    display: "command",
    title: "Добавить блок виджет",
    innerHTML: "<span>Виджет-блок</span>",
    add: function (core, targetElement) {
        const context = core.context;
        context.placeholder = {
            targetButton: targetElement,
            tag: core.util.createElement("PLACEHOLDER_BLOCK")
        };
    },
    active: function(element) {
        if (element && element.getAttribute("data-type") === "placeholder") {
            const filteredArray = Array.from(this.codeViewDisabledButtons).filter((_, index) => index !== 7);
            this.functions.util.setDisabledButtons(true, filteredArray, true);
        } else {
            this.functions.util.setDisabledButtons(false, this.codeViewDisabledButtons, true);
        }
        return true;
    },
    action: function () {
        const selection = window.getSelection();
        const parentNode = selection.getRangeAt(0).commonAncestorContainer.parentNode;
        if (parentNode.getAttribute("data-widget")) {
            notification.error({
                message: "Нельзя добавить виджет"
            });
            return;
        }
        this.functions.insertHTML(`
            <div data-widget="true" id=${generateUUID()} data-type="considered-agreed-approved" class="placeholder-block">
                <div id="considered">${consideredBlock}</div>
                <div id="agreed">${agreedBlock}</div>
                <div id="approved">${approvedBlock}</div>
                <div contenteditable="false" data-type="block-control" class="block-edit">Редактировать</div>
            </div>
            <p>&zwnj;</p>
        `, true);

    },
};

const getBlockContent = (title) => {
    return `
    <div class="with-margin-bottom_small">${title}</div>
    <div class="with-margin-bottom">
        <span data-placeholder="Укажите должность" contenteditable="false" title="Свободный ввод" data-widget="true" data-type="placeholder" id=${generateUUID()} class="placeholder">Укажите должность</span>
    </div>
    <div class="with-margin-bottom_small with-text-align-right">
        <hr>
        <span data-placeholder="укажите ФИО" contenteditable="false" title="Свободный ввод" data-widget="true" data-type="placeholder" id=${generateUUID()} class="placeholder">укажите ФИО</span>
    </div>
    <span>
        <span data-placeholder="Номер приказа" contenteditable="false" title="Свободный ввод" data-widget="true" data-type="placeholder" id=${generateUUID()} class="placeholder">Номер приказа</span>
        <br>
        от «<span data-placeholder="число" contenteditable="false" title="Свободный ввод" data-widget="true" data-type="placeholder" id=${generateUUID()} class="placeholder">число</span>»
        <span data-placeholder="месяц" contenteditable="false" title="Свободный ввод" data-widget="true" data-type="placeholder" id=${generateUUID()} class="placeholder">месяц</span>
        <span data-placeholder="год" contenteditable="false" title="Свободный ввод" data-widget="true" data-type="placeholder" id=${generateUUID()} class="placeholder">год</span> г.
    </span>
    `;
};

export const consideredBlock = getBlockContent("РАССМОТРЕНО");

export const agreedBlock = getBlockContent("СОГЛАСОВАНО");

export const approvedBlock = getBlockContent("УТВЕРЖДЕНО");