import {AppButton} from "shared/UI/UIKit/Button";
import type {AppErrorFallbackProps} from "./ErrorFallback.types";
import styles from "./ErrorFallback.module.scss";

export const AppErrorFallback = (props: AppErrorFallbackProps) => {
  const {error, errorInfo, reset} = props;
  return (
    <div className={styles.container}>
      <h2 className={styles.header}>Что-то пошло не так</h2>
      <details className={styles.details}>
        <summary>Показать детали</summary>
        <h3 className={styles.subheader}>{error && error?.toString()}</h3>
        <br/>
        {errorInfo && errorInfo.componentStack}
      </details>
      <AppButton color="warning" onClick={reset} className={styles["try-again"]}>
        Обновить
      </AppButton>
    </div>
  );
};