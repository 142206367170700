import {memo} from "react";
import type {ChildrenWrapperProps} from "./MemoChildren.types";

const ChildrenWrapper = <AnyProps,>({children}: ChildrenWrapperProps<AnyProps>) => {
  return (
    <>{children}</>
  );
};

export const MemoChildren = memo(
  ChildrenWrapper,
  (prevProps, nextProps) => !nextProps.shouldUpdate(prevProps, nextProps)
) as <AnyProps,>(props: ChildrenWrapperProps<AnyProps>) => ReturnType<typeof ChildrenWrapper>;