import cn from "classnames";
import type {AppImageProps} from "./Image.types";
import styles from "./Image.module.scss";

type AppImageStyleProps = Pick<AppImageProps, "className" | "previewClassName"> & {
  error: boolean
};

export const getClassNames = ({error, className, previewClassName}: AppImageStyleProps) => {
  return cn(
    styles.image,
    error && styles.preview,
    className,
    error && previewClassName,
  );
};