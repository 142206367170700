import {createAsyncThunk} from "@reduxjs/toolkit";
import {deleteMainProgram, getMainPrograms, updateMainProgram} from "services/api";
import type {MainProgram, GetMainProgramListPayload} from "services/api";

export const getMainProgramsAction = createAsyncThunk("mainPrograms/getMainPrograms", async(payload: GetMainProgramListPayload) => {
  try {
    return await getMainPrograms(payload);
  } catch (e: unknown) {
    throw new Error(e as string);
  }
});

export const deleteMainProgramAction = createAsyncThunk("mainPrograms/deleteMainProgram", async(id: number) => {
  try {
    await deleteMainProgram(id);
    return id;
  } catch (e: unknown) {
    throw new Error(e as string);
  }
});

export const updateMainProgramAction = createAsyncThunk("mainPrograms/updateMainProgram", async(mainProgram: MainProgram) => {
  try {
    await updateMainProgram(mainProgram);
    return mainProgram;
  } catch (e: unknown) {
    throw new Error(e as string);
  }
});
