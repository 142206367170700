function getMinMax<T extends number | object, R extends number>(array: T[], getter: (item: T) => R): [R, R] {
  let min = getter(array[0]);
  let max = min;

  for (let i = 0; i < array.length; i++) {
    const value = getter(array[i]);
    if (min > value) {
      min = value;
    }
    if (max < value) {
      max = value;
    }
  }

  return [min, max];
}

function getMinMaxValue<T extends number>(array: T[]): [T | undefined, T | undefined];
function getMinMaxValue<T extends number, E>(array: T[], emptyValue: E): [T | E, T | E];
function getMinMaxValue<T extends number, E>(array: T[], emptyValue?: E): [T | E | undefined, T | E | undefined] {
  if (!array.length) {
    return [emptyValue, emptyValue];
  }

  return getMinMax(array, item => item);
}

function getMinMaxObjectValue<T extends object, R extends number>(array: T[], getter: (item: T) => R): [R | undefined, R | undefined];
function getMinMaxObjectValue<T extends object, R extends number, E>(array: T[], getter: (item: T) => R, emptyValue: E): [R | E, R | E];
function getMinMaxObjectValue<T extends object, R extends number, E>(array: T[], getter: (item: T) => R, emptyValue?: E): [R | E | undefined, R | E | undefined] {
  if (!array.length) {
    return [emptyValue, emptyValue];
  }

  return getMinMax(array, getter);
}

export {getMinMaxValue, getMinMaxObjectValue};