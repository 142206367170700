export const formNames = {
  value: "value",
  options: "options",
  readonly: "readonly",
} as const;

export const formItemLabels = {
  value: "Значение",
  options: "Список возможных значений (через разделитель \" | \")",
  readonly: "Только для чтения",
} as const;