import React from "react";
import type {ProgramTemplateBlockTableSection} from "services/api";
import type {TableBodyProps} from "./types";
import {TableSection} from "../TableSection";
import {TableSectionCreator} from "../TableSectionCreator";
import {TableModuleCreator} from "../TableModuleCreator";
import {modulesHelpers} from "../../../lib/helpers";
import {useTableBody} from "./use-table-body";

export const TableBody = (props: TableBodyProps) => {
  const [
    {
      tableSections,
      tableFooterSections,
      isTableSectionCreatorVisible,
      isTableModuleCreatorVisible,
      visibleColumnCount,
      areModulesExist,
      moduleLastSectionIndexes,
    }
  ] = useTableBody(props);
  let tableSectionCount = 0;

  const renderSection = (section: ProgramTemplateBlockTableSection) => {
    const isSectionCountable = !modulesHelpers.checkSectionIsModule(section);

    if (isSectionCountable) {
      tableSectionCount++;
    } else {
      tableSectionCount = 0;
    }

    return (
      <TableSection tableType={props.type} section={section} settings={props.settings}
                    ordinalNumber={isSectionCountable ? tableSectionCount : undefined}
                    methods={{
                      createRow: props.rowMethods.create,
                      clickCell: props.cellMethods.click,
                      updateCell: props.cellMethods.update,
                    }}/>
    );
  };

  return (
    <tbody className="table-constructor-tbody">
      {tableSections.map((tableSection, tableSectionIndex) => {
        const isLastSectionOfModule = moduleLastSectionIndexes.includes(tableSectionIndex);
        return (
          <React.Fragment key={tableSection.id}>
            {renderSection(tableSection)}
            {isLastSectionOfModule && isTableSectionCreatorVisible && (
              <TableSectionCreator colSpan={visibleColumnCount}
                                   onSectionCreate={() => props.sectionMethods.insert(tableSection.id!, "below")}/>
            )}
          </React.Fragment>
        );
      })}
      {isTableModuleCreatorVisible && (
        <TableModuleCreator colSpan={visibleColumnCount}
                            onModuleCreate={props.moduleMethods.create}/>
      )}
      {!areModulesExist && isTableSectionCreatorVisible && (
        <TableSectionCreator colSpan={visibleColumnCount}
                             onSectionCreate={props.sectionMethods.create}/>
      )}
      {tableFooterSections.map((tableFooterSection) => {
        return (
          <React.Fragment key={tableFooterSection.id}>
            {renderSection(tableFooterSection)}
          </React.Fragment>
        );
      })}
    </tbody>
  );
};