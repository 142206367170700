import type {TextMenuItemOptions} from "../base-menu/get-text-menu-item";
import {getTextMenuItem as getTextItem} from "../base-menu/get-text-menu-item";

export const getTextMenuItem = (options: TextMenuItemOptions) => {
  const {shouldCloseMenu = true, confirm, ...rest} = options;

  const getConfirm = () => {
    return confirm ? {
      ...confirm,
      shouldCloseMenuOnConfirm: confirm.shouldCloseMenuOnConfirm ?? true,
      shouldCloseMenuOnCancel: confirm.shouldCloseMenuOnCancel ?? false,
    } : undefined;
  };

  return getTextItem({
    shouldCloseMenu,
    confirm: getConfirm(),
    ...rest,
  });
};