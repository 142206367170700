import type {TimeFilterFormValue} from "../types";
import type {TimeFilterFieldsProps} from "./types";
import {FILTER_FORM_NAMES} from "../../../constants";
import {AppFilterOperator} from "../../../../constants";
import {TimeField} from "./TimeField";
import {TimeIntervalFields} from "./TimeIntervalFields";

export const TimeFilterFields = ({form, options, focusRef}: TimeFilterFieldsProps) => {
  const operator = form.getFieldValue(FILTER_FORM_NAMES.operator) as AppFilterOperator;

  const onChange = (values: TimeFilterFormValue[]) => {
    form.setFieldsValue({values});
  };

  switch (operator) {
    case AppFilterOperator.IS_NULL: {
      return null;
    }
    case AppFilterOperator.INTERVAL: {
      return (
        <TimeIntervalFields onChange={onChange} focusRef={focusRef}
                            placeholderStart={options?.placeholderStart}
                            placeholderEnd={options?.placeholderEnd}/>
      );
    }
    default: {
      return (
        <TimeField onChange={onChange} focusRef={focusRef}
                   placeholder={options?.placeholder}/>
      );
    }
  }
};