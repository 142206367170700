import {URI_SCHEME} from "shared/constants/uri-scheme";
import type {EmailRendererProps} from "../types";
import {LinkRenderer} from "./LinkRenderer";

export const EmailRenderer = ({email, ...rest}: EmailRendererProps) => {
  if (!email) {
    return null;
  }

  return (
    <LinkRenderer href={`${URI_SCHEME.MAILTO}:${email}`} text={email}
                  target="_self" {...rest}/>
  );
};