import {useState} from "react";
import {isHTMLElement} from "shared/helpers/utils/DOM";
import {useEvent} from "shared/helpers/hooks/use-event";
import type {PropsWithKey} from "shared/types";
import type {ProgramTemplateBlockTableRowField} from "services/api";
import type {MouseEvent, PointerEvent} from "react";
import type {TableRowProps} from "./types";
import type {TableBodyCellProps} from "../TableBodyCell";
import type {SelectedRow} from "../../../lib/types";
import {cellValidators} from "../../../lib/core";
import {fieldsHelpers} from "../../../lib/helpers";
import {CONTROL_PANEL_VIEW_DATA_TYPE} from "../../../ui/components/ControlPanel";
import {useTableConstructorContext} from "../../contexts/table-constructor-context";
import {useTableActionsContext} from "../../components/TableActions";
import {useTableContext} from "../TableWithActions";
import {getParentTd} from "./utils";

export const useTableRow = (props: TableRowProps) => {
  const {isFullAccess, isPrograms, editMode, singleColumns, visibleColumns} = useTableConstructorContext();
  const {references, referencesLoading, getDefaultDatePickerValue} = useTableContext();
  const {showActionsPanel, hideActionsPanel} = useTableActionsContext();
  const [isRowSelected, setRowSelected] = useState(false);

  const {visibleFields, hiddenFields} = fieldsHelpers.splitFieldsByVisibility(
    singleColumns, props.sectionType, props.row.type, props.row.fields,
  );
  const visibleColumnCount = visibleColumns.length;
  const visibleFieldsCount = visibleFields.length;

  const handleRowClick = (e: MouseEvent<HTMLTableRowElement>) => {
    if (isHTMLElement(e.target) && e.currentTarget !== e.target && e.currentTarget.contains(e.target)) {
      const td = getParentTd(e.target);
      const fieldIndex = +td.dataset.index!;
      const currentField = visibleFields.find(field => field.index === fieldIndex)!;
      props.onCellClick(currentField, props.row);
    }
  };

  const handleRowPointerEnter = (e: PointerEvent<HTMLTableRowElement>) => {
    if (!editMode || props.row.type === "SECTION_FOOTER_ROW") {
      return;
    }

    setRowSelected(true);
    const selectedRow: SelectedRow = {
      target: e.currentTarget,
      meta: {
        section: {
          id: props.sectionId!,
          type: props.sectionType,
          createdOnWorkProgram: props.isSectionCreatedOnWorkProgram,
          isModule: props.isSectionModule,
        },
        row: {
          id: props.row.id!,
          type: props.row.type,
          createdOnWorkProgram: props.row.createdOnWorkProgram,
        },
      },
    };
    showActionsPanel(selectedRow, () => setRowSelected(false));
  };

  const handleRowPointerLeave = (e: PointerEvent<HTMLTableRowElement>) => {
    if (!editMode || props.row.type === "SECTION_FOOTER_ROW") {
      return;
    }

    if (
      e.relatedTarget instanceof HTMLElement &&
      e.relatedTarget.dataset.type === CONTROL_PANEL_VIEW_DATA_TYPE
    ) {
      return;
    }

    setRowSelected(false);
    hideActionsPanel();
  };

  const handleFieldChange = useEvent((field: ProgramTemplateBlockTableRowField) => {
    props.onCellUpdate(field, props.row.id!, props.row.type);
  });

  const checkFieldIsHidden = (field: ProgramTemplateBlockTableRowField) => {
    // we shouldn't hide the last filler field if there are more visible columns than visible fields
    if (
      field.type === "FILLER" &&
      props.row.fields.at(-1) === field &&
      visibleFieldsCount + 1 < visibleColumnCount // visibleFieldsCount + 1 because zero cell always takes two cell spaces
    ) {
      return false;
    }

    return hiddenFields.some(hiddenField => hiddenField.id === field.id);
  };

  const getColSpan = (field: ProgramTemplateBlockTableRowField): number | undefined => {
    if (props.sectionType === "TABLE") {
      if (props.row.type === "SECTION_HEADER_ROW") {
        return visibleColumnCount;
      }

      if (props.row.type === "SECTION_FOOTER_ROW") {
        if (field.index === 1) {
          return 2;
        }
        if (field.type === "FILLER") {
          return visibleColumnCount - visibleFieldsCount;
        }
      }
    }

    if (props.sectionType === "TABLE_FOOTER") {
      if (field.index === 1) {
        return 2;
      }
      // we should set colSpan only at the last filler field
      if (
        field.type === "FILLER" &&
        props.row.fields.at(-1) === field &&
        visibleFieldsCount + 1 < visibleColumnCount // visibleFieldsCount + 1 because zero cell always takes two cell spaces
      ) {
        return visibleColumnCount - visibleFieldsCount;
      }
    }
  };

  const getHiddenCellProps = (
    field: ProgramTemplateBlockTableRowField
  ): PropsWithKey<TableBodyCellProps["Hidden"]> => {
    return {
      key: field.id,
      field,
      isFullAccess,
      editMode,
    };
  };

  const getVisibleCellProps = (
    field: ProgramTemplateBlockTableRowField,
  ): PropsWithKey<TableBodyCellProps[keyof TableBodyCellProps]> => {
    const baseProps = {
      key: field.id,
      field,
      isFullAccess,
      editMode,
    };

    switch (field.type) {
      case "FILLER": {
        const cellProps: PropsWithKey<TableBodyCellProps["Filler"]> = {
          ...baseProps,
          colSpan: getColSpan(field),
        };
        return cellProps;
      }
      case "INDEX": {
        const cellProps: PropsWithKey<TableBodyCellProps["Index"]> = {
          ...baseProps,
          tableType: props.tableType,
          sectionOrdinalNumber: props.sectionOrdinalNumber,
          rowOrdinalNumber: props.ordinalNumber,
        };
        return cellProps;
      }
      case "WIDGET_STRING": {
        const cellProps: PropsWithKey<TableBodyCellProps["WidgetString"]> = {
          ...baseProps,
          onChange: handleFieldChange,
          colSpan: getColSpan(field),
          isPrograms,
          sectionType: props.sectionType,
          rowType: props.row.type,
          validationStatus: cellValidators.widgetStringCellValidator(field).status,
          sectionOrdinalNumber: props.sectionOrdinalNumber,
        };
        return cellProps;
      }
      case "WIDGET_NUMBER": {
        const cellProps: PropsWithKey<TableBodyCellProps["WidgetNumber"]> = {
          ...baseProps,
          onChange: handleFieldChange,
          isPrograms,
          validationStatus: cellValidators.widgetNumberCellValidator(field, {
            columns: singleColumns,
            sectionType: props.sectionType,
            rowType: props.row.type,
            fields: visibleFields,
          }).status,
        };
        return cellProps;
      }
      case "WIDGET_DATE": {
        const cellProps: PropsWithKey<TableBodyCellProps["WidgetDate"]> = {
          ...baseProps,
          onChange: handleFieldChange,
          isPrograms,
          validationStatus: cellValidators.widgetDateCellValidator(field).status,
          defaultPickerValue: getDefaultDatePickerValue(field.index, props.row.id!),
        };
        return cellProps;
      }
      case "WIDGET_PERIOD": {
        const cellProps: PropsWithKey<TableBodyCellProps["WidgetPeriod"]> = {
          ...baseProps,
          onChange: handleFieldChange,
          isPrograms,
          validationStatus: cellValidators.widgetPeriodCellValidator(field).status,
        };
        return cellProps;
      }
      case "WIDGET_LIST": {
        const cellProps: PropsWithKey<TableBodyCellProps["WidgetList"]> = {
          ...baseProps,
          onChange: handleFieldChange,
          isPrograms,
          validationStatus: cellValidators.widgetListCellValidator(field).status,
          columnWidth: singleColumns.find((column, index) => field.index === index)!.width,
        };
        return cellProps;
      }
      case "WIDGET_REFERENCE": {
        const cellProps: PropsWithKey<TableBodyCellProps["WidgetReference"]> = {
          ...baseProps,
          onChange: handleFieldChange,
          isPrograms,
          validationStatus: cellValidators.widgetReferenceCellValidator(field).status,
          referenceItems: field.listId
            ? references.get(field.listId) || []
            : [],
          referenceItemsLoading: referencesLoading,
          columnWidth: singleColumns.find((column, index) => field.index === index)!.width,
        };
        return cellProps;
      }
      case "WIDGET_SUM": {
        const cellProps: PropsWithKey<TableBodyCellProps["WidgetSum"]> = {
          ...baseProps,
          isPrograms,
          validationStatus: cellValidators.widgetSumCellValidator(field).status,
          isCellSimple: fieldsHelpers.isFieldPracticalWorkHours(
            singleColumns, props.sectionType, props.row.type, field,
          ),
        };
        return cellProps;
      }
      case "WIDGET_PERCENT": {
        const cellProps: PropsWithKey<TableBodyCellProps["WidgetPercent"]> = {
          ...baseProps,
          isPrograms,
          validationStatus: cellValidators.widgetPercentCellValidator(field, {
            columns: singleColumns,
            sectionType: props.sectionType,
            rowType: props.row.type,
            fields: visibleFields,
          }).status,
        };
        return cellProps;
      }
    }
  };

  return [
    {
      isHeaderRowSelected: isRowSelected && props.row.type === "SECTION_HEADER_ROW",
      isBodyRowSelected: isRowSelected && props.row.type === "SECTION_BODY_ROW",
    },
    {
      handleRowClick,
      handleRowPointerEnter,
      handleRowPointerLeave,
      checkFieldIsHidden,
      getHiddenCellProps,
      getVisibleCellProps,
    }
  ] as const;
};