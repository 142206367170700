import cn from "classnames";
import {AppTitle} from "shared/UI/UIKit/Title";
import {AppFlex} from "shared/UI/UIKit/Flex";
import {AppBreadcrumbs} from "shared/UI/UIKit/Breadcrumbs";
import type {AppContentProps} from "./Content.types";
import styles from "./Content.module.scss";

export const AppContent = (props: AppContentProps) => {
  return (
    <main className={cn(styles.content, props.className)}>
      <AppFlex unit="30" className={styles.wrapper}>
        <AppFlex>
          {props.pageTitle && <AppTitle>{props.pageTitle}</AppTitle>}
          {props.breadcrumbs && <AppBreadcrumbs {...props.breadcrumbs}/>}
        </AppFlex>
        {props.children}
      </AppFlex>
    </main>
  );
};
