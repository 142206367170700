import cn from "classnames";
import type {DividerDefaultProps} from "./DividerDefault.types";
import styles from "./DividerDefault.module.scss";

export const getClassNames = (props: DividerDefaultProps) => {
  return cn(
    styles.divider,
    props.className,
    props.vertical && styles["divider--vertical"],
    props.borderStyle && styles[`divider--${props.borderStyle}`],
    props.width && styles[`divider--${props.width}`],
  );
};