import cn from "classnames";
import type {AppIconButtonProps} from "./IconButton.types";
import styles from "./IconButton.module.scss";

export type BaseIconButtonStyleProps = Pick<AppIconButtonProps, "className" | "size" | "type">;

export const getClassNames = ({className, size, type}: BaseIconButtonStyleProps) => {
  return cn(
    styles["icon-button"],
    size && styles[size],
    type === undefined && styles.borderless,
    className,
  );
};