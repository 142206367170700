import {AppFlex} from "shared/UI/UIKit/Flex";
import {AppText} from "shared/UI/UIKit/Text";
import type {CSSProperties} from "react";
import type {AppTableItem} from "../../../../../types";
import type {ColumnCellProps} from "./ColumnCell.types";
import {
  DateRenderer,
  TimeRenderer,
  DateTimeRenderer,
  EmailRenderer,
  LinkRenderer,
  TextRenderer,
  TooltipRenderer
} from "../../../../../../lib/helpers/renderers";
import styles from "./ColumnCell.module.scss";

const DEFAULT_MIN_WIDTH = "5rem";
const DEFAULT_MAX_WIDTH = undefined;
const DEFAULT_MAX_WIDTH_WITH_ELLIPSIS = "20rem";

export const ColumnCell = <TRecord extends AppTableItem>({
  value, record, index, column,
}: ColumnCellProps<TRecord>) => {
  const renderValue = () => {
    const val = value === undefined || value === null ? value : String(value);

    switch (column.type) {
      case "date": return (
        <DateRenderer date={val}/>
      );
      case "time": return (
        <TimeRenderer time={val}/>
      );
      case "datetime": return (
        <DateTimeRenderer date={val}/>
      );
      case "link": return (
        <LinkRenderer href={val}/>
      );
      case "email": return (
        <EmailRenderer email={val}/>
      );
      default: return (
        <TextRenderer text={val}/>
      );
    }
  };

  const renderContent = () => {
    if (column.render) {
      return (
        <>{column.render(value, record, index)}</>
      );
    }

    const content = renderValue();

    return column.ellipsis ? (
      <TooltipRenderer content={content}/>
    ) : content;
  };

  const renderChildrenNumber = () => {
    if (value === undefined || value === null
      || !record._childrenNumber
      || column.showChildrenNumber === false
    ) {
      return null;
    }

    return (
      <AppText className={styles["children-number"]}>
        {`(${record._childrenNumber})`}
      </AppText>
    );
  };

  const getCellStyles = (): CSSProperties => {
    const minWidth = column.minWidth ?? DEFAULT_MIN_WIDTH;
    const maxWidth = column.maxWidth ?? (column.ellipsis ? DEFAULT_MAX_WIDTH_WITH_ELLIPSIS : DEFAULT_MAX_WIDTH);

    return {
      minWidth,
      maxWidth,
      wordBreak: maxWidth ? "break-word" : undefined,
    };
  };

  return (
    <AppFlex display="inline-flex" direction="row" unit="4"
             style={getCellStyles()}>
      {renderContent()}
      {renderChildrenNumber()}
    </AppFlex>
  );
};