import {toOptions} from "shared/helpers/utils/to-options";
import type {ActionReducerMapBuilder} from "@reduxjs/toolkit";
import type {CreateMainProgramState} from "./types";
import {
  createMainProgramAction,
  getCurriculumsByEducationLevelAction,
  getWorkProgramsBySubjectsAction,
  reloadWorkProgramsBySubjectIdAction,
} from "./actions";

export function getCurriculumsByEducationLevelReducerBuilder(builder: ActionReducerMapBuilder<CreateMainProgramState>) {
  builder.addCase(getCurriculumsByEducationLevelAction.pending, (state) => {
    state.loading = true;
  });
  builder.addCase(getCurriculumsByEducationLevelAction.fulfilled, (state, action) => {
    state.allCurriculums = action.payload.items;
    state.allCurriculumsTotal = action.payload.total;
    state.loading = false;
  });
  builder.addCase(getCurriculumsByEducationLevelAction.rejected, (state) => {
    state.loading = false;
  });
}

export function getWorkProgramsBySubjectsReducerBuilder(builder: ActionReducerMapBuilder<CreateMainProgramState>) {
  builder.addCase(getWorkProgramsBySubjectsAction.pending, (state) => {
    state.loading = true;
  });
  builder.addCase(getWorkProgramsBySubjectsAction.fulfilled, (state, action) => {
    state.workProgramsOptionsBySubjectId = action.payload;
    state.loading = false;
  });
  builder.addCase(getWorkProgramsBySubjectsAction.rejected, (state) => {
    state.loading = false;
  });
}

export function reloadWorkProgramsBySubjectIdReducerBuilder(builder: ActionReducerMapBuilder<CreateMainProgramState>) {
  builder.addCase(reloadWorkProgramsBySubjectIdAction.pending, (state) => {
    state.loading = true;
  });
  builder.addCase(reloadWorkProgramsBySubjectIdAction.fulfilled, (state, action) => {
    const {workPrograms, subjectId} = action.payload;
    state.workProgramsOptionsBySubjectId[subjectId] = toOptions(workPrograms, "id", "programName");
    state.loading = false;
  });
  builder.addCase(reloadWorkProgramsBySubjectIdAction.rejected, (state) => {
    state.loading = false;
  });
}

export function createMainProgramReducerBuilder(builder: ActionReducerMapBuilder<CreateMainProgramState>) {
  builder.addCase(createMainProgramAction.pending, (state) => {
    state.loading = true;
  });
  builder.addCase(createMainProgramAction.fulfilled, (state) => {
    state.loading = false;
  });
  builder.addCase(createMainProgramAction.rejected, (state) => {
    state.loading = false;
  });
}
