import {AppRadioGroup} from "shared/UI/UIKit/Radio";
import {AppForm, AppFormItem} from "shared/UI/UIKit/Form";
import type {FormInstance} from "antd/lib/form";
import styles from "./styles.module.scss";
import {EDUCATION_LEVEL_FORM_RULE, EDUCATION_LEVEL_OPTIONS} from "./constants";

type EducationLevelFormProps = {
  form: FormInstance;
}

export const EducationLevelForm = (props: EducationLevelFormProps) => {
  return (
    <AppForm form={props.form} className={styles.form}>
      <AppFormItem rules={[EDUCATION_LEVEL_FORM_RULE]}
                   name="educationLevel">
        <AppRadioGroup options={EDUCATION_LEVEL_OPTIONS}
                       className={styles["radio-group-card"]}/>
      </AppFormItem>
    </AppForm>
  );
};