import {createContext, useContext} from "react";
import type {TableConstructorContextType} from "./types";

const defaultValue: TableConstructorContextType = {
  editMode: false,
  isPrograms: false,
  isFullAccess: false,
  columns: [],
  singleColumns: [],
  visibleColumns: [],
};

export const TableConstructorContext = createContext<TableConstructorContextType>(defaultValue);

export const useTableConstructorContext = () => {
  return useContext(TableConstructorContext);
};