import type {AppIconProps} from "../../Icons.types";
import {getClassNames} from "../../Icons.utils";
import styles from "./ChevronLeft.module.scss";

export const AppChevronLeftIcon = (props: AppIconProps) => {
  const {className, ...rest} = props;
  const classNames = getClassNames(styles.icon, className);

  return (
    <svg {...rest} className={classNames} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9 14" fill="none">
      <path d="M8 1L2 7L8 13" stroke="#737781" strokeWidth="2" strokeLinecap="round"/>
    </svg>
  );
};