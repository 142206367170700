import cn from "classnames";
import {AppBlockingLoader} from "shared/UI/UIKit/Loader";
import type {ModalBlockingLoaderProps} from "./ModalBlockingLoader.types";
import styles from "./ModalBlockingLoader.module.scss";

export const ModalBlockingLoader = ({
  children,
  className,
  loading,
  loadingOpacity,
}: ModalBlockingLoaderProps) => {
  const classNames = cn(styles.blocker, className);

  return (
    <AppBlockingLoader loading={!!loading} loadingOpacity={loadingOpacity}
                       className={classNames}>
      {children}
    </AppBlockingLoader>
  );
};