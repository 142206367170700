import cn from "classnames";
import type {TableHeaderBaseCellProps} from "./types";
import styles from "./styles.module.scss";

export const TableHeaderBaseCell = ({
  isFullAccess,
  editMode,
  hidden,
  className,
  ...rest
}: TableHeaderBaseCellProps) => {
  const increasedPadding = isFullAccess && editMode;
  const classNames = cn(
    "table-constructor-cell",
    hidden && "table-constructor-cell_hidden",
    increasedPadding && styles["cell_increased-pd"],
    className,
  );

  return (
    <th className={classNames} {...rest}/>
  );
};