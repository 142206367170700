import type {AppDatePickerComponent} from "shared/UI/UIKit/DatePicker";
import type {FilterDropdownProps} from "../../types";
import type {TimeFilterFormValue} from "./types";
import {useFilterDropdown} from "../../hooks/use-filter-dropdown";
import {FilterForm} from "../FilterForm";
import {TimeFilterFields} from "./filter-fields";
import {getInitialFormValues, getFilterToSubmit, getFilterToReset} from "./TimeFilterDropdown.utils";

export const TimeFilterDropdown = (props: FilterDropdownProps) => {
  const {form, focusRef, onSubmit, onReset} = useFilterDropdown<TimeFilterFormValue, AppDatePickerComponent>({
    ...props, getInitialFormValues, getFilterToSubmit, getFilterToReset,
  });

  return (
    <FilterForm form={form} filterType={props.filter.type}
                onSubmit={onSubmit} onReset={onReset}>
      <TimeFilterFields form={form} focusRef={focusRef}
                        options={props.filter.options}/>
    </FilterForm>
  );
};