import type {AppTabPaneProps} from "./TabPane.types";
import {getClassNames} from "./TabPane.utils";

export const AppTabPane = ({content, active}: AppTabPaneProps) => {
  const classNames = getClassNames(active);

  return (
    <div className={classNames}>
      {content}
    </div>
  );
};