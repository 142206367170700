import {forwardRef} from "react";
import {AppDropdownMenu} from "shared/UI/UIKit/DropdownMenu";
import type {Menu} from "antd";
import type {DropdownMenuProps} from "./types";
import styles from "./styles.module.scss";

export const DropdownMenu = forwardRef<Menu, DropdownMenuProps>(({menu, ...rest}, ref) => {
  return (
    <AppDropdownMenu trigger={["click"]} placement="bottom"
                     overlayClassName={styles["dropdown-overlay"]}
                     menu={{className: styles["dropdown-menu"], ...menu}}
                     {...rest} ref={ref}/>
  );
});