import type {AppBlockerProps} from "./Blocker.types";
import {useAppBlocker} from "./hooks/use-blocker";
import {getClassNames} from "./Blocker.utils";

export const AppBlocker = (props: AppBlockerProps) => {
  const {
    children,
    visible = true,
    blocking,
    opacity = "medium",
    className,
    icon,
  } = props;
  const service = useAppBlocker(blocking);
  const classNames = getClassNames({blocking, visible, opacity, className});

  const getTopBlocker = () => {
    return blocking && (
      <div tabIndex={0}
           onKeyUp={service.tabbedUpTop}
           onKeyDown={service.tabbedDownTop}
           ref={service.topRef}>
        {icon}
      </div>
    );
  };

  const getBottomBlocker = () => {
    return blocking && (
      <div tabIndex={0}
           onKeyUp={service.tabbedUpBottom}
           onKeyDown={service.tabbedDownBottom}
           ref={service.bottomRef}/>
    );
  };

  return (
    <div className={classNames} aria-busy={blocking}>
      {getTopBlocker()}
      {children}
      {getBottomBlocker()}
    </div>
  );
};