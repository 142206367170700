import cn from "classnames";
import {AppImage} from "shared/UI/UIKit/Image";
import {AppUserIcon} from "shared/UI/UIKit/icons/User";
import type {AvatarProps} from "./types";
import styles from "./Avatar.module.scss";

export const Avatar = (props: AvatarProps) => {
  const {imgClassName, emptyClassName, avatarUrl} = props;
  const imgClassNames = cn(styles.avatar, imgClassName);

  return avatarUrl ? (
    <AppImage className={imgClassNames} src={avatarUrl} alt="avatar"/>
  ) : (
    <AppUserIcon className={emptyClassName}/>
  );
};
