import {useEffect, useRef} from "react";

type Comparator<T> = (prev: T | undefined, next: T) => boolean;

export const useMemoCompare = <T>(next: T, compare: Comparator<T>): T | undefined => {
  const previousRef = useRef<T>();

  const isEqual = compare(previousRef.current, next);

  useEffect(() => {
    if (!isEqual) {
      previousRef.current = next;
    }
  });

  return isEqual ? previousRef.current : next;
};