const updateTableHeaderBorderRadius = ($thead: Element | null) => {
  const $theadCells: NodeListOf<HTMLTableCellElement> | undefined = $thead?.querySelectorAll("tr:first-child th");
  let $firstVisibleCell: HTMLTableCellElement | undefined;
  let $lastVisibleCell: HTMLTableCellElement | undefined;

  $theadCells?.forEach(el => {
    el.classList.remove("table-constructor-cell_first-row-first-visible");
    el.classList.remove("table-constructor-cell_first-row-last-visible");
  });
  $theadCells?.forEach(el => {
    if (!el.classList.contains("table-constructor-cell_hidden")) {
      if (!$firstVisibleCell) {
        $firstVisibleCell = el;
      }
      $lastVisibleCell = el;
    }
  });

  $firstVisibleCell?.classList.add("table-constructor-cell_first-row-first-visible");
  $lastVisibleCell?.classList.add("table-constructor-cell_first-row-last-visible");
};

const updateTableBodyBorderRadius = ($body: Element | null) => {
  const $tbodyCells: NodeListOf<HTMLTableCellElement> | undefined = $body?.querySelectorAll("tr:last-child td");
  let $firstVisibleCell: HTMLTableCellElement | undefined;
  let $lastVisibleCell: HTMLTableCellElement | undefined;

  $tbodyCells?.forEach(el => {
    el.classList.remove("table-constructor-cell_last-row-first-visible");
    el.classList.remove("table-constructor-cell_last-row-last-visible");
  });
  $tbodyCells?.forEach(el => {
    if (!el.classList.contains("table-constructor-cell_hidden")) {
      if (!$firstVisibleCell) {
        $firstVisibleCell = el;
      }
      $lastVisibleCell = el;
    }
  });

  $firstVisibleCell?.classList.add("table-constructor-cell_last-row-first-visible");
  $lastVisibleCell?.classList.add("table-constructor-cell_last-row-last-visible");
};

export const updateTableBorderRadius = (innerTableContainer: HTMLDivElement) => {
  const $thead = innerTableContainer.querySelector(".table-constructor-thead");
  const $tbody = innerTableContainer.querySelector(".table-constructor-tbody");
  updateTableHeaderBorderRadius($thead);
  updateTableBodyBorderRadius($tbody);
};