import {Form} from "antd";
import type {EditableRowProps} from "./EditableRow.types";
import {EditableContext} from "../EditableContext";

export const EditableRow = ({children, ...rest}: EditableRowProps) => {
  const [form] = Form.useForm();

  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...rest}>
          {children}
        </tr>
      </EditableContext.Provider>
    </Form>
  );
};