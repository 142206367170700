import {forwardRef} from "react";
import {Menu} from "antd";
import cn from "classnames";
import type {AppMenuProps} from "./Menu.types";
import {useAppMenu} from "./hooks/use-app-menu";
import {AppMenuProvider} from "./context";
import {ExpandIcon} from "./components/ExpandIcon";
import styles from "./Menu.module.scss";

export const AppMenu = forwardRef<Menu, AppMenuProps>((props, ref) => {
  const {onClick: onClickProp, ...rest} = props;
  const {onClick, openKeys, onOpenChange} = useAppMenu(
    onClickProp, rest.openKeys, rest.onOpenChange,
  );

  const className = cn(styles.menu, props.className);

  return (
    <AppMenuProvider value={{onClick}}>
      <Menu {...rest} selectable={false} ref={ref}
            expandIcon={ExpandIcon}
            openKeys={openKeys}
            onOpenChange={onOpenChange}
            className={className}/>
    </AppMenuProvider>
  );
});