import type {AppFilter} from "../../../types";
import type {FilterFormValues} from "../../types";
import type {TextFilterFormValue} from "./types";
import {AppFilterOperator} from "../../../constants";

const DEFAULT_OPERATOR = AppFilterOperator.CONTAINS;

export const getInitialFormValues = (currentFilter: AppFilter): FilterFormValues<TextFilterFormValue> => ({
  operator: currentFilter.operator ?? DEFAULT_OPERATOR,
  values: (currentFilter.values ?? []).map(value => typeof value === "number" ? String(value) : value),
});

export const getFilterToSubmit = (
  currentFilter: AppFilter, formValues: FilterFormValues<TextFilterFormValue>
): AppFilter => ({
  ...currentFilter,
  operator: formValues.operator,
  /*
    formValues.values will be undefined when the form has no form items.
    This is possible when operator === APP_FILTER_OPERATORS.IS_NULL (cause the only form item is not rendering)
    Thus, to detect a situation when there are no values, but the filter is set, we set values as list with null element
   */
  values: formValues.values ?? [null],
});

export const getFilterToReset = (currentFilter: AppFilter): AppFilter => ({
  ...currentFilter,
  operator: DEFAULT_OPERATOR,
  values: [],
});