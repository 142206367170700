import type {ProgramTemplateBlockTableSettings} from "services/api";

export type TableSettingsName = keyof ProgramTemplateBlockTableSettings;
export type TableSettingsOption = {label: string, value: TableSettingsName};

export const tableSettingsNames: TableSettingsName[] = [
  "canDownloadXlsx",
  // access rights
  "sectionCreationAllowed",
  "programTemplateSectionMovingAllowed",
  "workProgramSectionMovingAllowed",
  "programTemplateSectionDeletionAllowed",
  "workProgramSectionDeletionAllowed",
  "rowCreationAllowed",
  "programTemplateRowMovingAllowed",
  "workProgramRowMovingAllowed",
  "programTemplateRowDeletionAllowed",
  "workProgramRowDeletionAllowed",
];

export const tableSettingsOptions: TableSettingsOption[] = [
  {
    label: "Разрешить экспорт в XLSX",
    value: "canDownloadXlsx",
  },
  // access rights
  {
    label: "Разрешить создание разделов",
    value: "sectionCreationAllowed",
  },
  {
    label: "Разрешить перемещение разделов, созданных в шаблоне программы",
    value: "programTemplateSectionMovingAllowed",
  },
  {
    label: "Разрешить перемещение разделов, созданных в рабочей программе",
    value: "workProgramSectionMovingAllowed",
  },
  {
    label: "Разрешить удаление разделов, созданных в шаблоне программы",
    value: "programTemplateSectionDeletionAllowed",
  },
  {
    label: "Разрешить удаление разделов, созданных в рабочей программе",
    value: "workProgramSectionDeletionAllowed",
  },
  {
    label: "Разрешить создание строк",
    value: "rowCreationAllowed",
  },
  {
    label: "Разрешить перемещение строк, созданных в шаблоне программы",
    value: "programTemplateRowMovingAllowed",
  },
  {
    label: "Разрешить перемещение строк, созданных в рабочей программе",
    value: "workProgramRowMovingAllowed",
  },
  {
    label: "Разрешить удаление строк, созданных в шаблоне программы",
    value: "programTemplateRowDeletionAllowed",
  },
  {
    label: "Разрешить удаление строк, созданных в рабочей программе",
    value: "workProgramRowDeletionAllowed",
  },
];

export const tableSettingsNamesToDTO = (
  settings: TableSettingsName[] = []
): ProgramTemplateBlockTableSettings => {
  return tableSettingsNames.reduce((acc, tableSettingsName) => {
    acc[tableSettingsName] = settings.includes(tableSettingsName);
    return acc;
  }, {} as ProgramTemplateBlockTableSettings);
};

export const DTOTableSettingsToNames = (
  settings: ProgramTemplateBlockTableSettings
): TableSettingsName[] => {
  return Object.entries(settings)
    .filter(([, value]) => !!value)
    .map(([key]) => key as TableSettingsName);
};