import {AppForm} from "shared/UI/UIKit/Form";
import {AppFlex} from "shared/UI/UIKit/Flex";
import {AppButton} from "shared/UI/UIKit/Button";
import type {MainProgramItem,Curriculum} from "services/api";
import type {FormInstance} from "antd/lib/form";
import {CurriculumsList} from "../CurriculumsList";

type ThirdStepProps = {
  selectedCurriculums: Curriculum[];
  form: FormInstance;
  handlePrevStep: () => void;
  handleNextStep: () => void;
  addMainProgramItemToStateByFieldName: (fieldName: string, item: MainProgramItem) => void;
}

export const ThirdStep = (props: ThirdStepProps) => {
  return (
    <AppFlex unit="40">
      <AppForm layout="horizontal"
               colon={false}
               form={props.form}
               scrollToFirstError
               labelAlign="left"
               labelWrap
               labelCol={{span: 10}}
               wrapperCol={{span: 13, offset: 1}}>
        <CurriculumsList addMainProgramItemToStateByFieldName={props.addMainProgramItemToStateByFieldName}
                         curriculums={props.selectedCurriculums}/>
      </AppForm>
      <AppFlex direction="row"
               justifyContent="space-between">
        <AppButton size="large"
                   onClick={props.handlePrevStep}>
          {"<"} Назад
        </AppButton>
        <AppButton size="large"
                   color="success"
                   onClick={props.handleNextStep}>
          Генерировать ООП {">"}
        </AppButton>
      </AppFlex>
    </AppFlex>
  );
};