import {EducationLevel, SubjectObligation} from "services/api";
import {generateUUID} from "shared/helpers/utils/generate-uuid";
import {
  directionOfExtracurricularActivitiesOptions,
  formsOfExtracurricularActivitiesOptions
} from "features/main-program/redux/constants";
import {isBlock} from "features/preview-main-program/components/Preview/utils";
import type {MainProgram, UserProfile, ExtracurricularTableBodyRow} from "services/api";

export const processWidgets = (content: string, isTemplate?: boolean, isPreview?: boolean) => {

  const node = new DOMParser().parseFromString(content, "text/html");
  const placeholderBlock = node.querySelectorAll(".placeholder-block");

  if (placeholderBlock[0]) {
    const placeholdersInsideBlock = placeholderBlock[0].querySelectorAll("[data-widget=\"true\"]");
    // 0, 6, 12 - должность
    // 1, 7, 13 - фио
    // 2, 8, 14 - приказ
    // 3, 9, 15 - число
    // 4, 10, 16 - месяц
    // 5, 11, 17 - год

    placeholdersInsideBlock.forEach(item => {
      item.innerHTML = item.getAttribute("data-value") || item.getAttribute("data-placeholder")!;
    });

    if (!isTemplate) {
      placeholdersInsideBlock.forEach((item, index) => {
        item.setAttribute("data-inside-block", "true");
        if (item.getAttribute("data-value")) {
          return;
        }
        if (item.getAttribute("data-placeholder")) {
          item.innerHTML = item.getAttribute("data-placeholder") || "";
        }
        if (isPreview) {
          if (index === 3 || index === 9 || index === 15) { // число
            item.innerHTML = "&nbsp;".repeat(6);
          } else if (index === 4 || index === 10 || index === 16) { // месяц
            item.innerHTML = "&nbsp;".repeat(20);
          } else if (index === 5 || index === 11 || index === 17) { // год
            item.innerHTML = "&nbsp;".repeat(13);
          } else {
            item.innerHTML = "&nbsp;";
          }
        }
      });
    }
  }

  if (!isTemplate) {
    const placeholders = node.querySelectorAll("[data-widget=\"true\"]");

    placeholders.forEach(item => {
      if (!item.getAttribute("data-value") && !item.getAttribute("data-inside-block") && !isBlock(item.getAttribute("data-type"))) {
        if (item.parentElement) {
          item.parentElement.remove();
        } else {
          item.remove();
        }
      }
    });
  }

  return node.body.innerHTML;
};

export const replaceVariables = (html: string, profile: UserProfile | null, mainProgram: MainProgram | null) => {
  if (!html) {
    return "";
  }

  if (!profile || mainProgram?.isTemplate) {
    return html;
  }

  let newHtml = html;

  const availableVariables = {
    ORGANIZATION_NAME: "%organization_name%",
  };

  newHtml = newHtml.replaceAll(availableVariables.ORGANIZATION_NAME, profile.organization.fullName);

  return newHtml;
};

export const prepareTableContent = (content: string, mainProgram: MainProgram | null): string => {
  if (content && content !== "[]") {
    return content;
  }

  const extracurricularItems = mainProgram?.items.filter(item => item.workProgram.obligation === SubjectObligation.EXTRACURRICULAR);

  if (!extracurricularItems?.length) {
    return "[]";
  }

  let rows: ExtracurricularTableBodyRow[] = [];

  const footerHOORow: ExtracurricularTableBodyRow = {
    rowId: generateUUID(),
    cells: [
      {
        value: "Итого часов:",
        type: "TEXT",
        options: [],
        cellId: generateUUID(),
        colSpan: 3,
        styles: {
          textAlign: "right",
          fontWeight: 700,
        }
      },
      {
        type: "NUMBER",
        options: [],
        cellId: generateUUID(),
        styles: {
          textAlign: "center",
        }
      },
      {
        type: "NUMBER",
        options: [],
        cellId: generateUUID(),
        styles: {
          textAlign: "center",
        }
      },
      {
        type: "NUMBER",
        options: [],
        cellId: generateUUID(),
        styles: {
          textAlign: "center",
        }
      },
      {
        type: "NUMBER",
        options: [],
        cellId: generateUUID(),
        styles: {
          textAlign: "center",
        }
      },
      {
        type: "NUMBER",
        options: [],
        cellId: generateUUID(),
        styles: {
          textAlign: "center",
        }
      },
    ]
  };

  const footerOOORow: ExtracurricularTableBodyRow = {
    rowId: generateUUID(),
    cells: [
      {
        value: "Итого часов:",
        type: "TEXT",
        options: [],
        cellId: generateUUID(),
        colSpan: 3,
        styles: {
          textAlign: "right",
          fontWeight: 700,
        }
      },
      {
        type: "NUMBER",
        options: [],
        cellId: generateUUID(),
        styles: {
          textAlign: "center",
        }
      },
      {
        type: "NUMBER",
        options: [],
        cellId: generateUUID(),
        styles: {
          textAlign: "center",
        }
      },
      {
        type: "NUMBER",
        options: [],
        cellId: generateUUID(),
        styles: {
          textAlign: "center",
        }
      },
      {
        type: "NUMBER",
        options: [],
        cellId: generateUUID(),
        styles: {
          textAlign: "center",
        }
      },
      {
        type: "NUMBER",
        options: [],
        cellId: generateUUID(),
        styles: {
          textAlign: "center",
        }
      },
      {
        type: "NUMBER",
        options: [],
        cellId: generateUUID(),
        styles: {
          textAlign: "center",
        }
      },
    ]
  };

  const footerCOORow: ExtracurricularTableBodyRow = {
    rowId: generateUUID(),
    cells: [
      {
        value: "Итого часов:",
        type: "TEXT",
        options: [],
        cellId: generateUUID(),
        colSpan: 3,
        styles: {
          textAlign: "right",
          fontWeight: 700,
        }
      },
      {
        type: "NUMBER",
        options: [],
        cellId: generateUUID(),
        styles: {
          textAlign: "center",
        }
      },
      {
        type: "NUMBER",
        options: [],
        cellId: generateUUID(),
        styles: {
          textAlign: "center",
        }
      },
      {
        type: "NUMBER",
        options: [],
        cellId: generateUUID(),
        styles: {
          textAlign: "center",
        }
      },
    ]
  };

  switch (mainProgram?.educationLevel) {
    case EducationLevel.НОО:
      // 8 колонок
      rows = extracurricularItems.map((item) => {
        return {
          rowId: generateUUID(),
          cells: [
            {
              value: "",
              type: "LIST",
              options: directionOfExtracurricularActivitiesOptions,
              editable: true,
              cellId: generateUUID(),
            },
            {
              value: item.customSubjectName || item.baseSubjectName || "",
              type: "TEXT",
              options: [],
              cellId: generateUUID(),
            },
            {
              value: [],
              type: "LIST",
              options: formsOfExtracurricularActivitiesOptions,
              multipleSelect:true,
              editable: true,
              cellId: generateUUID(),
            },
            {
              type: "NUMBER",
              options: [],
              editable: true,
              cellId: generateUUID(),
              styles: {
                textAlign: "center",
              },
              factor: 33
            },
            {
              type: "NUMBER",
              options: [],
              editable: true,
              styles: {
                textAlign: "center",
              },
              cellId: generateUUID()
            },
            {
              type: "NUMBER",
              options: [],
              editable: true,
              styles: {
                textAlign: "center",
              },
              cellId: generateUUID()
            },
            {
              type: "NUMBER",
              options: [],
              editable: true,
              styles: {
                textAlign: "center",
              },
              cellId: generateUUID()
            },
            {
              type: "SUM",
              options: [],
              styles: {
                textAlign: "center",
              },
              cellId: generateUUID()
            },
          ]
        };
      });
      rows.push(footerHOORow);
      break;
    case EducationLevel.ООО:
      // 9 колонок
      rows = extracurricularItems.map((item) => {
        return {
          rowId: generateUUID(),
          cells: [
            {
              value: "",
              type: "LIST",
              options: directionOfExtracurricularActivitiesOptions,
              editable: true,
              cellId: generateUUID(),
            },
            {
              value: item.customSubjectName || item.baseSubjectName || "",
              type: "TEXT",
              options: [],
              cellId: generateUUID(),
            },
            {
              value: [],
              type: "LIST",
              options: formsOfExtracurricularActivitiesOptions,
              multipleSelect: true,
              editable: true,
              cellId: generateUUID(),
            },
            {
              type: "NUMBER",
              options: [],
              editable: true,
              cellId: generateUUID(),
              styles: {
                textAlign: "center",
              },
            },
            {
              type: "NUMBER",
              options: [],
              editable: true,
              cellId: generateUUID(),
              styles: {
                textAlign: "center",
              },
            },
            {
              type: "NUMBER",
              options: [],
              editable: true,
              cellId: generateUUID(),
              styles: {
                textAlign: "center",
              },
            },
            {
              type: "NUMBER",
              options: [],
              editable: true,
              cellId: generateUUID(),
              styles: {
                textAlign: "center",
              },
            },
            {
              type: "NUMBER",
              options: [],
              editable: true,
              cellId: generateUUID(),
              styles: {
                textAlign: "center",
              },
            },
            {
              type: "SUM",
              options: [],
              cellId: generateUUID(),
              styles: {
                textAlign: "center",
              },
            },
          ]
        };
      });
      rows.push(footerOOORow);
      break;
    case EducationLevel.СОО:
      // 6 колонок
      rows = extracurricularItems.map((item) => {
        return {
          rowId: generateUUID(),
          cells: [
            {
              value: "",
              type: "LIST",
              options: directionOfExtracurricularActivitiesOptions,
              editable: true,
              cellId: generateUUID(),
            },
            {
              value: item.customSubjectName || item.baseSubjectName || "",
              type: "TEXT",
              options: [],
              cellId: generateUUID(),
            },
            {
              value: [],
              type: "LIST",
              editable: true,
              options: formsOfExtracurricularActivitiesOptions,
              multipleSelect: true,
              cellId: generateUUID(),
            },
            {
              type: "NUMBER",
              options: [],
              editable: true,
              cellId: generateUUID(),
              styles: {
                textAlign: "center",
              },
            },
            {
              type: "NUMBER",
              options: [],
              editable: true,
              cellId: generateUUID(),
              styles: {
                textAlign: "center",
              },
            },
            {
              type: "SUM",
              options: [],
              cellId: generateUUID(),
              styles: {
                textAlign: "center",
              },
            }
          ]
        };
      });
      rows.push(footerCOORow);
      break;
  }

  return JSON.stringify(rows);
};

export const calculateSum = (rows: ExtracurricularTableBodyRow[]) => {
  // считаем сумму по горизонтали
  for (let i = 0; i < rows.length - 1; i++) {
    const numberCells = rows[i].cells.filter(cell => cell.type === "NUMBER");

    const sum = numberCells.reduce((acc, item) => {
      const factor = item.factor || 34;

      const value = item.value as number || 0;
      acc = acc + (value * factor);

      return acc;
    }, 0);

    rows[i].cells[rows[i].cells.length - 1].value = sum > 0 ? sum : undefined;
  }

  // считаем сумму по вертикали
  const lastRow = rows[rows.length - 1];
  for (let i = 1; i < lastRow.cells.length; i++) {
    lastRow.cells[i].value = 0;
  }

  for (let i = 0; i < rows.length - 1; i++) {
    const row = rows[i];

    for (let j = 3; j < row.cells.length; j++) {
      const cell = row.cells[j];

      const newValue = (lastRow.cells[j - 2].value as number || 0) + (cell.value as number || 0);

      lastRow.cells[j - 2].value = newValue > 0 ? newValue : undefined;
    }

  }

  return [...rows];
};