import {useState} from "react";
import type {AppMenuProps} from "../Menu.types";

export const useAppMenu = (
  onClickProp?: AppMenuProps["onClick"],
  openKeysProp?: AppMenuProps["openKeys"],
  onOpenChangeProp?: AppMenuProps["onOpenChange"],
) => {
  const [_openKeys, _setOpenKeys] = useState<string[]>([]);

  if ((openKeysProp && !onOpenChangeProp) || (!openKeysProp && onOpenChangeProp)) {
    throw new Error("openKeys prop should be passed with onOpenChange prop");
  }

  const openKeys = openKeysProp ?? _openKeys;
  const onOpenChange = onOpenChangeProp ?? _setOpenKeys;

  const onClick: AppMenuProps["onClick"] = (info) => {
    if (info.shouldCloseMenu) {
      onOpenChange([]);
    }
    onClickProp?.(info);
  };

  return {
    openKeys,
    onOpenChange,
    onClick,
  };
};