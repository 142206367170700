export const clearWordNumberedList = (node: Document): void => {
  const numberedListItems = node.querySelectorAll("ol > li");

  numberedListItems.forEach((item) => {
    item.removeAttribute("style");
    const content = (item as HTMLElement).innerText;
    const regexp = /^\d+\.\s/;

    if (regexp.test(content)) {
      const clearedContent = content.replace(regexp, "");

      if (item.children.length) {
        item.children[0].innerHTML = clearedContent;
        Array.from(item.children).forEach((child, index) => {
          if (index) {
            child.remove();
          }
        });
      } else {
        item.innerHTML = clearedContent;
      }
    }
  });
};