import type {AppIconProps} from "../Icons.types";
import {getClassNames} from "../Icons.utils";
import styles from "./Edit.module.scss";

export const AppEditIcon = (props: AppIconProps) => {
  const {className, ...rest} = props;
  const classNames = getClassNames(styles.icon, className);

  return (
    <svg {...rest} className={classNames} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 21" fill="none">
      <path d="M18.2506 7.52251L7.35451 18.4186L1.38829 19.6118L2.58153 13.6456L13.4775 2.74954C14.7956 1.43153 16.9325 1.43153 18.2506 2.74954C19.5686 4.06757 19.5686 6.2045 18.2506 7.52251Z" stroke="#737781" strokeWidth="2"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M7.90914 19.455L1.60892 20.715C0.821728 20.8724 0.127693 20.1784 0.285137 19.3912L1.54518 13.091L1.90051 12.7357L8.26453 19.0996L7.90914 19.455Z" fill="#737781"/>
    </svg>
  );
};