export const omit = <T extends object, K extends keyof T>(obj: T, fields: K[] | K): Omit<T, K> => {
  const clone = {...obj};

  if (Array.isArray(fields)) {
    fields.forEach(key => {
      delete clone[key];
    });
  } else {
    delete clone[fields];
  }

  return clone;
};