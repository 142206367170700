import {AppText} from "shared/UI/UIKit/Text";
import type {ProgramTemplateBlockTableRowField} from "services/api";
import {FIELD_CONSTANTS} from "../../../../../lib/constants";
import styles from "./styles.module.scss";

export const periodValueRenderer = (
  value: ProgramTemplateBlockTableRowField["value"],
  placeholder: ProgramTemplateBlockTableRowField["placeholder"],
  isWidget: boolean,
) => {
  const showValue = !!value || !isWidget;

  const getValue = () => {
    const [from, to] = (value || "").split(FIELD_CONSTANTS.MULTIPLE_VALUE_SPLITTER);

    if (!from && !to) {
      return "";
    }

    return `${from} - ${to}`;
  };

  return (
    <AppText size={16} className={styles.text}>
      {showValue ? getValue() : placeholder}
    </AppText>
  );
};