import {forwardRef} from "react";
import {Typography} from "antd";
import type {AppTextProps} from "./Text.types";
import {getClassNames} from "./Text.utils";

export const AppText = forwardRef<HTMLDivElement, AppTextProps>((props, ref) => {
  const {className, size = 14, weight, ...rest} = props;
  const classNames = getClassNames(size, weight, className);

  return (
    <Typography.Paragraph {...rest} className={classNames} ref={ref}>
      {props.children}
    </Typography.Paragraph>
  );
});