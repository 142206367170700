import cn from "classnames";
import type {AppBlockerProps} from "./Blocker.types";
import styles from "./Blocker.module.scss";

type GetClassNamesArgs = {
  blocking: boolean;
  visible?: boolean;
  opacity?: AppBlockerProps["opacity"];
  className?: string;
}

export const getClassNames = ({blocking, visible, opacity, className}: GetClassNamesArgs) => {
  return cn(
    styles.blocker,
    blocking && styles["blocker_blocking"],
    visible && styles["blocker_visible"],
    opacity && styles[`blocker_opacity_${opacity}`],
    className,
  );
};