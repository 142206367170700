import cn from "classnames";
import {memo, useState} from "react";
import type {FocusEvent, KeyboardEvent} from "react";
import type {TableWidgetPeriodCellProps} from "./types";
import type {PeriodValueEditorSubmitValues} from "../../cell-value-editors/period-value-editor";
import {visibility} from "../../../../../lib/core";
import {periodValueRenderer} from "../../cell-value-renderers/period-value-renderer";
import {PeriodValueEditor} from "../../cell-value-editors/period-value-editor";
import widgetCellStyles from "../../styles/widget.module.scss";
import {TableBaseCell} from "../TableBaseCell";
import {LockedIcon} from "../LockedIcon";
import widgetPeriodCellStyles from "./styles.module.scss";

export const TableWidgetPeriodCell = memo(({
  field,
  onChange,
  isPrograms,
  isFullAccess,
  editMode,
  validationStatus,
}: TableWidgetPeriodCellProps) => {
  const [isEditorEnabled, setEditorEnabled] = useState(false);
  const isCellEditable = editMode && (isFullAccess || !field.readonly);
  const isCellHighlightable = editMode || !isPrograms;
  const isCellWidget = isCellEditable || (!editMode && !isPrograms && !field.readonly);
  const isCellWidgetError = isCellHighlightable && validationStatus === "error";
  const isLockedIconVisible = visibility.checkTableCellLockedIconVisible({editMode, isFullAccess});
  const cellClassName = cn(
    isCellWidget && widgetCellStyles["widget-cell"],
    isCellWidgetError && widgetCellStyles["widget-cell_error"],
    isEditorEnabled && widgetPeriodCellStyles["widget-period-cell_edit"],
  );

  const handleCellClick = () => {
    if (isCellEditable) {
      setEditorEnabled(true);
    }
  };

  const handleCellBlur = (e: FocusEvent<HTMLTableCellElement>) => {
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setEditorEnabled(false);
    }
  };

  const handleEditorKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      setEditorEnabled(false);
    }
  };

  const handleEditorSubmit = (editorValues: PeriodValueEditorSubmitValues) => {
    onChange({...field, value: editorValues.value});
  };

  const handleLockedIconToggle = (nextLocked: boolean) => {
    onChange({...field, readonly: nextLocked});
  };

  return (
    <TableBaseCell isFullAccess={isFullAccess} editMode={editMode}
                   index={field.index} editable={isCellEditable} className={cellClassName}
                   onClick={handleCellClick} onBlur={handleCellBlur}>
      {isEditorEnabled
        ? (
          <PeriodValueEditor onSubmit={handleEditorSubmit} onKeyDown={handleEditorKeyDown}
                             value={{
                               initialValue: field.value,
                               placeholder: field.placeholder,
                               required: !isFullAccess && !!field.required,
                             }}/>
        ) : (
          <>
            {isLockedIconVisible && <LockedIcon locked={!!field.readonly} toggle={handleLockedIconToggle}/>}
            {periodValueRenderer(field.value, field.placeholder, isCellWidget)}
          </>
        )
      }
    </TableBaseCell>
  );
});