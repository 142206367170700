import {errorMessages} from "shared/UI/UIKit/Form";

export const notRequiredLinkValidator = () => {
  return (_: any, value: string) => {
    if (!value) {
      return Promise.resolve();
    }

    try {
      new URL(value);
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(errorMessages.linkError);
    }
  };
};