import moment from "moment";
import {dateValidator} from "shared/UI/UIKit/Form";
import type {DateValueEditorFormValues, DateValueEditorSubmitValues} from "./types";
import {FIELD_CONSTANTS} from "../../../../../lib/constants";

export const getInitialFormValues = (values: DateValueEditorSubmitValues): DateValueEditorFormValues => {
  return {
    value: !values.value ? null : moment(values.value, FIELD_CONSTANTS.VALUE_DATE_FORMAT),
  };
};

export const getSubmitValues = (values: DateValueEditorFormValues): DateValueEditorSubmitValues => {
  return {
    value: !values.value ? null : values.value.format(FIELD_CONSTANTS.VALUE_DATE_FORMAT),
  };
};

export const formRules = {
  getValueValidator: (required: boolean) => {
    return {
      validator: dateValidator(required),
    };
  }
};

export const formFieldValidators = {
  checkValueValid: async (value: DateValueEditorFormValues["value"], required: boolean) => {
    const {validator} = formRules.getValueValidator(required);
    return await validator(undefined, value);
  }
};