import cn from "classnames";
import {forwardRef} from "react";
import type {ModalWrapperProps} from "./ModalWrapper.types";
import {getWrapperPlacement} from "./ModalWrapper.utils";
import styles from "./ModalWrapper.module.scss";

export const ModalWrapper = forwardRef<HTMLDivElement, ModalWrapperProps>(({
  children,
  placement = "center",
  className,
  style,
  onKeyDown,
  modalStatus,
}, ref) => {
  const wrapperPlacement = getWrapperPlacement(placement);
  const classNames = cn(
    styles.wrapper,
    styles[`wrapper_placement_${wrapperPlacement}`],
    styles[`wrapper_${modalStatus.name}_${modalStatus.stage}`],
    className,
  );

  return (
    <div ref={ref} tabIndex={-1} className={classNames} style={style} onKeyDown={onKeyDown}>
      {children}
    </div>
  );
});