import {BACK_END_DATE_TIME_FORMAT, USER_DATE_TIME_FORMAT_MIN} from "shared/constants/date";
import type {DateTimeRendererProps} from "../types";
import {DateRenderer} from "./DateRenderer";

export const DateTimeRenderer = ({date, ...rest}: DateTimeRendererProps) => {
  return (
    <DateRenderer date={date}
                  sourceFormat={BACK_END_DATE_TIME_FORMAT}
                  targetFormat={USER_DATE_TIME_FORMAT_MIN}
                  {...rest}/>
  );
};