import type {OptionalType} from "shared/types";
import type {AppMenuItem} from "../../../ui/Menu.types";
import type {TextMenuItemProps, TextMenuItemConfirmProps} from "../../components/TextMenuItem";
import {TextMenuItem} from "../../components/TextMenuItem";

type TextMenuItemConfirmOptions = OptionalType<
  TextMenuItemConfirmProps,
  "shouldCloseMenuOnConfirm" | "shouldCloseMenuOnCancel"
>;

export type TextMenuItemOptions = {
  key: TextMenuItemProps["id"],
  label: TextMenuItemProps["label"],
  className?: string,
  wrapperClassName?: string;
  icon?: TextMenuItemProps["icon"],
  disabled?: TextMenuItemProps["disabled"],
  shouldCloseMenu?: TextMenuItemProps["shouldCloseMenu"],
  onClick?: TextMenuItemProps["onClick"],
  ellipsis?: TextMenuItemProps["ellipsis"],
  confirm?: TextMenuItemConfirmOptions,
}

export const getTextMenuItem = ({
  key,
  label,
  className,
  wrapperClassName,
  icon,
  disabled,
  shouldCloseMenu = false,
  onClick,
  ellipsis = {tooltip: label},
  confirm,
}: TextMenuItemOptions): AppMenuItem => {
  const getConfirm = () => {
    return confirm ? {
      ...confirm,
      shouldCloseMenuOnConfirm: confirm.shouldCloseMenuOnConfirm ?? false,
      shouldCloseMenuOnCancel: confirm.shouldCloseMenuOnCancel ?? false,
    } : undefined;
  };

  const getLabel = () => {
    return (
      <TextMenuItem id={key}
                    className={className}
                    label={label}
                    icon={icon}
                    disabled={disabled}
                    onClick={onClick}
                    shouldCloseMenu={shouldCloseMenu}
                    ellipsis={ellipsis}
                    confirm={getConfirm()}/>
    );
  };

  return {
    key,
    label: getLabel(),
    className: wrapperClassName,
    disabled,
  };
};