import Checkbox from "antd/es/checkbox";
import {forwardRef} from "react";
import type {AppCheckboxProps} from "./Checkbox.types";
import {getClassName} from "./Checkbox.utils";

export const AppCheckbox = forwardRef<HTMLInputElement, AppCheckboxProps>(
  (props, ref) => {

    const {type = "default", className, ...rest} = props;
    const classNames = getClassName(className, type);

    return <Checkbox {...rest} className={classNames} ref={ref}/>;
  });
