import cn from "classnames";
import isNumeric from "antd/es/_util/isNumeric";
import type {CurriculumDetailedTableRow} from "services/api";
import styles from "./styles.module.scss";

type CurriculumRowProps = {
  Tag: "td" | "th";
  row: CurriculumDetailedTableRow;
  isFirstRow?: boolean;
}

export const CurriculumRow = ({Tag, row, isFirstRow}: CurriculumRowProps) => {
  return (
    <tr>
      {
        row.cells?.map((cell, index) => {
          const textColumnClassName = isFirstRow && index <= 1 && styles.textColumn;
          const parentCellForGradesClassName = isFirstRow && cell.colSpan > 1 && styles.parentCellForGrades;

          return (
            <Tag colSpan={cell.colSpan || 1}
                 rowSpan={cell.rowSpan || 1}
                 key={cell.id}
                 className={cn(
                   styles.cell,
                   cell.bold && styles.boldCell,
                   textColumnClassName,
                   parentCellForGradesClassName,
                   isNumeric(cell.value) && styles.center,
                 )}>
              {cell.value}
            </Tag>
          );
        })
      }
    </tr>
  );
};