import {forwardRef} from "react";
import type {AppExternalLinkProps} from "./ExternalLink.types";
import {getClassNames} from "./ExternalLink.utils";

export const AppExternalLink = forwardRef<HTMLAnchorElement, AppExternalLinkProps>(
  (props, ref) => {
    const {size, children, className} = props;
    const classNames = getClassNames({size, className});

    return (
      <a {...props} className={classNames} ref={ref}>
        {children}
      </a>
    );
  }
);