import {getMinMaxObjectValue} from "shared/helpers/utils/arrays/get-min-max-value";
import {getProgramTemplateBlockTableSectionDTO} from "services/api/blocks/dto";
import type {ProgramTemplateBlockTable, ProgramTemplateBlockTableSection} from "services/api";
import {indexHelpers, sectionsHelpers} from "../../helpers";

export const createSection = (
  table: ProgramTemplateBlockTable,
  isPrograms: boolean,
): ProgramTemplateBlockTableSection[] => {
  const nextSections = [...table.sections];
  const [, maxSectionIndex] = getMinMaxObjectValue(nextSections, section => section.index, -1);

  const footerSection = nextSections.find(section => section.type === "TABLE_FOOTER")!;
  footerSection.index++;
  const newSection = sectionsHelpers.createTableSection(table.id, maxSectionIndex, isPrograms);
  nextSections.push(newSection);

  return nextSections;
};

export const insertSection = (
  table: ProgramTemplateBlockTable,
  isPrograms: boolean,
  sectionId: string | number,
  placement: "above" | "below",
): ProgramTemplateBlockTableSection[] => {
  const nextSections = [...table.sections];
  const currentSectionIndex = nextSections.findIndex(section => section.id === sectionId);

  if (placement === "above") {
    const newSection = sectionsHelpers.createTableSection(
      table.id, nextSections[currentSectionIndex].index, isPrograms
    );
    sectionsHelpers.incrementSectionIndexes(nextSections, currentSectionIndex);
    nextSections.push(newSection);
  } else {
    const newSection = sectionsHelpers.createTableSection(
      table.id, nextSections[currentSectionIndex].index + 1, isPrograms
    );
    sectionsHelpers.incrementSectionIndexes(nextSections, currentSectionIndex + 1);
    nextSections.push(newSection);
  }

  nextSections[currentSectionIndex] = getProgramTemplateBlockTableSectionDTO(nextSections[currentSectionIndex]);

  return nextSections;
};

export const copySection = (
  table: ProgramTemplateBlockTable,
  isPrograms: boolean,
  sectionId: string | number,
): ProgramTemplateBlockTableSection[] => {
  const nextSections = [...table.sections];
  const currentSectionIndex = nextSections.findIndex(section => section.id === sectionId);
  const newSectionIndex = nextSections[currentSectionIndex].index + 1;
  const newSection = sectionsHelpers.copyTableSection(
    nextSections[currentSectionIndex], newSectionIndex, isPrograms
  );
  sectionsHelpers.incrementSectionIndexes(nextSections, currentSectionIndex + 1);
  nextSections.push(newSection);

  return nextSections;
};

export const moveSection = (
  table: ProgramTemplateBlockTable,
  sectionId: string | number,
  direction: "above" | "below",
): ProgramTemplateBlockTableSection[] | undefined => {
  if (!sectionsHelpers.checkMoreThanOneTableSectionExists(table.sections)) {
    return;
  }

  const nextSections = [...table.sections];
  const currentSectionIndex = nextSections.findIndex(section => section.id === sectionId);

  if (direction === "above") {
    const sectionIndexAbove = sectionsHelpers.getTableSectionIndexAbove(nextSections, currentSectionIndex - 1);
    if (sectionIndexAbove > currentSectionIndex) {
      sectionsHelpers.makeLowestTableSectionIndexHighest(nextSections, currentSectionIndex, sectionIndexAbove);
    } else {
      indexHelpers.swapIndexes(nextSections.at(currentSectionIndex)!, nextSections.at(sectionIndexAbove)!);
    }
  } else {
    const sectionIndexBelow = sectionsHelpers.getTableSectionIndexBelow(nextSections, currentSectionIndex + 1);
    if (sectionIndexBelow < currentSectionIndex) {
      sectionsHelpers.makeHighestTableSectionIndexLowest(nextSections, sectionIndexBelow, currentSectionIndex);
    } else {
      indexHelpers.swapIndexes(nextSections.at(currentSectionIndex)!, nextSections.at(sectionIndexBelow)!);
    }
  }

  return nextSections;
};

export const removeSection = (
  table: ProgramTemplateBlockTable,
  sectionId: string | number,
): ProgramTemplateBlockTableSection[] => {
  const nextSections = [...table.sections];
  const currentSectionIndex = nextSections.findIndex(section => section.id === sectionId);
  nextSections.splice(currentSectionIndex, 1);

  return nextSections;
};