import type {TableEditorWrapperProps} from "./types";
import {BlockTitle} from "../BlockTitle";
import {AddTableButton} from "../AddTableButton";
import {useTableEditorWrapper} from "./hooks/use-table-editor-wrapper";
import styles from "./styles.module.scss";

export const TableEditorWrapper = (props: TableEditorWrapperProps) => {
  const [
    {
      isBlockSettingsControlsVisible,
      isAddTableButtonVisible,
    },
    {
      handleAddTableBtnClick,
      blockSettingsModalMethods,
    }
  ] = useTableEditorWrapper(props);

  return (
    <>
      <div className={styles.editor}>
        <BlockTitle title={props.block.settings.title}
                    isControlsVisible={isBlockSettingsControlsVisible}
                    onEditModalShow={blockSettingsModalMethods.show}/>
        {props.children}
        {isAddTableButtonVisible && (
          <AddTableButton onItemClick={handleAddTableBtnClick}/>
        )}
      </div>
    </>
  );
};