import cn from "classnames";
import {AppChevronBottomIcon} from "shared/UI/UIKit/icons/Chevron";
import type {SvgProps} from "shared/types";
import styles from "../styles.module.scss";

export const MoveBelowIcon = ({className, ...rest}: SvgProps) => {
  return (
    <AppChevronBottomIcon className={cn(styles.icon, styles["icon_size_24"], className)}
                          {...rest}/>
  );
};