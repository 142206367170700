import type {AppFormRule, AppFormRuleObject, AppFormRuleRender,AppFormRules} from "./types";
import {
  arrayValidator,
  dateRangeValidator,
  booleanValidator,
  emailValidator,
  passwordValidator,
  notRequiredLinkValidator
} from "../validators";
import {errorMessages} from "../error-messages";

export const formRules: AppFormRules = {
  required: [{required: true, message: errorMessages.required, whitespace: true}],
  requiredBlock: [{required: true, message: errorMessages.requiredBlock, whitespace: true}],
  requiredNumber: [{type: "number", required: true, message: errorMessages.requiredNumber}],
  requiredStrNumber: [{required: true, message: errorMessages.requiredNumber}],
  requiredBoolean: [{required: true, validator: booleanValidator()}],
  requiredDate: [{type: "date", required: true, message: errorMessages.requiredDate}],
  requiredDateRange: [{validator: dateRangeValidator()}],
  requiredTime: [{type: "date", required: true, message: errorMessages.requiredTime}],
  requiredEmail: [{required: true, validator: emailValidator()}],
  requiredArray: [{validator: arrayValidator()}],
  requiredPassword: [{required: true, validator: passwordValidator()}],
  notRequiredLink: [{required: false, validator: notRequiredLinkValidator()}],
};

export type {AppFormRule, AppFormRuleObject, AppFormRuleRender};