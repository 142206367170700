import type {ProgramTemplateBlockTableSettings} from "services/api";
import type {SelectedRow, ActionTypes} from "../../types";
import type {GetTableActionNamesOptions} from "./types";

export const getTableActionNames = ({
  editMode,
  isFullAccess,
}: GetTableActionNamesOptions): ActionTypes.TableActionName[] => {
  if (!editMode) {
    return [];
  }

  const actions: {name: ActionTypes.TableActionName, visible: boolean}[] = [
    {
      name: "tableSettings",
      visible: true,
    },
    {
      name: "tableRemove",
      visible: isFullAccess,
    },
  ];

  return actions.filter(action => action.visible).map(action => action.name);
};

export const getModuleActionNames = (
  row: SelectedRow,
  isFullAccess: boolean,
  settings: ProgramTemplateBlockTableSettings,
): ActionTypes.ModuleActionName[] => {
  const actions: {name: ActionTypes.ModuleActionName, visible: boolean}[] = [
    {
      name: "moduleMove",
      visible: (
        isFullAccess ||
        (!row.meta.section.createdOnWorkProgram && !!settings.programTemplateSectionMovingAllowed) ||
        (!!row.meta.section.createdOnWorkProgram && !!settings.workProgramSectionMovingAllowed)
      ),
    },
    {
      name: "moduleInsert",
      visible: isFullAccess || !!settings.sectionCreationAllowed,
    },
    {
      name: "moduleCopy",
      visible: isFullAccess || !!settings.sectionCreationAllowed,
    },
    {
      name: "moduleRemove",
      visible: (
        isFullAccess ||
        (!row.meta.section.createdOnWorkProgram && !!settings.programTemplateSectionDeletionAllowed) ||
        (!!row.meta.section.createdOnWorkProgram && !!settings.workProgramSectionDeletionAllowed)
      ),
    },
  ];

  return actions.filter(action => action.visible).map(action => action.name);
};

export const getSectionActionNames = (
  row: SelectedRow,
  isFullAccess: boolean,
  settings: ProgramTemplateBlockTableSettings,
): ActionTypes.SectionActionName[] => {
  const actions: {name: ActionTypes.SectionActionName, visible: boolean}[] = [
    {
      name: "sectionMove",
      visible: (
        isFullAccess ||
        (!row.meta.section.createdOnWorkProgram && !!settings.programTemplateSectionMovingAllowed) ||
        (!!row.meta.section.createdOnWorkProgram && !!settings.workProgramSectionMovingAllowed)
      ),
    },
    {
      name: "sectionInsert",
      visible: isFullAccess || !!settings.sectionCreationAllowed,
    },
    {
      name: "sectionCopy",
      visible: isFullAccess || !!settings.sectionCreationAllowed,
    },
    {
      name: "sectionRemove",
      visible: (
        isFullAccess ||
        (!row.meta.section.createdOnWorkProgram && !!settings.programTemplateSectionDeletionAllowed) ||
        (!!row.meta.section.createdOnWorkProgram && !!settings.workProgramSectionDeletionAllowed)
      ),
    },
  ];

  return actions.filter(action => action.visible).map(action => action.name);
};

export const getRowActionNames = (
  row: SelectedRow,
  isFullAccess: boolean,
  settings: ProgramTemplateBlockTableSettings,
): ActionTypes.RowActionName[] => {
  if (!isFullAccess && row.meta.section.type === "TABLE_FOOTER") {
    return [];
  }

  const actions: {name: ActionTypes.RowActionName, visible: boolean}[] = [
    {
      name: "rowMove",
      visible: (
        isFullAccess ||
        (!row.meta.row.createdOnWorkProgram && !!settings.programTemplateRowMovingAllowed) ||
        (!!row.meta.row.createdOnWorkProgram && !!settings.workProgramRowMovingAllowed)
      ),
    },
    {
      name: "rowInsert",
      visible: isFullAccess || !!settings.rowCreationAllowed,
    },
    {
      name: "rowCopy",
      visible: isFullAccess || !!settings.rowCreationAllowed,
    },
    {
      name: "rowRemove",
      visible: (
        isFullAccess ||
        (!row.meta.row.createdOnWorkProgram && !!settings.programTemplateRowDeletionAllowed) ||
        (!!row.meta.row.createdOnWorkProgram && !!settings.workProgramRowDeletionAllowed)
      ),
    },
  ];

  return actions.filter(action => action.visible).map(action => action.name);
};