import type {AppIconProps} from "../Icons.types";
import {getClassNames} from "../Icons.utils";
import styles from "./Delete.module.scss";

export const AppDeleteIcon = (props: AppIconProps) => {
  const {className, ...rest} = props;
  const classNames = getClassNames(styles.icon, className);

  return (
    <svg {...rest} className={classNames} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 18" fill="none">
      <rect y="2.53333" rx="1" fill="#737781"/>
      <path d="M3.93341 16.5L3.07134 4H12.9287L12.0666 16.5L3.93341 16.5Z" stroke="#737781" strokeWidth="2"/>
      <path d="M10.2514 2H5.65649V1L10.2514 1V2Z" stroke="#737781" strokeWidth="2"/>
    </svg>
  );
};