import {
  getProgramTemplateBlockTableRowDTO,
  getProgramTemplateBlockTableRowFieldDTO,
  getProgramTemplateBlockTableSectionDTO
} from "services/api/blocks/dto";
import {generateUUID} from "shared/helpers/utils/generate-uuid";
import type {ProgramTemplateBlockTable, ProgramTemplateBlockTableSection} from "services/api";
import {rowsHelpers} from "../rows";

export const createTableSection = (
  tableId: ProgramTemplateBlockTable["id"],
  sectionIndex: ProgramTemplateBlockTable["index"],
  createdOnWorkProgram: boolean,
) => {
  return getProgramTemplateBlockTableSectionDTO({
    id: generateUUID("fake_"),
    table: {id: tableId},
    index: sectionIndex,
    type: "TABLE",
    createdOnWorkProgram,
    rows: [
      getProgramTemplateBlockTableRowDTO({
        id: generateUUID("fake_"),
        section: null,
        index: 0,
        type: "SECTION_HEADER_ROW",
        createdOnWorkProgram,
        fields: [
          getProgramTemplateBlockTableRowFieldDTO({
            id: generateUUID("fake_"),
            row: null,
            index: 0,
            type: "WIDGET_STRING",
            value: "**[[Название]]**",
            required: true,
            readonly: true,
            placeholder: "Введите название раздела",
          }),
        ],
      }),
      getProgramTemplateBlockTableRowDTO({
        id: generateUUID("fake_"),
        section: null,
        index: 1,
        type: "SECTION_FOOTER_ROW",
        createdOnWorkProgram,
        fields: [
          getProgramTemplateBlockTableRowFieldDTO({
            id: generateUUID("fake_"),
            row: null,
            index: 1,
            type: "WIDGET_STRING",
            value: "**[[Итого]]**",
            required: true,
            readonly: true,
            placeholder: "Введите значение",
          }),
          getProgramTemplateBlockTableRowFieldDTO({
            id: generateUUID("fake_"),
            row: null,
            index: 2,
            type: "WIDGET_SUM",
            value: "",
            targetValue: "0",
            operator: null,
            required: true,
            placeholder: "ввод данных",
          }),
        ],
      }),
    ],
  });
};

export const copyTableSection = (
  currentSection: ProgramTemplateBlockTableSection,
  sectionIndex: ProgramTemplateBlockTable["index"],
  createdOnWorkProgram: boolean,
) => {
  return getProgramTemplateBlockTableSectionDTO({
    ...currentSection,
    id: generateUUID("fake_"),
    index: sectionIndex,
    createdOnWorkProgram,
    rows: currentSection.rows.map(row => rowsHelpers.copyTableRow(row, row.index, createdOnWorkProgram)),
  });
};

export const checkMoreThanOneTableSectionExists = (sections: ProgramTemplateBlockTableSection[]) => {
  let singleTableSectionExist = false;

  for (let i = 0; i < sections.length; i++) {
    if (sections[i].type === "TABLE") {
      if (!singleTableSectionExist) {
        singleTableSectionExist = true;
      } else {
        return true;
      }
    }
  }

  return false;
};

export const getTableSectionIndexAbove = (
  sections: ProgramTemplateBlockTableSection[],
  startIndex: number,
): number => {
  /*
    Here we sure that table contains at least two sections with type "TABLE"
   */
  let targetIndex = startIndex < 0 ? sections.length + startIndex : startIndex;

  while (sections.at(targetIndex)!.type !== "TABLE") {
    if (targetIndex === 0) {
      targetIndex = sections.length - 1;
    } else {
      targetIndex--;
    }
  }

  return targetIndex;
};

export const getTableSectionIndexBelow = (
  sections: ProgramTemplateBlockTableSection[],
  startIndex: number,
): number => {
  /*
    Here we sure that table contains at least two sections with type "TABLE"
   */

  while (sections.at(startIndex)!.type !== "TABLE") {
    if (startIndex === sections.length - 1) {
      startIndex = 0;
    } else {
      startIndex++;
    }
  }

  return startIndex;
};

export const makeLowestTableSectionIndexHighest = (
  sections: ProgramTemplateBlockTableSection[],
  startIndex: number,
  endIndex: number,
): void => {
  for (let i = startIndex; i <= endIndex; i++) {
    sections[i].index--;
  }

  sections[startIndex].index = sections[endIndex].index + 1;
};

export const makeHighestTableSectionIndexLowest = (
  sections: ProgramTemplateBlockTableSection[],
  startIndex: number,
  endIndex: number,
): void => {
  for (let i = startIndex; i <= endIndex; i++) {
    sections[i].index++;
  }

  sections[endIndex].index = sections[startIndex].index - 1;
};

export const incrementSectionIndexes = (
  sections: ProgramTemplateBlockTableSection[],
  startIndex: number,
  incrementBy = 1,
): void => {
  for (let i = startIndex; i <= sections.length - 1; i++) {
    sections[i].index += incrementBy;
  }
};