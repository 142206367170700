import {InputNumber} from "antd";
import React, {forwardRef} from "react";
import type {Ref} from "react";
import type {AppInputNumberProps, AppInputNumberValue} from "./InputNumber.types";

const InputNumberComponent = <T extends AppInputNumberValue = number>(
  props: AppInputNumberProps<T>, ref: Ref<HTMLInputElement>
) => {
  const {type = "number", controls = false, ...rest} = props;

  return <InputNumber type={type} controls={controls}
                      {...rest} ref={ref}/>;
};

export const AppInputNumber = forwardRef(InputNumberComponent) as <T extends AppInputNumberValue = number>(
  props: AppInputNumberProps<T> & {ref?: Ref<HTMLInputElement>}
) => ReturnType<typeof InputNumberComponent>;