import {useEffect} from "react";
import {AppInputNumber} from "shared/UI/UIKit/InputNumber";
import {AppForm, AppFormItem} from "shared/UI/UIKit/Form";
import {AppMultipleSelect, AppSelect} from "shared/UI/UIKit/Select";
import type {ExtracurricularTableBodyCell} from "services/api";
import type {FormInstance} from "antd/lib/form";
import styles from "./styles.module.scss";

type EditingBodyRowCellProps = {
  cell: ExtracurricularTableBodyCell;
  form: FormInstance;
}

export const EditingBodyRowCell = (props: EditingBodyRowCellProps) => {

  useEffect(() => {
    props.form.setFieldValue("value", props.cell.value);
  }, [props.cell]);

  const sortOptions = () => {
    return 1;
  };

  return (
    <AppForm form={props.form}>
      <AppFormItem name="value" marginless>
        {
          props.cell.type === "LIST" ? (
            props.cell.multipleSelect ? (
              <AppMultipleSelect filterSort={sortOptions} defaultOpen options={props.cell.options} className={styles.control} autoFocus={true}/>
            ) : (
              <AppSelect filterSort={sortOptions} defaultOpen options={props.cell.options} className={styles.control} autoFocus={true}/>
            )
          ) : (
            <AppInputNumber className={styles.control} autoFocus={true}/>
          )
        }
      </AppFormItem>
    </AppForm>
  );
};