import type {MainProgramItem,EducationLevel,Curriculum, Subject} from "services/api";
import type {AppSelectOption} from "shared/UI/UIKit/Select";

export type CreateMainProgramState = {
  loading: boolean;
  allCurriculums: Curriculum[];
  allCurriculumsTotal: number;
  selectedCurriculumsEntities: Curriculum[];
  selectedEducationLevel?: EducationLevel;
  activeTabId: CreateWorkProgramTabs;
  workProgramsOptionsBySubjectId: Record<number, AppSelectOption[]>;
  selectedWorkProgramByFieldName: Record<string, MainProgramItem>;
}

export enum CreateWorkProgramTabs {
  EDUCATION_LEVEL = "EDUCATION_LEVEL",
  CURRICULUM = "CURRICULUM",
  WORK_PROGRAM = "WORK_PROGRAM",
}

export type SetSelectedWorkProgramByFieldNameActionPayload = {
  fieldName: string;
  item: MainProgramItem;
}

export type UpdateCustomSubjectNameInSelectedCurriculumActionPayload = {
  customSubjectName: string;
  curriculumId: number;
  subjectId: number;
}

export type AddRequiredSubjectInSelectedCurriculumActionPayload = {
  curriculumId: number;
  subject: Subject;
  indexToInsert: number;
}

export type AddSubjectInSelectedCurriculumActionPayload = {
  curriculumId: number;
  subject: Subject;
}

export type RemoveSubjectInSelectedCurriculumActionPayload = {
  curriculumId: number;
  subject: Subject;
}