export function setIndent(core, command) {
  const range = core.getRange();
    const rangeLines = core.getSelectedElements(null);
    const cells = [];
    const shift = "indent" !== command;
    const textIndent = "textIndent";
    let sc = range.startContainer;
    let ec = range.endContainer;
    let so = range.startOffset;
    let eo = range.endOffset;

    for (let i = 0, len = rangeLines.length, f, margin; i < len; i++) {
        f = rangeLines[i];
        if (!core.util.isListCell(f) || !core.plugins.list) {
            margin = /\d+/.test(f.style[textIndent]) ? core.util.getNumber(f.style[textIndent], 0) : 0;
            if (shift) {
                margin -= 35.45;
            } else {
                margin += 35.45;
            }
            core.util.setStyle(f, textIndent, (margin <= 0 ? "" : margin + "pt"));
        } else {
            if (shift || f.previousElementSibling) {
                cells.push(f);
            }
        }
    }

    // list cells
    if (cells.length > 0) {
        core.plugins.list.editInsideList.call(core, shift, cells);
    }

    core.effectNode = null;
    core.setRange(sc, so, ec, eo);

    // history stack
    core.history.push(false);
}