import React from "react";
import type {ProgramTemplateBlockTableRow} from "services/api";
import type {TableSectionProps} from "./types";
import {TableRow} from "../TableRow";
import {TableRowCreator} from "../TableRowCreator";
import {SortableContextProvider} from "../../contexts/drag-and-drop";
import {useTableSection} from "./use-table-section";

export const TableSection = (props: TableSectionProps) => {
  const [
    {
      sectionHeaderRows,
      sectionBodyRows,
      sectionFooterRows,
      isTableSectionHeaderVisible,
      isTableSectionFooterVisible,
      isTableRowCreatorVisible,
      isTableFooterRowCreatorVisible,
      isSectionModule,
      visibleColumnCount,
      sectionBodyRowsIds,
    },
    {handleRowCreate, handleCellClick, handleCellUpdate}
  ] = useTableSection(props);
  let sectionBodyRowCount = 0;

  const renderRow = (row: ProgramTemplateBlockTableRow) => {
    const isSectionBodyRow = row.type === "SECTION_BODY_ROW";

    if (isSectionBodyRow) {
      sectionBodyRowCount++;
    }

    return (
      <TableRow tableType={props.tableType} sectionId={props.section.id}
                sectionType={props.section.type} isSectionModule={isSectionModule}
                isSectionCreatedOnWorkProgram={props.section.createdOnWorkProgram}
                sectionOrdinalNumber={props.ordinalNumber} settings={props.settings}
                ordinalNumber={isSectionBodyRow ? sectionBodyRowCount : undefined}
                row={row}
                onCellClick={handleCellClick} onCellUpdate={handleCellUpdate}/>
    );
  };

  return (
    <>
      {isTableSectionHeaderVisible && (
        sectionHeaderRows.map((row) => {
          return (
            <React.Fragment key={row.id}>{renderRow(row)}</React.Fragment>
          );
        })
      )}
      <SortableContextProvider sectionBodyRowsIds={sectionBodyRowsIds}>
        {sectionBodyRows.map((row) => {
          return (
            <React.Fragment key={row.id}>{renderRow(row)}</React.Fragment>
          );
        })}
      </SortableContextProvider>
      {isTableRowCreatorVisible && (
        <TableRowCreator label="Добавить строку" colSpan={visibleColumnCount}
                         onRowCreate={handleRowCreate}/>
      )}
      {isTableFooterRowCreatorVisible && (
        <TableRowCreator label="Добавить строку Итого" colSpan={visibleColumnCount}
                         onRowCreate={handleRowCreate}/>
      )}
      {isTableSectionFooterVisible && (
        sectionFooterRows.map((row) => {
          return (
            <React.Fragment key={row.id}>{renderRow(row)}</React.Fragment>
          );
        })
      )}
    </>
  );
};