import {useForm} from "antd/es/form/Form";
import {useEffect} from "react";
import {AppModal} from "shared/UI/UIKit/Modal";
import {AppFlex} from "shared/UI/UIKit/Flex";
import {AppButton} from "shared/UI/UIKit/Button";
import type {WidgetValueModalProps, WidgetValueFormValues} from "./types";
import {WidgetValueForm} from "../WidgetValueForm";

export const WidgetValueModal = (props: WidgetValueModalProps) => {
  const [form] = useForm<WidgetValueFormValues>();

  useEffect(() => {
    if (props.selectedWidget) {
      const value = props.selectedWidget?.getAttribute("data-value");
      if (value) {
        form.setFieldValue("data-value", value);
      }
    }
  }, [props.selectedWidget]);

  const onSubmit = () => {
    form.validateFields().then(values => {
      if (values["data-value"]) {
        props.selectedWidget!.innerHTML = values["data-value"];
        props.selectedWidget!.setAttribute("data-value", values["data-value"]);
      } else {
        props.selectedWidget?.removeAttribute("data-value");
        const placeholderValue = props.selectedWidget?.getAttribute("data-placeholder");
        props.selectedWidget!.innerHTML = placeholderValue || "";
      }
      const newHtml = document.querySelector(".se-wrapper-inner.se-wrapper-wysiwyg")!.innerHTML;
      props.setHtmlContent(newHtml);
      onHide();
    }).catch(console.log);
  };

  const onHide = () => {
    form.resetFields();
    props.hide();
  };

  const content = <WidgetValueForm selectedWidget={props.selectedWidget!}
                                               form={form}/>;

  const footer = (
    <AppFlex direction="row">
      <AppButton onClick={onSubmit}>
        Сохранить
      </AppButton>
      <AppButton type="outline" color="danger"
                 onClick={onHide}>
        Отмена
      </AppButton>
    </AppFlex>
  );

  return (
    <AppModal content={content}
              title="Заполнение виджета"
              footer={footer}
              visible={props.visible}
              onCancel={onHide}/>
  );
};