import {useSortable} from "@dnd-kit/sortable";
import type {ProgramTemplateBlockTableSettings,ProgramTemplateBlockTableRow} from "services/api";
import {useTableConstructorContext} from "../../contexts/table-constructor-context";

export const useRowDragAndDrop = (
  row: ProgramTemplateBlockTableRow,
  sectionId: string | number,
  settings: ProgramTemplateBlockTableSettings,
) => {
  const {isFullAccess} = useTableConstructorContext();

  const isMoveRowAllowed = () => {
    return (
      isFullAccess ||
      (!row.createdOnWorkProgram && !!settings.programTemplateRowMovingAllowed) ||
      (!!row.createdOnWorkProgram && !!settings.workProgramRowMovingAllowed)
    );
  };

  const {
    attributes,
    setNodeRef,
    transform,
    transition,
    listeners,
  } = useSortable({
    id: row.id!,
    data: {
      row: {
        ...row,
        section: {
          id: sectionId
        }
      },
    },
    disabled: !isMoveRowAllowed()
  });

  const getRowStyle = () => {
    return {
      transform: `translate3d(0px, ${transform?.y || 0}px, 0)`,
      transition
    };
  };

  return {
    setNodeRef,
    attributes,
    listeners,
    getRowStyle,
  };
};