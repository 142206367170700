import {memo} from "react";
import type {TableRowCreatorProps} from "./types";
import {AddButton} from "../../../ui/components/AddButton";
import styles from "./styles.module.scss";

export const TableRowCreator = memo((props: TableRowCreatorProps) => {
  return (
    <tr>
      <td colSpan={props.colSpan} className={styles.td}>
        <AddButton label={props.label} labelSize={14}
                   onClick={props.onRowCreate}/>
      </td>
    </tr>
  );
});