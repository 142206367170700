import type {ValueType, ValidatorOptions} from "./is-before-date-validator.types";
import {errorMessages} from "../../error-messages";

const DEFAULT_OPTIONS: Required<ValidatorOptions> = {
  comparisonValueRequired: true,
  granularity: "day",
  variant: "before",
  message: errorMessages.dateError,
};

export const isBeforeDateValidator = (
  required = true,
  comparisonValue: ValueType,
  options: ValidatorOptions = {},
) => {
  const {
    comparisonValueRequired = DEFAULT_OPTIONS.comparisonValueRequired,
    granularity = DEFAULT_OPTIONS.granularity,
    variant = DEFAULT_OPTIONS.variant,
    message = DEFAULT_OPTIONS.message,
  } = options;

  return (_: any, value: ValueType) => {
    if (!value) {
      return required ? Promise.reject(errorMessages.required) : Promise.resolve();
    }

    if (!comparisonValue) {
      return comparisonValueRequired ? Promise.reject(message) : Promise.resolve();
    }

    switch (variant) {
      case "before": {
        if (value.isBefore(comparisonValue, granularity)) {
          return Promise.resolve();
        } else {
          return Promise.reject(message);
        }
      }
      case "same or before": {
        if (value.isSameOrBefore(comparisonValue, granularity)) {
          return Promise.resolve();
        } else {
          return Promise.reject(message);
        }
      }
      default: {
        return Promise.reject(message);
      }
    }
  };
};