import {arrayValidator} from "shared/UI/UIKit/Form";
import {cssWidthToPixelWidth} from "shared/helpers/utils/DOM";
import type {ListValueEditorFormValues, ListValueEditorSubmitValues} from "./types";
import {STYLES_CONSTANTS, FIELD_CONSTANTS} from "../../../../../lib/constants";

export const getInitialFormValues = (values: ListValueEditorSubmitValues): ListValueEditorFormValues => {
  const getValue = (): string[] => {
    if (!values.value) {
      return [];
    }

    return values.value.split(FIELD_CONSTANTS.MULTIPLE_VALUE_SPLITTER);
  };

  return {
    value: getValue(),
  };
};

export const getSubmitValues = (values: ListValueEditorFormValues): ListValueEditorSubmitValues => {
  const getValue = (): string | null => {
    if (!values.value) {
      return null;
    }

    return values.value.join(FIELD_CONSTANTS.MULTIPLE_VALUE_SPLITTER);
  };

  return {
    value: getValue(),
  };
};

export const formRules = {
  getValueValidator: (required: boolean) => {
    return {
      validator: arrayValidator(required),
    };
  }
};

export const formFieldValidators = {
  checkValueValid: async (value: ListValueEditorFormValues["value"], required: boolean) => {
    const {validator} = formRules.getValueValidator(required);
    return await validator(undefined, value);
  }
};

export const getMaxWidth = (valueA: string | undefined, valueB: string | undefined): string => {
  if (valueA === undefined || valueB === undefined) {
    return `${STYLES_CONSTANTS.TABLE_WIDTH / 2}`; // empirical value as half of table width. 100% value breaks table layout
  }

  const widthA = cssWidthToPixelWidth(valueA);
  const widthB = cssWidthToPixelWidth(valueB);
  const maxWidth = Math.max(widthA, widthB);
  return `${maxWidth}px`;
};