import type {RuleRender} from "rc-field-form/lib/interface";
import {formNames} from "../constants";

const requireIfOperatorIsNotEmpty: RuleRender = (form) => {
  if (form.getFieldValue(formNames.operator) !== "empty") {
    return {
      required: true,
      message: "Поле обязательно для заполнения, если выбран оператор",
    };
  } else {
    return {};
  }
};

export const numberCellFormRules = {
  requireIfOperatorIsNotEmpty,
};