import {createSelector} from "@reduxjs/toolkit";
import type {Curriculum, Subject} from "services/api";
import type {RootState} from "services/redux/types";

export const selectCreateMainProgramState = (state: RootState) => state.createMainProgramState;

export const selectCreateMainProgramLoading = createSelector(
  selectCreateMainProgramState, state => {
    return state.loading;
  }
);

export const selectCreateMainProgramAllCurriculums = createSelector(
  selectCreateMainProgramState, state => {
    return state.allCurriculums;
  }
);

export const selectCreateMainProgramSelectedCurriculumsEntities = createSelector(
  selectCreateMainProgramState, state => {
    return state.selectedCurriculumsEntities;
  }
);

export const selectCreateMainProgramSelectedCurriculumsEntitiesSubjects = createSelector(
  selectCreateMainProgramSelectedCurriculumsEntities, selectedCurriculums => {
    let subjects: Subject[] = [];
    selectedCurriculums.forEach((curriculum: Curriculum) => {
      subjects = [...subjects, ...curriculum.subjects];
    });
    return subjects;
  }
);

export const selectCurriculumsTotalCount = createSelector(
  selectCreateMainProgramState, state => {
    return state.allCurriculumsTotal;
  }
);

export const selectCreateMainProgramActiveTabId = createSelector(
    selectCreateMainProgramState, state => {
      return state.activeTabId;
    }
);

export const selectCreateMainProgramEducationLevel = createSelector(
  selectCreateMainProgramState, state => {
    return state.selectedEducationLevel;
  }
);

export const selectAllWorkProgramsOptions = createSelector(
  selectCreateMainProgramState, state => {
    return state.workProgramsOptionsBySubjectId;
  }
);

export const selectWorkProgramsOptionsBySubjectId = (subjectId: number) => {
  return createSelector(
    selectAllWorkProgramsOptions,
    workProgramOptions => {
      return workProgramOptions[subjectId] || [];
    }
  );
};