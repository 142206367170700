import type {CurriculumDetailedTableRow} from "services/api";
import {CurriculumRow} from "../CurriculumRow";

type CurriculumBodyProps = {
  rows: CurriculumDetailedTableRow[];
}
export const CurriculumBody = (props: CurriculumBodyProps) => {
  return (
    <tbody>
      {
        props.rows.map((row, index) => {
          return <CurriculumRow key={`${row.id}-${index}`} Tag="td" row={row}/>;
        })
      }
    </tbody>
  );
};