import type {LinkMenuItemOptions} from "../base-menu/get-link-menu-item";
import {getLinkMenuItem as getLinkItem} from "../base-menu/get-link-menu-item";

export const getLinkMenuItem = (options: LinkMenuItemOptions) => {
  const {shouldCloseMenu = true, ...rest} = options;

  return getLinkItem({
    shouldCloseMenu,
    ...rest,
  });
};