import React, {Suspense} from "react";
import {BrowserRouter} from "react-router-dom";
import {ConfigProvider} from "antd";
import ruRU from "antd/es/locale/ru_RU";
import {AppLoader} from "shared/UI/UIKit/Loader";
import {VisibleIndexContextProvider} from "features/main-program/context/visible-index-context";
import type {AppProvidersProps} from "./Providers.types";

export const AppProviders = ({children}: AppProvidersProps) => {
  return (
    <ConfigProvider locale={ruRU}>
      <BrowserRouter>
        <Suspense fallback={<AppLoader size="large" stretched/>}>
          <VisibleIndexContextProvider>
            {children}
          </VisibleIndexContextProvider>
        </Suspense>
      </BrowserRouter>
    </ConfigProvider>
  );
};