import type {ExtracurricularTableBodyRow} from "services/api";
import {BodyRow} from "./Row";

type BodyProps = {
  rows: ExtracurricularTableBodyRow[];
  editable?: boolean;
}

export const Body = (props: BodyProps) => {
  return (
    <tbody>
      {
        props.rows.map(row => {
          return (
            <BodyRow editable={props.editable} row={row} key={row.rowId}/>
          );
        })
      }
    </tbody>
  );
};