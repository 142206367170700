import {AppModal} from "shared/UI/UIKit/Modal";
import type {TableSettingsModalProps} from "./types";
import type {TableSettingsFormValues} from "./form";
import {useTableConstructorContext} from "../../contexts/table-constructor-context";
import {TableSettingsForm} from "./form";
import {getUpdatedTable} from "./utils";

export const TableSettingsModal = (props: TableSettingsModalProps) => {
  const {isFullAccess, isPrograms, singleColumns} = useTableConstructorContext();

  const handleSubmit = (values: TableSettingsFormValues) => {
    const nextTable = getUpdatedTable(props.table, singleColumns, values);
    props.onTableUpdate(nextTable);
    handleCancel();
  };

  const handleCancel = () => {
    props.onHide();
  };

  return (
    <AppModal visible={props.visible} onCancel={handleCancel}>
      <TableSettingsForm table={props.table} columns={singleColumns}
                         options={{
                           name: {visible: isFullAccess},
                           viewSettings: {visible: isFullAccess},
                           settings: {visible: isFullAccess},
                           hiddenColumnIndexes: {visible: isPrograms},
                         }}
                         onSubmit={handleSubmit} onCancel={handleCancel}/>
    </AppModal>
  );
};