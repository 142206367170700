import {AppFlex} from "shared/UI/UIKit/Flex";
import {AppText} from "shared/UI/UIKit/Text";
import {AppButton} from "shared/UI/UIKit/Button";
import type {FormInstance} from "antd/lib/form";
import {EducationLevelForm} from "../EducationLevelForm";

type FirstStepProps = {
  handleNextStep: () => void;
  form: FormInstance;
}

export const FirstStep = (props: FirstStepProps) => {
  return (
    <AppFlex unit="24">
      <AppText size={24}>
        Выберите уровень образования
      </AppText>
      <EducationLevelForm form={props.form}/>
      <AppFlex alignItems="flex-end">
        <AppButton size="large"
                   onClick={props.handleNextStep}>
          Шаг 2. Учебный план {">"}
        </AppButton>
      </AppFlex>
    </AppFlex>
  );
};