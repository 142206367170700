import {
  getProgramTemplateBlockTableRowDTO,
  getProgramTemplateBlockTableRowFieldDTO,
  getProgramTemplateBlockTableSectionDTO
} from "services/api/blocks/dto";
import type {ProgramTemplateBlockTableSection, ProgramTemplateBlockTable} from "services/api";
import type {ClickedCell} from "../../types";

export const updateCell = (
  table: ProgramTemplateBlockTable,
  cell: ClickedCell,
): ProgramTemplateBlockTableSection[] => {
  const {field, meta: {sectionId, rowId}} = cell;

  const nextSections = [...table.sections];
  const sectionIndex = nextSections.findIndex(section => section.id === sectionId);
  const nextRows = [...nextSections[sectionIndex].rows];
  const rowIndex = nextRows.findIndex(row => row.id === rowId);
  const nextRowFields = [...nextRows[rowIndex].fields];
  const fieldIndex = nextRowFields.findIndex(rowField => rowField.id === field.id);

  nextRowFields[fieldIndex] = getProgramTemplateBlockTableRowFieldDTO({...field});
  nextRows[rowIndex] = getProgramTemplateBlockTableRowDTO({
    ...nextRows[rowIndex],
    fields: nextRowFields,
  });
  nextSections[sectionIndex] = getProgramTemplateBlockTableSectionDTO({
    ...nextSections[sectionIndex],
    rows: nextRows,
  });

  return nextSections;
};