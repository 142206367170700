import {getByPath} from "shared/helpers/utils/get-by-path";
import {setByPath} from "shared/helpers/utils/set-by-path";
import type {AppTableItem} from "../../../../types";
import type {GroupedItemsMap, GroupedMapItem} from "./types";

export const getGroupedDataSource = <TRecord extends AppTableItem>(
  dataSource: TRecord[],
  dataIndex: string[],
  groupKeyPrefix: string,
  calcChildrenNumber: boolean,
): TRecord[] => {
  const groupedItemsMap = getGroupedItemsMap(dataSource, dataIndex);
  return getGroupedItems(groupedItemsMap, dataIndex, groupKeyPrefix, calcChildrenNumber);
};

const getGroupedItemsMap = <TRecord extends AppTableItem>(
  items: TRecord[],
  dataIndex: string[],
): GroupedItemsMap<TRecord> => {
  const groupedItemsMap: GroupedItemsMap<TRecord> = new Map();

  items.forEach(item => {
    const key = getByPath(item, dataIndex);
    const value: GroupedMapItem<TRecord> = groupedItemsMap.get(key) ?? {children: []};
    value.children = value.children.concat(item);

    groupedItemsMap.set(key, value);
  });

  return groupedItemsMap;
};

const getGroupedItems = <TRecord extends AppTableItem>(
  itemsMap: GroupedItemsMap<TRecord>,
  dataIndex: string[],
  groupKeyPrefix: string,
  calcChildrenNumber: boolean,
): TRecord[] => {
  return Array.from(itemsMap)
    .map(([key, item]) => {
      const childrenWithUndefinedByDataIndex = item.children.map(child => {
        return setByPath<TRecord>(child, dataIndex, undefined);
      });

      const groupedItem = {
        key: `${groupKeyPrefix}_${key}`,
        children: childrenWithUndefinedByDataIndex,
        _childrenNumber: calcChildrenNumber ? childrenWithUndefinedByDataIndex.length : undefined,
        _group: true,
      };
      const groupedItemWithKeyByDataIndex = setByPath<TRecord>(groupedItem, dataIndex, key);

      return groupedItemWithKeyByDataIndex;
    });
};