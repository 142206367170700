import cn from "classnames";
import type {AppTextProps} from "./Text.types";
import styles from "./Text.module.scss";

export const getClassNames = (
  size: AppTextProps["size"],
  weight: AppTextProps["weight"],
  className: AppTextProps["className"],
) => {
  return cn(
    styles.text,
    size && styles[`text--${size}`],
    weight && styles[`text--${weight}`],
    className,
  );
};