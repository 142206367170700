import React from "react";
import {createRoot} from "react-dom/client";
import {Provider} from "react-redux";
import {App} from "app";
import {AuthContextProvider, KeycloakProvider} from "services/auth";
import {store} from "services/redux/store";

const root = createRoot(document.getElementById("root")!);

root.render(
  <KeycloakProvider>
    <Provider store={store}>
      <AuthContextProvider>
        <React.StrictMode>
          <App/>
        </React.StrictMode>
      </AuthContextProvider>
    </Provider>
  </KeycloakProvider>
);
