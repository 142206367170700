import {AppFlex} from "shared/UI/UIKit/Flex";
import type {TableHeaderChildCellProps} from "./types";
import {visibility} from "../../../../../lib/core";
import {TableHeaderBaseCell} from "../TableHeaderBaseCell";
import {LockedIcon} from "../LockedIcon";
import {EditIcon} from "../EditIcon";
import styles from "./styles.module.scss";

export const TableHeaderChildCell = (props: TableHeaderChildCellProps) => {
  const isLockedIconVisible = visibility.checkHeaderCellLockedIconVisible({
    editMode: props.editMode,
    isFullAccess: props.isFullAccess,
  });
  const isEditIconVisible = visibility.checkHeaderCellEditIconVisible({
    editMode: props.editMode,
    column: props.cell.column,
  });

  const handleLockToggle = (nextLocked: boolean) => {
    props.onCellUpdate({
      ...props.cell,
      column: {
        ...props.cell.column,
        locked: nextLocked,
      },
    });
  };

  const handleEditClick = () => {
    props.onColumnEditClick(props.cell.column);
  };

  return (
    <TableHeaderBaseCell isFullAccess={props.isFullAccess} editMode={props.editMode}
                         {...props.cell.meta.attrs}>
      {props.cell.column.name}
      <AppFlex direction="row" alignItems="center" unit="2" className={styles["actions-container"]}>
        {isEditIconVisible && (
          <EditIcon onClick={handleEditClick}/>
        )}
        {isLockedIconVisible && (
          <LockedIcon locked={!!props.cell.column.locked} disabled={props.cell.column.lockDisabled}
                      toggle={handleLockToggle}/>
        )}
      </AppFlex>
    </TableHeaderBaseCell>
  );
};