import {useEffect} from "react";
import {AppModal} from "shared/UI/UIKit/Modal";
import {AppForm, AppFormItem, formRules, useAppForm} from "shared/UI/UIKit/Form";
import {AppInput} from "shared/UI/UIKit/Input";
import {AppButton} from "shared/UI/UIKit/Button";
import {useAppDispatch} from "services/redux/hooks/use-dispatch";
import {updateCustomSubjectNameInSelectedCurriculum} from "features/create-main-program/redux/slice";
import type {Curriculum, LocalStorageSubject, Subject} from "services/api";

type EditSubjectModalProps = {
  isOpen: boolean;
  closeModal: () => void;
  selectedSubject?: Subject;
  curriculum: Curriculum;
}

export const EditSubjectModal = (props: EditSubjectModalProps) => {
  const [form] = useAppForm();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (props.selectedSubject) {
      form.setFieldsValue({
        customSubjectName: props.selectedSubject.customSubjectName,
      });
    }
  }, [props.selectedSubject]);

  const submit = () => {
    form.validateFields().then(values => {
      dispatch(updateCustomSubjectNameInSelectedCurriculum({
        subjectId: props.selectedSubject!.uniqueId!,
        curriculumId: props.curriculum.id,
        customSubjectName: values.customSubjectName,
      }));

      const currentWizard = localStorage.getItem("WIZARD") || "{}";
      const parserWizard = JSON.parse(currentWizard);
      const curriculum = parserWizard.curriculums.find((item: Curriculum) => item.id === props.curriculum.id);
      const requiredElement = curriculum.subjects.find((item: Subject) => item.uniqueId === props.selectedSubject!.uniqueId);
      const extraElement = parserWizard.extraSubjects?.find((item: LocalStorageSubject) => item.subject.uniqueId === props.selectedSubject!.uniqueId);

      let nextWizard;
      if (requiredElement) {
        nextWizard = {
          ...parserWizard,
          curriculums: parserWizard.curriculums.map((item: Curriculum) => {
            if (item.id === props.curriculum.id) {
              return {
                ...item,
                subjects: item.subjects.map((subject) => {
                  if (subject.uniqueId === props.selectedSubject!.uniqueId) {
                    return {
                      ...subject,
                      customSubjectName: values.customSubjectName,
                    };
                  } else {
                    return subject;
                  }
                })
              };
            } else {
              return item;
            }
          }),
        };
      } else if (extraElement) {
        nextWizard = {
          ...parserWizard,
          extraSubjects: parserWizard.extraSubjects.map((extraSub: LocalStorageSubject) => {
            if (extraSub.subject.uniqueId === extraElement.uniqueId) {
              return {
                ...extraSub,
                subject: {
                  ...extraSub.subject,
                  customSubjectName: values.customSubjectName,
                }
              };
            } else {
              return extraSub;
            }
          }),
        };
      }

      localStorage.setItem("WIZARD", JSON.stringify(nextWizard));
      hide();
    }).catch(() => null);
  };

  const hide = () => {
    props.closeModal();
    form.resetFields();
  };

  const content = (
    <AppForm form={form}>
      <AppFormItem name="customSubjectName" label="Название предмета" rules={formRules.required}>
        <AppInput placeholder="Введите название"/>
      </AppFormItem>
    </AppForm>
  );

  const footer = (
    <AppButton onClick={submit}>
      Сохранить
    </AppButton>
  );

  return (
    <AppModal destroyOnClose loading={false}
              title="Редактирование предмета"
              visible={props.isOpen} onCancel={hide}
              content={content} footer={footer}/>
  );
};