import {createAsyncThunk} from "@reduxjs/toolkit";
import {
  getAllSubjects,
  getWorkPrograms,
  setWorkProgramAvailableInMainProgram,
  toggleVisibleWorkProgramInMainProgramList
} from "services/api";
import type {
  GetWorkProgramsPayload, SetWorkProgramAvailableInMainProgramPayload
, ToggleVisibleWorkProgramInMainProgramListPayload
} from "services/api";

export const getWorkProgramsAction = createAsyncThunk("workPrograms/getWorkPrograms", async(payload: GetWorkProgramsPayload) => {
  try {
    return await getWorkPrograms(payload);
  } catch (e: unknown) {
    throw new Error(e as string);
  }
});

export const getAllSubjectsAction = createAsyncThunk("workPrograms/subjects", async() => {
  try {
    return await getAllSubjects();
  } catch (e: unknown) {
    throw new Error(e as string);
  }
});

export const setWorkProgramAvailableInMainProgramAction = createAsyncThunk("workPrograms/setAvailableInMainProgram", async(payload: SetWorkProgramAvailableInMainProgramPayload) => {
  try {
    return await setWorkProgramAvailableInMainProgram(payload);
  } catch (e: unknown) {
    throw new Error(e as string);
  }
});

export const toggleVisibleWorkProgramInMainProgramListAction = createAsyncThunk("workPrograms/toggleVisibleWorkProgramInMainProgramList", async(payload: ToggleVisibleWorkProgramInMainProgramListPayload) => {
  try {
    return await toggleVisibleWorkProgramInMainProgramList(payload);
  } catch (e: unknown) {
    throw new Error(e as string);
  }
});
