import type {AppTableChangeEvent, AppTableSorter} from "../../../ui";

export const getSort = (sorters: AppTableSorter[]): AppTableChangeEvent["sort"] => {
  if (!sorters.length) {
    return null;
  }

  return sorters
    .filter(item => !!item.order)
    .map(item => ({
      property: item.dataIndex.join("."),
      order: item.order!,
      weight: item.weight,
    }));
};