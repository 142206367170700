import {useEffect, useState} from "react";
import {useForm} from "antd/es/form/Form";
import {AppModal} from "shared/UI/UIKit/Modal";
import {AppFlex} from "shared/UI/UIKit/Flex";
import {AppButton} from "shared/UI/UIKit/Button";
import {AppForm, AppFormItem, formRules} from "shared/UI/UIKit/Form";
import {AppSelect} from "shared/UI/UIKit/Select";
import {
  deleteMainProgramItem,
  type ExtracurricularTableBodyRow,
  PROGRAM_TEMPLATE_BLOCK_TYPE,
  SubjectObligation,
  updateMainProgramBlock
} from "services/api";
import {useAppSelector} from "services/redux/hooks/use-selector";
import {selectMainProgram, selectMainProgramBlock} from "features/main-program/redux/selectors";
import {useAppDispatch} from "services/redux/hooks/use-dispatch";
import {setMainProgramAction, updateAdditionalEducationTableAction} from "features/main-program/redux/slice";
import {prepareTableContent} from "features/main-program/redux/utils";
import type {MainProgram, MainProgramBlockDtoBody} from "services/api";
import type {AppSelectOption} from "shared/UI/UIKit/Select";

type DeleteElementModalProps = {
  visible: boolean;
  hideModal: () => void;
}

export const DeleteElementModal = (props: DeleteElementModalProps) => {
  const [form] = useForm();
  const mainProgram = useAppSelector(selectMainProgram);
  const activeBlock = useAppSelector(selectMainProgramBlock);
  const [loading, setLoading] = useState(false);

  const [workProgramsOptions, setWorkProgramsOptions] = useState<AppSelectOption[]>([]);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const workProgramOptions: AppSelectOption[] = mainProgram?.items
      .filter(item => item.workProgram.obligation === SubjectObligation.ADDITIONAL || item.workProgram.obligation === SubjectObligation.EXTRACURRICULAR)
      .map(item => {
        return {
          label: item.customSubjectName,
          value: item.id!,
        };
      }) || [];
    setWorkProgramsOptions(workProgramOptions);
  }, []);

  const hideModal = () => {
    setLoading(false);
    form.resetFields();
    props.hideModal();
  };

  const submit = () => {
    form.validateFields().then(values => {
      setLoading(true);
      const nextMainProgram: MainProgram = {
        ...mainProgram!,
        items: mainProgram?.items.filter(item => item.id !== values.selectedItem) || [],
      };

      const selectedOption = workProgramsOptions.find(option => option.value === values.selectedItem);

      deleteMainProgramItem(selectedOption?.value as number).then(() => {
        dispatch(setMainProgramAction(nextMainProgram));
        const mainProgramExtracurricularPlanTableBlock = mainProgram?.blocks.find(block => block.type === PROGRAM_TEMPLATE_BLOCK_TYPE.TABLE);
        if (mainProgramExtracurricularPlanTableBlock) {
          if (mainProgramExtracurricularPlanTableBlock.content) {
            const content = JSON.parse(mainProgramExtracurricularPlanTableBlock.content) as ExtracurricularTableBodyRow[] || [];
            const nextContent = content.filter(row => row.cells[1].value !== selectedOption?.label);
            const nextBlock: MainProgramBlockDtoBody = {
              ...mainProgramExtracurricularPlanTableBlock,
              content: JSON.stringify(nextContent),
              mainProgram: {
                id: mainProgram!.id!
              }
            };

            updateMainProgramBlock(nextBlock as MainProgramBlockDtoBody).then(() => {
              if (activeBlock) {
                dispatch(updateAdditionalEducationTableAction(JSON.stringify(nextContent)));
              }

              setLoading(false);
              hideModal();
            });
          } else {
            const nextTableContent = prepareTableContent("", nextMainProgram);
            dispatch(updateAdditionalEducationTableAction(nextTableContent));
            setLoading(false);
            hideModal();
          }
        } else {
          setLoading(false);
          hideModal();
        }
      });
    }).catch(err => {
      console.log(err);
      hideModal();
    }).finally(() => {
      setLoading(false);
    });
  };

  const footer = (
    <AppFlex direction="row" justifyContent="flex-start">
      <AppButton onClick={submit} color="danger">
        Удалить
      </AppButton>
      <AppButton type="outline" onClick={hideModal}>
        Отмена
      </AppButton>
    </AppFlex>
  );

  const content = (
    <AppForm form={form}>
      <AppFormItem name="selectedItem" label="Выберите элемент" rules={formRules.requiredNumber}>
        <AppSelect options={workProgramsOptions} placeholder="Выберите элемент"/>
      </AppFormItem>
    </AppForm>
  );

  return (
    <AppModal visible={props.visible} onCancel={hideModal} size="auto"
              content={content} destroyOnClose footer={footer} loading={loading}
              title="Удалить предмет"/>
  );
};