import {getProgramTemplateBlockTableRowDTO, getProgramTemplateBlockTableRowFieldDTO} from "services/api/blocks/dto";
import {generateUUID} from "shared/helpers/utils/generate-uuid";
import type {
  ProgramTemplateTableSectionType,
  ProgramTemplateBlockTableSection,
  ProgramTemplateBlockTableRow
} from "services/api";
import type {TableConstructorSingleColumn} from "../../types";
import {fieldProperties} from "../fields";

export const createTableRow = (
  sectionId: ProgramTemplateBlockTableSection["id"],
  sectionType: ProgramTemplateTableSectionType,
  rowIndex: ProgramTemplateBlockTableSection["index"],
  singleColumns: TableConstructorSingleColumn[],
  createdOnWorkProgram: boolean,
) => {
  return getProgramTemplateBlockTableRowDTO({
    id: generateUUID("fake_"),
    section: {id: sectionId},
    index: rowIndex,
    type: "SECTION_BODY_ROW",
    createdOnWorkProgram,
    fields: singleColumns
      .map((column, columnIndex) => {
        return getProgramTemplateBlockTableRowFieldDTO({
          id: generateUUID("fake_"),
          row: null,
          index: columnIndex,
          type: column.type,
          value: fieldProperties.getInitialFieldValue(sectionType, column.type, columnIndex, singleColumns.length),
          required: column.required,
          placeholder: column.placeholder,
          readonly: fieldProperties.getInitialFieldReadonly(sectionType, columnIndex, singleColumns.length),
        });
      })
      .filter(field => field.type !== "INDEX")
  });
};

export const copyTableRow = (
  currentRow: ProgramTemplateBlockTableRow,
  rowIndex: ProgramTemplateBlockTableSection["index"],
  createdOnWorkProgram: boolean,
) => {
  return getProgramTemplateBlockTableRowDTO({
    ...currentRow,
    id: generateUUID("fake_"),
    index: rowIndex,
    createdOnWorkProgram,
    fields: currentRow.fields.map(field => {
      return {
        ...field,
        row: null,
        id: generateUUID("fake_"),
      };
    })
  });
};

export const checkMoreThanOneSectionBodyRowExists = (rows: ProgramTemplateBlockTableRow[]) => {
  let singleSectionBodyRowExist = false;

  for (let i = 0; i < rows.length; i++) {
    if (rows[i].type === "SECTION_BODY_ROW") {
      if (!singleSectionBodyRowExist) {
        singleSectionBodyRowExist = true;
      } else {
        return true;
      }
    }
  }

  return false;
};

export const getSectionBodyRowIndexAbove = (
  rows: ProgramTemplateBlockTableRow[],
  startIndex: number,
): number => {
  /*
    Here we sure that rows contain at least two rows with type "SECTION_BODY_ROW"
   */

  while (rows.at(startIndex)!.type !== "SECTION_BODY_ROW") {
    if (startIndex === 0) {
      startIndex = rows.length - 1;
    } else {
      startIndex--;
    }
  }

  return startIndex;
};

export const getSectionBodyRowIndexBelow = (
  rows: ProgramTemplateBlockTableRow[],
  startIndex: number,
): number => {
  /*
    Here we sure that rows contain at least two rows with type "SECTION_BODY_ROW"
   */

  while (rows.at(startIndex)!.type !== "SECTION_BODY_ROW") {
    if (startIndex === rows.length - 1) {
      startIndex = 0;
    } else {
      startIndex++;
    }
  }

  return startIndex;
};

export const makeLowestSectionBodyRowIndexHighest = (
  rows: ProgramTemplateBlockTableRow[],
  startIndex: number,
  endIndex: number,
): void => {
  for (let i = startIndex; i <= endIndex; i++) {
    rows[i].index--;
  }

  rows[startIndex].index = rows[endIndex].index + 1;
};

export const makeHighestSectionBodyRowIndexLowest = (
  rows: ProgramTemplateBlockTableRow[],
  startIndex: number,
  endIndex: number,
): void => {
  for (let i = startIndex; i <= endIndex; i++) {
    rows[i].index++;
  }

  rows[endIndex].index = rows[startIndex].index - 1;
};

export const incrementRowIndexes = (
  rows: ProgramTemplateBlockTableRow[],
  startIndex: number,
): void => {
  for (let i = startIndex; i <= rows.length - 1; i++) {
    rows[i].index++;
  }
};