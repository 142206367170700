import cn from "classnames";
import type {ModalMaskProps} from "./ModalMask.types";
import styles from "./ModalMask.module.scss";

export const ModalMask = ({
  visible,
  onClick,
  className,
  style,
  modalStatus,
}: ModalMaskProps) => {
  const classNames = cn(
    styles["modal-mask"],
    !visible && styles["modal-mask_transparent"],
    visible && styles[`modal-mask_${modalStatus.name}_${modalStatus.stage}`],
    className,
  );

  return (
    <div className={classNames} style={style} onClick={onClick}/>
  );
};