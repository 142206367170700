import {useEffect, useRef} from "react";
import {Form} from "antd";
import {AppInputNumber} from "shared/UI/UIKit/InputNumber";
import {AppForm, AppFormItem} from "shared/UI/UIKit/Form";
import {formatNumber} from "shared/helpers/utils/format-number";
import type {Callbacks as FormCallbacks} from "rc-field-form/lib/interface";
import type {FocusEvent} from "react";
import type {NumberValueEditorProps, NumberValueEditorFormValues} from "./types";
import {formNames} from "./constants";
import {getInitialFormValues, getSubmitValues, formFieldValidators, formRules} from "./utils";
import styles from "./styles.module.scss";

export const NumberValueEditor = ({value, onSubmit, onKeyDown}: NumberValueEditorProps) => {
  const [form] = Form.useForm<NumberValueEditorFormValues>();
  const formValuesRef = useRef<NumberValueEditorFormValues>(); // we use ref because form values aren't available at unmount stage

  const handleInputValueBlur = (e: FocusEvent<HTMLInputElement>) => {
    (e.target as HTMLInputElement).focus();
  };

  const handleFormValuesChange: FormCallbacks<NumberValueEditorFormValues>["onValuesChange"] = (
    changedValues, values
  ) => {
    formValuesRef.current = {
      value: formatNumber(values.value),
    };
  };

  useEffect(() => {
    const submit = async () => {
      try {
        const {checkValueValid} = formFieldValidators;
        await checkValueValid(formValuesRef.current!.value, !!value.required);
        const submitValues = getSubmitValues(formValuesRef.current!);
        onSubmit(submitValues);
      } catch (e) {
        // just don't submit values
      }
    };

    return () => {
      submit();
    };
  }, []);

  return (
    <AppForm form={form} onValuesChange={handleFormValuesChange}
             initialValues={getInitialFormValues({
               value: value.initialValue,
             })}>
      <AppFormItem name={formNames.value} marginless
                   rules={[formRules.getValueValidator(!!value.required)]}>
        <AppInputNumber<string> stringMode autoFocus onBlur={handleInputValueBlur}
                                onKeyDown={onKeyDown}
                                className={styles["input-number"]}
                                formatter={val => formatNumber(val)}
                                placeholder={value.placeholder || ""}/>
      </AppFormItem>
    </AppForm>
  );
};