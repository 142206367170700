import {VerticalMenuHelpers} from "shared/UI/UIKit/Menu";
import type {TextMenuItemOptions} from "shared/UI/UIKit/Menu";
import styles from "./styles.module.scss";

export const getDropdownMenuItem = (options: TextMenuItemOptions) => {
  return VerticalMenuHelpers.getTextMenuItem({
    className: styles["dropdown-menu-item"],
    wrapperClassName: styles["dropdown-menu-item-wrapper"],
    ellipsis: false,
    ...options,
  });
};