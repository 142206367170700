import type {AppSelectOption} from "shared/UI/UIKit/Select";
import {FIELD_CONSTANTS} from "../../../../../lib/constants";

export const stringOptionsToSelectOptions = (options: string | null | undefined): AppSelectOption[] => {
  if (!options) {
    return [];
  }

  return options
    .split(FIELD_CONSTANTS.MULTIPLE_VALUE_SPLITTER)
    .map(option => ({label: option, value: option}));
};