import {errorMessages} from "../../error-messages";

export const passwordValidator = (msg = "Введен недопустимый пароль") => {
  return (_: any, value: string | null | undefined) => {
    if (!value) {
      return Promise.reject(errorMessages.required);
    }

    if (/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?([^\w\s]|[_])).{8,}$/.test(value)) {
      return Promise.resolve();
    }

    return Promise.reject(msg);
  };
};