import {createAsyncThunk} from "@reduxjs/toolkit";
import {
  createMainProgram,
  getCurriculumsByEducationLevel,
  getWorkProgramsBySubjectId
} from "services/api";
import {mockBlocks} from "services/api/main-programs/mock-main-program-blocks";
import {toOptions} from "shared/helpers/utils/to-options";
import type {CreateMainProgramBody, GetCurriculumsByEducationLevelRequest, WorkProgram} from "services/api";
import type {AppSelectOption} from "shared/UI/UIKit/Select";
import type {RootState} from "services/redux/types";
import {extractUniqueSubjectIdsFromSelectedCurriculums, extractWorkProgramItems} from "./utils";

export const getCurriculumsByEducationLevelAction = createAsyncThunk("createMainProgram/getCurriculums", async(body: Omit<GetCurriculumsByEducationLevelRequest, "educationLevel">, thunkAPI) => {
  const {selectedEducationLevel} = (thunkAPI.getState() as RootState).createMainProgramState;

  try {
    return await getCurriculumsByEducationLevel({
      educationLevel: selectedEducationLevel!,
      ...body,
    });
  } catch (e: unknown) {
    throw new Error(e as string);
  }
});

export const getWorkProgramsBySubjectsAction = createAsyncThunk("createMainProgram/getWorkPrograms", async(_, thunkAPI) => {
  const {selectedCurriculumsEntities, selectedEducationLevel} = (thunkAPI.getState() as RootState).createMainProgramState;
  const {profile} = (thunkAPI.getState() as RootState).profileState;
  const selectedUniqueSubjectIds = extractUniqueSubjectIdsFromSelectedCurriculums(selectedCurriculumsEntities);

  try {
    const promises = selectedUniqueSubjectIds.map((item) => getWorkProgramsBySubjectId(item, selectedEducationLevel!, profile?.organization.id));
    const response = await Promise.allSettled(promises);

    return selectedUniqueSubjectIds.reduce((acc, subjectId, index) => {
      if (response[index].status === "fulfilled") {
        acc[subjectId] = toOptions((response[index] as PromiseFulfilledResult<WorkProgram[]>).value, "id", "programName");
      } else {
        acc[subjectId] = [];
      }
      return acc;
    }, {} as Record<number, AppSelectOption[]>);

  } catch (e: unknown) {
    throw new Error(e as string);
  }
});

export const reloadWorkProgramsBySubjectIdAction = createAsyncThunk("createMainProgram/reloadWorkPrograms", async(subjectId: number, thunkAPI) => {
  const {profile} = (thunkAPI.getState() as RootState).profileState;
  const {selectedEducationLevel} = (thunkAPI.getState() as RootState).createMainProgramState;

  try {
    const res = await getWorkProgramsBySubjectId(subjectId, selectedEducationLevel!, profile?.organization.id);

    return {
      workPrograms: res || [],
      subjectId,
    };
  } catch (e: unknown) {
    throw new Error(e as string);
  }
});

export const createMainProgramAction = createAsyncThunk("createMainProgram/createMainProgram", async(_, thunkAPI) => {
  const {
    selectedEducationLevel,
    selectedCurriculumsEntities,
    selectedWorkProgramByFieldName,
  } = (thunkAPI.getState() as RootState).createMainProgramState;
  const selectedWorkProgramsItems = extractWorkProgramItems(selectedWorkProgramByFieldName, selectedCurriculumsEntities);

  const createMainProgramBody: CreateMainProgramBody = {
    educationLevel: selectedEducationLevel!,
    curriculums: selectedCurriculumsEntities.map(curriculum => {
      return {
        ...curriculum,
        subjects: curriculum.subjects.map(item => {
          return {
            id: item.id,
            name: item.name,
            obligation: item.obligation,
            planId: item.planId,
            position: item.position,
            subjectAreaId: item.subjectAreaId,
            initialPosition: item.initialPosition,
          };
        })
      };
    }),
    items: selectedWorkProgramsItems.map(mainProgramItem => {
      return {
        ...mainProgramItem,
        curriculum: {
          ...mainProgramItem.curriculum,
          subjects: mainProgramItem.curriculum.subjects.map(item => {
            return {
              id: item.id,
              name: item.name,
              obligation: item.obligation,
              planId: item.planId,
              position: item.position,
              subjectAreaId: item.subjectAreaId,
              initialPosition: item.initialPosition,
            };
          })
        },
        subject: {
          id: mainProgramItem.subject.id,
          name: mainProgramItem.subject.name,
          obligation: mainProgramItem.subject.obligation,
          planId: mainProgramItem.subject.planId,
          position: mainProgramItem.subject.position,
          subjectAreaId: mainProgramItem.subject.subjectAreaId,
          initialPosition: mainProgramItem.subject.initialPosition,
        }
      };
    }),
    blocks: mockBlocks,
  };

  try {
    const res = await createMainProgram(createMainProgramBody);

    return res.data;
  } catch (e: unknown) {
    throw new Error(e as string);
  }
});
