import type {TableWithModalsProps} from "./types";
import {TableWithActions} from "../TableWithActions";
import {TableSettingsModal} from "../../modals/TableSettingsModal";
import {ColumnSettingsModal} from "../../modals/ColumnSettingsModal";
import {CellSettingsModal} from "../../modals/CellSettingsModal";
import {useTableMethods} from "./hooks/use-table-methods";
import {useTableModals} from "./hooks/use-table-modals";

export const TableWithModals = (props: TableWithModalsProps) => {
  const [, {
    tableMethods,
    tableHeaderMethods,
    tableModuleMethods,
    tableSectionMethods,
    tableRowMethods,
    tableCellMethods,
  }] = useTableMethods({
    table: props.table,
    onTableUpdate: props.onTableUpdate,
    onTableRemove: props.onTableRemove,
  });

  const [
    {
      tableSettingsModalState,
      columnSettingsModalState,
      cellSettingsModalState,
    },
    {
      tableSettingsModalMethods,
      columnSettingsModalMethods,
      cellSettingsModalMethods,
      clickedCellMethods,
    }
  ] = useTableModals();

  return (
    <>
      <TableWithActions table={props.table} containerClassName={props.containerClassName}
                        tableMethods={{
                          remove: tableMethods.remove,
                          showSettings: tableSettingsModalMethods.show,
                        }}
                        headerMethods={{
                          updateCell: tableHeaderMethods.updateCell,
                          showColumnSettings: columnSettingsModalMethods.show,
                        }}
                        moduleMethods={{
                          create: tableModuleMethods.create,
                          insert: tableModuleMethods.insert,
                          copy: tableModuleMethods.copy,
                          move: tableModuleMethods.move,
                          remove: tableModuleMethods.remove,
                        }}
                        sectionMethods={{
                          create: tableSectionMethods.create,
                          insert: tableSectionMethods.insert,
                          copy: tableSectionMethods.copy,
                          move: tableSectionMethods.move,
                          remove: tableSectionMethods.remove,
                        }}
                        rowMethods={{
                          create: tableRowMethods.create,
                          insert: tableRowMethods.insert,
                          copy: tableRowMethods.copy,
                          move: tableRowMethods.move,
                          remove: tableRowMethods.remove,
                          dragEnd: tableRowMethods.dragEnd
                        }}
                        cellMethods={{
                          click: clickedCellMethods.click,
                          update: tableCellMethods.update,
                        }}/>
      <TableSettingsModal visible={tableSettingsModalState.visible}
                          table={props.table} onTableUpdate={tableMethods.update}
                          onHide={tableSettingsModalMethods.hide}/>
      <ColumnSettingsModal visible={columnSettingsModalState.visible}
                           column={columnSettingsModalState.payload?.column}
                           onColumnUpdate={tableHeaderMethods.updateColumn}
                           onHide={columnSettingsModalMethods.hide}/>
      <CellSettingsModal visible={cellSettingsModalState.visible}
                         cell={cellSettingsModalState.payload}
                         onCellUpdate={tableCellMethods.update}
                         onHide={cellSettingsModalMethods.hide}/>
    </>
  );
};