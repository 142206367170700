export function getByPath<TResult = unknown>(item: object, path: string[]): TResult | undefined;
export function getByPath<TResult = unknown, TDefault = unknown>(item: object, path: string[], defaultValue: TDefault): TResult | TDefault;
export function getByPath<TResult = unknown, TDefault = unknown>(item: object, path: string[], defaultValue?: TDefault): TResult | TDefault | undefined {
  if (!path.length) {
    return defaultValue as TDefault;
  }

  const result = path.reduce((value, subPath) => {
    return (value as any)?.[subPath];
  }, item);

  return result !== undefined
    ? result as unknown as TResult
    : defaultValue as TDefault;
}