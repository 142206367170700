import {NavLink} from "react-router-dom";
import {forwardRef} from "react";
import type {AppNavLinkProps} from "./NavLink.types";
import {getClassNames} from "./NavLink.utils";

export const AppNavLink = forwardRef<HTMLAnchorElement, AppNavLinkProps>(
  (props, ref) => {
    const {size, children, className} = props;

    const getActiveClassNames = ({isActive}: { isActive: boolean }) => getClassNames({size, className, isActive});

    return (
      <NavLink {...props} className={getActiveClassNames} ref={ref}>
        {children}
      </NavLink>
    );
  }
);