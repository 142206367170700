import {AppChevronLeftIcon} from "shared/UI/UIKit/icons/Chevron";
import type {AppBreadcrumbSeparatorProps} from "./BreadcrumbSeparator.types";
import styles from "./BreadcrumbSeparator.module.scss";

export const AppBreadcrumbItemSeparator = (props: AppBreadcrumbSeparatorProps) => {
  const {separator = <AppChevronLeftIcon className={styles.chevron}/>} = props;
  return (
    <span className={styles.separator}>
      {separator}
    </span>
  );
};