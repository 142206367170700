import React, {Fragment} from "react";
import {AppFlex} from "shared/UI/UIKit/Flex";
import {SubjectObligation} from "services/api";
import {WORK_PROGRAMS_GROUP_NAME} from "common/constants";
import {usePayloadedModal} from "shared/UI/UIKit/Modal";
import {AppText} from "shared/UI/UIKit/Text";
import {
  CreateRequiredElementModal
} from "features/create-main-program/components/ThirdStep/SubjectsList/CreateRequiredElementModal";
import {useAppSelector} from "services/redux/hooks/use-selector";
import {
  selectCreateMainProgramSelectedCurriculumsEntitiesSubjects
} from "features/create-main-program/redux/selectors";
import type {Curriculum, MainProgramItem, Subject} from "services/api";
import {GroupedSubjectsList} from "../GroupedSubjectsList";
import {CreateNewElementModal} from "./CreateNewElementModal";

type SubjectsListProps = {
  subjects: Subject[];
  curriculum: Curriculum;
  isLastCurriculumItem: boolean;
  addMainProgramItemToStateByFieldName: (fieldName: string, item: MainProgramItem) => void;
}

type CreateNewElementModalPayload = {
  subjectType: SubjectObligation.ADDITIONAL | SubjectObligation.EXTRACURRICULAR;
}

export const SubjectsList = (props: SubjectsListProps) => {
  const selectedCurriculumsEntitiesSubjects = useAppSelector(selectCreateMainProgramSelectedCurriculumsEntitiesSubjects);
  const requiredSubjects = props.subjects?.filter(item => item.obligation === SubjectObligation.REQUIRED) || [];
  const additionalSubjects = selectedCurriculumsEntitiesSubjects?.filter(item => item.obligation === SubjectObligation.ADDITIONAL) || [];
  const extracurricularSubjects = selectedCurriculumsEntitiesSubjects?.filter(item => item.obligation === SubjectObligation.EXTRACURRICULAR) || [];
  const {state, methods} = usePayloadedModal<CreateNewElementModalPayload>();
  const {state: addRequiredModalState, methods: addRequiredModalMethods} = usePayloadedModal<{id: number, indexToInsert: number}>();

  const showCreateNewElementModal = (subjectType: SubjectObligation.ADDITIONAL | SubjectObligation.EXTRACURRICULAR) => () => {
    methods.show({subjectType});
  };

  const showCreateRequiredElementModal = (id: number, indexToInsert: number) => {
    addRequiredModalMethods.show({id, indexToInsert});
  };

  return (
    <AppFlex unit="40">
      <GroupedSubjectsList subjects={requiredSubjects}
                           title={WORK_PROGRAMS_GROUP_NAME.REQUIRED}
                           curriculum={props.curriculum}
                           required
                           addMainProgramItemToStateByFieldName={props.addMainProgramItemToStateByFieldName}
                           showCreateRequiredNewElementModal={showCreateRequiredElementModal}/>
      {
        props.isLastCurriculumItem && (
          <Fragment>
            <AppText size={24}>
              Выберите рабочие программы по внеурочной деятельности и по учебных предметам, входящим в часть учебного плана, формируемую участниками образовательных отношений
            </AppText>
            <GroupedSubjectsList subjects={additionalSubjects || []}
                                 title={WORK_PROGRAMS_GROUP_NAME.ADDITIONAL}
                                 curriculum={props.curriculum}
                                 addMainProgramItemToStateByFieldName={props.addMainProgramItemToStateByFieldName}
                                 showCreateNewElementModal={showCreateNewElementModal(SubjectObligation.ADDITIONAL)}/>
            <GroupedSubjectsList subjects={extracurricularSubjects || []}
                                 title={WORK_PROGRAMS_GROUP_NAME.EXTRACURRICULAR}
                                 curriculum={props.curriculum}
                                 addMainProgramItemToStateByFieldName={props.addMainProgramItemToStateByFieldName}
                                 showCreateNewElementModal={showCreateNewElementModal(SubjectObligation.EXTRACURRICULAR)}/>
          </Fragment>
        )
      }
      {
        state.payload?.subjectType && (
          <CreateNewElementModal curriculum={props.curriculum}
                                 closeModal={methods.hide}
                                 isOpen={state.visible}
                                 subjectType={state.payload?.subjectType}
                                 additionalSubjects={additionalSubjects}
                                 extracurricularSubjects={extracurricularSubjects}/>
        )
      }
      {
        addRequiredModalState.payload?.id && addRequiredModalState.payload?.indexToInsert && (
          <CreateRequiredElementModal curriculum={props.curriculum}
                                      closeModal={addRequiredModalMethods.hide}
                                      isOpen={addRequiredModalState.visible}
                                      id={addRequiredModalState.payload?.id}
                                      indexToInsert={addRequiredModalState.payload?.indexToInsert}
                                      requiredSubjects={requiredSubjects}/>
        )
      }
    </AppFlex>
  );
};

