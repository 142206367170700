import React from "react";
import {AppButton} from "shared/UI/UIKit/Button";
import type {PaginationProps} from "antd";

export const showTotal = (total: number, range: [number, number]) => {
  return `${range[0]} - ${range[1]} из ${total}`;
};

export const itemRender: PaginationProps["itemRender"] = (page, type, element) => {
  if (type === "prev") {
    return <AppButton type="outline">Назад</AppButton>;
  }
  if (type === "next") {
    return <AppButton type="outline">Дальше</AppButton>;
  }
  return element;
};