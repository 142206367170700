import {Switch} from "antd";
import {AppText} from "shared/UI/UIKit/Text";
import {AppButton} from "shared/UI/UIKit/Button";
import type {MouseEvent} from "react";
import type {AppTableColumn} from "shared/UI/UIKit/Table";
import type {WorkProgram} from "services/api";

export const getWorkProgramsTableColumns = (
  toggleVisibleInMainProgram: (record: WorkProgram) => void,
  toggleVisibleInMainProgramList: (record: WorkProgram) => void,
): AppTableColumn<WorkProgram>[] => {
  return [
    {
      key: "id",
      title: "ID",
      dataIndex: ["id"],
      maxWidth: "10rem"
    },
    {
      key: "name",
      title: "Название шаблона",
      dataIndex: ["name"],
      render: (value: any, record: WorkProgram) => {
        return (
          <AppText>
            {record.name}
          </AppText>
        );
      }
    },
    {
      key: "programName",
      title: "Название",
      dataIndex: ["programName"],
      render: (value: any, record: WorkProgram) => {
        return (
          <AppText>
            {record.programName}
          </AppText>
        );
      }
    },
    {
      key: "subject",
      title: "Предмет",
      dataIndex: ["subject", "name"],
    },
    {
      key: "hasDocx",
      title: "Есть docx",
      dataIndex: ["hasDocx"],
      render: (_: any, record: WorkProgram) => {
        return (
          <AppText type={record.hasDocx ? "success" : "warning"}>
            {record.hasDocx ? "Есть" : "Нет"}
          </AppText>
        );
      }
    },
    {
      key: "showInMainPrograms",
      title: "Доступно в ООП",
      dataIndex: ["showInMainPrograms"],
      onCell: () => {
        return {
          onClick: (e: MouseEvent) => {
            e.stopPropagation();
          },
        };
      },
      render: (value: any, record: WorkProgram) => {
        return (
          <Switch onChange={() => toggleVisibleInMainProgram(record)} checked={record.showInMainPrograms}/>
        );
      }
    },
    {
      key: "controls",
      title: "",
      dataIndex: [],
      minWidth: "18.5rem",
      onCell: () => {
        return {
          onClick: (e: MouseEvent) => {
            e.stopPropagation();
          },
        };
      },
      render: (_: any, record: WorkProgram) => {
        const onClick = (e: MouseEvent) => {
          e.preventDefault();
          toggleVisibleInMainProgramList(record);
        };

        return (
          <AppButton onClick={onClick}>
            {record.hideForManagerInMainProgram ? "Показать РП" : "Скрыть РП"}
          </AppButton>
        );
      }
    },
    {
      key: "controls_1",
      title: "",
      dataIndex: [],
      minWidth: "18.5rem",
      maxWidth: "18.5rem",
      onCell: () => {
        return {
          onClick: (e: MouseEvent) => {
            e.stopPropagation();
          },
        };
      },
      render: (_: any, record: WorkProgram) => {
        const onClick = (e: MouseEvent) => {
          e.preventDefault();
          const url = `${process.env.REACT_APP_CONSTRUCTOR_URL}/work-programs/${record.id}`;
          window.open(url, "_blank");
        };

        return (
          <AppButton onClick={onClick}>
            В конструктор
          </AppButton>
        );
      }
    },
  ];
};