import type {TableSectionCreatorProps} from "./types";
import {AddButton} from "../../../ui/components/AddButton";
import styles from "./styles.module.scss";

export const TableSectionCreator = (props: TableSectionCreatorProps) => {
  return (
    <tr>
      <td colSpan={props.colSpan} className={styles.td}>
        <AddButton label="Добавить раздел" labelSize={14}
                   onClick={props.onSectionCreate}/>
      </td>
    </tr>
  );
};