import {forwardRef} from "react";
import type {AppFlexProps} from "./Flex.types";
import {getClassNames} from "./Flex.utils";

export const AppFlex = forwardRef<HTMLDivElement, AppFlexProps>((props, ref) => {
  const {
    display = "flex",
    direction = "column",
    unit = "16",
    wrap,
    justifyContent,
    alignItems,
    className,
    ...rest
  } = props;
  const classNames = getClassNames({display, direction, unit, justifyContent, alignItems, wrap, className});

  return (
    <div {...rest} ref={ref} className={classNames}>
      {props.children}
    </div>
  );
});