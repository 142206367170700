import {useEffect, useRef} from "react";
import {useScrollLock} from "shared/helpers/hooks/use-scroll-lock";
import type {KeyboardEvent, MouseEvent} from "react";
import type {UseBaseModalOptions} from "./types";
import {useModalStatus} from "../use-modal-status";

export const useBaseModal = ({
  visible,
  destroyOnClose,
  forceRender,
  onCancel,
  afterClose,
  loading,
  maskClosable,
  keyboard,
  getContainer,
}: UseBaseModalOptions) => {
  const modalWrapperRef = useRef<HTMLDivElement | null>(null);
  const status = useModalStatus({
    visible,
    destroyOnClose,
    forceRender,
    onStatusChanged: (currentStatus) => {
      if (currentStatus.name !== "visible" && currentStatus.stage === "done") {
        afterClose?.();
      }
    },
  });
  useScrollLock(status.name === "visible" || status.stage !== "done", getContainer);

  const isMaskClosable = !loading && maskClosable;
  const isClosableByKeyboard = !loading && keyboard;

  const handleMaskClick = (e: MouseEvent<HTMLElement>) => {
    if (isMaskClosable) {
      onCancel?.(e);
    }
  };

  const handleCloseBtnClick = (e: MouseEvent<HTMLElement>) => {
    onCancel?.(e);
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    if (isClosableByKeyboard && e.key === "Escape") {
      onCancel?.(e);
    }
  };

  useEffect(() => {
    if (status.name === "visible" && status.stage === "init") {
      modalWrapperRef.current?.focus();
    }
  }, [status]);

  return [
    {
      modalWrapperRef,
      status,
    },
    {
      handleMaskClick,
      handleCloseBtnClick,
      handleKeyDown,
    }
  ] as const;
};