import classNames from "classnames";
import {AppTextEditor} from "shared/UI/UIKit/TextEditor";
import type {MouseEvent} from "react";
import {usePasteInDocumentEditor} from "./use-paste-in-document-editor";
import styles from "./styles.module.scss";

export type BlockTextEditorProps = {
  id: number;
  content: string;
  editable?: boolean;
  className?: string;
  setData?: (value: string) => void;
  onClick?: (e: MouseEvent<HTMLDivElement>) => void;
}

export const DocumentEditor = (props: BlockTextEditorProps) => {
  const {editorRef, onPaste} = usePasteInDocumentEditor(props);
  return (
    <div className={classNames(styles.editor, props.className)} key={props.id}>
      <AppTextEditor readOnly={!props.editable}
                     disable={!props.editable}
                     hideToolbar={!props.editable}
                     onChange={props.setData}
                     onEditorClick={props.onClick}
                     defaultValue={props.content}
                     onPaste={onPaste}
                     editorRef={editorRef}/>
    </div>
  );
};