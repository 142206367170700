import {
  getProgramTemplateBlockTableDTO,
  getProgramTemplateBlockTableRowDTO,
  getProgramTemplateBlockTableRowFieldDTO,
  getProgramTemplateBlockTableSectionDTO
} from "services/api/blocks/dto";
import {generateUUID} from "shared/helpers/utils/generate-uuid";
import type {ProgramTemplateBlockTableSection,ProgramTemplateBlockTable} from "services/api";
import type {TableConstructorSingleColumn} from "../../types";
import type {
  PropertiesToCreateTable,
  OptionsToCreateTable,
  PropertiesToUpdateTable,
  OptionsToUpdateTable
} from "./types";
import {columnsHelpers} from "../columns";
import {fieldsHelpers} from "../fields";

export const createTable = (
 propertiesToCreateTable: PropertiesToCreateTable,
 optionsToCreateTable?: OptionsToCreateTable,
): ProgramTemplateBlockTable => {
  const tableHeader = columnsHelpers.addAdditionalColumnToHeader(propertiesToCreateTable.header);
  const tableFooter = getProgramTemplateBlockTableSectionDTO({
    id: generateUUID("fake_"),
    table: null,
    index: propertiesToCreateTable.type === "SIMPLE" ? 1 : 0,
    type: "TABLE_FOOTER",
    rows: [
      getProgramTemplateBlockTableRowDTO({
        id: generateUUID("fake_"),
        section: null,
        index: 0,
        type: "SECTION_FOOTER_ROW",
        fields: [
          getProgramTemplateBlockTableRowFieldDTO({
            id: generateUUID("fake_"),
            row: null,
            index: 1,
            type: "WIDGET_STRING",
            value: "**ОБЩЕЕ КОЛИЧЕСТВО ЧАСОВ ПО ПРОГРАММЕ**",
            required: true,
            readonly: true,
            placeholder: "Введите значение",
          }),
          getProgramTemplateBlockTableRowFieldDTO({
            id: generateUUID("fake_"),
            row: null,
            index: 2,
            type: "WIDGET_SUM",
            value: "",
            targetValue: "0",
            operator: null,
            required: true,
            placeholder: "ввод данных",
          }),
          ...(optionsToCreateTable?.showVerificationWorkHoursPercent
              ? [getProgramTemplateBlockTableRowFieldDTO({
                id: generateUUID("fake_"),
                row: null,
                index: 3,
                type: "WIDGET_PERCENT",
                value: "",
                targetValue: "10",
                operator: "max",
                required: true,
              })]
              : []
          ),
          ...(optionsToCreateTable?.showPracticalWorkHoursSum
              ? [getProgramTemplateBlockTableRowFieldDTO({
                id: generateUUID("fake_"),
                row: null,
                index: 4,
                type: "WIDGET_SUM",
                value: "",
                required: true,
              })]
              : []
          ),
        ],
      })
    ],
  });

  return getProgramTemplateBlockTableDTO({
    ...propertiesToCreateTable,
    id: generateUUID("fake_"),
    header: tableHeader,
    sections: [
      ...(propertiesToCreateTable.type === "SIMPLE"
        ? [getProgramTemplateBlockTableSectionDTO({
            id: generateUUID("fake_"),
            table: null,
            index: 0,
            type: "TABLE",
            rows: [],
          })]
        : []
      ),
      tableFooter,
    ]
  });
};

export const updateTable = (
  sourceTable: ProgramTemplateBlockTable,
  columns: TableConstructorSingleColumn[],
  propertiesToUpdateTable: PropertiesToUpdateTable,
  optionsToUpdateTable?: OptionsToUpdateTable,
): ProgramTemplateBlockTable => {
  const preUpdatedTable: ProgramTemplateBlockTable = {
    ...sourceTable,
    ...propertiesToUpdateTable,
  };

  return getProgramTemplateBlockTableDTO({
    ...preUpdatedTable,
    sections: preUpdatedTable.sections.map(section => {
      if (section.type === "TABLE") {
        return section;
      } else {
        return getUpdatedTableFooterSection(section, columns, optionsToUpdateTable);
      }
    })
  });
};


const getUpdatedTableFooterSection = (
  tableFooterSection: ProgramTemplateBlockTableSection,
  columns: TableConstructorSingleColumn[],
  options?: OptionsToUpdateTable,
): ProgramTemplateBlockTableSection => {
  const rows = tableFooterSection.rows;
  const footerRow = rows.find(row => row.type === "SECTION_FOOTER_ROW")!;
  const verificationWorkHoursPercentFieldIndex = 3;
  const practicalWorkHoursSumFieldIndex = 4;

  if (
    options?.showVerificationWorkHoursPercent &&
    !fieldsHelpers.isVerificationWorkHoursPercentCellExists(tableFooterSection, columns)
  ) {
    footerRow.fields = footerRow.fields.filter(field => field.index !== verificationWorkHoursPercentFieldIndex);
    footerRow.fields.push(getProgramTemplateBlockTableRowFieldDTO({
      id: generateUUID("fake_"),
      row: null,
      index: verificationWorkHoursPercentFieldIndex,
      type: "WIDGET_PERCENT",
      value: "",
      targetValue: "10",
      operator: "max",
      required: true,
    }));
  }
  if (
    !options?.showVerificationWorkHoursPercent &&
    fieldsHelpers.isVerificationWorkHoursPercentCellExists(tableFooterSection, columns)
  ) {
    footerRow.fields = footerRow.fields.filter(field => field.index !== verificationWorkHoursPercentFieldIndex);
  }

  if (
    options?.showPracticalWorkHoursSum &&
    !fieldsHelpers.isPracticalWorkHoursSumCellExists(tableFooterSection, columns)
  ) {
    footerRow.fields = footerRow.fields.filter(field => field.index !== practicalWorkHoursSumFieldIndex);
    footerRow.fields.push(getProgramTemplateBlockTableRowFieldDTO({
      id: generateUUID("fake_"),
      row: null,
      index: practicalWorkHoursSumFieldIndex,
      type: "WIDGET_SUM",
      value: "",
      required: true,
    }));
  }
  if (
    !options?.showPracticalWorkHoursSum &&
    fieldsHelpers.isPracticalWorkHoursSumCellExists(tableFooterSection, columns)
  ) {
    footerRow.fields = footerRow.fields.filter(field => field.index !== practicalWorkHoursSumFieldIndex);
  }

  return getProgramTemplateBlockTableSectionDTO({
    ...tableFooterSection,
    rows,
  });
};