import {useEvent} from "shared/helpers/hooks/use-event";
import type {ProgramTemplateBlockSettings, ProgramTemplateBlock, ProgramTemplateBlockTable} from "services/api";
import type {TableEditorProps} from "../types";
import {indexHelpers} from "../../lib/helpers";

export const useTableEditor = (props: TableEditorProps) => {
  const blockTables = indexHelpers.sortByIndex(props.block.tables);

  const _updateBlock = (nextBlockPart: Partial<ProgramTemplateBlock>) => {
    const nextBlock = {
      ...props.block,
      ...nextBlockPart,
    };
    props.setBlock?.(nextBlock);
  };

  const _updateBlockSettings = (nextSettings: ProgramTemplateBlockSettings) => {
    _updateBlock({
      settings: nextSettings,
    });
  };

  const _updateBlockTables = (nextTables: ProgramTemplateBlockTable[]) => {
    _updateBlock({
      tables: nextTables,
    });
  };

  const handleBlockSettingsUpdate = useEvent((settings: ProgramTemplateBlockSettings) => {
    _updateBlockSettings(settings);
  });

  const handleTableCreate = useEvent((createdTable: ProgramTemplateBlockTable) => {
    const nextTables = [...blockTables, createdTable];
    _updateBlockTables(nextTables);
  });

  const handleTableUpdate = useEvent((tableId: number | string, nextTable: ProgramTemplateBlockTable) => {
    const nextTables = [...blockTables];
    const tableIndex = nextTables.findIndex(table => table.id === tableId);
    nextTables[tableIndex] = nextTable;
    _updateBlockTables(nextTables);
  });

  const handleTableRemove = useEvent((tableId: number | string) => {
    const nextTables = [...blockTables];
    const tableIndex = nextTables.findIndex(table => table.id === tableId);
    nextTables.splice(tableIndex, 1);
    _updateBlockTables(nextTables);
  });

  return [
    {
      blockTables,
    },
    {
      blockSettingsMethods: {
        update: handleBlockSettingsUpdate,
      },
      blockTableMethods: {
        create: handleTableCreate,
        update: handleTableUpdate,
        remove: handleTableRemove,
      },
    }
  ] as const;
};