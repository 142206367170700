import {forwardRef} from "react";
import {AppMenu} from "shared/UI/UIKit/Menu";
import {AppDropdown} from "shared/UI/UIKit/Dropdown";
import type {Menu} from "antd";
import type {AppDropdownMenuProps} from "./DropdownMenu.types";
import {useAppDropdownMenu} from "./hooks/use-dropdown-menu";

export const AppDropdownMenu = forwardRef<Menu, AppDropdownMenuProps>((props, ref) => {
  const {menu: menuProps, ...dropdownProps} = props;
  const {onMenuItemClick, visible, onVisibleChange} = useAppDropdownMenu(
    menuProps.onClick, dropdownProps.visible, dropdownProps.onVisibleChange,
  );

  const overlay = <AppMenu {...menuProps}
                           onClick={onMenuItemClick}
                           ref={ref}/>;

  return (
    <AppDropdown {...dropdownProps}
                 overlay={overlay}
                 visible={visible}
                 onVisibleChange={onVisibleChange}/>
  );
});