import {AppPage} from "common/Layout";
import {AppFlex} from "shared/UI/UIKit/Flex";
import {AppLoader} from "shared/UI/UIKit/Loader";
import {AppTitle} from "shared/UI/UIKit/Title";

export const LogoutPage = () => {
  return (
    <AppPage documentTitle="Выход">
      <AppFlex direction="row" justifyContent="center">
        <AppTitle >Подождите, идёт выход из системы</AppTitle>
      </AppFlex>
      <AppLoader size="large"/>
    </AppPage>
  );
};