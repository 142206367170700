import cn from "classnames";
import {AppPlusIcon} from "shared/UI/UIKit/icons/Plus";
import type {SvgProps} from "shared/types";
import styles from "../styles.module.scss";

export const AddIcon = ({className, ...rest}: SvgProps) => {
  return (
    <AppPlusIcon className={cn(styles.icon, styles["icon_size_24"], styles["icon_fill"], className)}
                 {...rest}/>
  );
};