import {AppText} from "shared/UI/UIKit/Text";
import type {AppTextProps} from "shared/UI/UIKit/Text";
import type {SubMenuTitleItemProps} from "./SubMenuTitleItem.types";
import {useMenuContext} from "../../../ui/context";
import {getClassNames} from "./SubMenuTitleItem.utils";
import styles from "./SubMenuTitleItem.module.scss";

export const SubMenuTitleItem = (props: SubMenuTitleItemProps) => {
  const {id, label, icon, className, disabled, onClick, ellipsis} = props;
  const context = useMenuContext();
  const classNames = getClassNames(className);

  const onTextClickHandler: AppTextProps["onClick"] = (e) => {
    if (disabled) {
      return;
    }

    onClick?.(id, e);
    context.onClick({
      key: id,
      itemType: "SubmenuTitleItem",
      event: e,
    });
  };

  return (
    <AppText size={16} className={classNames} disabled={disabled}
             ellipsis={ellipsis} onClick={onTextClickHandler}>
      {icon && <span className={styles.icon}>{icon}</span>}
      <span>{label}</span>
    </AppText>
  );
};