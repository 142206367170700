import type {ProgramTemplateBlockTableRowField} from "services/api";
import type {CellValidationResult} from "./types";
import {makeErrorValidationResult, makeSuccessValidationResult} from "./utils";
import {validationMessages} from "./constants";

export const widgetPeriodCellValidator = (
  field: ProgramTemplateBlockTableRowField,
): CellValidationResult => {
  if (!field.value && field.required) {
    return makeErrorValidationResult(validationMessages.valueIsEmpty);
  }

  return makeSuccessValidationResult();
};