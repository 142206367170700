import {generateUUID} from "shared/helpers/utils/generate-uuid";
import type {TableConstructorSingleColumn} from "../../../types";

const getDefaultColumn = (): TableConstructorSingleColumn => {
  return {
    id: generateUUID(),
    name: "Название",
    type: "WIDGET_STRING",
    placeholder: "ввод данных",
    required: false,
    locked: false,
    lockDisabled: false,
    hidden: false,
  };
};

export const makeSingleColumn = (column: Partial<TableConstructorSingleColumn>): TableConstructorSingleColumn => {
  return {
    ...getDefaultColumn(),
    ...column,
  };
};