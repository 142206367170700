import {Form, notification} from "antd";
import {AppForm, AppFormItem} from "shared/UI/UIKit/Form";
import {AppInput} from "shared/UI/UIKit/Input";
import {AppFlex} from "shared/UI/UIKit/Flex";
import {AppButton} from "shared/UI/UIKit/Button";
import {AppCheckbox, AppCheckboxGroup} from "shared/UI/UIKit/Checkbox";
import {AppModalHeader, AppModalTitle, AppModalContent, AppModalFooter} from "shared/UI/UIKit/Modal";
import type {TableSettingsFormValues, TableSettingsFormProps} from "./types";
import {modalHelpers} from "../../../../lib/helpers";
import {formNames} from "./constants";
import {getHiddenColumnOptions, getInitialFormValues} from "./utils";
import styles from "./styles.module.scss";

export const TableSettingsForm = (props: TableSettingsFormProps) => {
  const [form] = Form.useForm<TableSettingsFormValues>();
  const filteredViewSettingsOptions = modalHelpers.filterViewSettingsOptions(
    modalHelpers.viewSettingsOptions, props.columns
  );
  const hiddenColumnOptions = getHiddenColumnOptions(props.columns);

  const handleSubmit = () => {
    form.validateFields()
      .then(values => {
        const nextValues: TableSettingsFormValues = {
          ...(props.options?.name?.visible
            ? {name: values.name}
            : {}
          ),
          ...(props.options?.viewSettings?.visible
              ? {viewSettings: values.viewSettings}
              : {}
          ),
          ...(props.options?.settings?.visible
              ? {settings: values.settings}
              : {}
          ),
          ...(props.options?.hiddenColumnIndexes?.visible
              ? {hiddenColumnIndexes: values.hiddenColumnIndexes}
              : {}
          ),
        };

        props.onSubmit(nextValues);
      })
      .catch((err) => {
        //remove after fix
        console.log("ERR", err);
        notification.error({message: err.toString()});
        return null;
      });
  };

  const renderName = () => {
    if (!props.options?.name?.visible) {
      return null;
    }

    return (
      <AppFormItem label="Наименование таблицы" size="xxl"
                   name={formNames.name}>
        <AppInput placeholder="Введите наименование таблицы"/>
      </AppFormItem>
    );
  };

  const renderViewSettings = () => {
    if (!props.options?.viewSettings?.visible || !filteredViewSettingsOptions.length) {
      return null;
    }

    return (
      <AppFormItem label="Настройки отображения" size="lg"
                   className={styles["view-settings-options-item"]}
                   name={formNames.viewSettings}>
        <AppCheckboxGroup direction="vertical">
          {filteredViewSettingsOptions.map((option) => {
            return (
              <AppCheckbox key={option.value} value={option.value}>
                {option.label}
              </AppCheckbox>
            );
          })}
        </AppCheckboxGroup>
      </AppFormItem>
    );
  };

  const renderSettings = () => {
    if (!props.options?.settings?.visible || !modalHelpers.tableSettingsOptions.length) {
      return null;
    }

    return (
      <AppFormItem label="Настройки таблицы" size="xxl"
                   className={styles["settings-options-item"]}
                   name={formNames.settings}>
        <AppCheckboxGroup direction="vertical">
          {modalHelpers.tableSettingsOptions.map((option) => {
            return (
              <AppCheckbox key={option.value} value={option.value}>
                {option.label}
              </AppCheckbox>
            );
          })}
        </AppCheckboxGroup>
      </AppFormItem>
    );
  };

  const renderHiddenColumnIndexes = () => {
    if (!props.options?.hiddenColumnIndexes?.visible || !hiddenColumnOptions.length) {
      return null;
    }

    return (
      <AppFormItem label="Скрывать следующие столбцы:"
                   className={styles["columns-options-item"]}
                   name={formNames.hiddenColumnIndexes}>
        <AppCheckboxGroup direction="vertical">
          {hiddenColumnOptions.map((option) => {
            return (
              <AppCheckbox key={option.value} value={option.value}>
                {option.label}
              </AppCheckbox>
            );
          })}
        </AppCheckboxGroup>
      </AppFormItem>
    );
  };

  return (
    <>
      <AppModalHeader>
        <AppModalTitle>Настройки таблицы</AppModalTitle>
      </AppModalHeader>
      <AppModalContent>
        <AppForm form={form} initialValues={getInitialFormValues(props.table, props.columns)}>
          {renderName()}
          {renderViewSettings()}
          {renderSettings()}
          {renderHiddenColumnIndexes()}
        </AppForm>
      </AppModalContent>
      <AppModalFooter>
        <AppFlex direction="row">
          <AppButton onClick={handleSubmit}>
            Сохранить
          </AppButton>
          <AppButton type="outline" onClick={props.onCancel}>
            Отмена
          </AppButton>
        </AppFlex>
      </AppModalFooter>
    </>
  );
};