import cn from "classnames";
import {AppExternalLink} from "shared/UI/UIKit/Link";
import type {LinkRendererProps} from "../types";
import styles from "../styles.module.scss";

export const LinkRenderer = ({href, text, target = "_blank", className, style}: LinkRendererProps) => {
  if (!href) {
    return null;
  }

  const classNames = cn(styles.link, className);

  return (
    <AppExternalLink href={href} target={target}
                     onClick={e => e.stopPropagation()}
                     className={classNames} style={style}>
      {text ?? href}
    </AppExternalLink>
  );
};