import {MainProgramsTableControls} from "../Controls";
import {MainProgramsTable} from "../Table";

type MainProgramsProps = {
  isTemplate?: boolean;
}

export const MainPrograms = (props: MainProgramsProps) => {
  return (
    <>
      <MainProgramsTableControls/>
      <MainProgramsTable isTemplate={props.isTemplate}/>
    </>
  );
};