import type {ExtracurricularTableBodyRow} from "services/api";
import {BodyRowCell} from "../Cell";

type BodyRowProps = {
  row: ExtracurricularTableBodyRow;
  editable?: boolean;
}

export const BodyRow = (props: BodyRowProps) => {
  return (
    <>
      <tr>
        {
          props.row.cells.map(cell => {
            return (
              <BodyRowCell editable={props.editable} cell={cell} key={cell.cellId}/>
            );
          })
        }
      </tr>
    </>
  );
};