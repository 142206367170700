import type {AppSelectOption} from "shared/UI/UIKit/Select";
import type {ListCellFormValues, ListCellSubmitValues} from "./types";
import {FIELD_CONSTANTS} from "../../../../../../lib/constants";

export const getInitialFormValues = (values: ListCellSubmitValues): ListCellFormValues => {
  const getValue = (): string[] => {
    if (!values.value) {
      return [];
    }

    return values.value.split(FIELD_CONSTANTS.MULTIPLE_VALUE_SPLITTER);
  };

  return {
    value: getValue(),
    options: values.options,
    readonly: values.readonly,
  };
};

export const getSubmitValues = (values: ListCellFormValues): ListCellSubmitValues => {
  const getValue = (): string | null => {
    if (!values.value) {
      return null;
    }

    return values.value.join(FIELD_CONSTANTS.MULTIPLE_VALUE_SPLITTER);
  };

  return {
    value: getValue(),
    options: values.options,
    readonly: values.readonly,
  };
};

export const optionsStringToSelectOptions = (options: string | null | undefined): AppSelectOption[] => {
  if (!options) {
    return [];
  }

  return options
    .split(FIELD_CONSTANTS.MULTIPLE_VALUE_SPLITTER)
    .map(option => ({label: option, value: option}));
};