import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {AppPage} from "common/Layout";
import {getMainProgramsUrl} from "services/navigation";

export const HomePage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(getMainProgramsUrl());
  }, []);

  return (
    <AppPage pageTitle="Главная" documentTitle="Главная">
    </AppPage>
  );
};

