import type {TableConstructorColumnType} from "../../types";

export const getColumnTypeLabel = (type: TableConstructorColumnType) => {
  switch (type) {
    case "INDEX": return "Индекс";
    case "WIDGET_STRING": return "Свободный ввод";
    case "WIDGET_NUMBER": return "Число";
    case "WIDGET_DATE": return "Дата";
    case "WIDGET_PERIOD": return "Период";
    case "WIDGET_LIST": return "Выбор из списка";
    case "WIDGET_REFERENCE": return "Выбор из справочника";
    default: return "";
  }
};

export const getColumnTypePlaceholder = (type: TableConstructorColumnType) => {
  switch (type) {
    case "INDEX": return "введите значение";
    case "WIDGET_STRING": return "введите значение";
    case "WIDGET_NUMBER": return "введите значение";
    case "WIDGET_DATE": return "введите дату";
    case "WIDGET_PERIOD": return "введите период";
    case "WIDGET_LIST": return "выберите значение";
    case "WIDGET_REFERENCE": return "выберите значение";
    default: return "";
  }
};