import type {ProgramTemplateTableSectionType} from "services/api";
import type {TableConstructorColumnType} from "../../types";

export const getInitialFieldValue = (
  sectionType: ProgramTemplateTableSectionType,
  columnType: TableConstructorColumnType,
  columnIndex: number,
  columnCount: number,
): string => {
  if (columnType === "WIDGET_STRING") {
    if (sectionType === "TABLE") {
      return "[[]]";
    }

    if (columnIndex === 1) {
      return "**[[Итого]]**";
    }

    if (columnIndex === columnCount - 1) {
      return "[[]]";
    }
  }

  return "";
};

export const getInitialFieldReadonly = (
  sectionType: ProgramTemplateTableSectionType,
  columnIndex: number,
  columnCount: number,
): boolean => {
  if (sectionType === "TABLE") {
    return false;
  }

  if (columnIndex >= 3 && columnIndex < columnCount - 1) {
    return true;
  }

  return false;
};