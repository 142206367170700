import {getNumericComparator} from "shared/helpers/utils/comparators";

export const validateBrackets = (str: string | null | undefined): boolean => {
  if (!str) {
    return true;
  }

  const positions: [number | undefined, number | undefined][] = [];
  const stack: number[] = [];

  let i = 0;
  while (i < str.length) {
    if (str[i] === "[" && str[i + 1] === "[") {
      positions.push([i, undefined]);
      stack.push(i);
      i += 2;
      continue;
    }

    if (str[i] === "]" && str[i + 1] === "]") {
      if (str[i + 2] === "]" && str[i + 3] !== "]") {
        i += 1;
        continue;
      }
      const index = positions.findIndex(pos => pos[0] === stack.at(-1));
      if (index === -1) {
        positions.push([undefined, i + 1]);
      } else {
        positions[index][1] = i + 1;
        stack.pop();
      }
      i += 2;
      continue;
    }

    i += 1;
  }

  const isStartPosAndEndPosExist = positions.some(pos => pos[0] !== undefined && pos[1] !== undefined);
  const isStartPosOrEndPosNotExist = positions.some(pos => pos[0] === undefined || pos[1] === undefined);
  const isNestedPosExists = positions.some((pos, _, arr) => {
    return arr.some(arrPos => {
      if ([arrPos[0], pos[0], arrPos[1], pos[1]].includes(undefined)) {
        return false;
      }

      return arrPos[0]! < pos[0]! && arrPos[1]! > pos[1]!;
    });
  });
  const isExtraPosExists = isStartPosAndEndPosExist && isStartPosOrEndPosNotExist;

  return !(isNestedPosExists || isExtraPosExists);
};

export const getBracketsPositions = (str: string | null | undefined): number[][] => {
  if (!str) {
    return [];
  }

  const positions: [number | undefined, number | undefined][] = [];
  let lastBracket: string | undefined;

  let i = 0;
  while (i < str.length) {
    if (str[i] === "[" && str[i + 1] === "[") {
      if (lastBracket === "[") {
        return []; // invalid case
      }
      positions.push([i, undefined]);
      lastBracket = "[";
      i += 2;
      continue;
    }

    if (str[i] === "]" && str[i + 1] === "]") {
      if (str[i + 2] === "]" && str[i + 3] !== "]") {
        i += 1;
        continue;
      }
      if (lastBracket === "]") {
        return []; // invalid case
      }
      if (positions.at(-1) === undefined) {
        return []; // invalid case
      }
      positions.at(-1)![1] = i + 1;
      lastBracket = "]";
      i += 2;
      continue;
    }

    i += 1;
  }

  if (positions.some(pos => pos[0] === undefined || pos[1] === undefined)) {
    return []; // invalid case
  }

  return positions as number[][];
};

const indexComparator = getNumericComparator("descend");

export const removeBracketsAtPositions = (str: string | null | undefined, positions: number[][] | undefined): string => {
  if (!str) {
    return "";
  }

  if (!positions || !positions.length) {
    return str;
  }

  const chars = str.split("");
  const indexes = positions.reduce((acc, [startIndex, endIndex]) => {
    return [...acc, startIndex, startIndex + 1, endIndex - 1, endIndex];
  }, [] as number[]);
  const descendSortedIndexes = indexes.sort(indexComparator);
  descendSortedIndexes.forEach(index => chars[index] = "");

  return chars.join("");
};

export const getValueAtPosition = (str: string | null | undefined, pos: number[]): string => {
  if (!str || pos.length < 2) {
    return "";
  }

  const startIndex = pos[0] + 2;
  const endIndex = pos[1] - 1;

  return str.slice(startIndex, endIndex);
};

export const replaceValueAtPosition = (str: string | null | undefined, pos: number[], replaceValue: string): string => {
  if (!str || pos.length < 2) {
    return "";
  }

  const startIndex = pos[0] + 2;
  const endIndex = pos[1] - 1;
  const chars = str.split("");
  chars.splice(startIndex, endIndex - startIndex, replaceValue);

  return chars.join("");
};