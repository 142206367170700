import {useEffect, useState} from "react";
import type {Key} from "react";
import type {ExpandableConfig} from "antd/lib/table/interface";
import type {AppTableItem} from "../../types";
import type {TableExpandableServiceOptions} from "./types";

export const useTableExpandable = <TRecord extends AppTableItem>({
  dataSource,
  expandable: expandableProp,
}: TableExpandableServiceOptions<TRecord>): ExpandableConfig<TRecord> => {
  const [localExpandedRowKeys, setLocalExpandedRowKeys] = useState<Key[]>([]);
  const {
    resetExpandedRowKeysOnDataSourceChange = true,
    expandedRowKeys = localExpandedRowKeys,
    onExpandedRowsChange = (keys: readonly Key[]) => setLocalExpandedRowKeys([...keys]),
    ...expandableRest
  } = expandableProp ?? {};

  useEffect(() => {
    if (resetExpandedRowKeysOnDataSourceChange) {
      onExpandedRowsChange([]);
    }
  }, [dataSource, resetExpandedRowKeysOnDataSourceChange]);

  return {
    expandedRowKeys,
    onExpandedRowsChange,
    ...expandableRest,
  };
};