import {useLocation} from "react-router-dom";
import type {Location} from "history";

type AppLocationState = {
  from?: Location;
}

export const useAppLocation = () => {
  const location = useLocation();

  return location as Location<AppLocationState>;
};