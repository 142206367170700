import {getProgramTemplateBlockTableHeaderDTO} from "services/api/blocks/dto";
import type {ProgramTemplateBlockTable} from "services/api";
import type {TableSettingsFormValues} from "./form";
import type {TableConstructorSingleColumn} from "../../../lib/types";
import {modalHelpers, tableHelpers} from "../../../lib/helpers";

export const getUpdatedTable = (
  table: ProgramTemplateBlockTable,
  columns: TableConstructorSingleColumn[],
  values: TableSettingsFormValues
): ProgramTemplateBlockTable => {
  const name = "name" in values
    ? (values.name || "")
    : table.name;
  const settings = "settings" in values
    ? modalHelpers.tableSettingsNamesToDTO(values.settings)
    : table.settings;
  const hiddenColumnIndexes = "hiddenColumnIndexes" in values
    ? (values.hiddenColumnIndexes || []).join(",")
    : table.header.hiddenColumnIndexes;
  const viewSettings = "viewSettings" in values
    ? (values.viewSettings || [])
    : modalHelpers.getViewSettings(table, columns);

  const propertiesToUpdateTable = {
    name,
    settings,
    header: getProgramTemplateBlockTableHeaderDTO({
      ...table.header,
      hiddenColumnIndexes,
    }),
  };
  const optionsToUpdateTable = {
    showVerificationWorkHoursPercent: !!viewSettings?.includes("showVerificationWorkHoursPercent"),
    showPracticalWorkHoursSum: !!viewSettings?.includes("showPracticalWorkHoursSum"),
  };

  return tableHelpers.updateTable(table, columns, propertiesToUpdateTable, optionsToUpdateTable);
};