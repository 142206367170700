import {PROGRAM_TEMPLATE_BLOCK_TYPE} from "services/api";
import {DocumentEditor, TableEditor} from "common/BlockEditors";
import {useAppSelector} from "services/redux/hooks/use-selector";
import {selectWorkProgram} from "features/main-program/redux/selectors";

export const WorkProgramViewer = () => {
  const workProgram = useAppSelector(selectWorkProgram);

  if (!workProgram) {
    return null;
  }

  return (
    <>
      {
        workProgram.blocks.map(block => {
          return block.type === PROGRAM_TEMPLATE_BLOCK_TYPE.DOCUMENT ? (
            <DocumentEditor id={block.id} content={block.content} key={block.id}/>
          ) : (
            <TableEditor isPrograms block={block} key={block.id}/>
          );
        })
      }
    </>
  );
};
