import cn from "classnames";
import {AppFlex} from "shared/UI/UIKit/Flex";
import {AppPlusAlternativeIcon} from "shared/UI/UIKit/icons/PlusAlternative";
import {AppText} from "shared/UI/UIKit/Text";
import type {AddButtonProps} from "./types";
import styles from "./styles.module.scss";

export const AddButton = (props: AddButtonProps) => {
  return (
    <AppFlex justifyContent="center" alignItems="center"
             direction="row" unit="14"
             className={cn(styles["btn-container"], props.className)}
             onClick={props.onClick}>
      <AppPlusAlternativeIcon/>
      <AppText size={props.labelSize ?? 16} className={styles["btn-label"]}>
        {props.label ?? "Добавить"}
      </AppText>
    </AppFlex>
  );
};