import {AppModalHeader, AppModalTitle} from "shared/UI/UIKit/Modal";
import type {ModalHeaderProps} from "./types";

export const ModalHeader = (props: ModalHeaderProps) => {
  const title = props.isFullAccess
    ? "Редактирование ячейки"
    : "Редактирование значения ячейки";

  return (
    <AppModalHeader>
      <AppModalTitle>{title}</AppModalTitle>
    </AppModalHeader>
  );
};