import type {ProgramTemplateBlockTableHeader} from "services/api";
import type {TableConstructorColumn, TableHeaderCellType} from "../../types";
import {columnsHelpers, headerHelpers} from "../../helpers";

export const getUpdatedHeaderByCell = (
  header: ProgramTemplateBlockTableHeader,
  updatedCell: TableHeaderCellType,
): ProgramTemplateBlockTableHeader => {
  const getLockedColumnIndexes = () => {
    if (headerHelpers.isTableHeaderCellParent(updatedCell)) {
      return header.lockedColumnIndexes;
    }

    const lockedColumnIndexesSet = new Set<string | null>(
      header.lockedColumnIndexes
        ? header.lockedColumnIndexes.split(",")
        : null
    );

    if (updatedCell.column.locked) {
      lockedColumnIndexesSet.add(`${updatedCell.meta.index}`);
    } else {
      lockedColumnIndexesSet.delete(`${updatedCell.meta.index}`);
    }

    return Array.from(lockedColumnIndexesSet.values()).join(",");
  };

  return {
    ...header,
    lockedColumnIndexes: getLockedColumnIndexes(),
  };
};

export const getUpdatedHeaderByColumn = (
  columns: TableConstructorColumn[],
  updatedColumn: TableConstructorColumn,
): ProgramTemplateBlockTableHeader => {
  const nextColumns = columnsHelpers.getUpdatedTableConstructorColumns(columns, updatedColumn);
  return columnsHelpers.getTableHeaderByColumns(nextColumns);
};