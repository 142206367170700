import {AppSelect, AppMultipleSelect} from "shared/UI/UIKit/Select";
import {AppFormItem, formRules} from "shared/UI/UIKit/Form";
import {AppCheckbox} from "shared/UI/UIKit/Checkbox";
import type {ReferenceCellFormProps} from "./types";
import {ModalHeader} from "../../ModalHeader";
import {ModalContent} from "../../ModalContent";
import {ModalFooter} from "../../ModalFooter";
import {formNames, formItemLabels} from "./constants";
import {useReferenceCellForm} from "./use-reference-cell-form";

export const ReferenceCellForm = (props: ReferenceCellFormProps) => {
  const [
    {
      form,
      referenceOptions,
      referenceItemsOptions,
      referenceOptionsLoading,
      referenceItemsOptionsLoading,
    },
    {handleListIdSelect, handleListIdClear, handleSubmit}
  ] = useReferenceCellForm(props);

  return (
    <>
      <ModalHeader isFullAccess={props.isFullAccess}/>
      <ModalContent form={form}>
        {props.value?.visible ? (
          <AppFormItem label={props.value?.label ?? formItemLabels.value}
                       rules={props.value?.required ? formRules.requiredArray : []}
                       name={formNames.value}>
            <AppMultipleSelect options={referenceItemsOptions} placeholder={props.field.placeholder || ""}
                               loading={referenceItemsOptionsLoading}/>
          </AppFormItem>
        ) : null}
        {props.listId?.visible ? (
          <AppFormItem label={props.listId?.label ?? formItemLabels.listId}
                       rules={props.listId?.required ? formRules.required : []}
                       name={formNames.listId}>
            <AppSelect options={referenceOptions} placeholder="выберите справочник"
                       loading={referenceOptionsLoading} allowClear
                       onSelect={handleListIdSelect} onClear={handleListIdClear}/>
          </AppFormItem>
        ) : null}
        {props.readonly?.visible ? (
          <AppFormItem valuePropName="checked"
                       rules={props.readonly?.required ? formRules.required : []}
                       name={formNames.readonly}>
            <AppCheckbox>{props.readonly?.label ?? formItemLabels.readonly}</AppCheckbox>
          </AppFormItem>
        ) : null}
      </ModalContent>
      <ModalFooter onSubmit={handleSubmit} onCancel={props.onCancel}/>
    </>
  );
};