import {useNavigate} from "react-router-dom";
import {AppButton} from "shared/UI/UIKit/Button";
import {AppFlex} from "shared/UI/UIKit/Flex";
import {getCreateMainProgramUrl} from "services/navigation";

const MAIN_PROGRAM_CREATE_PAGE_URL = getCreateMainProgramUrl();

export const MainProgramsTableControls = () => {
  const navigate = useNavigate();

  const toCreateMainProgramPage = () => navigate(MAIN_PROGRAM_CREATE_PAGE_URL);

  return (
    <AppFlex unit="0" direction="row" justifyContent="flex-end">
      <AppButton onClick={toCreateMainProgramPage}>Создать ООП</AppButton>
    </AppFlex>
  );
};