import {forwardRef} from "react";
import type {AppImageProps} from "./Image.types";
import {useAppImage} from "./hooks/use-image";
import {getClassNames} from "./Image.utils";

export const AppImage = forwardRef<HTMLImageElement, AppImageProps>(
  (props, ref) => {
    const {className, previewClassName, src: originalSrc, alt, ...rest} = props;

    const {src, error, onError} = useAppImage(originalSrc);

    const classNames = getClassNames({error, className, previewClassName});

    return (
      <img {...rest} alt={alt} draggable={false} src={src}
           className={classNames} onError={onError}
           ref={ref}/>
    );
  }
);