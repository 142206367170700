import {Fragment, useEffect, useRef, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {SubMenuKeys, useMainProgramPage} from "pages/ViewMainProgram/use-main-program";
import {
  getMainProgramByIdAction
} from "features/main-program/redux/actions";
import {useAppDispatch} from "services/redux/hooks/use-dispatch";
import {convertMainProgramToDocx, getDetailedCurriculumById, PROGRAM_TEMPLATE_BLOCK_TYPE} from "services/api";
import {BlockTableAdditionalEducationEditor} from "common/BlockEditors/BlockTableAdditionalEducationEditor";
import {DocumentEditor} from "common/BlockEditors";
import {CurriculumTable} from "features/main-program/components/CurriculumViewer/CurriculumTable";
import {prepareTableContent, processWidgets, replaceVariables} from "features/main-program/redux/utils";
import {useAppSelector} from "services/redux/hooks/use-selector";
import {AppFlex} from "shared/UI/UIKit/Flex";
import {AppLoader} from "shared/UI/UIKit/Loader";
import {getViewMainProgramUrl} from "services/navigation";
import {clearMainProgramStateAction} from "features/main-program/redux/slice";
import type {ViewMainProgramPageParams} from "services/navigation";
import type {Curriculum, MainProgramBlockDto,CurriculumDetailed} from "services/api";
import styles from "./styles.module.scss";
import {addClassToBr, convertNodeToHTML} from "./utils";

type TitleWrapperComponentProps = {
  title: string;
}

const TitleWrapperComponent = (props: TitleWrapperComponentProps) => {
  if (props.title.toLowerCase() === "содержательный раздел") {
    return null;
  }

  return (
    <div style={{fontSize: "16px", fontWeight: 700, fontFamily: "Times New Roman", color: "rgb(51, 51, 51) !important"}}>
      {props.title.toUpperCase()}
    </div>
    // <p
    //   style={{boxSizing: "border-box", fontFamily: "Times New Roman, sans-serif", scrollbarColor: "rgba(115, 119, 130, 0.6) rgba(115, 119, 130, 0.25)", scrollbarWidth: "thin", marginBottom: "0cm", overflow: "visible", color: "rgb(51, 51, 51)", fontSize: "14px", display: "block", fontStyle: "normal", fontVariantLigatures: "normal", fontVariantCaps: "normal", fontWeight: 400, letterSpacing: "normal", orphans: 2, textTransform: "none", widows: 2, wordSpacing: "0px", whiteSpace: "normal", textDecorationThickness: "initial", textDecorationStyle: "initial", textDecorationColor: "initial", textAlign: "justify", lineHeight: "21px", marginTop: "0px !important", marginRight: "0px !important", marginLeft: "0px !important"}}>
    //   <span>###Par###</span>
    //   <strong style={{boxSizing: "border-box", fontFamily: "Times New Roman, sans-serif !important", scrollbarColor: "rgba(115, 119, 130, 0.6) rgba(115, 119, 130, 0.25)", scrollbarWidth: "thin", fontWeight: "bolder", overflow: "visible", color: "inherit", fontSize: "inherit"}}>
    //     <span style={{boxSizing: "border-box", fontFamily: "Times New Roman, serif", scrollbarColor: "rgba(115, 119, 130, 0.6) rgba(115, 119, 130, 0.25)", scrollbarWidth: "thin", overflow: "visible", color: "inherit", display: "inline", margin: "0px", padding: "0px", verticalAlign: "baseline", lineHeight: "28px", fontSize: "16px"}}>{props.title}</span>
    //   </strong>
    // </p>
  );
};

export const PreviewMainProgram = () => {
  const {
    menuItems,
    mainProgram
  } = useMainProgramPage(false, true);

  const {mainProgramId, mainProgramPartId} = useParams<ViewMainProgramPageParams>();
  const dispatch = useAppDispatch();
  const [firstPartBlocks, setFirstPartBlocks] = useState<(MainProgramBlockDto | Curriculum | CurriculumDetailed)[]>([]);
  const [secondPartBlocks, setSecondPartBlocks] = useState<(MainProgramBlockDto | Curriculum | CurriculumDetailed)[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const profileState = useAppSelector(state => state.profileState);
  const firstPartHtmlContent = useRef<HTMLDivElement | null>(null);
  const secondPartHtmlContent = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    document.body.style.overflowY = "hidden";

    return () => {
      document.body.style.overflowY = "scroll";
      setFirstPartBlocks([]);
      setSecondPartBlocks([]);
      setLoading(true);
    };
  }, []);

  useEffect(() => {
    if (mainProgramId) {
      dispatch(getMainProgramByIdAction(mainProgramId));
    }

    return () => {
      dispatch(clearMainProgramStateAction());
    };
  }, [mainProgramId]);

  useEffect(() => {
    if (!loading) {
      if (firstPartHtmlContent?.current && secondPartHtmlContent?.current) {
        const firstHtmlPart = convertNodeToHTML(firstPartHtmlContent?.current);
        const secondHtmlPart = convertNodeToHTML(secondPartHtmlContent?.current);
        convertMainProgramToDocx(mainProgramId!, firstHtmlPart, secondHtmlPart).then(() => {
          navigate(getViewMainProgramUrl({
            mainProgramId: mainProgramId!,
            mainProgramPartId: mainProgramPartId!,
          }));
        });
      } else {
        alert("Непредвиденная ошибка");
      }
    }
  }, [loading]);

  useEffect(() => {
    if (menuItems.length) {
      const idsObj: { id: number, separate: boolean }[] = [];

      menuItems.forEach((menuItem: any, index) => {
        const nextSectionKey = menuItems[index + 1]?.key;
        if (menuItem.children) {
          menuItem.children.forEach((child: any, childIndex: number) => {
            const childId: string | number = child.label.props.id;
            if (typeof childId === "number") {
              idsObj.push({id: childId, separate: nextSectionKey === SubMenuKeys.CONTENT_SECTION && childIndex === menuItem.children.length - 1});
            }
          });
        } else {
          idsObj.push({id: menuItem.label.props.id, separate: false});
        }
      });
      let part: "first" | "second" = "first";
      idsObj.forEach(idObj => {
        const currentBlock = mainProgram?.blocks.find(block => block.id === idObj.id) || mainProgram?.curriculums.find(block => block.id === idObj.id);
        if (!currentBlock) {
          alert("Непредвиденная ошибка");
          return;
        }

        if (part === "first") {
          setFirstPartBlocks(prevState => [...prevState, currentBlock]);
        } else {
          setSecondPartBlocks(prevState => [...prevState, currentBlock]);
        }

        if (idObj.separate) {
          part = "second";
        }
      });
    }
  }, [menuItems.length]);

  useEffect(() => {
    if (!!firstPartBlocks.length && !!secondPartBlocks.length) {
      const curriculums = [...firstPartBlocks, ...secondPartBlocks].map(block => {
        const curriculum = mainProgram?.curriculums.find(curriculm => curriculm.id === block.id);
        if (curriculum) {
          return curriculum;
        }
      }).filter(Boolean);

      if (!curriculums.length) {
        setLoading(false);
        return;
      }

      const promises = curriculums.map((item: any) => {
        return getDetailedCurriculumById(String(item.id), Number(mainProgramId));
      });

      Promise.all(promises).then(detailedCurriculums => {
        detailedCurriculums.forEach(item => {

          setFirstPartBlocks(prevState => {
            return prevState.map(firstPartBlock => {
              if (firstPartBlock.id === Number(item.title)) {
                return item;
              } else {
                return {
                  ...firstPartBlock,
                  content: (firstPartBlock as MainProgramBlockDto).type === PROGRAM_TEMPLATE_BLOCK_TYPE.TABLE ?
                    prepareTableContent((firstPartBlock as MainProgramBlockDto).content, mainProgram) :
                    processWidgets(replaceVariables((firstPartBlock as MainProgramBlockDto).content, profileState.profile, mainProgram), false, true)
                };
              }
            });
          });

          setSecondPartBlocks(prevState => {
            return prevState.map(secondPartBlock => {
              if (secondPartBlock.id === Number(item.title)) {
                return item;
              } else {
                return {
                  ...secondPartBlock,
                  content: (secondPartBlock as MainProgramBlockDto).type === PROGRAM_TEMPLATE_BLOCK_TYPE.TABLE ?
                    prepareTableContent((secondPartBlock as MainProgramBlockDto).content, mainProgram) :
                    processWidgets(replaceVariables((secondPartBlock as MainProgramBlockDto).content, profileState.profile, mainProgram), false)
                };
              }
            });
          });

          setLoading(false);
        });
      });
    }
  }, [firstPartBlocks, secondPartBlocks]);

  const renderBlocks = (blocks: (MainProgramBlockDto | Curriculum | CurriculumDetailed)[]) => {
    const sections = new Set();
    blocks.forEach((block: any) => {
      if (block.section) {
        sections.add(block.section);
      }
    });

    const firstBlocksInSection = new Set<number>();
    Array.from(sections).forEach((section: any) => {
      let firstElementOfSection = false;

      blocks.map((block: any) => {
        if (block.section === section && !firstElementOfSection) {
          firstElementOfSection = true;
          firstBlocksInSection.add(block.id);
        }
      });
    });
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return blocks.map(block => {
      const isCurriculum = mainProgram?.curriculums?.find(curriculum => curriculum.id === Number((block as any)?.title));
      const shouldAddSectionName = firstBlocksInSection.has(block.id!);
      if (isCurriculum) {
        return (block as CurriculumDetailed).blocks.map(curriculumBlock => {
          return curriculumBlock.type === PROGRAM_TEMPLATE_BLOCK_TYPE.DOCUMENT ? (
            <>
              {shouldAddSectionName && <TitleWrapperComponent title={(block as any).section}/>}
              <DocumentEditor key={curriculumBlock.id} id={curriculumBlock.id} content={curriculumBlock.content}/>
              <div style={{visibility: "hidden", position: "absolute"}}><p>###P###</p></div>
            </>
          ) : (
            <>
              {shouldAddSectionName && <TitleWrapperComponent title={(block as any).section}/>}
              <CurriculumTable block={curriculumBlock} key={block.id}/>
              <div style={{visibility: "hidden", position: "absolute"}}><p>###L###</p></div>
            </>
        );
        });
      } else {
        return (block as MainProgramBlockDto).type === PROGRAM_TEMPLATE_BLOCK_TYPE.TABLE ? (
          <>
            {shouldAddSectionName && <TitleWrapperComponent title={(block as any).section}/>}
            <BlockTableAdditionalEducationEditor editable={false} educationLevel={mainProgram?.educationLevel} key={block.id} mainProgramBlock={block as MainProgramBlockDto}/>
            <div style={{visibility: "hidden", position: "absolute"}}><p>###L###</p></div>
          </>
        ) : (
          <>
            {shouldAddSectionName && <TitleWrapperComponent title={(block as any).section}/>}
            <DocumentEditor id={block.id!}
                            key={block.id}
                            className={(block as MainProgramBlockDto).name === "Содержание" ? "content-block" : undefined}
                            editable={false}
                            content={(block as MainProgramBlockDto).name === "Содержание" ? addClassToBr((block as MainProgramBlockDto).content) : (block as MainProgramBlockDto).content}/>
            <div style={{visibility: "hidden", position: "absolute"}}><p>###P###</p></div>
          </>
        );
      }
    });
  };

  if (loading) {
    return null;
  }

  return (
    <Fragment>
      <div className={styles.loader}>
        <AppLoader size='large'>Генерируем документ, не закрывайте страницу</AppLoader>
      </div>
      <AppFlex alignItems="center" className={`${styles.container} placeholder-final-document-view`}>
        <AppFlex alignItems="center" ref={firstPartHtmlContent} className={`${styles.container} placeholder-final-document-view`}>
          {renderBlocks(firstPartBlocks)}
        </AppFlex>
        <AppFlex alignItems="center" ref={secondPartHtmlContent} className={`${styles.container} placeholder-final-document-view`}>
          {renderBlocks(secondPartBlocks)}
        </AppFlex>
      </AppFlex>
    </Fragment>
  );
};