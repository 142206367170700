import {Component} from "react";
import type {ErrorInfo} from "react";
import type {AppErrorBoundaryState, AppErrorBoundaryProps} from "./ErrorBoundary.types";
import {AppErrorFallback} from "../ErrorFallback/ErrorFallback";

const initialState: AppErrorBoundaryState = {hasError: false, error: null, errorInfo: null};

export class AppErrorBoundary extends Component<AppErrorBoundaryProps, AppErrorBoundaryState> {
  public state: AppErrorBoundaryState = initialState;

  static getDerivedStateFromError(): AppErrorBoundaryState {
    return {hasError: true};
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({
      hasError: true,
      error: error,
      errorInfo: errorInfo
    });
  }

  resetErrorBoundary = () => {
    this.setState(initialState);
  };

  render () {
    const {hasError, error, errorInfo} = this.state;

    if (hasError) {
      return (
        <AppErrorFallback reset={this.resetErrorBoundary} error={error} errorInfo={errorInfo}/>
      );
    }
    return this.props.children;
  }

}