import cn from "classnames";
import type {AppFlexProps} from "./Flex.types";
import styles from "./Flex.module.scss";

export const getClassNames = (params: AppFlexProps) => {
  const {display, direction, unit, justifyContent, alignItems, wrap, className} = params;

  return cn(
    styles[`flex--display--${display}`],
    styles[`flex--${direction}--${unit}`],
    styles[`flex--justify--${justifyContent}`],
    styles[`flex--align--${alignItems}`],
    styles[`flex--wrap--${wrap}`],
    className,
  );
};