import cn from "classnames";
import type {AppFormItemProps} from "./FormItem.types";
import styles from "./FormItem.module.scss";

export const getClassName = (size: AppFormItemProps["size"], labelColor: AppFormItemProps["labelColor"], className?: AppFormItemProps["className"]) => {
  return cn(
    styles[`item-${size}`],
    styles[`label-${labelColor}`],
    className,
  );
};
