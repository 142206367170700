import {AppFlex} from "shared/UI/UIKit/Flex";
import {AppExternalLink, AppLink} from "shared/UI/UIKit/Link";
import {AppText} from "shared/UI/UIKit/Text";
import {AppDivider} from "shared/UI/UIKit/Divider";
import {Avatar} from "common/Avatar/Avatar";
import {useAppSelector} from "services/redux/hooks/use-selector";
import {getHomeUrl} from "services/navigation";
import styles from "./Menu.module.scss";

export const AppHeaderMenu = () => {
  const {profile} = useAppSelector(state => state.profileState);

  return (
    <AppFlex unit="16" direction="row" className={styles.links} alignItems="center">
      <AppFlex direction="row" unit="6">
        <AppText size={16} weight={400} className={styles["app-link"]}>
          Приложение:
        </AppText>
        <AppExternalLink target="_blank" className={styles["app-link-control"]} href="https://www.edsoo.ru/">
          edsoo.ru
        </AppExternalLink>
      </AppFlex>
      <AppDivider vertical className={styles.divider}/>
      <AppLink to={getHomeUrl()}>
        <Avatar avatarUrl={profile?.avatar} imgClassName={styles["avatar-img"]} emptyClassName={styles["avatar-empty"]}/>
      </AppLink>
    </AppFlex>
  );
};
