import {showTotal} from "shared/UI/UIKit/Pagination";
import {itemRender} from "shared/UI/UIKit/Pagination/Pagination.utils";
import type {RequiredType} from "shared/types";
import type {TableStateManagerState, AppFilter} from "../lib";
import type {AppTablePagination, AppTableSorter} from "../ui";

export const DEFAULT_TABLE_SORTER_WEIGHT = 0;

export const getDefaultTableSorters = (): AppTableSorter[] => [];

export const getDefaultTableFilters = (): AppFilter[] => [];

type DefaultTablePagination = RequiredType<AppTablePagination,
  "total" |
  "current" |
  "pageSize" |
  "defaultPageSize" |
  "position" |
  "showSizeChanger" |
  "hideOnSinglePage" |
  "showTotal">;

export const getDefaultTablePagination = (): DefaultTablePagination => ({
  total: 0,
  current: 1,
  pageSize: 10,
  defaultPageSize: 10,
  position: ["bottomRight"],
  showSizeChanger: true,
  hideOnSinglePage: false,
  showTotal,
  itemRender,
});

export const getDefaultTableState = (): TableStateManagerState => ({
  sorters: getDefaultTableSorters(),
  filters: getDefaultTableFilters(),
  pagination: getDefaultTablePagination(),
});