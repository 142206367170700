import {getMinMaxObjectValue} from "shared/helpers/utils/arrays/get-min-max-value";
import {getProgramTemplateBlockTableSectionDTO} from "services/api/blocks/dto";
import type {DragEndEvent} from "@dnd-kit/core";
import type {ProgramTemplateBlockTable,ProgramTemplateBlockTableSection,ProgramTemplateBlockTableRow} from "services/api";
import type {TableConstructorSingleColumn} from "../../types";
import {indexHelpers, rowsHelpers} from "../../helpers";

type CreateRowOptions = {
  table: ProgramTemplateBlockTable;
  isPrograms: boolean;
  sectionId: string | number;
  singleColumns: TableConstructorSingleColumn[];
}

export const createRow = ({
  table,
  isPrograms,
  sectionId,
  singleColumns,
}: CreateRowOptions): ProgramTemplateBlockTableSection[] => {
  const nextSections = [...table.sections];
  const sectionIndex = nextSections.findIndex(section => section.id === sectionId);
  const [, maxRowIndex] = getMinMaxObjectValue(nextSections[sectionIndex].rows, row => row.index, -1);

  const footerRow = nextSections[sectionIndex].rows.find(row => row.type === "SECTION_FOOTER_ROW");
  const isFooterRowExists = !!footerRow;
  if (isFooterRowExists) {
    footerRow.index++;
  }

  const newRowIndex = isFooterRowExists ? maxRowIndex : maxRowIndex + 1;
  const newRow = rowsHelpers.createTableRow(
    nextSections[sectionIndex].id,
    nextSections[sectionIndex].type,
    newRowIndex,
    singleColumns,
    isPrograms,
  );
  nextSections[sectionIndex] = getProgramTemplateBlockTableSectionDTO({
    ...nextSections[sectionIndex],
    rows: nextSections[sectionIndex].rows.concat(newRow),
  });

  return nextSections;
};

type InsertRowOptions = {
  table: ProgramTemplateBlockTable;
  isPrograms: boolean;
  sectionId: string | number;
  rowId: string | number;
  placement: "above" | "below";
  singleColumns: TableConstructorSingleColumn[];
}

export const insertRow = ({
  table,
  isPrograms,
  sectionId,
  rowId,
  placement,
  singleColumns,
}: InsertRowOptions): ProgramTemplateBlockTableSection[] => {
  const nextSections = [...table.sections];
  const sectionIndex = nextSections.findIndex(section => section.id === sectionId);
  const nextRows = [...nextSections[sectionIndex].rows];
  const currentRowIndex = nextRows.findIndex(row => row.id === rowId);

  if (placement === "above") {
    const newRow = rowsHelpers.createTableRow(
      nextSections[sectionIndex].id,
      nextSections[sectionIndex].type,
      nextRows[currentRowIndex].index,
      singleColumns,
      isPrograms,
    );
    rowsHelpers.incrementRowIndexes(nextRows, currentRowIndex);
    nextRows.push(newRow);
  } else {
    const newRow = rowsHelpers.createTableRow(
      nextSections[sectionIndex].id,
      nextSections[sectionIndex].type,
      nextRows[currentRowIndex].index + 1,
      singleColumns,
      isPrograms,
    );
    rowsHelpers.incrementRowIndexes(nextRows, currentRowIndex + 1);
    nextRows.push(newRow);
  }

  nextSections[sectionIndex] = getProgramTemplateBlockTableSectionDTO({
    ...nextSections[sectionIndex],
    rows: nextRows,
  });

  return nextSections;
};

type CopyRowOptions = {
  table: ProgramTemplateBlockTable;
  isPrograms: boolean;
  sectionId: string | number;
  rowId: string | number;
}

export const copyRow = ({
  table,
  isPrograms,
  sectionId,
  rowId,
}: CopyRowOptions): ProgramTemplateBlockTableSection[] => {
  const nextSections = [...table.sections];
  const sectionIndex = nextSections.findIndex(section => section.id === sectionId);
  const nextRows = [...nextSections[sectionIndex].rows];
  const currentRowIndex = nextRows.findIndex(row => row.id === rowId);

  const newRow = rowsHelpers.copyTableRow(
    nextRows[currentRowIndex], nextRows[currentRowIndex].index + 1, isPrograms
  );
  rowsHelpers.incrementRowIndexes(nextRows, currentRowIndex + 1);
  nextRows.push(newRow);

  nextSections[sectionIndex] = getProgramTemplateBlockTableSectionDTO({
    ...nextSections[sectionIndex],
    rows: nextRows,
  });

  return nextSections;
};

type MoveRowOptions = {
  table: ProgramTemplateBlockTable;
  sectionId: string | number;
  rowId: string | number;
  direction: "above" | "below";
}

export const moveRow = ({
  table,
  sectionId,
  rowId,
  direction,
}: MoveRowOptions): ProgramTemplateBlockTableSection[] | undefined => {
  const nextSections = [...table.sections];
  const sectionIndex = nextSections.findIndex(section => section.id === sectionId);

  if (!rowsHelpers.checkMoreThanOneSectionBodyRowExists(nextSections[sectionIndex].rows)) {
    return;
  }

  const nextRows = [...nextSections[sectionIndex].rows];
  const rowIndex = nextRows.findIndex(row => row.id === rowId);

  if (direction === "above") {
    const rowIndexAbove = rowsHelpers.getSectionBodyRowIndexAbove(nextRows, rowIndex - 1);
    if (rowIndexAbove > rowIndex) {
      rowsHelpers.makeLowestSectionBodyRowIndexHighest(nextRows, rowIndex, rowIndexAbove);
    } else {
      indexHelpers.swapIndexes(nextRows.at(rowIndex)!, nextRows.at(rowIndexAbove)!);
    }
  } else {
    const rowIndexBelow = rowsHelpers.getSectionBodyRowIndexBelow(nextRows, rowIndex + 1);
    if (rowIndexBelow < rowIndex) {
      rowsHelpers.makeHighestSectionBodyRowIndexLowest(nextRows, rowIndexBelow, rowIndex);
    } else {
      indexHelpers.swapIndexes(nextRows.at(rowIndex)!, nextRows.at(rowIndexBelow)!);
    }
  }

  nextSections[sectionIndex] = getProgramTemplateBlockTableSectionDTO({
    ...nextSections[sectionIndex],
    rows: nextRows,
  });

  return nextSections;
};

type RemoveRowOptions = {
  table: ProgramTemplateBlockTable;
  sectionId: string | number;
  rowId: string | number;
}

export const removeRow = ({
  table,
  sectionId,
  rowId,
}: RemoveRowOptions): ProgramTemplateBlockTableSection[] => {
  const nextSections = [...table.sections];
  const sectionIndex = nextSections.findIndex(section => section.id === sectionId);

  nextSections[sectionIndex] = getProgramTemplateBlockTableSectionDTO({
    ...nextSections[sectionIndex],
    rows: nextSections[sectionIndex].rows.filter(row => row.id !== rowId),
  });

  return nextSections;
};

export const moveRowsOnDragEnd = (
  event: DragEndEvent,
  table: ProgramTemplateBlockTable
): ProgramTemplateBlockTableSection[] => {
  const activeRow = event.active.data.current?.row as ProgramTemplateBlockTableRow;
  const overRow = event.over?.data.current?.row as ProgramTemplateBlockTableRow;
  const nextSections = [...table.sections];

  if (activeRow.type === "SECTION_BODY_ROW" &&
    overRow.type === "SECTION_BODY_ROW" &&
    activeRow.section?.id === overRow.section?.id &&
    activeRow.id !== overRow.id) {
    const sectionIndex = nextSections.findIndex(section => section.id === activeRow.section?.id);
    const nextRows = [...nextSections[sectionIndex].rows];
    const activeRowIndex = nextRows.findIndex(row => row.id === activeRow.id);
    const overRowIndex = nextRows.findIndex(row => row.id === overRow.id);

    if (activeRowIndex > overRowIndex) {
      for (let i = overRowIndex; i <= activeRowIndex; i++) {
        if (nextRows[i].id === activeRow.id) {
          nextRows[i].index = overRow.index;
        } else {
          nextRows[i].index++;
        }
      }
    } else {
      for (let i = activeRowIndex; i <= overRowIndex; i++) {
        if (nextRows[i].id === activeRow.id) {
          nextRows[i].index = overRow.index;
        } else {
          nextRows[i].index--;
        }
      }
    }

    nextSections[sectionIndex] = getProgramTemplateBlockTableSectionDTO({
      ...nextSections[sectionIndex],
      rows: nextRows,
    });
  }

  return nextSections;
};