import {Dropdown} from "antd";
import type {AppDropdownProps} from "./Dropdown.types";
import {getClassNames} from "./Dropdown.utils";

export const AppDropdown = (props: AppDropdownProps) => {
  const {trigger = ["click"], className, ...rest} = props;
  const classNames = getClassNames(className);

  return (
    <Dropdown {...rest} className={classNames}
              trigger={trigger}>
      {props.children}
    </Dropdown>
  );
};