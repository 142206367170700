import {memo} from "react";
import type {TableFillerCellProps} from "./types";
import {TableBaseCell} from "../TableBaseCell";

export const TableFillerCell = memo(({
  isFullAccess,
  editMode,
  field,
  colSpan,
}: TableFillerCellProps) => {
  return (
    <TableBaseCell isFullAccess={isFullAccess} editMode={editMode}
                   index={field.index} colSpan={colSpan}/>
  );
});