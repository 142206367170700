import {AppEditIcon} from "shared/UI/UIKit/icons/Edit";
import type {EditIconProps} from "./types";
import styles from "./styles.module.scss";

export const EditIcon = (props: EditIconProps) => {
  return (
    <div className={styles["icon-wrapper"]} onClick={props.onClick}>
      <AppEditIcon className={styles.icon}/>
    </div>
  );
};