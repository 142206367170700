import React, {forwardRef} from "react";
import type {AppButtonProps} from "./Button.types";
import {AppPrimaryButton} from "./components/PrimaryButton";
import {AppOutlineButton} from "./components/OutlineButton";
import {AppTextButton} from "./components/TextButton";

export const AppButton = forwardRef<HTMLButtonElement, AppButtonProps>((
  {type, ...rest}, ref
) => {
  switch (type) {
    case "outline": {
      return <AppOutlineButton {...rest} ref={ref}/>;
    }
    case "text": {
      return <AppTextButton {...rest} ref={ref}/>;
    }
    case "primary":
    default: {
      return <AppPrimaryButton {...rest} ref={ref}/>;
    }
  }
});