import {useNavigate} from "react-router-dom";
import {notification} from "antd";
import {useEffect} from "react";
import {AppTabs} from "shared/UI/UIKit/Tabs";
import {AppFlex} from "shared/UI/UIKit/Flex";
import {useAppForm} from "shared/UI/UIKit/Form";
import {useAppSelector} from "services/redux/hooks/use-selector";
import {useAppDispatch} from "services/redux/hooks/use-dispatch";
import {AppBlockingLoader} from "shared/UI/UIKit/Loader";
import {getViewMainProgramUrl} from "services/navigation";
import {useCurriculums} from "features/create-main-program/components/CreateMainProgram/use-curriculums";
import {getNumericFieldComparator} from "shared/helpers/utils/comparators";
import {useOopLocalStorage} from "features/create-main-program/components/CreateMainProgram/use-oop-local-storage";
import type {MainProgramItem, EducationLevel, MainProgram} from "services/api";
import type {AppTabItem} from "shared/UI/UIKit/Tabs";
import type {
  EducationLevelFormFields
} from "./types";
import {
  selectCreateMainProgramActiveTabId,
  selectCreateMainProgramAllCurriculums,
  selectCreateMainProgramLoading, selectCreateMainProgramSelectedCurriculumsEntities
} from "../../redux/selectors";
import {
  addSubjectInSelectedCurriculum,
  clearAllCurriculumsAction,
  clearCreateMainProgramStateAction,
  clearSelectedCurriculumsEntitiesAction,
  clearSelectedWorkProgramByFieldNameAction,
  clearWorkProgramsOptionsBySubjectIdAction,
  setActiveTabIdAction, setSelectedCurriculumsEntitiesAction,
  setSelectedEducationLevelAction,
  setSelectedWorkProgramByFieldNameAction
} from "../../redux/slice";
import {
  createMainProgramAction,
  getWorkProgramsBySubjectsAction
} from "../../redux/actions";
import {FirstStep} from "../FirstStep";
import {SecondStep} from "../SecondStep";
import {ThirdStep} from "../ThirdStep";
import {type AddSubjectInSelectedCurriculumActionPayload, CreateWorkProgramTabs} from "../../redux/types";
import styles from "./styles.module.scss";

export const CreateMainProgram = () => {
  const [educationLevelForm] = useAppForm<EducationLevelFormFields>();
  const [mainProgramForm] = useAppForm<Record<string, string>>();
  const loading = useAppSelector(selectCreateMainProgramLoading);
  const allCurriculums = useAppSelector(selectCreateMainProgramAllCurriculums);
  const activeTabId = useAppSelector(selectCreateMainProgramActiveTabId);
  const selectedCurriculumsEntities = useAppSelector(selectCreateMainProgramSelectedCurriculumsEntities);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    page,
    getCurriculums,
    searchQueryChange,
    paginationChange,
    total,
    setSearchQuery,
    setPage,
  } = useCurriculums();
  const {
    addEducationLevelToLocalStorage,
    addCurriculumsToLocalStorage,
    addActiveStepToLocalStorage,
    clearWizardInLocalStorage,
    clearWorkProgramsByFieldNameInLocalStorage,
    addWorkProgramByFieldNameToLocalStorage,
  } = useOopLocalStorage();

  useEffect(() => {
    const currentWizard = localStorage.getItem("WIZARD");
    if (currentWizard) {
      const parsedWizard = JSON.parse(currentWizard);

      switch (parsedWizard.activeStep) {
        case 2:
          educationLevelForm.setFieldValue("educationLevel", parsedWizard.educationLevel);
          dispatch(setActiveTabIdAction(CreateWorkProgramTabs.CURRICULUM));
          dispatch(setSelectedEducationLevelAction(parsedWizard.educationLevel as EducationLevel));
          getCurriculums({
            page: 0,
            searchQuery: "",
          });
          break;
        case 3:
          educationLevelForm.setFieldValue("educationLevel", parsedWizard.educationLevel);
          dispatch(setActiveTabIdAction(CreateWorkProgramTabs.WORK_PROGRAM));
          dispatch(setSelectedEducationLevelAction(parsedWizard.educationLevel as EducationLevel));
          getCurriculums({
            page: 0,
            searchQuery: "",
          });
          dispatch(setSelectedCurriculumsEntitiesAction(parsedWizard.curriculums));
          dispatch(getWorkProgramsBySubjectsAction());
          parsedWizard?.extraSubjects?.forEach((item: AddSubjectInSelectedCurriculumActionPayload) => {
            dispatch(addSubjectInSelectedCurriculum(item));
          });
          parsedWizard?.workPrograms?.forEach((item: any) => {
            dispatch(setSelectedWorkProgramByFieldNameAction({
              fieldName: item.fieldName,
              item: item.mainProgramItem,
            }));

            mainProgramForm.setFieldValue(item.fieldName, item.mainProgramItem.workProgram.id);
          });
          break;
        default:
          return;
      }
    }
  }, []);

  const educationLevelNextStep = () => {
    educationLevelForm.validateFields().then((values: EducationLevelFormFields) => {
      dispatch(setActiveTabIdAction(CreateWorkProgramTabs.CURRICULUM));
      dispatch(setSelectedEducationLevelAction(values.educationLevel as EducationLevel));

      addEducationLevelToLocalStorage(values.educationLevel as EducationLevel);
      addActiveStepToLocalStorage(2);

      getCurriculums({
        page: 0,
        searchQuery: "",
      });
    });
  };

  const curriculumNextStep = () => {
    if (!selectedCurriculumsEntities.length) {
      notification.warning({
        message: "Выберите минимум один учебный план"
      });

      return;
    }

    dispatch(setActiveTabIdAction(CreateWorkProgramTabs.WORK_PROGRAM));
    dispatch(getWorkProgramsBySubjectsAction());
    addActiveStepToLocalStorage(3);
    addCurriculumsToLocalStorage(selectedCurriculumsEntities);
  };

  const curriculumPrevStep = () => {
    setSearchQuery("");
    setPage(0);
    dispatch(clearSelectedCurriculumsEntitiesAction());
    dispatch(clearAllCurriculumsAction());
    dispatch(setActiveTabIdAction(CreateWorkProgramTabs.EDUCATION_LEVEL));
    addCurriculumsToLocalStorage([]);
    addActiveStepToLocalStorage(1);
  };

  const workProgramNextStep = () => {
    mainProgramForm.validateFields().then(() => {
      dispatch(createMainProgramAction())
        .unwrap()
        .then((res: MainProgram) => {
          const sortedBlocks = res.blocks.sort(getNumericFieldComparator(item => item.index, "ascend"));
          const titleBlock = (sortedBlocks || []).find(item => item.name === "Титульный лист");

          navigate(getViewMainProgramUrl({
            mainProgramId: String(res.id),
            mainProgramPartId: String(titleBlock?.id) || String(sortedBlocks[0]?.id || "") || String(res.items[0].workProgram.id)
          }));
          dispatch(clearCreateMainProgramStateAction());
          clearWizardInLocalStorage();
        });
    }).catch(errors => {
      mainProgramForm.scrollToField(errors?.errorFields?.[0]?.name?.[0]);
    });
  };

  const workProgramPrevStep = () => {
    mainProgramForm.resetFields();
    dispatch(clearWorkProgramsOptionsBySubjectIdAction());
    dispatch(clearSelectedWorkProgramByFieldNameAction());
    dispatch(setActiveTabIdAction(CreateWorkProgramTabs.CURRICULUM));
    clearWorkProgramsByFieldNameInLocalStorage();
    addActiveStepToLocalStorage(2);
  };

  const addMainProgramItemToStateByFieldName = (fieldName: string, mainProgramItem: MainProgramItem) => {
    dispatch(setSelectedWorkProgramByFieldNameAction({
      fieldName,
      item: mainProgramItem,
    }));

    addWorkProgramByFieldNameToLocalStorage(fieldName, mainProgramItem);
  };

  useEffect(() => {
    return () => {
      dispatch(clearCreateMainProgramStateAction());
    };
  }, []);

  const tabs: AppTabItem[] = [
    {
      title: "Шаг 1. Уровень образования",
      id: CreateWorkProgramTabs.EDUCATION_LEVEL,
      content: <FirstStep form={educationLevelForm}
                          handleNextStep={educationLevelNextStep}/>
    },
    {
      title: "Шаг 2. Учебный план",
      id: CreateWorkProgramTabs.CURRICULUM,
      content: <SecondStep searchQueryChange={searchQueryChange}
                           paginationChange={paginationChange}
                           curriculums={allCurriculums}
                           paginationPage={page}
                           total={total}
                           activeTabId={activeTabId}
                           handlePrevStep={curriculumPrevStep}
                           handleNextStep={curriculumNextStep}/>
    },
    {
      title: "Шаг 3. Рабочая программа",
      id: CreateWorkProgramTabs.WORK_PROGRAM,
      content: <ThirdStep form={mainProgramForm}
                          selectedCurriculums={selectedCurriculumsEntities}
                          handlePrevStep={workProgramPrevStep}
                          handleNextStep={workProgramNextStep}
                          addMainProgramItemToStateByFieldName={addMainProgramItemToStateByFieldName}/>
    }
  ];

  return (
    <AppFlex alignItems="center">
      <AppFlex className={styles["tabs-container"]}>
        <AppBlockingLoader loading={loading}>
          <AppTabs tabs={tabs} activeId={activeTabId}/>
        </AppBlockingLoader>
      </AppFlex>
    </AppFlex>
  );
};