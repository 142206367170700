import cn from "classnames";
import type {TableBaseCellProps} from "./types";
import {Loader} from "../Loader";
import styles from "./styles.module.scss";

export const TableBaseCell = ({
  isFullAccess,
  editMode,
  index,
  editable,
  hidden,
  loading,
  className,
  children,
  ...rest
}: TableBaseCellProps) => {
  const increasedPadding = isFullAccess && editMode;
  const classNames = cn(
    "table-constructor-cell",
    hidden && "table-constructor-cell_hidden",
    editable && "table-constructor-cell_editable",
    increasedPadding && styles["cell_increased-pd"],
    className,
  );

  return (
    <td tabIndex={-1} data-index={index} className={classNames} {...rest}>
      {loading ? <Loader/> : children}
    </td>
  );
};