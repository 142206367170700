import type {ProgramTemplateBlockTableRowField} from "services/api";

const defaultObj: ProgramTemplateBlockTableRowField = {
  id: null,
  row: null,
  index: 0,
  type: "FILLER",
  value: null,
  targetValue: null,
  operator: null,
  required: false,
  readonly: false,
  placeholder: null,
  code: "",
  placeholderId: null,
  listId: null,
  options: null,
  hint: null,
};

export const getProgramTemplateBlockTableRowFieldDTO = (
  obj: Partial<ProgramTemplateBlockTableRowField>
) => ({
  ...defaultObj,
  ...obj,
});