import cn from "classnames";
import {AppChevronTopIcon} from "shared/UI/UIKit/icons/Chevron";
import type {SvgProps} from "shared/types";
import styles from "../styles.module.scss";

export const MoveAboveIcon = ({className, ...rest}: SvgProps) => {
  return (
    <AppChevronTopIcon className={cn(styles.icon, styles["icon_size_24"], className)}
                       {...rest}/>
  );
};