import {forwardRef} from "react";
import {Popconfirm} from "antd";
import type {AppConfirmComponent, AppConfirmProps} from "./Confirm.types";

export const AppConfirm = forwardRef<AppConfirmComponent, AppConfirmProps>(
  (props, ref) => {
    const {title = "Подтвердите действие", ...rest} = props;
    return (
      <Popconfirm title={title} {...rest} ref={ref}>
        {props.children}
      </Popconfirm>
    );
});