import cn from "classnames";
import type {AppFormItemBaseProps} from "./FormItemBase.types";
import styles from "./FormItemBase.module.scss";

export const getClassName = (props: AppFormItemBaseProps) => {
  return cn(
    styles.item,
    props.withoutExplain && styles["without-explain"],
    props.marginless && styles.marginless,
    props.className,
  );
};

export const getWrapperClassName = (props: AppFormItemBaseProps) => {
  return cn(
    props.wrapperClassName,
  );
};