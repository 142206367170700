import Checkbox from "antd/es/checkbox";
import {forwardRef} from "react";
import type {AppCheckboxGroupProps} from "./CheckboxGroup.types";
import {getClassNames} from "./CheckboxGroup.utils";

export const AppCheckboxGroup = forwardRef<HTMLInputElement, AppCheckboxGroupProps>(
  (props, ref) => {
    const {direction = "horizontal", className, ...rest} = props;
    const classNames = getClassNames(className, direction);

    return <Checkbox.Group {...rest} className={classNames} ref={ref}/>;
  });
