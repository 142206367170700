import type {NumberFilterFormValue} from "../types";
import type {NumberFilterFieldsProps} from "./types";
import {FILTER_FORM_NAMES} from "../../../constants";
import {AppFilterOperator} from "../../../../constants";
import {NumberField} from "./NumberField";
import {NumberIntervalFields} from "./NumberIntervalFields";

export const NumberFilterFields = ({form, options, focusRef}: NumberFilterFieldsProps) => {
  const operator = form.getFieldValue(FILTER_FORM_NAMES.operator) as AppFilterOperator;

  const onChange = (values: NumberFilterFormValue[]) => {
    const nextValues = values.map(value => {
      if (value === null || value === undefined) {
        return value;
      }

      return (value < Number.MIN_SAFE_INTEGER || value > Number.MAX_SAFE_INTEGER) ? 0 : value;
    });

    form.setFieldsValue({
      values: nextValues,
    });
  };

  switch (operator) {
    case AppFilterOperator.IS_NULL: {
      return null;
    }
    case AppFilterOperator.INTERVAL: {
      return (
        <NumberIntervalFields onChange={onChange} focusRef={focusRef}
                              placeholderStart={options?.placeholderStart}
                              placeholderEnd={options?.placeholderEnd}/>
      );
    }
    default: {
      return (
        <NumberField onChange={onChange} focusRef={focusRef}
                     placeholder={options?.placeholder}/>
      );
    }
  }
};