import type {TableConstructorSingleColumn} from "../../../types";
import {getColumnTypePlaceholder} from "../column-properties";
import {makeSingleColumn} from "./single-column";

export const makeWidgetNumberColumn = (column?: Partial<TableConstructorSingleColumn>): TableConstructorSingleColumn => {
  return makeSingleColumn({
    type: "WIDGET_NUMBER",
    placeholder: getColumnTypePlaceholder("WIDGET_NUMBER"),
    ...column,
  });
};

export const makeWidgetNumberTotalWorkHoursColumn = (column?: Partial<TableConstructorSingleColumn>): TableConstructorSingleColumn => {
  return makeWidgetNumberColumn({
    subtype: "TOTAL_WORK_HOURS",
    name: "Всего",
    width: "9rem",
    required: true,
    locked: true,
    lockDisabled: true,
    ...column,
  });
};

export const makeWidgetNumberVerificationWorkHoursColumn = (column?: Partial<TableConstructorSingleColumn>): TableConstructorSingleColumn => {
  return makeWidgetNumberColumn({
    subtype: "VERIFICATION_WORK_HOURS",
    name: "Контрольные работы",
    width: "13rem",
    ...column,
  });
};

export const makeWidgetNumberPracticalWorkHoursColumn = (column?: Partial<TableConstructorSingleColumn>): TableConstructorSingleColumn => {
  return makeWidgetNumberColumn({
    subtype: "PRACTICAL_WORK_HOURS",
    name: "Практические работы",
    width: "13rem",
    ...column,
  });
};