import {useState} from "react";
import type {AppMenuProps} from "shared/UI/UIKit/Menu";
import type {AppDropdownMenuProps} from "..";

export const useAppDropdownMenu = (
  onMenuItemClickProp?: AppMenuProps["onClick"],
  visibleProp?: AppDropdownMenuProps["visible"],
  onVisibleChangeProp?: AppDropdownMenuProps["onVisibleChange"],
) => {
  const [_visible, _setVisible] = useState<boolean>(false);

  if ((visibleProp !== undefined && !onVisibleChangeProp) || (visibleProp === undefined && onVisibleChangeProp)) {
    throw new Error("visible prop should be passed with onVisibleChange prop");
  }

  const visible = visibleProp ?? _visible;
  const onVisibleChange = onVisibleChangeProp ?? _setVisible;

  const onMenuItemClick: AppMenuProps["onClick"] = (info) => {
    if (info.shouldCloseMenu) {
      onVisibleChange(false);
    }
    onMenuItemClickProp?.(info);
  };

  return {
    visible,
    onVisibleChange,
    onMenuItemClick,
  };
};