import type {TableStateManagerState, ResetCurrentPageCallBack} from "./types";

export const resetCurrentPageOnPaginationUpdate: ResetCurrentPageCallBack = (
  currentPagination, nextPagination
) => {
  const {current, total, pageSize} = getMergedPagination(currentPagination, nextPagination);

  if (current !== undefined && total !== undefined && pageSize !== undefined) {
    const lastPage = Math.ceil(total / pageSize);
    if (current > lastPage) {
      return lastPage;
    }
  }

  return current;
};

export const getMergedPagination = (
  currentPagination: TableStateManagerState["pagination"],
  nextPagination: TableStateManagerState["pagination"],
) => {
  return {...currentPagination, ...nextPagination};
};