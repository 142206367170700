import {useEvent} from "shared/helpers/hooks/use-event";
import type {DragEndEvent} from "@dnd-kit/core";
import type {
  TableConstructorColumn,
  TableHeaderCellType,
  ClickedCell,
} from "../../../../../lib/types";
import type {TableMethodsOptions, TableMethodsReturnValue} from "./types";
import {
  headerMethods,
  moduleMethods,
  sectionMethods,
  rowMethods,
  cellMethods
} from "../../../../../lib/core";
import {useTableConstructorContext} from "../../../../contexts/table-constructor-context";

export const useTableMethods = (props: TableMethodsOptions): TableMethodsReturnValue => {
  const {columns, singleColumns, isPrograms} = useTableConstructorContext();

  // TABLE HEADER METHODS
  const handleTableHeaderCellUpdate = useEvent((nextCell: TableHeaderCellType) => {
    const nextHeader = headerMethods.getUpdatedHeaderByCell(props.table.header, nextCell);
    props.onTableUpdate({header: nextHeader});
  });

  const handleTableHeaderColumnUpdate = (nextColumn: TableConstructorColumn) => {
    const nextHeader = headerMethods.getUpdatedHeaderByColumn(columns, nextColumn);
    props.onTableUpdate({header: nextHeader});
  };

  // TABLE MODULE METHODS
  const handleTableModuleCreate = useEvent(() => {
    const nextSections = moduleMethods.createModule(props.table, isPrograms);
    props.onTableUpdate({sections: nextSections});
  });

  const handleTableModuleInsert = (sectionId: string | number, placement: "above" | "below") => {
    const nextSections = moduleMethods.insertModule(props.table, isPrograms, sectionId, placement);
    props.onTableUpdate({sections: nextSections});
  };

  const handleTableModuleCopy = (sectionId: string | number) => {
    const nextSections = moduleMethods.copyModule(props.table, isPrograms, sectionId);
    props.onTableUpdate({sections: nextSections});
  };

  const handleTableModuleMove = (sectionId: string | number, direction: "above" | "below") => {
    const nextSections = moduleMethods.moveModule(props.table, sectionId, direction);

    if (nextSections) {
      props.onTableUpdate({sections: nextSections});
    }
  };

  const handleTableModuleRemove = (sectionId: string | number) => {
    const nextSections = moduleMethods.removeModule(props.table, sectionId);
    props.onTableUpdate({sections: nextSections});
  };

  // TABLE SECTION METHODS
  const handleTableSectionCreate = useEvent(() => {
    const nextSections = sectionMethods.createSection(props.table, isPrograms);
    props.onTableUpdate({sections: nextSections});
  });

  const handleTableSectionInsert = (sectionId: string | number, placement: "above" | "below") => {
    const nextSections = sectionMethods.insertSection(props.table, isPrograms, sectionId, placement);
    props.onTableUpdate({sections: nextSections});
  };

  const handleTableSectionCopy = (sectionId: string | number) => {
    const nextSections = sectionMethods.copySection(props.table, isPrograms, sectionId);
    props.onTableUpdate({sections: nextSections});
  };

  const handleTableSectionMove = (sectionId: string | number, direction: "above" | "below") => {
    const nextSections = sectionMethods.moveSection(props.table, sectionId, direction);

    if (nextSections) {
      props.onTableUpdate({sections: nextSections});
    }
  };

  const handleTableSectionRemove = (sectionId: string | number) => {
    const nextSections = sectionMethods.removeSection(props.table, sectionId);
    props.onTableUpdate({sections: nextSections});
  };

  // TABLE ROW METHODS
  const handleTableRowCreate = (sectionId: string | number) => {
    const nextSections = rowMethods.createRow({
      table: props.table,
      isPrograms,
      sectionId,
      singleColumns,
    });
    props.onTableUpdate({sections: nextSections});
  };

  const handleTableRowInsert = (sectionId: string | number, rowId: string | number, placement: "above" | "below") => {
    const nextSections = rowMethods.insertRow({
      table: props.table,
      isPrograms,
      sectionId,
      rowId,
      placement,
      singleColumns,
    });
    props.onTableUpdate({sections: nextSections});
  };

  const handleTableRowCopy = (sectionId: string | number, rowId: string | number) => {
    const nextSections = rowMethods.copyRow({
      table: props.table,
      isPrograms,
      sectionId,
      rowId,
    });
    props.onTableUpdate({sections: nextSections});
  };

  const handleTableRowMove = (sectionId: string | number, rowId: string | number, direction: "above" | "below") => {
    const nextSections = rowMethods.moveRow({
      table: props.table,
      sectionId,
      rowId,
      direction,
    });

    if (nextSections) {
      props.onTableUpdate({sections: nextSections});
    }
  };

  const handleTableRowRemove = (sectionId: string | number, rowId: string | number) => {
    const nextSections = rowMethods.removeRow({
      table: props.table,
      sectionId,
      rowId,
    });
    props.onTableUpdate({sections: nextSections});
  };

  const handleTableRowDragEnd = (event: DragEndEvent) => {
    const nextSections = rowMethods.moveRowsOnDragEnd(event, props.table);
    props.onTableUpdate({sections: nextSections});
  };

  // TABLE CELL METHODS
  const handleTableCellUpdate = useEvent((cell: ClickedCell) => {
    const nextSections = cellMethods.updateCell(props.table, cell);
    props.onTableUpdate({sections: nextSections});
  });

  return [
    null,
    {
      tableMethods: {
        update: props.onTableUpdate,
        remove: props.onTableRemove,
      },
      tableHeaderMethods: {
        updateCell: handleTableHeaderCellUpdate,
        updateColumn: handleTableHeaderColumnUpdate,
      },
      tableModuleMethods: {
        create: handleTableModuleCreate,
        insert: handleTableModuleInsert,
        copy: handleTableModuleCopy,
        move: handleTableModuleMove,
        remove: handleTableModuleRemove,
      },
      tableSectionMethods: {
        create: handleTableSectionCreate,
        insert: handleTableSectionInsert,
        copy: handleTableSectionCopy,
        move: handleTableSectionMove,
        remove: handleTableSectionRemove,
      },
      tableRowMethods: {
        create: handleTableRowCreate,
        insert: handleTableRowInsert,
        copy: handleTableRowCopy,
        move: handleTableRowMove,
        remove: handleTableRowRemove,
        dragEnd: handleTableRowDragEnd,
      },
      tableCellMethods: {
        update: handleTableCellUpdate,
      }
    }
  ];
};