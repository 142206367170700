import type {Comparator, ObjectComparatorGetter} from "../types";

export const getObjectComparator: ObjectComparatorGetter = <TObject extends object, TField>(
  getValue: ((item: TObject) => TField), comparator: Comparator<TField>
) => {
  return (a: TObject, b: TObject) => {
    const valA = getValue(a);
    const valB = getValue(b);
    return comparator(valA, valB);
  };
};