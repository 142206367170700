import {Empty} from "antd";
import type {AppEmptyProps} from "./Empty.types";
import {getClassNames} from "./Empty.utils";

export const AppEmpty = (props: AppEmptyProps) => {
  const classNames = getClassNames(props);

  return (
    <Empty {...props} className={classNames}/>
  );
};