import cn from "classnames";
import type {ModalLayoutProps} from "./ModalLayout.types";
import styles from "./ModalLayout.module.scss";

export const ModalLayout = ({children, className, style}: ModalLayoutProps) => {
  const classNames = cn(styles.layout, className);

  return (
    <div className={classNames} style={style}>
      {children}
    </div>
  );
};