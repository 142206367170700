import type {AppIconProps} from "../../Icons.types";
import {getClassNames} from "../../Icons.utils";
import styles from "./ArrowDropdown.module.scss";

export const AppArrowDropdownIcon = (props: AppIconProps) => {
  const {className, ...rest} = props;
  const classNames = getClassNames(styles.icon, className);

  return (
    <svg {...rest} className={classNames} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <path d="M3 6H13L8 11L3 6Z" fill="#737781"/>
    </svg>
  );
};