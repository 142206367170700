import {AppConfirm} from "shared/UI/UIKit/Confirm";
import type {RemoveActionProps} from "./types";
import {DeleteIcon} from "../../../../../ui/components/icons";

const DELETION_CONFIRM_ID = "TABLE_CONSTRUCTOR_DELETION_CONFIRM";

export const RemoveAction = ({onConfirm, onConfirmVisibleChange}: RemoveActionProps) => {
  const handleConfirmVisibleChanged = (confirmVisible: boolean) => {
    if (confirmVisible) {
      onConfirmVisibleChange(document.getElementById(DELETION_CONFIRM_ID));
    } else {
      onConfirmVisibleChange(null);
    }
  };

  return (
    <AppConfirm title="Удалить таблицу?" okText="Да" cancelText="Отмена"
                placement="topRight"
                onConfirm={onConfirm} id={DELETION_CONFIRM_ID} // ref doesnt work
                afterVisibleChange={handleConfirmVisibleChanged}>
      <DeleteIcon/>
    </AppConfirm>
  );
};