export const PROGRAM_TEMPLATE_BLOCK_TYPE = {
  DOCUMENT: "DOCUMENT",
  TABLE: "TABLE",
  TABLE_CURRICULUM: "TABLE_CURRICULUM",
} as const;

export const PROGRAM_TEMPLATE_TABLE_TYPE = {
  SIMPLE: "SIMPLE",
  EXTENDED: "EXTENDED",
} as const;

export const PROGRAM_TEMPLATE_TABLE_SECTION_TYPE = {
  TABLE: "TABLE",
  TABLE_FOOTER: "TABLE_FOOTER",
} as const;

export const PROGRAM_TEMPLATE_TABLE_ROW_TYPE = {
  SECTION_HEADER_ROW: "SECTION_HEADER_ROW",
  SECTION_BODY_ROW: "SECTION_BODY_ROW",
  SECTION_FOOTER_ROW: "SECTION_FOOTER_ROW",
} as const;

export const PROGRAM_TEMPLATE_TABLE_FIELD_TYPE = {
  FILLER: "FILLER", // used only on front end
  INDEX: "INDEX", // used only on front end
  WIDGET_STRING: "WIDGET_STRING",
  WIDGET_NUMBER: "WIDGET_NUMBER",
  WIDGET_DATE: "WIDGET_DATE",
  WIDGET_PERIOD: "WIDGET_PERIOD",
  WIDGET_LIST: "WIDGET_LIST",
  WIDGET_REFERENCE: "WIDGET_REFERENCE",
  WIDGET_SUM: "WIDGET_SUM",
  WIDGET_PERCENT: "WIDGET_PERCENT",
} as const;

export const PROGRAM_TEMPLATE_TABLE_FIELD_OPERATOR = {
  required: "required",
  min: "min",
  max: "max",
} as const;