import {generateUUID} from "shared/helpers/utils/generate-uuid";
import type {TableConstructorGroupColumn} from "../../../types";

const getDefaultColumn = (): TableConstructorGroupColumn => {
  return {
    id: generateUUID(),
    name: "Название",
    children: [],
  };
};

export const makeGroupColumn = (column: Partial<TableConstructorGroupColumn>): TableConstructorGroupColumn => {
  return {
    ...getDefaultColumn(),
    ...column,
  };
};