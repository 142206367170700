import {AppButton} from "shared/UI/UIKit/Button";
import styles from "./styles.module.scss";

export type SaveButtonProps = {
  click: () => void;
}

export const SaveButton = (props: SaveButtonProps) => {
  return (
    <div className={styles["sticky-wrap"]}>
      <AppButton className={styles["sticky-button"]} onClick={props.click}>
        Сохранить
      </AppButton>
    </div>
  );
};
