import type {TableProps} from "./types";
import {TableDragAndDropProvider} from "../../contexts/drag-and-drop";
import {TableHeader} from "../TableHeader";
import {TableBody} from "../TableBody";

export const Table = (props: TableProps) => {
  return (
    <table className="table-constructor">
      <TableHeader onCellUpdate={props.headerMethods.onCellUpdate}
                   onColumnEditClick={props.headerMethods.onColumnEditClick}/>
      <TableDragAndDropProvider table={props.table}
                                onRowDragEnd={props.rowMethods.dragEnd}>
        <TableBody type={props.table.type} sections={props.table.sections}
                   settings={props.table.settings}
                   moduleMethods={{
                     create: props.moduleMethods.create,
                   }}
                   sectionMethods={{
                     create: props.sectionMethods.create,
                     insert: props.sectionMethods.insert,
                   }}
                   rowMethods={{
                     create: props.rowMethods.create,
                   }}
                   cellMethods={{
                     click: props.cellMethods.click,
                     update: props.cellMethods.update,
                   }}/>
      </TableDragAndDropProvider>
    </table>
  );
};