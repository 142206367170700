import type {TableConstructorSingleColumn} from "../../../types";
import {getColumnTypePlaceholder} from "../column-properties";
import {makeSingleColumn} from "./single-column";

export const makeWidgetDateColumn = (column?: Partial<TableConstructorSingleColumn>): TableConstructorSingleColumn => {
  return makeSingleColumn({
    type: "WIDGET_DATE",
    name: "Дата изучения",
    width: "10rem",
    placeholder: getColumnTypePlaceholder("WIDGET_DATE"),
    ...column,
  });
};