import {EmailValidator} from "commons-validator-js";
import {errorMessages} from "../../error-messages";

export const emailValidator = (required = true) => {
  const validator = new EmailValidator();

  return (_: any, value: string) => {
    if (!value) {
      return required ? Promise.reject(errorMessages.required) : Promise.resolve();
    }

    if (value.match(/\s/) || !validator.isValid(value)) {
      return Promise.reject(errorMessages.emailError);
    }

    return Promise.resolve();
  };
};