import type {TableConstructorSingleColumn} from "../../../types";
import {getColumnTypePlaceholder} from "../column-properties";
import {makeSingleColumn} from "./single-column";

export const makeWidgetStringColumn = (column?: Partial<TableConstructorSingleColumn>): TableConstructorSingleColumn => {
  return makeSingleColumn({
    type: "WIDGET_STRING",
    locked: true,
    placeholder: getColumnTypePlaceholder("WIDGET_STRING"),
    ...column,
  });
};

export const makeWidgetStringAdditionalColumn = (column?: Partial<TableConstructorSingleColumn>): TableConstructorSingleColumn => {
  return makeWidgetStringColumn({
    subtype: "ADDITIONAL",
    name: "Дополнительная информация",
    width: "11rem",
    locked: false,
    hidden: true,
    ...column,
  });
};