import cn from "classnames";
import {createElement} from "react";
import type {PropsWithKey} from "shared/types";
import type {TableRowProps} from "./types";
import type {TableBodyCellProps} from "../TableBodyCell";
import {TableBodyCell} from "../TableBodyCell";
import {useRowDragAndDrop} from "./use-row-drag-and-drop";
import {useTableRow} from "./use-table-row";
import styles from "./styles.module.scss";

export const TableRow = (props: TableRowProps) => {
  const {
    getRowStyle,
    setNodeRef,
    attributes,
    listeners,
  } = useRowDragAndDrop(props.row, props.sectionId!, props.settings);

  const [
    {
      isHeaderRowSelected,
      isBodyRowSelected,
    },
    {
      handleRowClick,
      handleRowPointerEnter,
      handleRowPointerLeave,
      checkFieldIsHidden,
      getHiddenCellProps,
      getVisibleCellProps,
    }
  ] = useTableRow(props);

  const rowClassName = cn(
    isHeaderRowSelected && [styles["header-row_selected"]],
    isBodyRowSelected && [styles["body-row_selected"]],
  );

  return (
    <tr style={getRowStyle()}
        className={rowClassName} onClick={handleRowClick}
        ref={setNodeRef} {...attributes} {...listeners}
        onPointerEnter={handleRowPointerEnter} onPointerLeave={handleRowPointerLeave}>
      {props.row.fields.map((field) => {
        if (checkFieldIsHidden(field)) {
          return createElement(TableBodyCell.Hidden, getHiddenCellProps(field));
        }

        switch (field.type) {
          case "FILLER": return createElement(
            TableBodyCell.Filler,
            getVisibleCellProps(field) as PropsWithKey<TableBodyCellProps["Filler"]>
          );
          case "INDEX": return createElement(
            TableBodyCell.Index,
            getVisibleCellProps(field) as PropsWithKey<TableBodyCellProps["Index"]>
          );
          case "WIDGET_STRING": return createElement(
            TableBodyCell.WidgetString,
            getVisibleCellProps(field) as PropsWithKey<TableBodyCellProps["WidgetString"]>
          );
          case "WIDGET_NUMBER": return createElement(
            TableBodyCell.WidgetNumber,
            getVisibleCellProps(field) as PropsWithKey<TableBodyCellProps["WidgetNumber"]>
          );
          case "WIDGET_DATE": return createElement(
            TableBodyCell.WidgetDate,
            getVisibleCellProps(field) as PropsWithKey<TableBodyCellProps["WidgetDate"]>
          );
          case "WIDGET_PERIOD": return createElement(
            TableBodyCell.WidgetPeriod,
            getVisibleCellProps(field) as PropsWithKey<TableBodyCellProps["WidgetPeriod"]>
          );
          case "WIDGET_LIST": return createElement(
            TableBodyCell.WidgetList,
            getVisibleCellProps(field) as PropsWithKey<TableBodyCellProps["WidgetList"]>
          );
          case "WIDGET_REFERENCE": return createElement(
            TableBodyCell.WidgetReference,
            getVisibleCellProps(field) as PropsWithKey<TableBodyCellProps["WidgetReference"]>
          );
          case "WIDGET_SUM": return createElement(
            TableBodyCell.WidgetSum,
            getVisibleCellProps(field) as PropsWithKey<TableBodyCellProps["WidgetSum"]>
          );
          case "WIDGET_PERCENT": return createElement(
            TableBodyCell.WidgetPercent,
            getVisibleCellProps(field) as PropsWithKey<TableBodyCellProps["WidgetPercent"]>
          );
        }
      })}
    </tr>
  );
};