import cn from "classnames";
import {InlineMenuHelpers} from "shared/UI/UIKit/Menu";
import type {SubMenuItemOptions} from "shared/UI/UIKit/Menu";
import {getIcon} from "./get-icon";
import styles from "./styles.module.scss";

type GetSubMenuItemProps = SubMenuItemOptions;

export const getSubMenuItem = ({
  icon,
  wrapperClassName,
  className,
  ...rest
}: GetSubMenuItemProps) => {
  return InlineMenuHelpers.getSubMenuItem({
    icon: getIcon(icon),
    wrapperClassName: cn(styles["submenu-item__wrapper"], wrapperClassName),
    className: cn(styles["submenu-item__content"], className),
    ...rest,
  });
};