import MDEditor, {commands} from "@uiw/react-md-editor";
import rehypeSanitize from "rehype-sanitize";
import type {AppMarkdownEditorProps} from "./MarkdownEditor.types";
import "./styles.scss";

export const AppMarkdownEditor = (props: AppMarkdownEditorProps) => {
  return (
    <div data-color-mode="light">
      <MDEditor previewOptions={{rehypePlugins: [[rehypeSanitize]]}}
                commands={[
                  commands.bold,
                  commands.italic,
                  commands.strikethrough,
                ]}
                extraCommands={[]}
                minHeight={200} maxHeight={400}
                {...props}/>
    </div>
  );
};