const clone = (source1: object, source2 = {}) => {
  return Object.assign(
    Array.isArray(source1) ? [] : {},
    source1,
    source2,
  );
};

export function setByPath<TResult = unknown>(item: object, path: string[], targetValue: any): TResult {
  if (!path.length) {
    return clone(item) as unknown as TResult;
  }

  if (path.length === 1) {
    return clone(item, {
      [path[0]]: targetValue,
    }) as unknown as TResult;
  }

  return clone(item, {
    [path[0]]: setByPath(
      (item as any)[path[0]] ?? {},
      path.slice(1),
      targetValue,
    ),
  }) as unknown as TResult;
}