import {EducationLevel} from "services/api";
import type {AppFormRule} from "shared/UI/UIKit/Form/lib/rules/types";

export const EDUCATION_LEVEL_OPTIONS = [
  {
    label: "Начальное общее образование (НОО)",
    value: EducationLevel.НОО
  },
  {
    label: "Основное общее образование (ООО)",
    value: EducationLevel.ООО
  },
  {
    label: "Среднее общее образование (СОО)",
    value: EducationLevel.СОО
  }
];

export const EDUCATION_LEVEL_FORM_RULE: AppFormRule = {
  required: true,
  message: "Выберите уровень образования",
  whitespace: true
};