import {memo} from "react";
import cn from "classnames";
import {AppText} from "shared/UI/UIKit/Text";
import type {TableTitleProps} from "./types";
import styles from "./styles.module.scss";

export const TableTitle = memo((props: TableTitleProps) => {
  if (!props.title) {
    return null;
  }

  return (
    <AppText size={16} weight={700} className={cn(styles.title, "table-title-docx")}>
      {props.title}
    </AppText>
  );
});