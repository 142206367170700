import {createContext, useState} from "react";
import {noop} from "shared/helpers/utils/noop";
import type {PropsWithChildren} from "shared/types";

export type VisibleIndexContextType = {
  isIndexesVisible: boolean;
  toggleIsIndexesVisible: () => void;
}

export const VisibleIndexContext = createContext<VisibleIndexContextType>({
  isIndexesVisible: false,
  toggleIsIndexesVisible: noop,
});

export const VisibleIndexContextProvider = (props: PropsWithChildren) => {
  const [isIndexesVisible, setIsIndexesVisible] = useState<boolean>(false);

  const toggleIsIndexesVisible = () => setIsIndexesVisible(!isIndexesVisible);

  return (
    <VisibleIndexContext.Provider value={{
      isIndexesVisible,
      toggleIsIndexesVisible,
    }}>
      {props.children}
    </VisibleIndexContext.Provider>
  );
};