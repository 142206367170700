import {docxStyles} from "./docx-styles";

export const isBlock = (type?: string | null) => {
  return type === "considered-agreed-approved" ||
    type === "considered-approved" ||
    type === "agreed-approved" ||
    type === "approved";
};

const clearNode = (node: HTMLElement) => {
  const clonedNode = node.cloneNode(true) as HTMLElement;
  const editorToolbarSelectors = ["se-toolbar", "se-line-breaker", "se-dialog", "se-controller", "se-notice", "se-resizing-back", "se-loading-box", "block-edit"]
    .map((selector) => `[class*="${selector}"]`).join(",");
  const trashElements = clonedNode.querySelectorAll("button, svg, textarea, [style*=\"display: none\"], " + editorToolbarSelectors);
  trashElements.forEach((element) => {
    if (element.parentElement?.classList.contains("hide-tail")) {
      return;
    }
    element.remove();
  });
  const defaultIndent = 35;
  const textIndentToSpanElements = clonedNode.querySelectorAll("p");
  textIndentToSpanElements.forEach((p) => {
    if (p.style.textIndent) {
      let repeat = 1;
      if (p.style.textIndent.includes("pt") && parseInt(p.style.textIndent) >= 70) {
        repeat = Math.trunc(parseInt(p.style.textIndent) / defaultIndent);
      }
      const span = document.createElement("span");
      span.textContent = "###Par###".repeat(repeat);
      p.style.textIndent = "";
      p.insertBefore(span, p.firstChild);
    }
  });

  const considered = clonedNode.querySelectorAll("#considered")[0];
  const agreed = clonedNode.querySelectorAll("#agreed")[0];
  const approved = clonedNode.querySelectorAll("#approved")[0];
  considered?.setAttribute("class", "considered");
  agreed?.setAttribute("class", "agreed");
  approved?.setAttribute("class", "approved");

  const allBr = clonedNode.querySelectorAll("br");
  if (allBr?.length) {
    allBr.forEach(item => {
      if (item.parentElement?.getAttribute("data-widget") || item.classList.contains("content_br")) {
        return;
      } else {
        item.remove();
      }
    });
  }

  const placeholders = clonedNode.querySelectorAll("[data-widget=\"true\"]");

  placeholders.forEach(placeholder => {
    if (isBlock(placeholder.getAttribute("data-type"))) {
      const placeholdersInsideBlock = placeholder.querySelectorAll("[data-widget=\"true\"]");
      // 0, 6, 12 - должность
      // 1, 7, 13 - фио
      // 2, 8, 14 - приказ
      // 3, 9, 15 - число
      // 4, 10, 16 - месяц
      // 5, 11, 17 - год
      placeholdersInsideBlock.forEach((item, index) => {
        item.setAttribute("data-inside-block", "true");
        if (item.getAttribute("data-value")) {
          return;
        }
        if (index === 3 || index === 9 || index === 15) { // число
          item.innerHTML = "&nbsp;".repeat(6);
        } else if (index === 4 || index === 10 || index === 16) { // месяц
          item.innerHTML = "&nbsp;".repeat(20);
        } else if (index === 5 || index === 11 || index === 17) { // год
          item.innerHTML = "&nbsp;".repeat(13);
        } else {
          item.innerHTML = "&nbsp;";
        }
      });
    }
  });

  placeholders.forEach(item => {
    if (!item.getAttribute("data-value") && !item.getAttribute("data-inside-block") && !isBlock(item.getAttribute("data-type"))) {
      if (item.parentElement) {
        item.parentElement.remove();
      } else {
        item.remove();
      }
    }
  });

  return clonedNode;
};

export const convertNodeToHTML = (node: HTMLElement) => {
  const clearedNode = clearNode(node);
  const markup = clearedNode.outerHTML.replaceAll(/\[.*?\]/g,"");

  return `
    <!doctype html>
    <html lang="en">
    <head>
        <meta charset="UTF-8">
        <meta name="viewport"
              content="width=device-width, user-scalable=no, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0">
        <meta http-equiv="X-UA-Compatible" content="ie=edge">
<!--        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/katex@0.16.8/dist/katex.min.css" integrity="sha384-GvrOXuhMATgEsSwCs4smul74iXGOixntILdUW9XmUC6+HX0sLNAK3q71HotJqlAn" crossorigin="anonymous">-->
        <title>Document</title>
        <style>${docxStyles}</style>
    </head>
    <body>
      ${markup}
    </body>
    </html>
  `;
};

export const addClassToBr = (content: string): string => {
  return content.replaceAll("<br>", "<br class='content_br'/>");
};