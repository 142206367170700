import {Form} from "antd";
import {AppForm, AppFormItem, formRules} from "shared/UI/UIKit/Form";
import {AppInput} from "shared/UI/UIKit/Input";
import {AppFlex} from "shared/UI/UIKit/Flex";
import {AppButton} from "shared/UI/UIKit/Button";
import {AppModalHeader, AppModalTitle, AppModalContent, AppModalFooter} from "shared/UI/UIKit/Modal";
import type {ColumnSettingsModalFormValues, ColumnSettingsModalFormProps} from "./types";
import {formNames} from "./constants";

export const ColumnSettingsModalForm = (props: ColumnSettingsModalFormProps) => {
  const [form] = Form.useForm<ColumnSettingsModalFormValues>();

  const handleSubmit = () => {
    form.validateFields()
      .then(props.onSubmit)
      .catch(() => null);
  };

  return (
    <>
      <AppModalHeader>
        <AppModalTitle>Настройки столбца</AppModalTitle>
      </AppModalHeader>
      <AppModalContent>
        <AppForm form={form} initialValues={{[formNames.name]: props.column.name}}>
          <AppFormItem label="Наименование столбца" size="xxl"
                       name={formNames.name} rules={formRules.required}>
            <AppInput placeholder="Введите наименование столбца"/>
          </AppFormItem>
        </AppForm>
      </AppModalContent>
      <AppModalFooter>
        <AppFlex direction="row">
          <AppButton onClick={handleSubmit}>
            Сохранить
          </AppButton>
          <AppButton type="outline" onClick={props.onCancel}>
            Отмена
          </AppButton>
        </AppFlex>
      </AppModalFooter>
    </>
  );
};