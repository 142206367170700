import {memo} from "react";
import type {TableIndexCellProps} from "./types";
import {TableBaseCell} from "../TableBaseCell";
import styles from "./styles.module.scss";

export const TableIndexCell = memo(({
  isFullAccess,
  editMode,
  field,
  sectionOrdinalNumber,
  rowOrdinalNumber,
  tableType,
}: TableIndexCellProps) => {
  const getValue = () => {
    if (tableType === "SIMPLE") {
      return `${rowOrdinalNumber}`;
    }

    return `${sectionOrdinalNumber}.${rowOrdinalNumber}`;
  };

  return (
    <TableBaseCell isFullAccess={isFullAccess} editMode={editMode}
                   index={field.index} className={styles["index-cell"]}>
      {getValue()}
    </TableBaseCell>
  );
});