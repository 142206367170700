import {useEffect, useRef} from "react";
import type {Focusable} from "shared/types";

const FOCUS_DELAY = 100;

export const useAutoFocusEffect = <T extends Focusable>(effectDeps: unknown[], focusCondition = true) => {
  const ref = useRef<T>(null);

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (focusCondition) {
      timeout = setTimeout(() => ref.current?.focus(), FOCUS_DELAY);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, effectDeps);

  return ref;
};