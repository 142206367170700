import {AppLink} from "shared/UI/UIKit/Link";
import {AppFlex} from "shared/UI/UIKit/Flex";
import {AppText} from "shared/UI/UIKit/Text";
import type {AppBreadcrumbItemProps} from "./BreadcrumbItem.types";
import styles from "../BreadcrumbItem.module.scss";
import {AppBreadcrumbItemSeparator} from "../Separator";

export const AppBreadcrumbItem = (props: AppBreadcrumbItemProps) => {
  const link = (
    <AppFlex direction="row" unit="10">
      <AppLink to={props.url} className={styles.item}>
        <AppFlex direction="row" unit="4">
          {props.icon && <span className={styles.prefix}>{props.icon}</span>}
          {props.title && <AppText className={styles.title}>{props.title}</AppText>}
        </AppFlex>
      </AppLink>
      <AppBreadcrumbItemSeparator separator={props.separator}/>
    </AppFlex>
  );

  const lastElement = (
    <>
      {props.icon && <span className={styles.last}>{props.icon}</span>}
      <span className={styles.last}>{props.title}</span>
    </>
  );

  return props.isLast ? lastElement : link;
};
