import cn from "classnames";
import {AppLockOnIcon} from "shared/UI/UIKit/icons/LockOn";
import {AppLockOffIcon} from "shared/UI/UIKit/icons/LockOff";
import type {LockedIconProps} from "./types";
import styles from "./styles.module.scss";

export const LockedIcon = (props: LockedIconProps) => {
  const iconWrapperClassName = cn(
    styles["icon-wrapper"],
    props.disabled && styles["icon-wrapper_disabled"],
    props.locked ? styles["icon-wrapper_locked"] : styles["icon-wrapper_unlocked"],
  );
  const iconClassName = cn(
    styles.icon,
    props.disabled && styles["icon_disabled"],
    props.locked ? styles["icon_locked"] : styles["icon_unlocked"],
  );

  const handleWrapperClick = () => {
    !props.disabled && props.toggle?.(!props.locked);
  };

  return (
    <div className={iconWrapperClassName} onClick={handleWrapperClick}>
      {props.locked
        ? <AppLockOnIcon className={iconClassName}/>
        : <AppLockOffIcon className={iconClassName}/>
      }
    </div>
  );
};