import {useLayoutEffect} from "react";
import {scrollbarWidth} from "@xobotyi/scrollbar-width";
import {isFunction} from "shared/helpers/utils/is-function";

export const useScrollLock = (enabled?: boolean, getContainer?: HTMLElement | (() => HTMLElement)) => {
  useLayoutEffect(() => {
    if (enabled) {
      const container = isFunction(getContainer)
        ? getContainer()
        : getContainer || document.body;

      container.style.setProperty("overflow", "hidden");
      if (container === document.body) {
        const scrollbarOffset = scrollbarWidth() || 0;
        container.style.setProperty("padding-right", `${scrollbarOffset}px`);
      }

      return () => {
        container.style.removeProperty("overflow");
        if (container === document.body) {
          container.style.removeProperty("padding-right");
        }
      };
    }
  }, [enabled, getContainer]);
};