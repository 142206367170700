import axios, {isAxiosError} from "axios";
import {LocaleStorageService} from "shared/helpers/local-storage";
import {LSKeys} from "shared/constants/local-storage-keys";
import {getAuthorizationUrl} from "services/navigation";
import type {InternalAxiosRequestConfig} from "axios";

export const MAIN_PROGRAM_API_URL = process.env.REACT_APP_MAIN_PROGRAM_API_URL || "/api";

export const mainProgramAppApi = axios.create({
  baseURL: MAIN_PROGRAM_API_URL,
});

export const isApiError = isAxiosError;

mainProgramAppApi.interceptors.request.use((config: InternalAxiosRequestConfig) => {
  const accessToken = LocaleStorageService.getStringItem(LSKeys.edsooAccessToken);
  if (config.headers && accessToken) {
    config.headers["Authorization"] = `Bearer ${accessToken}`;
  }
  return config;
});

mainProgramAppApi.interceptors.response.use((response) => response, (error) => {
  if (error.response.status === 401) {
    document.location.href = getAuthorizationUrl();
  } else {
    throw error;
  }
});



