import cn from "classnames";
import type {AppNavLinkProps} from "./NavLink.types";
import styles from "./NavLink.module.scss";

type AppLinkStyleProps = Pick<AppNavLinkProps, "size" | "className"> & {isActive: boolean};

export const getClassNames = (props: AppLinkStyleProps) => {
  return cn(
    styles.link,
    props.size && styles[`link--${props.size}`],
    props.isActive && styles.isActive,
    props.className,
  );
};