export const ElementaryEducationHeader = () => {
  return (
    <thead>
      <tr>
        <th colSpan={1} rowSpan={2} style={{width: "20%"}}>
          Направление внеурочной деятельности
        </th>
        <th colSpan={1} rowSpan={2} style={{width: "20%"}}>
          Наименование курса внеурочной деятельности
        </th>
        <th colSpan={1} rowSpan={2} style={{width: "20%"}}>
          Формы внеурочной деятельности
        </th>
        <th colSpan={5} rowSpan={1} style={{width: "35%"}}>
          Классы/количество часов
        </th>
        <th colSpan={1} rowSpan={2} style={{width: "8%"}}>
          Всего часов
        </th>
      </tr>
      <tr>
        <th colSpan={1}
          rowSpan={1} style={{width: "8%"}}>
          5 кл.
        </th>
        <th colSpan={1}
          rowSpan={1} style={{width: "8%"}}>
          6 кл.
        </th>
        <th colSpan={1}
          rowSpan={1} style={{width: "8%"}}>
          7 кл.
        </th>
        <th colSpan={1}
          rowSpan={1} style={{width: "8%"}}>
          8 кл.
        </th>
        <th colSpan={1}
          rowSpan={1} style={{width: "8%"}}>
          9 кл.
        </th>
      </tr>
    </thead>
  );
};