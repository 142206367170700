import {forwardRef} from "react";
import type {AppPrimaryButtonProps} from "./PrimaryButton.types";
import {AppBaseButton} from "../BaseButton";
import {getClassNames} from "./PrimaryButton.utils";

export const AppPrimaryButton = forwardRef<HTMLButtonElement, AppPrimaryButtonProps>((
props, ref) => {
  const classNames = getClassNames(props);

  return (
    <AppBaseButton {...props} className={classNames} ref={ref}/>
  );
});