import {AppModal} from "shared/UI/UIKit/Modal";
import type {TableConstructorColumn} from "../../../lib/types";
import type {ColumnSettingsModalProps} from "./types";
import type {ColumnSettingsModalFormValues} from "./form";
import {ColumnSettingsModalForm} from "./form";

export const ColumnSettingsModal = (props: ColumnSettingsModalProps) => {
  const handleSubmit = (values: ColumnSettingsModalFormValues) => {
    const nextColumn: TableConstructorColumn = {...props.column!, ...values};
    props.onColumnUpdate(nextColumn);
    handleCancel();
  };

  const handleCancel = () => {
    props.onHide();
  };

  return (
    <AppModal visible={props.visible} onCancel={handleCancel}>
      {!!props.column && (
        <ColumnSettingsModalForm column={props.column} onSubmit={handleSubmit}
                                 onCancel={handleCancel}/>
      )}
    </AppModal>
  );
};