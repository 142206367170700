import type {AppFilterDropdownProps} from "./FilterDropdown.types";
import {
  TextFilterDropdown,
  NumberFilterDropdown,
  DateFilterDropdown,
  TimeFilterDropdown,
  EnumFilterDropdown
} from "../dropdowns";

export const AppFilterDropdown = (props: AppFilterDropdownProps) => {
  switch (props.filter.type) {
    case "string": {
      return (
        <TextFilterDropdown {...props}/>
      );
    }
    case "number": {
      return (
        <NumberFilterDropdown {...props}/>
      );
    }
    case "date": {
      return (
        <DateFilterDropdown {...props}/>
      );
    }
    case "time": {
      return (
        <TimeFilterDropdown {...props}/>
      );
    }
    case "enum": {
      return (
        <EnumFilterDropdown {...props}/>
      );
    }
  }
};