type Callback = (value: number, index: number, array: number[]) => any;

export const makeArray = (count: number, from = 0, cb?: Callback): any[] => {
  const defaultMapper: Callback = ((value) => value);
  const mapper = cb ?? defaultMapper;

  if (count < 0) {
    return [];
  }

  return Array(count)
    .fill(null)
    .map((_, i, array) => mapper(from + i, i, array));
};