import type {AppSelectOption} from "shared/UI/UIKit/Select";
import type {ProgramTemplateBlockTable} from "services/api";
import type {TableConstructorSingleColumn} from "../../../../lib/types";
import type {TableSettingsFormValues} from "./types";
import {modalHelpers} from "../../../../lib/helpers";

export const getHiddenColumnOptions = (columns: TableConstructorSingleColumn[]): AppSelectOption[] => {
  return columns.reduce((acc, column, index) => {
    if (!column.locked) {
      const option: AppSelectOption = {label: column.name, value: `${index}`};

      return [...acc, option];
    }
    return acc;
  }, [] as AppSelectOption[]);
};

export const getInitialFormValues = (
  table: ProgramTemplateBlockTable,
  columns: TableConstructorSingleColumn[],
): TableSettingsFormValues => {
  return {
    name: table.name,
    viewSettings: modalHelpers.getViewSettings(table, columns),
    settings: modalHelpers.DTOTableSettingsToNames(table.settings),
    hiddenColumnIndexes: (table.header.hiddenColumnIndexes || "").split(","),
  };
};