import {createSlice} from "@reduxjs/toolkit";
import {
  getAllSubjectsReducerBuilder,
  getWorkProgramsReducerBuilder,
  setWorkProgramAvailableInMainProgramReducerBuilder, toggleVisibleWorkProgramInMainProgramListReducerBuilder
} from "features/work-programs/redux/reducers";
import type {WorkProgramsState} from "./types";

const initialState: WorkProgramsState = {
  loading: false,
  workPrograms: [],
  subjects: [],
  total: null,
};

const workProgramsSlice = createSlice({
  name: "workPrograms",
  initialState,
  reducers: {
    clearWorkProgramsStateAction: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    getWorkProgramsReducerBuilder(builder);
    setWorkProgramAvailableInMainProgramReducerBuilder(builder);
    getAllSubjectsReducerBuilder(builder);
    toggleVisibleWorkProgramInMainProgramListReducerBuilder(builder);
  }
});

export const workProgramsReducer = workProgramsSlice.reducer;
export const {clearWorkProgramsStateAction} = workProgramsSlice.actions;
