import {createContext, useContext} from "react";
import {noop} from "shared/helpers/utils/noop";
import type {TableActionsContextType} from "./types";

const defaultValue: TableActionsContextType = {
  showActionsPanel: noop,
  hideActionsPanel: noop,
};

export const TableActionsContext = createContext<TableActionsContextType>(defaultValue);

export const useTableActionsContext = () => {
  return useContext(TableActionsContext);
};