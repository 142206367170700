import type {AppDividerProps} from "./Divider.types";
import {DividerDefault} from "../DividerDefault";
import {DividerWithChildren} from "../DividerWithChildren";

export const AppDivider = (props: AppDividerProps) => {
  const {children, childrenPosition = "center", borderStyle = "solid", width = "100", ...rest} = props;

  return (
    children ? (
      <DividerWithChildren childrenPosition={childrenPosition}
                           borderStyle={borderStyle}
                           width={width} {...rest}>
        {children}
      </DividerWithChildren>
    ) : (
      <DividerDefault borderStyle={borderStyle} width={width} {...rest}/>
    )
  );
};