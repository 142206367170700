import {AppTitle} from "shared/UI/UIKit/Title";
import type {CurriculumDetailedBlock} from "services/api";
import {CurriculumHeader} from "../CurriculumHeader";
import {CurriculumBody} from "../CurriculumBody";
import {CurriculumFooter} from "../CurriculumFooter";
import styles from "./styles.module.scss";

type CurriculumTableProps = {
  block: CurriculumDetailedBlock;
}

export const CurriculumTable = (props: CurriculumTableProps) => {
  return (
    <div className={styles.wrapper}>
      <AppTitle className={styles.title} level={3}>
        {props.block.title || "Учебный план"}
      </AppTitle>
      <table className={styles.table}>
        {props.block.headerRows && <CurriculumHeader rows={props.block.headerRows}/>}
        {props.block.bodyRows && <CurriculumBody rows={props.block.bodyRows}/>}
        {props.block.footerRows && <CurriculumFooter rows={props.block.footerRows}/>}
      </table>
    </div>
  );
};