import type {AppIconProps} from "../../Icons.types";
import {getClassNames} from "../../Icons.utils";
import styles from "./ChevronTop.module.scss";

export const AppChevronTopIcon = (props: AppIconProps) => {
  const {className, ...rest} = props;
  const classNames = getClassNames(styles.icon, className);

  return (
    <svg {...rest} className={classNames} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 10" fill="none">
      <path d="M1 9L8 2L15 9" stroke="#737781" strokeWidth="2" strokeLinecap="round"/>
    </svg>
  );
};