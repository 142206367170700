import cn from "classnames";
import {AppLockOnIcon} from "shared/UI/UIKit/icons/LockOn";
import {AppLockOffIcon} from "shared/UI/UIKit/icons/LockOff";
import type {MouseEventHandler} from "react";
import type {LockedIconProps} from "./types";
import styles from "./styles.module.scss";

export const LockedIcon = (props: LockedIconProps) => {
  const iconWrapperClassName = cn(
    styles["icon-wrapper"],
    props.locked ? styles["icon-wrapper_locked"] : styles["icon-wrapper_unlocked"],
  );
  const iconClassName = cn(
    styles.icon,
    props.locked ? styles["icon_locked"] : styles["icon_unlocked"],
  );

  const handleWrapperClick: MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();
    props.toggle?.(!props.locked);
  };

  return (
    <div onClick={handleWrapperClick} className={iconWrapperClassName}>
      {props.locked
        ? <AppLockOnIcon className={iconClassName}/>
        : <AppLockOffIcon className={iconClassName}/>
      }
    </div>
  );
};