import {createSlice} from "@reduxjs/toolkit";
import type {MainProgram} from "services/api";
import type {PayloadAction} from "@reduxjs/toolkit";
import type {MainProgramState} from "./types";
import {
  createMainProgramBlockActionReducerBuilder, deleteMainProgramBlockActionReducerBuilder,
  getDetailedCurriculumByIdReducerBuilder,
  getMainProgramBlockByIdReducerBuilder,
  getMainProgramByIdReducerBuilder,
  getWorkProgramByIdReducerBuilder, renameCurriculumActionReducerBuilder, updateMainProgramBlockActionReducerBuilder
} from "./reducers";

const initialState: MainProgramState = {
  loading: true,
  mainProgramPartLoading: true,
  mainProgram: null,
  workProgram: null,
  mainProgramBlock: null,
  detailedCurriculum: null,
  renderType: null,
};


const mainProgramSlice = createSlice({
  name: "mainProgram",
  initialState,
  reducers: {
    updateAdditionalEducationTableAction: (state, action: PayloadAction<string>) => {
      if (state.mainProgramBlock) {
        state.mainProgramBlock.content = action.payload;
      }
    },
    setMainProgramAction: (state, action: PayloadAction<MainProgram>) => {
      state.mainProgram = action.payload;
    },
    setMainProgramLoadingAction: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    clearMainProgramStateAction: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    getMainProgramByIdReducerBuilder(builder);
    getWorkProgramByIdReducerBuilder(builder);
    getMainProgramBlockByIdReducerBuilder(builder);
    getDetailedCurriculumByIdReducerBuilder(builder);
    createMainProgramBlockActionReducerBuilder(builder);
    updateMainProgramBlockActionReducerBuilder(builder);
    deleteMainProgramBlockActionReducerBuilder(builder);
    renameCurriculumActionReducerBuilder(builder);
  }
});

export const mainProgramReducer = mainProgramSlice.reducer;
export const {setMainProgramAction, clearMainProgramStateAction, setMainProgramLoadingAction, updateAdditionalEducationTableAction} = mainProgramSlice.actions;
