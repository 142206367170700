import {useCallback, useState} from "react";
import type {ModalService} from "./types";

export const useModal = (): ModalService => {
  const [visible, setVisible] = useState<boolean>(false);

  const show = useCallback(() => setVisible(true), []);
  const hide = useCallback(() => setVisible(false), []);

  return {
    state: {
      visible,
    },
    methods: {
      show,
      hide,
    },
  };
};