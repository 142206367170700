import type {AppTabsPanesProps} from "./TabsPanes.types";
import {AppTabPane} from "../TabPane";
import styles from "./TabsPanes.module.scss";

export const AppTabsPanes = ({tabs, activeId, destroyInactiveTabs}: AppTabsPanesProps) => {
  return (
    <div className={styles["tab-container"]}>
      {
        tabs.map(tab => {
          const active = activeId === tab.id;
          const render = active || !destroyInactiveTabs;

          return render ? <AppTabPane {...tab} key={tab.id} active={active}/> : null;
        })
      }
    </div>
  );
};