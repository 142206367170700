import {useCallback, useEffect, useMemo, useState} from "react";
import type {TableContextProviderProps, ReferencesMap, TableContextType} from "./types";
import {makeDefaultDatePickerValuesMap} from "../../../../../lib/helpers";
import {dictionaryServices} from "../../../../../lib/services";
import {useTableConstructorContext} from "../../../../contexts/table-constructor-context";
import {TableContext} from "./context";

export const TableContextProvider = ({table, children}: TableContextProviderProps) => {
  const [references, setReferences] = useState<ReferencesMap>(() => new Map());
  const [referencesLoading, setReferencesLoading] = useState(false);
  const {singleColumns} = useTableConstructorContext();

  const defaultDatePickerValuesMap = useMemo(() => {
    return makeDefaultDatePickerValuesMap(singleColumns, table.sections);
  }, [singleColumns, table.sections]);

  const getDefaultDatePickerValue = useCallback((columnIndex: number, rowId: string | number) => {
    return defaultDatePickerValuesMap.get(columnIndex)?.get(rowId);
  }, [defaultDatePickerValuesMap]);

  const value = useMemo<TableContextType>(
    () => ({references, referencesLoading, getDefaultDatePickerValue}),
    [references, referencesLoading, getDefaultDatePickerValue]
  );

  useEffect(() => {
    const makeReferencesMap = async () => {
      try {
        setReferencesLoading(true);
        const map: ReferencesMap = new Map();
        const dictionaries = await dictionaryServices.getAll();
        dictionaries.data.forEach(dict => {
          if (dict) {
            map.set(dict.code, dict.items);
          }
        });
        setReferences(map);
      } finally {
        setReferencesLoading(false);
      }
    };

    const isWidgetReferenceColumnVisible = singleColumns.some(
      column => column.type === "WIDGET_REFERENCE" && !column.hidden
    );
    if (isWidgetReferenceColumnVisible && !references.size) {
      makeReferencesMap();
    }
  }, [singleColumns]);

  return (
    <TableContext.Provider value={value}>
      {children}
    </TableContext.Provider>
  );
};