import cn from "classnames";
import type {AppExternalLinkProps} from "./ExternalLink.types";
import styles from "./ExternalLink.module.scss";

type AppExternalLinkStyleProps = Pick<AppExternalLinkProps, "size" | "className">;

export const getClassNames = (props: AppExternalLinkStyleProps) => {
  return cn(
    styles.link,
    props.size && styles[`link--${props.size}`],
    props.className,
  );
};