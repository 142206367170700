import type {RequiredType} from "shared/types";
import type {
  PROGRAM_TEMPLATE_BLOCK_TYPE,
  PROGRAM_TEMPLATE_TABLE_TYPE,
  PROGRAM_TEMPLATE_TABLE_SECTION_TYPE,
  PROGRAM_TEMPLATE_TABLE_ROW_TYPE,
  PROGRAM_TEMPLATE_TABLE_FIELD_TYPE,
  PROGRAM_TEMPLATE_TABLE_FIELD_OPERATOR,
} from "./constants";
import type {Subject} from "../main-programs/types";

export enum EducationLevel {
  НОО = "HOO",
  ООО = "OOO",
  СОО = "COO",
}

export enum ProgramTemplateStatus {
  DONE = "DONE",
  IN_PROGRESS = "IN_PROGRESS",
}

export type ProgramTemplate = {
  id: number;
  name: string;
  isProgram: boolean;
  programName: string;
  educationLevel: EducationLevel;
  generationInProgress: boolean;
  status: ProgramTemplateStatus;
  blocks: ProgramTemplateBlock[];
  subject: Subject | null;
  createdBy?: string;
  shareToken?: string;
}

export type ProgramTemplateBlockType = keyof typeof PROGRAM_TEMPLATE_BLOCK_TYPE;
export type ProgramTemplateTableType = keyof typeof PROGRAM_TEMPLATE_TABLE_TYPE;
export type ProgramTemplateTableSectionType = keyof typeof PROGRAM_TEMPLATE_TABLE_SECTION_TYPE;
export type ProgramTemplateTableRowType = keyof typeof PROGRAM_TEMPLATE_TABLE_ROW_TYPE;
export type ProgramTemplateTableFieldType = keyof typeof PROGRAM_TEMPLATE_TABLE_FIELD_TYPE;
export type ProgramTemplateTableFieldOperator = keyof typeof PROGRAM_TEMPLATE_TABLE_FIELD_OPERATOR | string | null;

export type ProgramTemplateBlockSettings = {
  padding: number;
  title?: string | null;
}

export type ProgramTemplateBlock = {
  id: number;
  template: ProgramTemplate;
  name: string;
  content: string;
  index: number;
  settings: ProgramTemplateBlockSettings;
  type: ProgramTemplateBlockType;
  fields: ProgramTemplateBlockField[];
  tables: ProgramTemplateBlockTable[];
  variative: boolean;
  visible: boolean;
  userCanEditText: boolean;
}

export type ProgramTemplateBlockField = {
  id: number | string | null;
  block: ProgramTemplateBlock;
  code: string;
  value: string;
  placeholderId: string;
  listId: string;
  options: string;
  type: string;
  required: boolean;
  placeholder: string;
  hint: string;
}

export type ProgramTemplateBlockTable = {
  id: number | string | null;
  block: RequiredType<Partial<ProgramTemplateBlock>, "id"> | null;
  name: string;
  index: number;
  type: ProgramTemplateTableType;
  settings: ProgramTemplateBlockTableSettings;
  header: ProgramTemplateBlockTableHeader;
  sections: ProgramTemplateBlockTableSection[];
}

export type ProgramTemplateBlockTableHeader = {
  variant: string;
  lockedColumnIndexes: string | null;
  hiddenColumnIndexes: string | null;
}

export type ProgramTemplateBlockTableSettings = {
  canDownloadXlsx: boolean | null;
  // access rights
  sectionCreationAllowed: boolean | null;
  programTemplateSectionMovingAllowed: boolean | null;
  workProgramSectionMovingAllowed: boolean | null;
  programTemplateSectionDeletionAllowed: boolean | null;
  workProgramSectionDeletionAllowed: boolean | null;
  rowCreationAllowed: boolean | null;
  programTemplateRowMovingAllowed: boolean | null;
  workProgramRowMovingAllowed: boolean | null;
  programTemplateRowDeletionAllowed: boolean | null;
  workProgramRowDeletionAllowed: boolean | null;
}

export type ProgramTemplateBlockTableSection = {
  id: number | string | null;
  table: RequiredType<Partial<ProgramTemplateBlockTable>, "id"> | null | undefined;
  index: number;
  type: ProgramTemplateTableSectionType;
  name: string; // todo: remove on back end
  createdOnWorkProgram: boolean | null;
  rows: ProgramTemplateBlockTableRow[];
}

export type ProgramTemplateBlockTableRow = {
  id: number | string | null;
  section: RequiredType<Partial<ProgramTemplateBlockTableSection>, "id"> | null | undefined;
  index: number;
  type: ProgramTemplateTableRowType;
  createdOnWorkProgram: boolean | null;
  fields: ProgramTemplateBlockTableRowField[];
}

export type ProgramTemplateBlockTableRowField = {
  id: number | string | null;
  row: RequiredType<Partial<ProgramTemplateBlockTableRow>, "id"> | null | undefined;
  index: number;
  type: ProgramTemplateTableFieldType;
  value: string | null;
  targetValue: string | null;
  operator: ProgramTemplateTableFieldOperator;
  required: boolean | null;
  readonly: boolean | null;
  placeholder: string | null;
  listId: string | null;
  options: string | null;
  code: string | null; // not used
  placeholderId: string | null; // not used
  hint: string | null; // not used
}