import {AppTimePicker} from "shared/UI/UIKit/DatePicker";
import {AppFormItem, formRules} from "shared/UI/UIKit/Form";
import type {TimeFieldProps} from "./types";
import {FILTER_FORM_NAMES} from "../../../constants";

export const TimeField = ({onChange, placeholder, focusRef}: TimeFieldProps) => {
  return (
    <AppFormItem name={[FILTER_FORM_NAMES.values, 0]} rules={formRules.requiredTime}
                 marginless withoutExplain>
      <AppTimePicker onChange={value => onChange([value])}
                     placeholder={placeholder} ref={focusRef}/>
    </AppFormItem>
  );
};