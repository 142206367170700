import cn from "classnames";
import {AppExpandIcon} from "shared/UI/UIKit/icons/Expand";
import type {ExpandIconProps} from "./ExpandIcon.types";
import styles from "./ExpandIcon.module.scss";

export const ExpandIcon = ({isOpen}: ExpandIconProps) => {
  const className = cn({
    [styles.icon]: true,
    [styles["icon_open"]]: isOpen,
  });

  return (
    <AppExpandIcon className={className}/>
  );
};