import type {EducationLevel} from "services/api";
import {WorkProgramsTable} from "../Table";

type WorkProgramsProps = {
  educationLevel: EducationLevel;
}

export const WorkPrograms = (props: WorkProgramsProps) => {
  return (
    <WorkProgramsTable educationLevel={props.educationLevel}/>
  );
};