import {preProcess, isWordContent} from "tinymce-word-paste-filter/standalone/WordFilter";
import type {StyleMap} from "tinymce-word-paste-filter/standalone/Styles";
import type {Editor} from "tinymce-word-paste-filter/standalone/WordFilter";

export const parseStyle = (css: string) => {
  if (!css) {
    return {};
  }

  const styles: Record<string, string> = {};

  for (const property of css.split(";")) {
    if (!property.includes(":")) {
      continue;
    }

    const propArray = property.split(":");
    const name = propArray[0].trim().toLowerCase();
    const value = propArray[1].trim();

    if (name && value) {
      styles[name] = value;
    }
  }

  return styles;
};

export const serializeStyle = (styles: StyleMap) => {
  let css = "";

  for (const [key, value] of Object.entries(styles)) {
    css += `${key}:${value};`;
  }

  return css;
};
/*
    create a mock editor object, expected by plugin;
    must include style de/serializers
*/
const editor: Editor = {
  settings: {},
  dom: {
    parseStyle,
    serializeStyle,
  },
  getParam: (_key: string, defaultValue: boolean) => {
    if (_key === "paste_retain_style_properties") {
      // getParam output type is wrong, must be boolean | string
      return "all" as any;
    }

    return defaultValue;
  }
};

export const wordPasteFilter = (content: string): string => {
  if (!isWordContent(content)) {
    return content;
  }

  return preProcess(editor, content);
};
