import {AppText} from "shared/UI/UIKit/Text";
import type {ProgramTemplateBlockTableRowField} from "services/api";
import styles from "./styles.module.scss";

export const dateValueRenderer = (
  value: ProgramTemplateBlockTableRowField["value"],
  placeholder: ProgramTemplateBlockTableRowField["placeholder"],
  isWidget: boolean,
) => {
  const showValue = !!value || !isWidget;

  return (
    <AppText size={16} className={styles.text}>
      {showValue ? `${value || ""}` : placeholder}
    </AppText>
  );
};