import {useEffect, useState} from "react";
import {useIsFirstRender} from "shared/helpers/hooks/use-is-first-render";
import type {UseModalStatusOptions, ModalStatus} from "./types";
import {getInitialStatus, getStatusOnVisibilityChange} from "./utils";

const INIT_STAGE_DURATION = 0;
const ACTIVE_STAGE_DURATION = 300;

export const useModalStatus = ({
  visible,
  destroyOnClose,
  forceRender,
  onStatusChanged,
}: UseModalStatusOptions) => {
  const isFirstRender = useIsFirstRender();
  const [status, setStatus] = useState<ModalStatus>(
    () => getInitialStatus(!!visible, !!destroyOnClose, !!forceRender)
  );

  const getActualStatus = () => {
    const hasVisibilityChanged = !isFirstRender && (
      (visible && status.name !== "visible") || (!visible && status.name === "visible")
    );

    if (hasVisibilityChanged) {
      const nextStatus = getStatusOnVisibilityChange(!!visible, !!destroyOnClose);
      setStatus(nextStatus);
      return nextStatus;
    }

    return status;
  };

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    switch (status.stage) {
      case "init": {
        timeout = setTimeout(() => setStatus({name: status.name, stage: "active"}), INIT_STAGE_DURATION);
        break;
      }
      case "active": {
        timeout = setTimeout(() => setStatus({name: status.name, stage: "done"}), ACTIVE_STAGE_DURATION);
        break;
      }
      default: {
        // do nothing
      }
    }

    return () => clearTimeout(timeout);
  }, [status]);

  useEffect(() => {
    if (!isFirstRender) {
      onStatusChanged?.(status);
    }
  }, [status]);

  return getActualStatus();
};