import type {
  AppFilter,
  AppFilterCondition,
  AppFilterGroupCondition,
  AppFilterSingleCondition,
  AppFilterValue
} from "../types";
import {AppFilterDateFormat, AppFilterOperator} from "../constants";

const filterValueToConditionValue = (filterValue: AppFilterValue): AppFilterSingleCondition["value"] => {
  if (filterValue === null || filterValue === undefined) {
    return;
  }

  return String(filterValue);
};

const getDateFormat = (filter: AppFilter): Pick<AppFilterSingleCondition, "dateFormat"> => {
  return filter.type === "date"
    ? {dateFormat: filter.options?.dateFormat ?? AppFilterDateFormat.DAY}
    : {};
};

const getIsNullOperatorCondition = (filter: AppFilter): AppFilterSingleCondition => {
  return {
    property: filter.dataIndex.join("."),
    operator: AppFilterOperator.IS_NULL,
  };
};

const getIntervalOperatorCondition = (filter: AppFilter): AppFilterGroupCondition => {
  return {
    group: "AND",
    conditions: [
      {
        property: filter.dataIndex.join("."),
        operator: AppFilterOperator.MORE_OR_EQUAL,
        value: filterValueToConditionValue(filter.values?.[0]),
        ...getDateFormat(filter),
      },
      {
        property: filter.dataIndex.join("."),
        operator: AppFilterOperator.LESS_OR_EQUAL,
        value: filterValueToConditionValue(filter.values?.[1]),
        ...getDateFormat(filter),
      },
    ],
  };
};

const getEnumTypeCondition = (filter: AppFilter): AppFilterGroupCondition => {
  return {
    group: filter.operator === AppFilterOperator.NOT_EQUAL ? "AND" : "OR",
    conditions: (filter.values ?? []).map(value => {
      return {
        property: filter.dataIndex.join("."),
        operator: filter.operator!,
        value: filterValueToConditionValue(value),
      };
    }),
  };
};

const getCommonCondition = (filter: AppFilter): AppFilterSingleCondition => {
  return {
    property: filter.dataIndex.join("."),
    operator: filter.operator!,
    value: filterValueToConditionValue(filter.values?.[0]),
    ...getDateFormat(filter),
  };
};

export const getFilterConditions = (filters: AppFilter[]): AppFilterCondition[] | null => {
  if (!filters.length) {
    return null;
  }

  return filters
    .filter(filter => !!filter.operator && !!filter.values?.length)
    .map(filter => {
      if (filter.operator === AppFilterOperator.IS_NULL) {
        return getIsNullOperatorCondition(filter);
      }
      if (filter.operator === AppFilterOperator.INTERVAL) {
        return getIntervalOperatorCondition(filter);
      }
      if (filter.type === "enum") {
        return getEnumTypeCondition(filter);
      }
      return getCommonCondition(filter);
    });
};