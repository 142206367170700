import type {AppFormItemProps} from "./FormItem.types";
import {AppFormItemBase} from "./Base";
import {getClassName} from "./FormItem.utils";

export const AppFormItem = (props: AppFormItemProps) => {
  const {size = "max", labelColor = "black", className, ...rest} = props;
  const classNames = getClassName(size, labelColor, className);

  return (
    <AppFormItemBase {...rest} className={classNames}/>
  );
};
