import {combineReducers} from "@reduxjs/toolkit";
import {createMainProgramReducer} from "features/create-main-program";
import {mainProgramsReducer} from "features/main-programs";
import {mainProgramReducer} from "features/main-program";
import {workProgramsReducer} from "features/work-programs";
import {profileReducer} from "./profile";

export const reducer = combineReducers({
  profileState: profileReducer,
  createMainProgramState: createMainProgramReducer,
  mainProgramsState: mainProgramsReducer,
  mainProgramState: mainProgramReducer,
  workProgramsState: workProgramsReducer
});
