import React, {useMemo} from "react";
import {AppFormItem, formRules} from "shared/UI/UIKit/Form";
import {AppSelect} from "shared/UI/UIKit/Select";
import {useAppSelector} from "services/redux/hooks/use-selector";
import {AppIconButton} from "shared/UI/UIKit/Button";
import {AppFlex} from "shared/UI/UIKit/Flex";
import {AppEditIcon} from "shared/UI/UIKit/icons/Edit";
import type {Curriculum,MainProgramItem,Subject, WorkProgram} from "services/api";
import type {DefaultOptionType} from "rc-select/lib/Select";
import {selectWorkProgramsOptionsBySubjectId} from "../../../redux/selectors";
import styles from "./styles.module.scss";

type SubjectItemProps = {
  subject: Subject;
  curriculum: Curriculum;
  required?: boolean;
  index: number;
  editSubjectName: (e: React.MouseEvent, subject: Subject) => void;
  addMainProgramItemToStateByFieldName: (fieldName: string, item: MainProgramItem) => void;
}

export const SubjectItem = (props: SubjectItemProps) => {
  const workProgramsOptions = useAppSelector(selectWorkProgramsOptionsBySubjectId(props.subject.id));
  const fieldName = useMemo(() => {
    return `${props.curriculum.id}_${props.subject.id}_${props.subject.uniqueId}`;
  }, [props.subject]);

  const handleWorkProgramSelect = (_: number, workProgramOption: DefaultOptionType | DefaultOptionType[]) => {
    const workProgramEntity: WorkProgram = {
      id: (workProgramOption as DefaultOptionType).value as number,
      programName: (workProgramOption as DefaultOptionType).programName as string,
      obligation: props.subject.obligation
    };

    props.addMainProgramItemToStateByFieldName(fieldName, {
      curriculum: props.curriculum,
      subject: props.subject,
      workProgram: workProgramEntity,
      baseSubjectName: props.subject.baseSubjectName,
      customSubjectName: props.subject.customSubjectName,
      index: props.index,
    });
  };

  return (
    <AppFlex direction="row" unit="8" className={styles.container}>
      <AppFormItem marginless withoutExplain
                   rules={props.required ? formRules.requiredNumber : []}
                   className={styles.control}
                   label={props.subject.customSubjectName || "Название не указано"}
                   name={fieldName}>
        <AppSelect options={workProgramsOptions}
                   className={!workProgramsOptions.length ? styles.warning : undefined}
                   onChange={handleWorkProgramSelect}/>
      </AppFormItem>
      <AppIconButton type="primary" onClick={(e) => props.editSubjectName(e, props.subject)}>
        <AppEditIcon className={styles.icon}/>
      </AppIconButton>
    </AppFlex>

  );
};