export const BACK_END_DATE_FORMAT = "YYYY-MM-DD";
export const BACK_END_DATE_TIME_FORMAT = "YYYY-MM-DD HH:mm:ss.SSS";
export const BACK_END_TIME_FORMAT = "HH:mm:ss";
export const USER_DATE_FORMAT = "DD.MM.YYYY";
export const USER_DATE_TIME_FORMAT = "DD.MM.YYYY HH:mm:ss";
export const USER_DATE_TIME_FORMAT_MIN = "DD.MM.YYYY HH:mm";
export const USER_TIME_FORMAT = "HH:mm";
export const USER_YEAR_FORMAT = "YYYY";
export const DAY_MONTH_FORMAT = "dddd, D MMMM";
export const DAY_MONTH_YEAR_FORMAT = "D MMMM YYYY";
export const MONTH_FORMAT = "D MMMM";
