import moment from "moment";
import type {Moment} from "moment";
import type {Granularity} from "./date-range-validator.types";
import {errorMessages} from "../../error-messages";

export const dateRangeValidator = (
  required = true,
  granularity: Granularity = "day",
  sameValuesValid = false,
  message = "",
) => {
  return (_:any, value: [Moment | null | undefined, Moment | null | undefined]) => {
    if (!value || !value[0] || !value[1]) {
      return required ? Promise.reject(errorMessages.required) : Promise.resolve();
    }

    if (moment(value[0]).isSame(moment(value[1]), granularity)) {
      return sameValuesValid
        ? Promise.resolve()
        : Promise.reject(message || errorMessages.dateError);
    } else {
      return sameValuesValid
        ? Promise.reject(message || errorMessages.dateError)
        : Promise.resolve();
    }
  };
};